import React, { Component } from "react";
import { Select, Checkbox, Button, Icon, Tooltip } from "antd";
import GenericSelector from "./GenericSelector";
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";

const { Option } = Select;

class SelectorBonTags extends Component {
    constructor(props/*: Props*/) {
        super(props);

        this.id = 'BONIM_TAGS';
        this.api = 'get_tags_from_type_2';

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] }
    }

    static contextType = LangContext;
    getText = (id) => this.context.get(id) || '[' + id + ']';

    componentDidMount() {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.id, this.props.user.companyCode, this.props.user.token, this.api);
    }

    sizeCol = (text, record) => {
        let jsonData = this.props.value ? JSON.parse(this.props.value).data : [];

        return record.tagId ? (
            <Select style={{ width: '100%' }} value={text} onChange={v => {
                let data = jsonData.map(x => (x.tagId == record.tagId) ? { ...x, size: v } : x)
                this.props.onChange(JSON.stringify({ data }))
            }}>
                {['1', '2', '3', '4', '5', '6', '7'].map(x => (<Option value={x}>{x}</Option>))}
            </Select>)
            : ''
    }

    indentationCol = (text, record) => {
        let jsonData = this.props.value ? JSON.parse(this.props.value).data : [];

        return record.tagId ? (
            <Select style={{ width: '100%' }} value={text} onChange={v => {
                let data = jsonData.map(x => (x.tagId == record.tagId) ? { ...x, indentation: v } : x)
                this.props.onChange(JSON.stringify({ data }))
            }}>
                <Option value={0}>{this.getText(16275)}</Option>
                <Option value={1}>{this.getText(16276)}</Option>
                <Option value={2}>{this.getText(16277)}</Option>
            </Select>)
            : ''
    }

    checkCol = (text, record) => {
        let jsonData = this.props.value ? JSON.parse(this.props.value).data : [];
        let data = [];

        return [
            <Checkbox checked={text == record.code} onChange={e => {
                if (e.target.checked) {
                    data = [...jsonData, { tagId: record.code, size: 1, indentation: 0 }]
                } else {
                    data = jsonData.filter(f => f.tagId != record.code)
                }

                this.props.onChange(JSON.stringify({ data }))
            }} />,
            text == record.code ?
                <Tooltip title={this.getText(20575)}>
                    <Icon type="copy" style={{ marginRight: 5 }} onClick={() => {
                        let fullList = this.props.data[this.id].dataset;

                        this.props.onChange(JSON.stringify({
                            data: fullList.map(x => {
                                return { ...x, tagId: x.code, size: record.size, indentation: record.indentation }
                            })
                        }))
                    }} />
                </Tooltip>
                : ""

        ]
    }

    render() {
        let jsonData = this.props.value ? JSON.parse(this.props.value).data : [];
        let fullList = this.props.data[this.id].dataset;

        let tableData = fullList.map((x) => {
            let y = jsonData.find(f => f.tagId == x.code);
            return y ? { ...x, ...y } : x
        });

        let columns = [
            { key: 'code', dataIndex: 'code', title: this.getText(16270), width: '10%', render: t => t },
            { key: 'name', dataIndex: 'name', title: this.getText(16271), width: '30%', render: t => t },
            { key: 'size', dataIndex: 'size', title: this.getText(16272), width: '20%', render: this.sizeCol },
            { key: 'indentation', dataIndex: 'indentation', title: this.getText(16273), width: '30%', render: this.indentationCol },
            { key: 'tagId', dataIndex: 'tagId', title: this.getText(16274), width: '10%', render: this.checkCol },
        ];

        return (<GenericModal
            visible={this.props.visible}
            onCancel={this.props.onCancel}
            title={this.props.title}
            width={800}
            footer={[
                <Button key="back" onClick={this.props.onCancel}>{this.getText(12664)}</Button>,
                <Button key="clear" onClick={() => { this.props.onChange(null) }}>{this.getText(12665)}</Button>,
                <Button key="create" type="primary" onClick={this.props.onOk}>{this.getText(12666)}</Button>,
            ]}
        >
            {this.props.beforeTable}
            <ResponsiveTable
                dataSource={tableData}
                columns={columns}
                tableOnly
                scroll={{ y: window.innerHeight / 2 }}
                pagination={false}
            />
        </GenericModal>)
    }
}

export default SelectorBonTags;