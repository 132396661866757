/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Tooltip, Icon, Card, Row, Col } from "antd";
import moment from "moment";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";

class DocTypesSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            LastTimeRefreshe: null,
            mobileTypeSelect: 1,
        }

        this.id = "DOC_TYPES_DATA";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos
        ) {
            this.getDataDocTypes()
        }
    }

    getDataDocTypes = () => {
        let request = {
            dashboardDate: this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null,
            dashboardPos: this.props.dashboardPos,
        };

        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_doc_types");
        this.setState({ LastTimeRefreshe: moment().format('HH:mm:ss'/*'LTS'*/) });
    }

    createSubTitle = (id, text) => {
        return (<Col span={8}>
            <h3
                style={{
                    textAlign: 'center',
                    background: this.state.mobileTypeSelect == id ? "lightblue" : "white",
                    paddingBottom: 3
                }}
                onClick={() => { this.setState({ mobileTypeSelect: id }) }}>
                {this.getText(text)}
            </h3>
        </Col>)
    }

    calculateSum = (dataSource) => {
        let totalSum = 0;
        let totalCount = 0;

        dataSource.forEach(x => {
            let dSum = parseFloat(x.dSum);
            let dCount = parseFloat(x.dCount);
            if (dSum) totalSum += dSum;
            if (dCount) totalCount += dCount;
        });

        return {
            dType: 'total',
            dTypeName: this.getText(17099),
            dSum: Math.round(totalSum * 100) / 100,
            dCount: Math.round(totalCount * 100) / 100,
        }
    }

    createContent = () => {
        let dataObj = this.props.data[this.id] ?
            this.props.data[this.id].data.map(x => {
                let y = x.split("\f");
                return {
                    dType: y[0],
                    dTypeName: y[1],
                    dCount: y[2],
                    dSum: y[3],
                    dIsSales: y[4],
                    dIsPayments: y[5]
                }
            })
            : [];

        let dataSource1 = dataObj.filter(f => f.dIsSales == '1')
        let dataSource2 = dataObj.filter(f => f.dIsPayments == '1')
        let dataSource3 = dataObj.filter(f => f.dIsSales != '1' && f.dIsPayments != '1');

        dataSource1.push(this.calculateSum(dataSource1));
        dataSource2.push(this.calculateSum(dataSource2));
        dataSource3.push(this.calculateSum(dataSource3));


        if (window.innerWidth <= 600) {
            let title = (<Row>
                {this.createSubTitle(1, 17090)}
                {this.createSubTitle(2, 17091)}
                {this.createSubTitle(3, 17092)}
            </Row>)

            switch (this.state.mobileTypeSelect) {
                case 1: return this.createTableCard(dataSource1, title);
                case 2: return this.createTableCard(dataSource2, title);
                case 3: return this.createTableCard(dataSource3, title);
            }
        } else {
            return (<Row>
                <Col span={8}>{this.createTableCard(dataSource1, <h3 style={{ textAlign: 'center' }}>{this.getText(17090)}</h3>)}</Col>
                <Col span={8}>{this.createTableCard(dataSource2, <h3 style={{ textAlign: 'center' }}>{this.getText(17091)}</h3>)}</Col>
                <Col span={8}>{this.createTableCard(dataSource3, <h3 style={{ textAlign: 'center' }}>{this.getText(17092)}</h3>)}</Col>
            </Row>)
        }
    }

    createTableCard = (dataSource, title) => {
        let gt = this.getText;
        let render = (text, record, field) => {
            let val = field == 'dSum' ? text ? parseFloat(text).toFixed(2) : '0.00' : text
            return record.dType === 'total' ? <b>{val}</b> : val;
        }

        return (<Card title={title} style={{ padding: 0, height: 200, overflowY: 'scroll' }}>
            <ResponsiveTable
                tableOnly
                dataSource={dataSource}
                columns={[
                    { title: gt(17094), dataIndex: 'dTypeName', key: 'dTypeName', render },
                    { title: gt(17095), dataIndex: 'dCount', key: 'dCount', render },
                    { title: gt(17096), dataIndex: 'dSum', key: 'dSum', render: (t, r) => render(t, r, 'dSum') },
                ]}
                pagination={false}
            />
        </Card>)
    }

    render() {


        return (<div style={{ marginBottom: "24px" }}>
            <Card
                title={<div>
                    <span>{this.getText(17089)}</span>
                    <span style={{ float: "left" }}>
                        <span style={{ marginLeft: 4 }}>{this.state.LastTimeRefreshe}</span>
                        <Button className={"btn"}
                            disabled={this.props.ui.workInProgress}
                            onClick={this.getDataDocTypes}>
                            <Icon type={"reload"} style={{ fontSize: 16, padding: 4 }} />
                        </Button>
                    </span>
                </div>}
            >{this.createContent()}</Card>
        </div>)
    }
}
export default DocTypesSection;
