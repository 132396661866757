/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import { Icon, Popconfirm, message, Tooltip, Modal, Button, Form, Checkbox } from "antd";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableIDs from "../../data/TableIDs";
import ModalCreateEditFoods from "../../components/ModalCreateEditFoods";
import ModalCreateEditFoodLevels from "../../components/ModalCreateEditFoodLevels";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericSelector from '../../components/GenericSelector';
import PageToolbar from '../../components/PageToolbar';
import ModalCreateEditItem from '../../components/ModalCreateEditItem';
import SelectorBonTags from "../../components/SelectorBonTags";
import { escapeRegExp } from "../../utils/utils";

const FormItem = Form.Item;
const topHeight = 300; // px
const rowHeight = 40;  // px

class FoodsTable extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            modalFilter: false,
            selectorBonTags: null,
            selectorBonTagsData: null,
            selectorBonTagsName: null,

            filterActive: false,
            valueSearch: '',

            multiFoodDishToBonTags: "",

        };

        this.doOnResize = null;

        this.id = "FOODS_TABLE";

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        if (props.data[TableIDs.genericSelectorTagsToSite] === undefined) {
            props.data[TableIDs.genericSelectorTagsToSite] = { ...props.data.genericSelector };
        }

        if (props.data["FOOD_DISH_LIST"] === undefined) {
            props.data["FOOD_DISH_LIST"] = { ...props.data.genericSelector };
        }

        this.api = {
            get: "get_foods_table",
            delete: "delete_foods_table",
            copy: "copy_foods_table",
            edit_bons: "edit_food_dish_bon_tags",
        }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(12643);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let request = {
            _id: this.props.itemCode,
            // ui
            page: 1,
            recordsPerPage: /*5,*/numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: /*5,*/numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',
            _tagList: ''
        };

        this.setFilter(request);

        this.setState({ filterActive: false, valueSearch: "" })

    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 7;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "fID": col--;
            case "fName": col--;
            case "fRemarks": col--;
            case "fItemCode": col--;
            case "fItemName": col--;
            case "fRemarkRequierd": col--;
            case "fColumns": break; // 7
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.setFilter(request)
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    sendAPI = (script, data, sCallback, eCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, eCallback);
    }

    tooltipIcon = (icon, tooltip, onClick) => {
        return (<Tooltip title={this.getText(tooltip)}>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={icon} onClick={onClick} />
        </Tooltip>)
    }

    saveBonTags = () => {
        const { selectorBonTags, selectorBonTagsData, multiFoodDishToBonTags } = this.state;
        let dataSend = 'fID\ffBonTags\r'
        let addRowData = '\f' + (selectorBonTagsData ? selectorBonTagsData : '')
        if (selectorBonTags == "multi") {
            dataSend += multiFoodDishToBonTags.split(",").join(addRowData + "\r") + addRowData
        } else {
            dataSend += selectorBonTags + addRowData;
        }

        this.sendAPI(this.api.edit_bons, dataSend, () => {
            this.setState({
                selectorBonTags: null,
                selectorBonTagsData: null,
                selectorBonTagsName: null,
                multiFoodDishToBonTags: ""
            }, () => {
                message.success(this.getText(16280))
                this.refreshData()
            })
        }, e => { console.error(e) })
    }

    renderEditCol = (text, record) => {
        return (<span>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index);
                this.props.uiActions.showModal(TableIDs.modalCreateEditFoods, { fID: record.key });
            }} />
            <Popconfirm title={this.getText(12650)}
                onConfirm={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id,
                        record['fID'],
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.delete,
                        this.api.get);
                }}
                okText={this.getText(12651)} cancelText={this.getText(12652)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
            </Popconfirm>
            {this.tooltipIcon('copy', 12731, () => {
                this.sendAPI(this.api.copy, "fID\r" + record['fID'], () => {
                    message.success(this.getText(12730));
                    this.refreshData();
                }, (e) => {
                    message.error(e)
                })
            })}
            {this.tooltipIcon('table', 12663, () => {
                this.props.uiActions.showModal(TableIDs.modalCreateEditFoodLevels, { fID: record.key, fName: record.fName });
            })}
            {this.tooltipIcon('block', 16267, () => {
                this.setState({ selectorBonTags: record.key, selectorBonTagsData: record.fBonTags, selectorBonTagsName: `${this.getText(20672)} ${record.fName}` });
            })}

        </span >)
    }

    render() {

        let gt = this.getText;
        let render = text => text;
        let renderCB = t => (<Checkbox checked={t == 1} disabled />)
        let sorter = true;

        let columns = [
            { title: gt(12644), dataIndex: "fID", key: "fID", width: '5%', sorter, render },
            { title: gt(12645), dataIndex: "fName", key: "fName", width: '14%', sorter, render },
            { title: gt(12646), dataIndex: "fRemarks", key: "fRemarks", width: '30%', sorter, render },
            { title: gt(12647), dataIndex: "fItemCode", key: "fItemCode", width: '10%', sorter, render },
            { title: gt(12648), dataIndex: "fItemName", key: "fItemName", width: '14%', sorter, render },
            { title: gt(16337), dataIndex: "fRemarkRequierd", key: "fRemarkRequierd", width: '7%', sorter, render: renderCB },
            { title: gt(20467), dataIndex: "fColumns", key: "fColumns", width: '8%', sorter, render },
            { title: gt(12649), width: '12%', render: this.renderEditCol },
        ];

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        let dFilter = this.props.data[this.id].filters;

        return (<div style={divStyle}>
            <ResponsiveTable
                idTable={this.props.data[this.id]}
                columns={columns}
                percentageToMobile={[20, 80]}
                title={this.getText(12643)}
                actionButtons={[
                    {
                        buttonType: 'filter',
                        buttonFunction: () => {
                            this.setState({ modalFilter: true })
                        }
                    }, {
                        buttonDisabled: !this.state.filterActive,
                        buttonType: 'clear',
                        buttonFunction: this.initFilters,
                    }, {
                        buttonType: 'add',
                        buttonFunction: () => {
                            this.props.uiActions.showModal(TableIDs.modalCreateEditFoods, { fID: 0 });
                            this.props.dataActions.genericDataStartInlineCreate(this.id);
                        }
                    }, {
                        buttonType: 'refresh',
                        buttonFunction: this.refreshData
                    },
                ]}
                genericActionButtons={[
                    <Tooltip title={this.getText(20698)}>
                        <Button className={"btn"} style={{ marginTop: 5, marginLeft: 5 }} onClick={() => {
                            this.setState({ selectorBonTags: "multi", selectorBonTagsData: "", selectorBonTagsName: this.getText(20698) });
                        }}><Icon type={"block"} style={{ fontSize: 16 }} /></Button>
                    </Tooltip>
                ]}
                showsearch={(val) => {
                    if (val) { this.setState({ filterActive: true }) }
                    this.setFilter({
                        ...dFilter,
                        _words: escapeRegExp(val),
                        // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
                        page: 1,
                        _page_num: 1,
                    })
                }}
                valueSearch={this.state.valueSearch}
                onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                onChange={this.handleTableChange}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
            />
            <Modal
                destroyOnClose={true}
                width={window.innerWidth <= 600 ? '95%' : "50%"}
                visible={this.state.modalFilter}
                title={(<div style={{ padding: 4 }}>
                    <PageToolbar
                        title={this.getText(14098)} actionButtons={[]} isModal={true}
                        ui={this.props.ui} uiActions={this.props.uiActions}
                        data={this.props.data} dataActions={this.props.dataActions}
                    />
                </div>)}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataAction={this.props.dataAction}
                footer={false}>
                <FormItem label={this.getText(14100)}>
                    <GenericSelector
                        id={TableIDs.genericSelectorTagsToSite}
                        api={"get_tags_to_site"}
                        filters={{ tParnetId: -1 }} // width all parnets
                        multi={true}
                        value={dFilter._tagList ? dFilter._tagList.split(',') : []}
                        onChange={(data) => {
                            this.setFilter({ ...dFilter, _tagList: data ? data.join(',') : "" })
                            if (data && data.length) { this.setState({ filterActive: true }) }
                        }}
                        user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                    />
                </FormItem>
            </Modal>
            <ModalCreateEditFoods
                id={TableIDs.modalCreateEditFoods}
                main_id={this.id}
                user={this.props.user}
                ActionQueue={this.props.ActionQueue}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditFoods)}
            />
            <ModalCreateEditFoodLevels
                id={TableIDs.modalCreateEditFoodLevels}
                main_id={this.id}
                user={this.props.user}
                ActionQueue={this.props.ActionQueue}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditFoodLevels)}
            />
            <ModalCreateEditItem
                id={TableIDs.modalCreateEditItem}
                // context={"ITEMS_TABLE"} context_api_get={"get_items_list"}
                user={this.props.user}
                uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditItem)}
                title={this.getText(10241)}
            />
            <SelectorBonTags
                {...this.props}
                title={this.state.selectorBonTagsName}
                visible={this.state.selectorBonTags}
                onCancel={() => { this.setState({ selectorBonTags: null, selectorBonTagsData: null, selectorBonTagsName: null }) }}
                onOk={this.saveBonTags}
                value={this.state.selectorBonTagsData}
                onChange={(v) => { this.setState({ selectorBonTagsData: v }) }}
                beforeTable={this.state.selectorBonTags == "multi" ? [
                    <GenericSelector {...this.props} multi
                        id={"FOOD_DISH_LIST"}
                        api={"food_dish_list"}
                        value={this.state.multiFoodDishToBonTags ? this.state.multiFoodDishToBonTags.split(",") : []}
                        onChange={e => { this.setState({ multiFoodDishToBonTags: e ? e.join(",") : "" }) }}
                    />
                ] : ""}
            />
        </div>);
    }
}

export default FoodsTable;