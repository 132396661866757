
import React from "react";
import { Card, DatePicker, Form, InputNumber, Input, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message, Upload } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import GenericSelector from "./GenericSelector";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import SelectorSalesAlerts from "./SelectorSalesAlerts";
import SelectorTaxType from "./SelectorTaxType";
import InputNumeric from "./InputNumeric";
//import ItemPicker from "./ItemPicker";
import moment from "moment";
import LangContext from "../contextProvider/LangContext";
import CustPicker from "./CustPicker";


const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

// const codeInvalid = () => {
//     message.error('קוד הפריט כבר קיים במסד הנתונים');
// };
// const codeValid = () => {
//     message.success('קוד פריט פנוי');
// };
// const codeExists = () => {
//     message.warning('קוד פריט כבר קיים במסד הנתונים אך בסטטוס לא פעיל');
// };

//type State = {}
class modalCreateEditBranch extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            loadingImg: false,
        }

        this.id = 'CREATE_EDIT_BRANCH';
        this.franchise_id = 'FRANCHISEES_TABLE';
        this.kosher_id = 'KOSHER_TYPES';

        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        //this.state.itemPick = null;
        //this.fieldsMeta = { unknown: { validateStatus: null, value: null } };

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[this.franchise_id] === undefined) { props.data[this.franchise_id] = { ...props.data.genericData }; }

        if (props.data["LIST_POS_PER_BRANCH"] === undefined) props.data["LIST_POS_PER_BRANCH"] = { ...props.data.genericSelector };
        if (props.data[this.kosher_id] === undefined) props.data[this.kosher_id] = { ...props.data.genericSelector };
        if (props.data['AREAS_LIST'] === undefined) props.data['AREAS_LIST'] = { ...props.data.genericSelector };


        this.api = {
            get: "get_branch_by_id",

            edit: "edit_create_branch",
            selector: "get_franchisees_table",
            // selector: "list_pos",
            //delete: "delete_branch",
        }

        this.actionButtons = [
            /*{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/];

        this.dataSource = [];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData();
        // document.title = "Cash On Tab - סניפים";
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleOkLeave = () => {
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    handleOk = () => {
        //this.clear();
    }
    handleCancel = () => {
        //this.setState({ visible: false, itemPick: null });
        //this.props.data[this.id].data = [];
        //this.clear();
        this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
    }
    handleClear = () => {
        //this.clear();
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.franchise_id, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    handleSaveChanges = (e: Event) => {
        e.preventDefault();

        if (this.props.data[this.id].editedRecord['mFranchiseID']) {
            //this.validateFieldsAndScroll((err, values) => {if(!err) { console.log('xxx: ', values)}});
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericDataJustSaveEdit,
                args: [this.id, this.props.user.companyCode, this.props.user.token, this.api.edit]
            })
            // update main table after change
            setTimeout(() => {
                if (this.props.onSave) {
                    this.props.onSave()
                } else {
                    this.props.ActionQueue.addToQueue({
                        action: this.props.dataActions.genericDataGetRequested,
                        args: ["BRANCHES_MAIN", this.props.user.companyCode, this.props.user.token, "get_branches_list_table"]
                    });
                }
                this.props.uiActions.hideModal(TableIDs.modalCreateEditBranch);
            }, 500);
        } else {
            message.error(this.getText(18074))
        }
    }

    render() {
        const { loading, loadingImg } = this.state;
        //const sqlFormat = "YYYY-MM-DD";
        //const timeFormat = "HH:mm:ss";
        //const dateFormat = "DD/MM/YYYY";

        let request = { _id: 0, };
        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={this.actionButtons}
                    //showsearch={(val) => {
                    //    this.props.dataActions.genericDataSetFilter(
                    //        this.id, this.props.user.companyCode, this.props.user.token,
                    //        { ...request, _words: val }, this.api.get)
                    //}}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)

        const list = this.props.data[this.franchise_id].data.map((x) => {
            let y = x.split('\f');
            return (<Option key={y[0]} value={y[0]} valuefilter={y[0] + y[1]}>{y[1]}</Option>)
        })

        // const list = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj, index) => {
        //     return (<Option key={obj['code']} value={obj['code']}>{obj['name']}</Option>)
        // });

        return (
            <Modal
                //style={modalStyle}
                // title={this.props.title} // pass title in props for generics
                destroyOnClose={true} width={'80%'} visible={false || this.props.toggle} title={titleBar}
                onOk={this.handleOk} onCancel={this.handleCancel}
                /*footer={[
                    <Button key="back"
                        onClick={this.handleCancel}>ביטול</Button>,
                    <Button key="clear"
                        loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                    <Button key="create" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    <Button key="createLeave" disabled={!this.state.isValidItemCode}
                        type="primary" loading={loading} onClick={this.handleOkLeave}>שמור וצא</Button>,
                ]}*/
                footer={false}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}>

                <Form id="create_edit_branch_form" className="data-entry-form" layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: '3' }}
                    onSubmit={this.handleSaveChanges}>

                    <FormItem
                        key={'mName'} label={this.getText(11475)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mName']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mName']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mAddress'} label={this.getText(11476)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mAddress']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mAddress']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem
                        key={'mPhones'} label={this.getText(11477)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mPhones']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mPhones']: value.target.value });
                            }
                            } />
                    </FormItem>
                    <FormItem key={'mIsActive'} >
                        <Checkbox
                            checked={this.props.data[this.id].editedRecord['mIsActive'] == '1'}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mIsActive']: value.target.checked ? "1" : "0" });
                            }}>{this.getText(18797)}</Checkbox>
                    </FormItem>
                    <FormItem
                        key={'mActivityTime'} label={this.getText(11478)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <TextArea
                            value={this.props.data[this.id].editedRecord['mActivityTime']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mActivityTime']: value.target.value });
                            }}
                            autosize={{ minRows: 5, maxRows: 5 }}
                        />
                    </FormItem>
                    <FormItem
                        key={'mAltitude'} label={this.getText(11479)} style={{ marginBottom: 8/*, textAlign: 'unset'*/ }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <InputNumber
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            min={-90} max={90} precision={3} step={0.1}
                            value={this.props.data[this.id].editedRecord['mAltitude']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mAltitude']: value });
                            }}
                        />
                    </FormItem>
                    <FormItem
                        key={'mLongitude'} label={this.getText(11480)} style={{ marginBottom: 8/*, textAlign: 'unset'*/ }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <InputNumber
                            style={{ width: '80%', direction: "ltr" /*,textAlign: 'unset'*/, textAlign: 'right' }}
                            min={-180} max={180} precision={3} step={0.1}
                            value={this.props.data[this.id].editedRecord['mLongitude']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mLongitude']: value });
                            }} />
                    </FormItem>
                    {this.props.staticFranchise ? "" :
                        <FormItem
                            key={'mFranchiseID'} label={this.getText(11481)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                        //validateStatus={}
                        >
                            <Select
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                value={this.props.data[this.id].editedRecord['mFranchiseID']}
                                defaultValue={'0'}
                                style={{ width: '90%', paddingLeft: '4px' }}
                                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mFranchiseID']: e }) }}
                            >
                                {list}
                            </Select>
                        </FormItem>}
                    <FormItem
                        key={'mOrdersPos'} label={this.getText(14315)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                    //validateStatus={}
                    >
                        <GenericSelector
                            id={"LIST_POS_PER_BRANCH"}
                            value={this.props.data[this.id].editedRecord['mOrdersPos']}
                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mOrdersPos']: e }) }}
                            user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                        />
                    </FormItem>
                    <FormItem
                        key={'mKosherId'} label={this.getText(15576)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                    //validateStatus={}
                    >
                        <GenericSelector
                            id={this.kosher_id} api={"kosher_type_list"}
                            value={this.props.data[this.id].editedRecord['mKosherId']}
                            onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { ['mKosherId']: e }) }}
                            user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                        />
                    </FormItem>
                    <FormItem
                        key={'mEDI'} label={this.getText(17254)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    //validateStatus={}
                    >
                        <Input
                            value={this.props.data[this.id].editedRecord['mEDI']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mEDI']: value.target.value });
                            }} />
                    </FormItem>
                    <FormItem
                        key={'mCustomerId'} label={this.getText(19150)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}
                    >
                        <CustPicker
                            {...this.props}
                            value={this.props.data[this.id].editedRecord['mCustomerId']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mCustomerId']: value });
                            }}
                            defaultView={this.getText(12121)}
                        />
                    </FormItem>
                    <FormItem
                        key={'mAreaId'} label={this.getText(20585)} style={{ marginBottom: 8 }} help={''} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                    >
                        <GenericSelector
                            {...this.props}
                            id={'AREAS_LIST'}
                            api={'areas_list'}
                            value={this.props.data[this.id].editedRecord['mAreaId']}
                            onChange={(value) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { ['mAreaId']: value });
                            }}
                        />
                    </FormItem>
                </Form>
                <div className="action_footer">
                    <Button
                        type="primary" htmlType="submit" form="create_edit_branch_form"
                        loading={this.props.ui.workInProgress}
                        className={"form-button-save"} icon={"check"}>{this.getText(11482)}</Button>

                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toggle === false && this.props.toggle === true) {
            this.props.dataActions.genericDataGetEditWithParams(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                { _id: this.props.ui.data },
                //{ _id: (id_data) ? id_data : this.props.ui.data },
                this.api.get
            );

            this.props.dataActions.genericSelectorRefreshDataset(
                "LIST_POS_PER_BRANCH", this.props.user.companyCode, this.props.user.token, "list_pos_per_branch", { _branch: this.props.ui.data })

            if (this.props.staticFranchise) {
                setTimeout(() => {
                    this.props.dataActions.genericDataSetEdit(this.id, { ['mFranchiseID']: this.props.staticFranchise })
                }, 1000);
            }

        }
    }
}

export default modalCreateEditBranch;
