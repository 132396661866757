/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Input, Form, Button, Tooltip, Icon, Row, Col, Checkbox, message } from 'antd';
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import TableLineIcons from "../../components/TableLineIcons";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableIDs from '../../data/TableIDs.js';
import { fixCurrentPage } from "../../utils/utils";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class UpdateParameters extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);

        this.state = {
            filters: {
                _groupID: '0',
                _setByUser: 1,
                _langID: 2,
            },
            modalCreateEdit: null,
            modalGroups: false,
            editGroup: null,
            loading: false,
            paramView: null,
        }

        this.id = "GENERAL_PARAMETERS_TABLE";
        this.pos_id = "POS_PARAMETERS_TABLE";

        this.selectors = [
            { id: "GENERAL_PARAMETERS_GROUPS_LIST", api: "general_parameters_groups_list" },
            { id: "VALIDATION_METHODS_LIST", api: "validation_methods_list" },
        ]

        this.api = {
            get: "get_general_parameters_table",
            create_edit: "create_edit_general_parameters",

            get_pos: "get_pos_parameters_table",
            edit_pos: "edit_pos_parameters_table",

            save_group: "general_parameters_groups_create_edit"
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.pos_id] === undefined) props.data[this.pos_id] = { ...props.data.genericData };
        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

        this.actionButtons = [{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        }]
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach(x => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    componentDidUpdate(p) {
        document.title = "Cash On Tab - " + this.getText(14675);

        let _langID = parseInt(this.getText(100));
        let adminNotDevModule = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset ?
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '503')
            : false;
        let _setByUser = this.props.admin || adminNotDevModule ? 0 : 1;


        if ((_langID && _langID != this.state.filters._langID) || (_setByUser != this.state.filters._setByUser)) {
            this.setState({ filters: { ...this.state.filters, _langID, _setByUser } }, () => {
                this.props.dataActions.genericDataSetFilter(
                    this.id, this.props.user.companyCode, this.props.user.token, { _langID, _setByUser }, this.api.get);
            })
        }

        if (p.data[this.id].data !== this.props.data[this.id].data) {
            let row1 = this.props.data[this.id].data[0];
            let key = row1 ? row1.split('\f')[0] : null;
            this.apiGetPosParameters({ key });
        }

        let tableRows = document.getElementsByClassName('ant-table-row-level-0');
        if (tableRows && window.innerWidth > 600) {
            [...tableRows].forEach(x => {
                let v = x.attributes[1].value;
                let f = this.props.data[this.pos_id].filters;
                if (v === f._param) {
                    x.style.backgroundColor = '#ddffff'
                } else {
                    x.style.backgroundColor = 'white'
                }
            })
        }
    }

    handleCreateNew = () => {
        this.setState({
            modalCreateEdit: {
                key: '',
                rVariableName: '',
                rInternalName: '',
                rHelpDescription: '',
                rDefaultValue: '',
                rCanBeSetByUser: '0',
                rIsActive: '1',
                rValidationMethod: '1',
                rDicName: '',
                rDicDesc: '',
                _groupID: this.state.filters._groupID,
                _langID: this.state.filters._langID,

            }
        });
    }

    editCol = (text, record) => {
        let e = this.props.data[this.id].editing;
        if (e === record.key) {
            return (<TableLineIcons
                onCheck={() => { }}
                onClose={() => { this.props.dataActions[e == 0 ? 'genericDataCancelInlineCreate' : 'genericDataCancelEditing'](this.id) }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
            />)
        }
    }

    getInput = (label, field) => {
        let s = this.state.modalCreateEdit;
        if (s) {
            let res = '';
            let value = s[field];
            let onChange = (e) => { this.setState({ modalCreateEdit: { ...s, [field]: e } }) }
            switch (field) {
                case 'rValidationMethod':
                    res = this.getSelector(1, { value, onChange, nameText: false });
                    break;
                case '_groupID':
                    res = this.getSelector(0, { value, onChange, nameText: this.getText });
                    break;
                case 'rHelpDescription':
                    res = (<Input.TextArea value={value} onChange={(e) => { onChange(e.target.value) }} rows={5} />);
                    break;
                default:
                    res = (<Input value={value} onChange={(e) => { onChange(e.target.value) }} />);
            }
            return (<Form.Item label={this.getText(label)}>{res}</Form.Item>)
        } else {
            return ''
        }
    }

    getCheckbox = (label, field) => {
        let s = this.state.modalCreateEdit;
        return s ? (<div style={{ paddingBottom: 5 }}>
            <Checkbox
                checked={s[field] == '1'}
                onChange={(e) => { this.setState({ modalCreateEdit: { ...s, [field]: e.target.checked ? '1' : '0' } }) }}
            >{this.getText(label)}</Checkbox>
        </div>) : ''
    }

    getEditIcons = (text, record) => {
        let active = record.rIsActive == '1';
        let morePrm = {
            // _groupID: this.state.filters._groupID,
            _langID: this.state.filters._langID,
        }
        return (<TableLineIcons
            onEdit={() => { this.setState({ modalCreateEdit: { ...record, ...morePrm } }) }}
            genericIcons={[
                {
                    type: active ? 'close-circle' : 'check-circle',
                    onClick: () => { this.saveParameter({ ...record, ...morePrm, rIsActive: active ? '0' : '1' }) },
                    tooltip: this.getText(active ? 15190 : 15191),
                }
            ]} />)
    }

    getSelector = (index, props, addFirstRow) => {
        const { id } = this.selectors[index]
        return (<GenericSelector {...this.props} {...props} {...{ id, addFirstRow }} />)
    }

    saveParameter = (data) => {
        this.props.dataActions.genericDataSaveEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, data);
    }

    validDefault = (validMethod, defaultValue) => {
        let valid = true;
        switch (validMethod) {
            case '2': case '4': valid = defaultValue == parseInt(defaultValue); break;
            case '3': case '5': valid = defaultValue == parseFloat(defaultValue); break;
            case '9': valid = parseFloat(defaultValue) > 0; break;
            case '11': valid = defaultValue == '0' || defaultValue == '1'; break;
        }
        return valid;
    }

    handleOk = () => {
        const { rValidationMethod, rDefaultValue } = this.state.modalCreateEdit;

        if (this.validDefault(rValidationMethod, rDefaultValue)) {
            this.saveParameter(this.state.modalCreateEdit);
            this.handleCancel();
        } else {
            message.error(this.getText(15208))
        }
    }

    handleCancel = () => {
        this.setState({ modalCreateEdit: null });
    }

    apiGetPosParameters = (record) => {
        this.props.dataActions.genericDataSetFilter(
            this.pos_id, this.props.user.companyCode, this.props.user.token, { _param: record.key }, this.api.get_pos);
    }

    apiSavePosParameters = (params) => {
        this.props.dataActions.genericDataSaveEditWithParams(
            this.pos_id, this.props.user.companyCode, this.props.user.token, this.api.edit_pos, this.api.get_pos, params);
    }

    posParamEditIcon = (text, record) => {
        let e = this.props.data[this.pos_id].editing;
        let er = this.props.data[this.pos_id].editedRecord;
        let param = this.props.data[this.pos_id].filters._param;

        let props = (record.key !== e) ? {
            onEdit: () => { this.props.dataActions.genericDataStartEditing(this.pos_id, record.key, record.index) },
            genericIcons: [{
                type: 'copy',
                onClick: () => {
                    let vm = param ? this.props.data[this.id].data.map((x) => x.split('\f')).find(f => f[0] === param)[6] : '1';
                    let validDefault = this.validDefault(vm, record.pValue);
                    if (validDefault) {
                        this.apiSavePosParameters({ _param: param, _all: 1, ...record });
                    } else {
                        message.error(this.getText(15208));
                    }
                },
                tooltip: this.getText(15204),
                popconfirm: { title: this.getText(15205), okText: this.getText(15206), cancelText: this.getText(15207) },
            }]
        } : {
            onCheck: () => {
                let vm = param ? this.props.data[this.id].data.map((x) => x.split('\f')).find(f => f[0] === param)[6] : '1';
                let validDefault = this.validDefault(vm, er.pValue);
                if (validDefault) {
                    this.apiSavePosParameters({ _param: param, _all: 0 })
                } else {
                    message.error(this.getText(15208));
                }
            },
            onClose: () => { this.props.dataActions.genericDataCancelEditing(this.pos_id) }
        }

        return (<TableLineIcons {...props} />)
    }

    refrashGroupSelect = () => {
        let config = this.selectors[0]
        this.props.dataActions.genericSelectorRefreshDataset(
            config.id, this.props.user.companyCode, this.props.user.token, config.api);
    }

    createNewGroup = () => {
        if (!this.state.editGroup) {
            let id = this.selectors[0].id;
            let newRow = { code: '', name: '' }
            this.setState({ editGroup: newRow }, () => {
                this.props.dataActions.setJson(id, {
                    dataset: [newRow, ...this.props.data[id].dataset]
                });
            })
        }
    }

    groupEditIcon = (text, record) => {
        let props = { onEdit: () => { } }
        let eg = this.state.editGroup;
        if (!eg) {
            props = { onEdit: () => { this.setState({ editGroup: { code: record.code, name: this.getText(parseInt(record.name)) } }) } }
        } else if (eg.code === record.code) {
            props = {
                onCheck: () => {
                    this.sendAPI(this.api.save_group, "gID\fgName\f_langID\r" + eg.code + "\f" + eg.name + "\f" + this.getText(100), () => {
                        this.setState({ editGroup: null }, this.refrashGroupSelect);
                    }, (e) => { console.error(e) })
                },
                onClose: () => {
                    this.setState({ editGroup: null }, () => {
                        let id = this.selectors[0].id;
                        this.props.dataActions.setJson(id, {
                            dataset: this.props.data[id].dataset.filter(f => f.code)
                        });
                    })
                }
            }
        }
        return (<TableLineIcons {...props} />)
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    render() {
        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let toolBarStyle = {
            direction: "rtl",
            backgroundColor: "#001529",
            //backgroundColor: "#1890ff",
            marginBottom: '5px',
            height: '100px',
            clear: 'both',
            width: '100%'
        }

        let titleStyle = {
            margin: '5px',
            color: 'white',
            fontSize: 'large',
        }

        let gt = this.getText;
        let rt = (t) => t;
        let cb = (t) => (<Checkbox disabled checked={t == '1'} />)
        let vm = (t) => this.getSelector(1, { disabled: true, value: t, onChange: () => { } })

        let columns = this.props.admin ?
            [
                { title: gt(14677), key: 'rVariableName', dataIndex: 'rVariableName', width: '10%', sorter: true, render: rt },
                { title: gt(14678), key: 'rInternalName', dataIndex: 'rInternalName', width: '15%', sorter: true, render: rt },
                { title: gt(14679), key: 'rHelpDescription', dataIndex: 'rHelpDescription', width: '30%', sorter: true, render: rt },
                { title: gt(14680), key: 'rDefaultValue', dataIndex: 'rDefaultValue', width: '12%', sorter: true, render: rt },
                { title: gt(14681), key: 'rCanBeSetByUser', dataIndex: 'rCanBeSetByUser', width: '7%', sorter: true, render: cb },
                { title: gt(14682), key: 'rIsActive', dataIndex: 'rIsActive', width: '7%', sorter: true, render: cb },
                { title: gt(14683), key: 'rValidationMethod', dataIndex: 'rValidationMethod', width: '12%', sorter: true, render: vm },
                { title: gt(14684), width: '7%', render: this.getEditIcons },
            ] : [
                { title: gt(14677), key: 'rVariableName', dataIndex: 'rVariableName', width: '15%', sorter: true, render: rt },
                { title: gt(14678), key: 'rInternalName', dataIndex: 'rInternalName', width: '15%', sorter: true, render: rt },
                { title: gt(14679), key: 'rHelpDescription', dataIndex: 'rHelpDescription', width: '40%', sorter: true, render: rt },
                { title: gt(14680), key: 'rDefaultValue', dataIndex: 'rDefaultValue', width: '15%', sorter: true, render: rt },
                { title: gt(14683), key: 'rValidationMethod', dataIndex: 'rValidationMethod', width: '15%', sorter: true, render: vm },
            ];

        let posParamValue = (t, r) => {
            let d = this.props.data[this.pos_id];
            return (r.key !== d.editing) ? t
                : (<Input value={d.editedRecord.pValue} onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.pos_id, { pValue: e.target.value }) }} />)
        }

        let groupName = (t, r) => {
            let eg = this.state.editGroup;
            if (eg && eg.code === r.code) {
                return (<Input value={eg.name} onChange={(e) => { this.setState({ editGroup: { ...eg, name: e.target.value } }) }} />)
            } else {
                return gt(parseInt(t));
            }
        }

        let paramFilter = this.props.data[this.pos_id].filters._param;

        let posTable = (<span>
            <div className="page-toolbar" style={toolBarStyle}>
                <div><label style={titleStyle}>{gt(15197)}</label></div>
                <div><label style={titleStyle}>{(paramFilter ? paramFilter : "")}</label></div>
            </div>
            <ResponsiveTable
                rowClassName={(record) => record.key === paramFilter ? 'table-row-is-marker' : undefined}
                idTable={this.props.data[this.pos_id]}
                tableOnly
                columns={[
                    { title: gt(15198), key: "pID", dataIndex: "pID", width: "20%", render: (t) => t },
                    { title: gt(15199), key: "pValue", dataIndex: "pValue", width: "60%", render: posParamValue },
                    { title: '', width: "20%", render: this.posParamEditIcon },
                ]}
                scroll={{ y: window.innerHeight - 380 }}
                pagination={false}
            />
        </span>)


        return (<div style={divStyle}>
            {this.getSelector(0, {
                style: { width: 300, display: 'inline-block' },
                nameText: this.getText,
                value: this.state.filters._groupID,
                onChange: (e) => {
                    this.setState({ filters: { ...this.state.filters, _groupID: e } }, () => {
                        this.props.dataActions.genericDataSetFilter(
                            this.id, this.props.user.companyCode, this.props.user.token, { _groupID: e, _page_num: 1 }, this.api.get);
                    })
                }
            }, { code: '0', name: 15680 })}
            <span style={{ display: this.props.admin ? 'inline-block' : 'none' }}>
                <Tooltip title={gt(15209)}>
                    <Button onClick={() => { this.setState({ modalGroups: true }) }}>
                        <Icon type="plus" style={{ fontSize: 16 }} />
                    </Button>
                </Tooltip>
            </span>
            <Row>
                <Col span={window.innerWidth > 600 ? 18 : 24} style={{ padding: 5 }}>
                    <GenericTable
                        id={this.id} getApi={this.api.get} title={gt(14676)}
                        topHeight={380}
                        rowHeight={45}
                        filters={this.state.filters}
                        columns={columns}
                        actionButtons={this.props.admin ? this.actionButtons : []}
                        percentageToMobile={[20, 0, 50, 30]}
                        searchBox={true}
                        onRowClick={window.innerWidth > 600 ? this.apiGetPosParameters : false}
                        onExpand={window.innerWidth <= 600 ? (e, r) => { this.apiGetPosParameters(r) } : false}
                        moreDetail={window.innerWidth <= 600 ? posTable : false}
                        data={this.props.data} dataActions={this.props.dataActions}
                        ui={this.props.ui} uiActions={this.props.uiActions} user={this.props.user}
                    />
                </Col>
                <Col span={window.innerWidth > 600 ? 6 : 0} style={{ padding: 5 }}>
                    {posTable}
                </Col>
            </Row>

            <GenericModal
                visible={this.state.modalCreateEdit !== null} onCancel={this.handleCancel} width={1000} title={gt(14685)}
                footer={[
                    <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{this.getText(14686)}</Button>,
                    <Button key={"create"} type="primary" onClick={this.handleOk} style={{ margin: 5 }}>{this.getText(14687)}</Button>
                ]}>
                <Row>
                    <Col span={window.innerWidth > 1000 ? 15 : 24} >
                        {this.getInput(14677, 'rVariableName')}
                        {this.getInput(14678, 'rInternalName')}
                        {this.getInput(14679, 'rHelpDescription')}
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 1 : 0} >
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 8 : 24} >
                        {this.getInput(14680, 'rDefaultValue')}
                        {this.getCheckbox(15203, 'rCanBeSetByUser')}
                        {this.getCheckbox(14682, 'rIsActive')}
                        {this.getInput(14683, 'rValidationMethod')}
                        {this.getInput(15192, '_groupID')}
                    </Col>
                </Row>
            </GenericModal>
            <GenericModal
                visible={this.state.modalGroups}
                onCancel={() => { this.setState({ modalGroups: false }) }}
                width={500} title={gt(15210)}
                actionButtons={[{
                    buttonType: 'add',
                    buttonFunction: this.createNewGroup
                }, {
                    buttonType: 'refresh',
                    buttonFunction: this.refrashGroupSelect
                }]}>
                <ResponsiveTable
                    tableOnly
                    dataSource={this.props.data[this.selectors[0].id].dataset}
                    columns={[
                        { title: gt(15211), key: "code", dataIndex: "code", width: "20%", render: (t) => t },
                        { title: gt(15212), key: "name", dataIndex: "name", width: "60%", render: groupName },
                        { title: '', width: "20%", render: this.groupEditIcon },
                    ]}
                    scroll={{ y: window.innerHeight - 380 }}
                    pagination={false}
                />
            </GenericModal>
        </div>)
    }

}

export default UpdateParameters;