
import React from "react";
import {
    Input, InputNumber, Card, Button, Modal, Select, Tooltip, Form,
    Checkbox, Collapse, message, Switch
} from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';

import moment from "moment";
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import FormItem from "antd/lib/form/FormItem";
import Jax from "../Jax/jax.es6.module";
import TextArea from "antd/lib/input/TextArea";
import LangContext from "../contextProvider/LangContext";

const { Option, OptGroup } = Select;

//type State = {}
class ModalCreateEditStorages extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {}

        this.id = "MODAL_CREATE_EDIT_STORAGES";

        this.api = {
            get: "get_storages_single_data",
            create: "create_storage",
            edit: "edit_storage",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, script: "get_branch_list" },
            { id: TableIDs.genericSelectorUsers, script: "get_users_list" },
        ];

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.selectors.map((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        // document.title = "Cash On Tab - הגדרות";
        this.initFilters();
        this.selectors.map((x) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericSelectorRefreshDataset,
                args: [x.id, this.props.user.companyCode, this.props.user.token, x.script]
            });
        });
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        let request = { sID: this.props.ui.data ? this.props.ui.data['sID'] : 0 }
        this.props.dataActions.genericDataGetEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get);
        if (this.props.staticBranch) {
            setTimeout(() => {
                this.props.dataActions.genericDataSetEdit(this.id, { sBranchID: this.props.staticBranch })
            }, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            this.initFilters();
        }
    }

    handleOk = () => {
        let isCreate = this.props.ui.data["sID"] <= 0 ? true : false;
        let er = this.props.data[this.id].editedRecord;
        if (!er.sID) {
            message.error(this.getText(16073))
        } else if (!er.sBranchID) {
            message.error(this.getText(17564))
        } else if (!er.mTransitNumber) {
            message.error(this.getText(16074))
        } else if (!er.mWasterNumber) {
            message.error(this.getText(19182))
        } else {

            let sTable = { id: "STORAGE_MAIN", s: "get_storage_list_table" }
            let user = this.props.user;
            let sCallback = (ob) => {
                if (ob.errorMessage) {
                    message.error(ob.errorMessage)
                } else {
                    if (this.props.onSave) {
                        this.props.onSave()
                    } else {
                        this.props.dataActions.genericDataGetRequested(sTable.id, user.companyCode, user.token, sTable.s);
                    }
                    message.success(this.getText(11456));
                    this.handleCancel();
                }
            }
            // if (isCreate) {
            let script = isCreate ? this.api.create : this.api.edit
            let u = new User(user.token, user.companyCode);
            let job = new Job(u, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            let k = 'key';
            let v = er.key;
            this.props.data[this.id].headers.map((x, i) => {
                // if (i) {
                k += "\f" + x;
                v += "\f" + er[x];
                // }
                // k += x;
                // v += er[x];
            });
            job.setInput(k + "\r" + v);
            job.send("/cgi-bin/CashOnTab", sCallback, (e) => e);
            // } else {
            //     this.props.dataActions.genericDataJustSaveEdit(this.id, user.companyCode, user.token, this.api.edit);
            //     sCallback({});
            // }
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditStorages);
    }

    render() {
        const { loading } = this.state;
        let sID = this.props.ui.data ? this.props.ui.data["sID"] : 0

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={sID ? this.getText(11457) + " " + sID : this.getText(11458)}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let editedRecord = this.props.data[this.id].editedRecord;
        let updateIn = (f, v) => this.props.dataActions.genericDataSetEdit(this.id, { [f]: v });
        let tInput = (l, f) => (<FormItem label={l}><Input value={editedRecord[f]} onChange={(v) => updateIn(f, v.target.value)} /></FormItem>)
        let nidInput = (l, f) => (<FormItem label={l}><InputNumber value={editedRecord[f]} onChange={(v) => {
            this.props.dataActions.genericDataSetEdit(this.id, { [f]: v, mTransitNumber: v + 100000, mWasterNumber: v + 200000 })
        }} /></FormItem>)
        let nInput = (l, f) => (<FormItem label={l}><InputNumber value={editedRecord[f]} onChange={(v) => updateIn(f, v)} /></FormItem>)
        let mSelector = (num, l, f, multi) => {
            let selectData = Jax.get(this.props.data, this.selectors[num].id + ".dataset", []);
            let selected = editedRecord && editedRecord[f] ?
                multi ? editedRecord[f].split(',') : editedRecord[f]
                : [];

            let aSelect = selectData.map((obj) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            });

            return (<span>
                <FormItem label={l}>{multi ?
                    <Checkbox onChange={(e) => {
                        if (e.target.checked)
                            this.props.dataActions.genericDataSetEdit(this.id, { [f]: selectData.map((obj) => obj['code']).join(',') });
                        else
                            this.props.dataActions.genericDataSetEdit(this.id, { [f]: '' });
                    }} />
                    : ''}
                    <Select
                        key={f} mode={multi ? "multiple" : ""} maxTagCount={4} showSearch filterOption={true} style={{ width: multi ? '95%' : '100%' }} optionFilterProp="valuefilter"
                        onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.id, { [f]: multi ? value.join(',') : value }) }}
                        value={selected} >
                        {aSelect}
                    </Select>

                </FormItem>
            </span>);
        }

        let mobile = window.innerWidth > 600 ? false : true;
        return (
            <Modal
                destroyOnClose={true} width={mobile ? '95%' : '40%'} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11459)}</Button>,
                    <Button key="clear" loading={loading} onClick={this.initFilters}>{this.getText(11460)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11461)}</Button>,
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >
                <Form //id="search_working_hours_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: '1', paddingTop: mobile ? 50 : 0 }}
                //onSubmit={this.handleSearch}
                >
                    {!sID ? nidInput(this.getText(11462), "sID") : ''}
                    {nInput(this.getText(16072), "mTransitNumber")}
                    {nInput(this.getText(19183), "mWasterNumber")}
                    {tInput(this.getText(11463), "sName")}
                    {this.props.staticBranch ? "" : mSelector(0, this.getText(11464), "sBranchID", false)}
                    {mSelector(1, this.getText(11465), "sUsers", true)}

                </Form>
            </Modal>
        )
    }
}

export default ModalCreateEditStorages;
