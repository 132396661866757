/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import { Button, Icon, message, Progress, Table, Upload } from "antd";
import { Job, OutputType, ProcessType, User } from "../../JmReact";


class SendToMail extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            progress: 0,
        };

        this.id = "SendToMail";

        this.api = {
            get: "SendToMail",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20675);
    }


    handleUpload = ({ file, onSuccess }) => {
        const reader = new FileReader();
        const name = file.name.slice(-24)
        const target = file.name.slice(0, -24)
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState((prevState) => {
                const base64 = reader.result?.split(";base64,")[1]
                const newFiles = [...prevState.files, { name, target, base64, status: "uploading" }];
                return { files: newFiles };
            }, () => {
                onSuccess("success");
            });
        };
    };

    startUpload = async () => {
        this.setState({ uploading: true, progress: 0 });

        const { files } = this.state;
        for (let i = 0; i < files.length; i++) {

            const { target, name, base64 } = files[i]
            await this.sendAPIPromise(
                "send_docs_from_hsk",
                `_target\f_fileName\f_fileData\r${target}\f${name}\f${base64}`,
                async ob => {
                    this.setState((prevState) => {
                        const updatedFiles = [...prevState.files];
                        updatedFiles[i].status = "success";
                        updatedFiles[i].mqId = ob.data;
                        return {
                            files: updatedFiles,
                            progress: Math.round(((i + 1) / files.length) * 100),
                        };
                    })
                },
                e => {
                    this.setState((prevState) => {
                        console.error(e)
                        const updatedFiles = [...prevState.files];
                        updatedFiles[i].status = "error";
                        return {
                            files: updatedFiles,
                            progress: Math.round(((i + 1) / files.length) * 100),
                        };
                    })
                });

            await new Promise((resolve) => setTimeout(resolve, 250)); // המתנה לריצה הבאה
        }

        message.success(this.getText(20680));
        this.setState({ uploading: false });
    };

    saveUpload = () => {
        this.sendAPIPromise("send_renew_message", "_id\r" + this.state.files.map(x => x.mqId).join("\r"),
            () => { message.success(this.getText(20687)) },
            () => { message.error(this.getText(20688)) })
    }

    sendAPIPromise = (script, data, sCall, eCall) => {
        return new Promise((resolve, reject) => {
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
            job.setInput(data);
            job.send("/cgi-bin/CashOnTab", ob => { resolve(sCall(ob)) }, e => { reject(eCall(e)) })
        })
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 1000,
            margin: "auto"
        };

        const { files, uploading, progress } = this.state;

        const columns = [
            { title: gt(20677), dataIndex: "name", key: "name" },
            { title: gt(20678), dataIndex: "target", key: "target" },
            { title: gt(20679), dataIndex: "status", key: "status", render: t => (<Icon type={t == "success" ? "check-circle" : t == "error" ? "close-circle" : "upload"} />) },
        ];

        return (<div style={divStyle}>
            <PageToolbar {...this.props} title={gt(20676)} actionButtons={[]} />

            <Upload customRequest={this.handleUpload} showUploadList={false} multiple accept=".pdf">
                <Button icon={"upload"}>{gt(20681)}</Button>
            </Upload>
            <Button
                onClick={() => { this.setState({ files: [], uploading: false, progress: 0, }) }}
                disabled={files.length === 0 || uploading}
            >{gt(20689)}</Button>
            &nbsp;&nbsp;&nbsp;
            {gt(20684)} {files.length} {gt(20685)}
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;
            <Button
                type="primary" onClick={this.startUpload}
                disabled={files.filter(f => f.status == "uploading").length === 0 || files.length === 0 || uploading}
            >{gt(20682)}</Button>
            &nbsp;&nbsp;&nbsp;
            {gt(20686)} {files.filter(f => f.status == "success").length} {gt(20685)}
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;
            <Button
                type="primary" onClick={this.saveUpload}
                disabled={files.filter(f => f.status == "uploading").length > 0 || files.length === 0 || uploading}
            >{gt(20683)}</Button>

            {uploading && <Progress percent={progress} />}

            <Table dataSource={files} columns={columns} rowKey="name" pagination={false} scroll={{ y: window.innerHeight - 400 }} />

        </div>)
    }

}

export default SendToMail;
