export default {
    // MODALS
    salesAlerts: "SALES_ALERTS",
    createItem: "CREATE_ITEM",
    editItem: "MODAL_EDIT_ITEM",
    
    modalRenewForm: "RENEW_FORM",
    modalCreateItem: "ITEMS_CREATE_MODAL",
    //modalSearchItem: "ITEMS_SEARCH_MODAL",
    modalEditItem: "ITEMS_EDIT_MODAL",
    modalCreatePriceList: "MODAL_CREATE_PRICE_LIST",
    modalCreatePrice: "MODAL_CREATE_PRICE",
    modalCreateEditCustomer: "MODAL_CREATE_EDIT_CUSTOMER",
    modalCreateEditSupplier: "MODAL_CREATE_EDIT_SUPPLIER",
    modalCreateEditEmployee: "MODAL_CREATE_EDIT_EMPLOYEE",
    modalCreateEditEmployeeGroup: "MODAL_CREATE_EDIT_EMPLOYEE_GROUP",
    modalCreateEditTagTypes: "MODAL_CREATE_EDIT_TAG_TYPES",
    modalCreateEditItem: "MODAL_CREATE_EDIT_ITEMS",
    modalSearchItem: "MODAL_SEARCH_ITEMS",
    modalCreateEditBrach: "MODAL_CREATE_EDIT_BRACH",
    modalCreateEditBranch: "MODAL_CREATE_EDIT_BRANCH",
    modalSearchHoursReport: "MODAL_SEARCH_HOURS_REPORT",
    modalCreateBOHoursReport: "MODAL_CREATE_BO_HOURS_REPORT",
    modalSearchPuchaseTable: "MODAL_SEARCH_PUCHASE_TABLE",
    modalEditPurchaseDocument: "MODAL_EDIT_PURCHASE_DOCUMENT",
    modalCreateEditDirectDebit: "MODAL_CREATE_EDIT_DIRECT_DEBIT",
    modalCreateEditStandingOrders: "MODAL_CREATE_EDIT_STANDING_ORDERS",
    modalAddPage: "MODAL_ADD_PAGE",
    modalEditPage: "MODAL_EDIT_PAGE",
    modalCopyPage: "MODAL_COPY_PAGE",
    modalDeletePage: "MODAL_DELETE_PAGE",
    modalAdvanceSearchReports: "MODAL_ADVANCE_SEARCH_REPORTS",
    modalCreateCreditCard: "MODAL_CREATE_CREDIT_CARD",
    modalDownloadCustomersWithCreditCard: "MODAL_DOWNLOAD_CUSTOMERS_WITH_CREDIT_CARD",
    modalUploadDebitCardChargesFile: "MODAL_UPLOAD_DEBIT_CARD_CHARGES_FILE",
    modalRunStandingOrders: "MODAL_RUN_STANDING_ORDERS",
    modalStandingOrdersChargesReport: "MODAL_STANDING_ORDERS_CHARGES_REPORT",
    modalStandingOrdershChargesSummaryReport: "MODAL_STANDING_ORDERS_SUMMARY_CHARGES_REPORT",
    modalCreateEditMessage: "MODAL_CREATE_EDIT_MESSAGE",
    modalMessageHistoryAdvanceSearch: "MODAL_MESSAGE_HISTORY_ADVANCE_SEARCH",
    modalStandingOrdersByCustomer: "MODAL_STANDING_ORDERS_BY_CUSTOMER",
    modalCustomersDebtReport: "MODAL_CUSTOMERS_DEBT_REPORT",
    modalCustomerDebtDetailedReport: "MODAL_CUSTOMER_DEBT_DETAILED_REPORT",
    modalAddSizesToRuler: "MODAL_ADD_SIZES_TO_RULER",
    modalCreateEditMatrixItem: "MODAL_CREATE_EDIT_MATRIX_ITEM",
    modalMatrix : "MODAL_MATRIX",
    modalMatrixReverse : "MODAL_MATRIX_REVERSE",
    modalEditEventConsts : "MODAL_EDIT_EVENT_CONSTS",
    modalLabelFormatsData : "MODAL_LABEL_FORMATS_DATA",
    modalEditLabelField : "MODAL_EDIT_LABEL_FIELD",
    modalAccumulationOfPoints : "MODAL_ACCUMULATION_OF_POINTS",
    modalAccumulationOfSubscribers : "MODAL_ACCUMULATION_OF_SUBSCRIBERS",
    modalAccumulationOfAggregateCard : "MODAL_ACCUMULATION_OF_AGGREGATE_CARD",
    modalCreateEditPointsCustomer : "MODAL_CREATE_EDIT_POINTS_CUSTOMER",
    modalCreateEditSubscribersCustomer : "MODAL_CREATE_EDIT_SUBSCRIBERS_CUSTOMER",
    modalServiceCall : "MODAL_SERVICE_CALL",
    modalSearchFixLabDocuments: "MODAL_SEARCH_FIX_LAB_DOCUMENTS",
    modalCreateEditFixLabDocuments: "MODAL_CREATE_EDIT_FIX_LAB_DOCUMENTS",
    modalCreateEditWinner: "MODAL_CREATE_EDIT_WINNER",
    SelectorItemsModal: "SELECTOR_ITEMS_MODAL",
    modalMatrixQuantities: "MODAL_MATRIX_QUANTITIES",
    modalSelectItemOrFatherItem: "MODAL_SELECT_ITEM_OR_FATHER_ITEM",
    modalWorkingHouersPerEmployee: "MODAL_WORKING_HOUERS_PER_EMPLOYEE",
    modalCreateEditPointsOfSale: "MODAL_CREATE_EDIT_POINTS_OF_SALE",
    modalNumeratorsPointsOfSale: "MODAL_NUMERATORS_POINTS_OF_SALE",
    modalCreateEditUsers: "MODAL_CREATE_EDIT_USERS",
    modalChangePasswordUser: "MODAL_CHANGE_PASSWORD_USER",
    modalCreateEditStorages: "MODAL_CREATE_EDIT_STORAGES",
    modalSearchMultilingual: "MODAL_SEARCH_MULTILINGUAL",
    modalSalesAlert: "MODAL_SALES_ALERT",
    modalCustomerFieldRoles: "MODAL_CUSTOMER_FIELD_ROLES",
    modalCreateEditMultilingual: "MODAL_CREATE_EDIT_MULTILINGUAL",
    modalSendLinkSelfCustomer: "MODAL_SEND_LINK_SELF_CUSTOMER",
    modalAttributeItemTags: "MODAL_ATTRIBUTE_ITEM_TAGS",
    modalCreateEditFoods: "MODAL_CREATE_EDIT_FOODS",
    modalCreateEditFoodLevels: "MODAL_CREATE_EDIT_FOOD_LEVELS",
    modalPriceListItemView: "MODAL_PRICE_LIST_ITEM_VIEW",
    modalSearchSubscribers: "MODAL_SEARCH_SUBSCRIBERS",
    modalSelectorUOMQty: "MODAL_SELECTOR_UOM_QTY",
    modalSearchCoupons: "MODAL_SEARCH_COUPONS",
    modalSearchAggregateCard: "MODAL_SEARCH_AGGREGATE_CARD",
    modalCreateEditMoveinSettings: "MODAL_CREATE_EDIT_MOVEIN_SETTINGS",
    modalChangeMoveinFormat: "MODAL_CHANGE_MOVEIN_FORMAT",
    modalProgress: "MODAL_PROGRESS",
    modalShareMessage: "MODAL_SHARE_MESSAGE",
    modalPLUfile: "MODAL_PLU_FILE",
    modalAppRegistration: "MODAL_APP_REGISTRATION",
    modalAppRegistrationExpired: "MODAL_APP_REGISTRATION_EXPIRED",
    modalPosPayTypeHAccounts: "MODAL_POS_PAY_TYPE_H_ACCOUNTS",
    modalPricerfile: "MODAL_PRICER_FILE",
    
    // TABLES
    createItem_Prices: "CREATE_ITEM_PRICES",
    createItem_Suppliers: "CREATE_ITEM_SUPPLIERS",

    // SELECTORS
    selectorSalesAlerts: "SELECTOR_SALES_ALERTS",
    selectorSuppliers: "SELECTOR_SUPPLIERS",
    selectorPrices: "SELECTOR_PRICES",
    selectorTaxType: "SELECTOR_TAX_TYPE",
    selectorCustomerFields: "SELECTOR_CUST_FIELDS",
    
    genericSelectorPrices: "GENERIC_SELECTOR_PRICES",
    genericSelectorPriceList: "GENERIC_SELECTOR_PRICE_LIST",
    genericSelectorTaxType: "GENERIC_SELECTOR_TAX_TYPE",
    genericSelectorTaxRegion: "GENERIC_SELECTOR_TAX_REGION",
    genericMultiSelectorCustomerTags: "GENERIC_MULTI_SELECTOR_CUSTOMER_TAGS",
    genericSelectorCustomerHakafaDocs: "GENERIC_SELECTOR_CUSTOMER_HAKAFA_DOCS",
    genericSelectorGroupEmployee: "GENERIC_SELECTOR_GROUP_EMPLOYEE",
    genericSelectorEmployees: "GENERIC_SELECTOR_EMPLOYEES",
    
    genericSelectorItemTagTypes: "GENERIC_SELECTOR_ITEM_TAG_TYPES",
    genericSelectorTagRelation: "GENERIC_SELECTOR_TAG_RELATION",
    genericSelectorSalesAlerts: "GENERIC_SELECTOR_SALES_ALERTS",
    genericMultiSelectorItemTags: "GENERIC_MULTI_SELECTOR_ITEM_TAGS",

    genericSelectorSuppliers: "GENERIC_SELECTOR_SUPPLIERS",
    genericSelectorPos: "GENERIC_SELECTOR_POS",
    genericSelectorPosByStorage: "GENERIC_SELECTOR_POS_BY_STORAGE",
    genericSelectorStorages: "GENERIC_SELECTOR_STORAGES",
    genericSelectorPaymentTypes: "GENERIC_SELECTOR_PAYMENT_TYPES",
    genericSelectorCerditCardCompanies: "GENERIC_SELECTOR_CERDITCARD_COMPANIES",
    genericSelectorDocumentTypes: "GENERIC_SELECTOR_DOCUMENT_TYPES",
    genericSelectorButtonPage: "GENERIC_SELECTOR_BUTTON_PAGE",
    genericSelectorButtonActions: "GENERIC_SELECTOR_BUTTON_ACTIONS",
    genericSelectorCustomers: "GENERIC_SELECTOR_CUSTOMERS",
    genericSelectorStandingOrderStatus: "GENERIC_SELECTOR_STANDING_ORDER_STATUS",
    genericSelectorMsgType: "GENERIC_SELECTOR_MSG_TYPE",
    genericSelectorPreDefinedMsg: "GENERIC_SELECTOR_PREDEFINED_MSG",
    genericSelectorRuler: "GENERIC_SELECTOR_RULER",
    genericSelectorColors: "GENERIC_SELECTOR_COLORS",
    genericSelectorSizes: "GENERIC_SELECTOR_SIZES",
    genericSelectorTypes: "GENERIC_SELECTOR_TYPES",
    genericSelectorAttributesTypes: "GENERIC_SELECTOR_ATTRIBUTES_TYPES",
    genericSelectorFixLabStatuses: "GENERIC_SELECTOR_FIX_LAB_STATUSES",
    genericSelectorFixLabQuestionAnswerTypes: "GENERIC_SELECTOR_FIX_LAB_QUESTION_ANSWER_TYPES",
    genericSelectorLabelsList: "GENERIC_SELECTOR_LABELS_LIST",
    genericSelectorExternalOrderSuppliers: "GENERIC_SELECTOR_EXTERNAL_ORDER_SUPPLIERS",
    genericSelectorPointsOfSaleTypes: "GENERIC_SELECTOR_POINTS_OF_SALE_TYPES",
    genericSelectorPosOs: "GENERIC_SELECTOR_POS_OS",
    genericSelectorInterfaceLocales: "GENERIC_SELECTOR_INTERFACE_LOCALES",
    genericSelectorUIComponents: "GENERIC_SELECTOR_UI_COMPONENTS",
    genericSelectorUsers: "GENERIC_SELECTOR_USERS",
    genericSelectorLanguages: "GENERIC_SELECTOR_LANGUAGES",
    genericSelectorDocumentsIDs: "GENERIC_SELECTOR_DOCUMENTS_IDS",
    genericSelectorElements: "GENERIC_SELECTOR_ELEMENTS",
    genericMultiSelectorTagTypes: "GENERIC_MULTI_SELECTOR_TAG_TYPES",
    genericSelectorTagsToSite: "GENERIC_SELECTOR_TAGS_TO_SITE",
    genericSelectorBargainTypes: "GENERIC_SELECTOR_BARGAIN_TYPES",
    genericSelectorImportTypes: "GENERIC_SELECTOR_IMPORT_TYPES",
    genericSelectorDeliveryMethods: "GENERIC_SELECTOR_DELIVERY_METHODS",
    genericSelectorFranchisees: "GENERIC_SELECTOR_FRANCHISEES",
    genericSelectorWinners: "GENERIC_SELECTOR_WINNERS",
    genericSelectorMut: "GENERIC_SELECTOR_MUT",
    genericSelectorOrderStatuses: "GENERIC_SELECTOR_ORDER_STATUSES",
    genericSelectorBranches: "GENERIC_SELECTOR_BRANCHES", 
    genericSelectorDeliveryStatus: "GENERIC_SELECTOR_DELIVERY_STATUS",
    
    genericSelectorFilterBranchesPos: "GENERIC_SELECTOR_FILTER_BRANCHES_POS",
    genericSelectorCustomerTitles: "GENERIC_SELECTOR_CUSTOMER_TITLES",
    genericSelectorWooStatuses: "GENERIC_SELECTOR_WOO_STATUSES",

    // GENERIC PAGE
    customer_Fields: "CUSTOMER_FIELDS",
    labelPrinter: "LABEL_PRINTER",

    // PSEUDO-SELECTOR
    pseudoSelectorUIcomponentsModules: "PSEUDO_SELECTOR_UI_COMPONENTS_MODULES",

    // DATASETS 
    matrixQuantities: "MATRIX_QUANTITIES",
    UsersDashboardConfigurations: "USERS_DASHBOARD_CONFIGURATIONS",
    selectorUOMQty: "SELECTOR_UOM_QTY",
   
    //FORMS
    formSelfCreateCustomer: "FORM_SELF_CREATE_CUSTOMER"


};
