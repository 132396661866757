
import React from "react";
import {
    Input, Button, Modal, Select, Form, message
} from 'antd';
import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import "../style/generalUI.css";
import FormItem from "antd/lib/form/FormItem";
import LangContext from "../contextProvider/LangContext";

const { Option, OptGroup } = Select;

//type State = {}
class ModalChangePasswordUser extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            rPassword1: '',
            rPassword2: '',
        }

        this.api = {
            hash: "set_cloud_user",
            update: "update_psw_user",
        }

        this.state.height = window.innerHeight;
        this.doOnResize = null;

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    initFilters = () => {
        this.setState({ rPassword1: '', rPassword2: '' });
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {

            this.setState({}, this.initFilters(), {});
        }
    }

    handleOk = () => {

        let s = this.state;

        if (s.rPassword1 === "" || s.rPassword2 === "") {
            message.error(this.getText(11778))
        } else  if ( s.rPassword1.length < 6 || s.rPassword1.length > 8  || s.rPassword2.length < 6 || s.rPassword2.length > 8) {
            message.error(this.getText(20701))
        } else if (this.passCheck()) {
            let sCallback = (ob) => {
                console.log(ob.data, 'set_cloud_user')
                let data = "uHash\fuID\r" + ob.data + "\f" + this.props.ui.data['rID'];
                let scall = (ob) => {
                    message.success(this.getText(11779))
                    this.handleCancel();
                }
                this.sendAPI(this.api.update, data, scall);
            }
            let dataSend = "uName\fuPSW\r" + this.props.ui.data['rName'] + "\f" + s.rPassword1;
            this.sendAPI(this.api.hash, dataSend, sCallback);
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalChangePasswordUser);
    }

    sendAPI = (script, data, sCallback) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCallback, (e) => { console.error(e) });
    }

    passCheck = () => {
        let p1 = this.state.rPassword1;
        let p2 = this.state.rPassword2;
        if (p1 !== "" && p2 !== "" && p1 !== p2) message.error(this.getText(11780))
        else return true
    }

    render() {

        const { loading } = this.state;

        let userId = this.props.ui.data ? this.props.ui.data['rID'] : 0;
        let userName = this.props.ui.data ? this.props.ui.data['rName'] : '';

        let titleName = this.getText(11781) + " " + userId + ' - ' + userName;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={titleName}
                    actionButtons={[]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let redS = (l) => (<span>{l}<span style={{ color: 'red' }}> * </span></span>)

        let pInput = (l, s) => (<FormItem label={redS(l)}>
            <Input.Password value={this.state[s]} onChange={(v) => { this.setState({ [s]: v.target.value })}} onBlur={this.passCheck} />
        </FormItem>)

        let mobile = window.innerWidth > 600 ? false : true

        return (
            <Modal
                destroyOnClose={true} width={mobile ? '95%' : 500} visible={false || this.props.toggle}
                title={titleBar} onOk={this.handleOk} onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11782)}</Button>,
                    <Button key="clear" loading={loading} onClick={this.initFilters}>{this.getText(11783)}</Button>,
                    <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11784)}</Button>,
                ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction} >
                <Form //id="search_working_hours_form" layout="horizontal" //className="ant-advanced-search-form"
                    style={{ columnWidth: 'auto', columnCount: '1' }}
                //onSubmit={this.handleSearch}
                >
                    {pInput(this.getText(11785), "rPassword1")}
                    {pInput(this.getText(11786), "rPassword2")}
                </Form>
            </Modal >)
    }
}

export default ModalChangePasswordUser;
