/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Select, Checkbox, message } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
//import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
//import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import DateTimePicker from "../../components/DateTimePicker";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import EventDatePicker from "../../components/EventDatePicker";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesMatrixItems extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            // dataView: "SalesOnly",
            SalesMatrixItems: {
                //mRequestedReport: 0,
                mLowerSelectionDate: null,
                mLowerSelectionTime: "00:00:00",
                mUpperSelectionDate: null,
                mUpperSelectionTime: "23:59:59",
                mCalculateBalanceSeparateStorage: 0,
                //mApplyRange_Apply: 0,
                //mApplyRange_Lower: 0,
                //mApplyRange_Upper: 0,
                //mGroupList: '',
                mPosList: '',
                mEmployeeList: '',
                mSupplierList: '',
                mStorageList: '',
                mIsActive: 1,
                //mMinimalPercentage: 0,
                mItemTagList: '', /*mTagList*/
                mTagRelation: '1',
                mSummary: 0,
                mItemFilter: '',
                _withSendingDocs: '1',
                viewSales: '1',
                viewStock: '0',
                reportName: "reports/salesMatrixItems_v2.pdf"
            }
        };
        this.state.type = "pdf";
        this.state.storageString = ""; // "ByStorage"

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;


        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    // reportStringBuilder = (isByStorage, type, dataView) => {
    //     return 'reports/salesMatrixItems' + (isByStorage ? 'ByStorage' : 'ByColor') + 'Vertical' + dataView + '.' + type;
    // }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.mPosList === '') params = { ...params, mPosList: fullPosList }
        if (params.mStorageList === '') params = { ...params, mStorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10202);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            // let reportName = this.props.ui.favoriteParams.reportName;
            // let dataView = reportName.indexOf('SalesOnly') > -1 ? 'SalesOnly' : reportName.indexOf('StockOnly') > -1 ? 'StockOnly' : '';
            // let type = reportName.split('.')[1];

            this.setReportParameters("SalesMatrixItems", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesMatrixItems.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesMatrixItems.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("SalesMatrixItems", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        this.onChange('LowerSelectionTime', value ? value.format("HH:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        this.onChange('UpperSelectionTime', value ? value.format("HH:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("SalesMatrixItems", { [field]: data });
                else
                    this.setReportParameters("SalesMatrixItems", { [field]: '' });
            }} />);
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f0\f2";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let _ItemCode = (ob.data).split('\f')[1];
                    if (!_ItemCode) this.props.uiActions.showModal(TableIDs.SelectorItemsModal, {});
                    else this.onChangeItemCode(_ItemCode)
                },
                (error) => {
                    message.error(this.getText(12141));
                }
            );
        }
    }

    keyboardListener(e) {
        ////console.log('yuda test ', this.barcodeReaderBuffer)
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.setReportParameters("SalesMatrixItems", { mItemFilter: '' })
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default: break;
        }
    }


    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setReportParameters("SalesMatrixItems", { mItemFilter: code ? code : '' })
        }
    }

    getDateTimePicker = (dateField, timeField, placeholder, minDate, maxDate) => {
        return (<Row>
            <Col span={window.innerWidth >= 600 ? 12 : 24}>
                <DateTimePicker
                    {...{ minDate, maxDate, placeholder }}
                    format={"DD-MM-YYYY"}
                    value={this.state.SalesMatrixItems[dateField]}
                    onChange={(e) => { this.setReportParameters("SalesMatrixItems", { [dateField]: e }) }}
                />
            </Col>
            <Col span={window.innerWidth >= 600 ? 12 : 24}>
                <DateTimePicker
                    format={"HH:mm:ss"}
                    value={this.state.SalesMatrixItems[timeField]}
                    onChange={(e) => { this.setReportParameters("SalesMatrixItems", { [timeField]: e }) }}
                />
            </Col>
        </Row>)
    }

    render() {
        const { SalesMatrixItems, endOpen } = this.state;
        const { mLowerSelectionDate, mLowerSelectionTime,
            mUpperSelectionDate, mUpperSelectionTime } = SalesMatrixItems;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { SalesMatrixItems } = this.state;
        const isMinPercShownStyle = { display: (SalesMatrixItems.mRequestedReport === 5 || SalesMatrixItems.mRequestedReport === 6) ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listEmployee = Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listItemTags = this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listItemTagTypes = Jax.get(this.props.data, TableIDs.genericSelectorItemTagTypes + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listItemGroup = Jax.get(this.props.data, TableIDs.genericSelectorItemGroup + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let listSuppliers = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });
        let mobile = window.innerWidth > 600 ? false : true;

        let encodeURIFields = [
            'mItemFilter'
        ]

        if (this.state.SalesMatrixItems.mTagRelation == '2') encodeURIFields.push("mItemTagList")

        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(12125)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: '95%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(12126)}>
                            <Select key={"mCalculateBalanceSeparateStorage"} style={{ width: 250 }}
                                // value={this.state.SalesMatrixItems.mCalculateBalanceSeparateStorage == 1 ? 'ByStorage' : ''}
                                value={this.state.SalesMatrixItems.mCalculateBalanceSeparateStorage == '1' ? '1' : '0'}
                                onChange={
                                    (value) => {
                                        this.setReportParameters("SalesMatrixItems",
                                            {
                                                mCalculateBalanceSeparateStorage: value,
                                                // mCalculateBalanceSeparateStorage: (value === '') ? 0 : 1,
                                                // reportName: this.reportStringBuilder((value === '') ? false : true, this.state.type, this.state.dataView)
                                            }
                                        );
                                    }
                                }
                            >
                                <Option key={1} value={'0'}>{this.getText(12142)}</Option>
                                <Option key={2} value={'1'}>{this.getText(12143)}</Option>
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(12127)}>
                            <Select
                                style={{ width: 250 }}
                                value={this.state.SalesMatrixItems.viewStock + this.state.SalesMatrixItems.viewSales}
                                onChange={(value) => {
                                    const [viewStock, viewSales] = value.split("")
                                    this.setReportParameters("SalesMatrixItems",
                                        {
                                            viewStock, viewSales
                                            // reportName: this.reportStringBuilder(
                                            //     (this.state.SalesMatrixItems.mCalculateBalanceSeparateStorage == 1 ? true : false),
                                            //     this.state.type,
                                            //     value)
                                        }
                                    )
                                }} >
                                <Option key={1} value={'01'}>{this.getText(12144)}</Option>
                                <Option key={2} value={'10'}>{this.getText(12145)}</Option>
                                <Option key={3} value={'11'}>{this.getText(12146)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <Checkbox checked={this.state.SalesMatrixItems.mSummary == 1 ? true : false} onChange={(e) => {
                                this.setReportParameters("SalesMatrixItems", { mSummary: e.target.checked ? 1 : 0 });
                            }} />
                            <span>{this.getText(12147)}</span>
                        </FormItem>

                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state.SalesMatrixItems._withSendingDocs}
                                onChange={e => { this.setReportParameters("SalesMatrixItems", { _withSendingDocs: e }) }}
                            />
                        </FormItem>


                        <FormItem label={this.getText(12128)}>
                            <SelectorItems
                                doFocus={true}
                                user={this.props.user}
                                uiActions={this.props.uiActions}
                                ui={this.props.ui}
                                dataActions={this.props.dataActions}
                                data={this.props.data}
                                ActionQueue={this.props.ActionQueue}
                                ctrlBKeyFlag={null}
                                isExpandMatrixFatherItem={false}
                                itemType={2}
                                value={this.state.SalesMatrixItems.mItemFilter}
                                onChangeSearch={(s) => { this.onChangeItemCode(s.mCode) }}
                                onChange={(s) => { this.onChangeItemCode(s.target.value) }}
                                onBlur={(s) => { this.checkIfItemExist(s.target.value) }}
                                onKeyDown={this.keyboardListener.bind(this)}
                            />
                        </FormItem>

                        <FormItem label={this.getText(12129)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setReportParameters("SalesMatrixItems", { mPosList: value.join(','), mStorageList: typeList.join(',') })}
                                value={(this.state.SalesMatrixItems.mPosList === '') ? [] : this.state.SalesMatrixItems.mPosList.split(',')}
                            />
                            {/* {this.createCheckBox('mPosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mPosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mPosList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mPosList === '') ? [] : this.state.SalesMatrixItems.mPosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>

                        {/* <FormItem label={this.getText(12130)} style={{ display: 'none' }}>
                            {this.createCheckBox('mEmployeeList', Jax.get(this.props.data, TableIDs.genericSelectorEmployees + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mEmployeeList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mEmployeeList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mEmployeeList === '') ? [] : this.state.SalesMatrixItems.mEmployeeList.split(',')}
                            >
                                {listEmployee}
                            </Select>
                        </FormItem> */}

                        {/* <FormItem label={this.getText(12131)}>
                            {this.createCheckBox('mStorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mStorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mStorageList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mStorageList === '') ? [] : this.state.SalesMatrixItems.mStorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}
                        <FormItem label={this.getText(12132)}>
                            {/* {
                                this.createCheckBox('mItemTagList', Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", [])
                                    .reduce((acc, i) => {
                                        //console.log("acc: ", acc); 
                                        return acc.concat((i.tags.map(j => j.id)));
                                    }, [])
                                    .join(',')
                                )
                            }
                            <Select key={"mItemTagList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mItemTagList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mItemTagList === '') ? [] : this.state.SalesMatrixItems.mItemTagList.split(',')}
                            >
                                {listItemTags}
                            </Select> 
                            <Switch checkedChildren={this.getText(12148)} unCheckedChildren={this.getText(12149)}
                            <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mItemTagList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mItemTagList === '') ? [] : this.state.SalesMatrixItems.mItemTagList.split(',')}
                            />
                            <TagRelationRadio showed={SalesMatrixItems.mItemTagList}
                                checked={SalesMatrixItems.mTagRelation == 1}
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mTagRelation: (value === true) ? 1 : 0 })}
                            //checked={}
                            /> */}
                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.SalesMatrixItems.mItemTagList}
                                onChange={(value) => { this.setReportParameters("SalesMatrixItems", { mItemTagList: value }) }}
                                tagRelationValue={this.state.SalesMatrixItems.mTagRelation}
                                tagRelationOnChange={(value) => {
                                    let mItemTagList = this.state.SalesMatrixItems.mItemTagList;
                                    let from = this.state.SalesMatrixItems.mTagRelation;
                                    let to = value.target.value;
                                    let oldJson = mItemTagList.indexOf("{") > -1 ? JSON.parse(mItemTagList) : {}
                                    if (from == "2") mItemTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") mItemTagList = JSON.stringify({ tagList1: mItemTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("SalesMatrixItems", { mTagRelation: to, mItemTagList })
                                }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(12133)}>
                            {/* {this.createCheckBox('mSupplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mSupplierList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mSupplierList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mSupplierList === '') ? [] : this.state.SalesMatrixItems.mSupplierList.split(',')}
                            >
                                {listSuppliers}
                            </Select> */}
                            <GenericSelectorSuppliers {...this.props} multi notApi
                                onChange={(value) => this.setReportParameters("SalesMatrixItems", { mSupplierList: value.join(',') })}
                                value={(this.state.SalesMatrixItems.mSupplierList === '') ? [] : this.state.SalesMatrixItems.mSupplierList.split(',')}
                            />
                        </FormItem>
                        <Row>
                            <Col span={4}>
                                <FormItem>
                                    <EventDatePicker {...this.props}
                                        onSelectDate={(from, to) => {
                                            this.setReportParameters("SalesMatrixItems", { mLowerSelectionDate: from, mUpperSelectionDate: to })
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={20}>
                                <FormItem label={this.getText(12134)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                    {this.getDateTimePicker("mLowerSelectionDate", "mLowerSelectionTime", this.getText(12150), null, mUpperSelectionDate)}
                                </FormItem>

                                <FormItem label={this.getText(12135)} /*style={isEndDateShownStyle}*/>
                                    {this.getDateTimePicker("mUpperSelectionDate", "mUpperSelectionTime", this.getText(12151), mLowerSelectionDate)}
                                </FormItem>
                            </Col>
                        </Row>
                        <ReportTypeButton
                            {...this.props}
                            params={this.state.SalesMatrixItems}
                            storagesField={'mStorageList'}
                            tagsField={'mItemTagList'}
                            posField={'mPosList'}
                            encodeURIFields={encodeURIFields}
                            datesToFavorites={[
                                { field: 'mLowerSelectionDate', label: this.getText(12134) },
                                { field: 'mUpperSelectionDate', label: this.getText(12135) },
                            ]}
                            favoriteSave
                        />
                        {/* <FormItem key={"reportName"} label={this.getText(12136)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/salesMatrixItems.pdf"}
                                //combobox={null}
                                defaultValue={"pdf"}
                                onChange={(value) => {
                                    //const type = this.state.SalesMatrixItems.mRequestedReport;
                                    //console.log(type);
                                    //const str = (type === 0) ? '' : type.toString();
                                    //console.log(value,str);
                                    //this.setReportParameters("SalesMatrixItems", { reportName: "reports/salesMatrixItems" + str + ".pdf"})}
                                    this.setState({ type: value },
                                        this.setReportParameters("SalesMatrixItems", {
                                            reportName: this.reportStringBuilder(
                                                (this.state.SalesMatrixItems.mCalculateBalanceSeparateStorage == 1 ? true : false),
                                                value, this.state.dataView
                                            )
                                        }));
                                    //  this.state.SalesMatrixItems.reportName.split('.')[0] + "." + value });
                                }}
                            >
                                <Option key={"pdf"}>{this.getText(12137)}</Option>
                                <Option key={"xls"}>{this.getText(12138)}</Option>
                                <Option key={"docx"}>{this.getText(12139)}</Option>
                            </Select>
                        </FormItem>

                        
                        <div style={{ paddingLeft: 32 }}>
                        <Button style={{ float: 'left' }} type={"primary"} onClick={() => {
                            this.generateReport.apply(this, ["SalesMatrixItems"])
                        }}>{this.getText(12140)}</Button>
                    </div> */}
                    </Form>
                </Card>
            </div >
        );
    }
}
export default SalesMatrixItems;