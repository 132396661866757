
/* @flow */
/*jshint esversion: 6 */
import { Button, Form, Icon, Input, Tooltip, message, Popconfirm } from "antd";
import React, { Component } from "react";
import TableIDs from "../data/TableIDs";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import GenericSelector from "./GenericSelector";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import ReportShowButton from "./ReportShowButton";


class QuesIdentityEntity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalView: false,
            modalSendLink: false,
            waitingSend: false,
            _mobile: "",
            _qId: "",
        }

        this.id = "QUES_IDENTITY_ENTITY";

        this.selector_id = "QUESTIONNAIRE_LIST";

        this.api = {
            get: "get_ques_identity_entity",

            get_selector: "get_questionnaire_list"
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { dataset: [] };
        if (props.data[this.selector_id] === undefined) props.data[this.selector_id] = { dataset: [] };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    onOpenModalSendLink = () => {

        this.sendAPI(this.api.get_selector, "", (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f");
                return { code: y[0], name: y[1], isAllowsDraft: y[2] }
            }) : [];
            this.props.dataActions.setJson(this.selector_id, { dataset })
        })
        // this.props.dataActions.genericSelectorRefreshDataset(
        //     this.selector_id, this.props.user.companyCode, this.props.user.token, this.api.get_selector);
    }

    apiData = () => {
        const { entityType, entityValue } = this.props;
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, { entityType, entityValue }, this.api.get)
    }

    getPrintAnswersIcon = (text, record) => {
        const getParams = (_withNType) => {
            const { qIdentityDatetime, qIdentityID } = record
            const { entityType, entityValue } = this.props;
            return {
                _id: qIdentityID,
                _datetime: encodeURIComponent(qIdentityDatetime),
                _type: entityType,
                _value: entityValue,
                _withNType,
                reportName: "reports/QuestionnaireAnswers.pdf",
                REPORT_LOCALE: this.getText(101)
            }
        }

        const onPrint = (params) => {
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, params]
            });
        }

        const confirmTexts = {
            title: this.getText(17663),
            okText: this.getText(17664),
            cancelText: this.getText(17665)
        }

        return [
            <Popconfirm
                onConfirm={() => { onPrint(getParams('1')) }}
                onCancel={() => { onPrint(getParams('0')) }}
                {...confirmTexts} >
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"printer"} />
            </Popconfirm>,
            <ReportShowButton
                {...this.props}
                iconButton
                validSubmit
                confirmWithParams={{
                    ...confirmTexts,
                    paramsWhenOk: getParams('1'),
                    paramsWhenCancel: getParams('0'),
                }}
            />
        ]
    }

    sendLink = () => {
        const { entityType, entityValue } = this.props;
        const { _mobile, _qId, waitingSend } = this.state;
        if (!waitingSend) {
            let patt = new RegExp(/^05\d([-]{0,1})\d{7}$/);
            let validMobile = patt.test(_mobile);

            let findQData = this.props.data[this.selector_id].dataset.find(f => f.code == _qId)
            let _qName = findQData ? findQData.name : "";
            let _qIsAllowsDraft = findQData ? findQData.isAllowsDraft : "";

            if (validMobile) {
                this.setState({ waitingSend: true }, () => {
                    let params = "entityType=" + entityType + "&entityValue=" + entityValue + "&qId=" + _qId + "&qName=" + _qName + "&qIsAllowsDraft=" + _qIsAllowsDraft;
                    let dataSend = "_type\f_params\f_mobile\f_lang\r7\f" + params + "\f" + _mobile + "\f" + this.getText(100);
                    this.sendAPI("create_random_connection_token", dataSend, () => {
                        this.setState({ modalSendLink: false, waitingSend: false }, () => {
                            message.success(this.getText(16010))
                        })
                    }, (e) => { console.error(e) })
                })
            } else {
                message.error(this.getText(16011))
            }
        }
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    }

    render() {
        let issetModule = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code == '506')
        const render = t => t;

        console.log("issetModule", this.props.data[this.id])

        if (issetModule) {
            return (<span>
                <Tooltip title={this.getText(17647)}>
                    <Icon
                        type="file-unknown"
                        onClick={() => { this.setState({ modalView: true }, this.apiData) }}
                        style={{ fontSize: 16 }}
                        className={"clickable margin-4"} />
                </Tooltip>
                <GenericModal
                    visible={this.state.modalView}
                    onCancel={() => { this.setState({ modalView: false }) }}
                    title={this.getText(17648)}
                    width={1000}
                    actionButtons={[{ buttonType: 'refresh', buttonFunction: this.apiData }]}
                    genericActionButtons={[
                        <Tooltip title={this.getText(17658)}>
                            <Button onClick={() => {
                                this.setState({ modalSendLink: true, _mobile: this.props.mobileToLink ? this.props.mobileToLink : "" }, this.onOpenModalSendLink)
                            }} >
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"mail"} />
                            </Button>
                        </Tooltip>
                    ]}>
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.id]}
                        columns={[
                            { title: this.getText(17649), key: "qID", dataIndex: "qID", width: "10%", render },
                            { title: this.getText(17650), key: "qName", dataIndex: "qName", width: "40%", render },
                            { title: this.getText(17651), key: "qIdentityID", dataIndex: "qIdentityID", width: "20%", render },
                            { title: this.getText(17652), key: "qIdentityDatetime", dataIndex: "qIdentityDatetime", width: "20%", render },
                            { title: this.getText(17653), width: "10%", render: this.getPrintAnswersIcon },
                        ]}
                    />
                    <GenericModal
                        visible={this.state.modalSendLink}
                        onCancel={() => { this.setState({ modalSendLink: false }) }}
                        title={this.getText(17659)}
                        width={400}>
                        <Form.Item label={this.getText(17660)}>
                            <Input value={this.state._mobile} onChange={e => { this.setState({ _mobile: e.target.value }) }} />
                        </Form.Item>
                        <Form.Item label={this.getText(17661)}>
                            <GenericSelector {...this.props} id={this.selector_id} value={this.state._qId} onChange={e => { this.setState({ _qId: e }) }} />
                        </Form.Item>
                        <Button type={"primary"} loading={this.state.waitingSend} onClick={this.sendLink}>{this.getText(17662)}</Button>
                    </GenericModal>
                </GenericModal>
            </span>)
        } else {
            return '';
        }
    }

}

export default QuesIdentityEntity;