/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import InvoiceApi from "../../components/InvoiceApi";


class InvoiceToken extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {

        };

        this.id = "INVOICE_TOKEN";

        this.api = {
            get: "get_invoice_token",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.refreshData()
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20023);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    render() {
        const gt = this.getText;
        const { count, data } = this.props.data[this.id]
        let dataSplit = data[0]?.split("\f") ?? []
        const [id, name, accessToken, refreshToken, expiration] = dataSplit
        const onChange = (i, v) => {
            dataSplit[i] = v
            this.props.dataActions.setJson(this.id, { data: dataSplit.join("\f") })
        }
        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
            maxWidth: 800,
            margin: 'auto'
        };

        return (<div style={divStyle}>
            <PageToolbar title={gt(20024) + " - " + (name ?? "")} actionButtons={[{ buttonType: 'refresh', buttonFunction: this.refreshData }]} />
            {count == 1 ?
                <InvoiceApi
                    {...this.props}
                    franchiseId={id}
                    onPagefocus={this.refreshData}
                    accessToken={accessToken}
                    refreshToken={refreshToken}
                    expiration={expiration}
                    onChangeAccessToken={e => { onChange(2, e) }}
                    onChangeRefreshToken={e => { onChange(3, e) }}
                />
                : <h1>{gt(20027)}</h1>}
        </div>)
    }

}

export default InvoiceToken;