/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import TableIDs from '../../data/TableIDs.js';
import {
    Checkbox, InputNumber, Select, Button, Row, Col, Modal, Icon, Input, Tooltip, message, Popconfirm,
    TimePicker, DatePicker, Form, Popover, Card, Table
} from "antd";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import ResponsiveTable from "../../components/ResponsiveTable";
// import moment from "moment";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import SelectorItems from "../../components/SelectorItems";
import GenericSelector from "../../components/GenericSelector";
import CustPicker from "../../components/CustPicker";
import { TwitterPicker, } from 'react-color';
import { colorPallete, colourHexToName, } from "../../utils/utils";
import PictureView from "../../components/PictureView";
import ImageSearch from "../../components/ImageSearch";
import VirtualShopMessages from "./VirtualShopMessages";
import VirtualShopStatuses from "./VirtualShopStatuses";
import VirtualShopRadius from "./VirtualShopRadius";
import VirtualShopCategories from "./VirtualShopCategories";
import VirtualShopDowHours from "./VirtualShopDowHours";
import VirtualShopMethods from "./VirtualShopMethods";
import VirtualShopNetworkLinks from "./VirtualShopNetworkLinks";
import { throttleSetter } from "lodash-decorators";
import VirtualShopCSSValues from "./VirtualShopCSSValues";
import EditorContainer from "../../components/EditorContainer";
import { isDeleteExpression } from "typescript";
import VirtualShopBranchShippingAreas from "./VirtualShopBranchShippingAreas.js";
import PizzaSlicesExchange from "./PizzaSlicesExchange.js";
import VideoView from "../../components/VideoView.js";

const { Option, OptGroup } = Select;

class OrdersSiteSettings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalCompanyDetails: false,
            modalDeliveryMethods: false,
            modalRadius: false,
            modalDowHours: false,
            modalNonValidDates: false,
            modalPayToSite: false,
            modalOrderTimes: false,
            modalTextsEdit: false,
            modalColors: false,
            modalImages: false,
            modalCategories: false,
            googleImage: null,
            googleImageField: null,
            modalMessages: false,
            modalStatuses: false,
            modalNetworkLinks: false,
            modalShopSettings: false,
            modalCustomerAuthentication: false,
            modalPrimaryView: false,
            modalComponents: false,
            modalPizzaSlicesTypes: false,

            _branch: '0',

            modalTextsSearch: "",

        }

        this.id = "ORDERS_SITE_SETTINGS";
        this.pay_id = "PAYMENT_TYPE_FOR_VIRTUAL_SHOP";
        this.details_id = "COMPANY_DETAILS_FOR_VIRTUAL_SHOP";
        this.texts_id = "TEXTS_FOR_VIRTUAL_SHOP";

        this.api = {
            get: "get_orders_site_settings",
            edit: "edit_orders_site_settings",
            save_colors: "save_colors_orders_site_settings",
            save_images: "save_images_orders_site_settings",

            get_tags: "get_tags_to_site",
            get_msg: "get_pre_defined_msg_list",
            get_cust_tag: "get_customer_tags_list",
            get_item_tag: "get_tags_with_types",

            get_pay: "get_pay_for_virtual_shop",
            save_pay: "save_pay_for_virtual_shop",

            get_details: "get_comp_details_for_virtual_shop",
            save_details: "save_comp_details_for_virtual_shop",

            get_texts: "get_texts_for_virtual_shop",
            save_texts: "save_texts_for_virtual_shop",

        }

        this.selectorTags = TableIDs.genericSelectorTagsToSite;
        this.selectorMsg = TableIDs.genericSelectorPreDefinedMsg;

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.pay_id] === undefined) props.data[this.pay_id] = { ...props.data.genericData };
        if (props.data[this.details_id] === undefined) props.data[this.details_id] = { ...props.data.genericData };
        if (props.data[this.texts_id] === undefined) props.data[this.texts_id] = { ...props.data.genericData };

        if (props.data[this.selectorTags] === undefined) props.data[this.selectorTags] = { ...props.data.genericSelector }
        if (props.data[this.selectorMsg] === undefined) props.data[this.selectorMsg] = { ...props.data.genericSelector }
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorDeliveryMethods] === undefined) props.data[TableIDs.genericSelectorDeliveryMethods] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector }


        this.mobile = window.innerWidth <= 600 ? true : false;

        this.urlCreateClicked = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadingData();
        // this.loadingDetails();
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(13448);
    }

    getEditorContainer = (value, onChange, key) => {
        return (<EditorContainer key={key} getText={this.getText} topMenu={false} value={value ?? ""} onChange={onChange} />)
    }

    loadingData = () => {
        const { _branch } = this.state;
        console.log("loadingData", _branch, Date.now())
        this.props.dataActions.genericDataGetEditRequested(this.id, this.props.user.companyCode, this.props.user.token, { script: this.api.get, params: { _branch } });
        this.props.dataActions.genericSelectorRefreshDataset(this.selectorTags, this.props.user.companyCode, this.props.user.token, this.api.get_tags, { tParnetId: null });
        this.props.dataActions.genericSelectorRefreshDataset(this.selectorMsg, this.props.user.companyCode, this.props.user.token, this.api.get_msg);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode, this.props.user.token, this.api.get_cust_tag);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.get_item_tag);
    }

    loadingPayments = () => {
        this.props.dataActions.genericDataSetFilter(this.pay_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_pay);
    }

    loadingDetails = () => {
        this.props.dataActions.genericDataGetEditWithParams(this.details_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_details);
    }

    loadingTexts = () => {
        this.props.dataActions.genericDataSetFilter(this.texts_id, this.props.user.companyCode, this.props.user.token, { lang: this.getText(100) }, this.api.get_texts);
    }

    handleOk = () => {
        const { _branch } = this.state;
        // this.saveEdit(this.id, this.api.edit, this.api.get, { _branch });
        this.saveEdit(this.id, this.api.edit, null, { _branch });
        setTimeout(this.loadingData, 1500);
    }

    getModalTitle = (title, actions, gActions, showsearch) => {
        return (<PageToolbar
            isModal={true}
            title={title}
            actionButtons={actions ? actions : []}
            genericActionButtons={gActions ? gActions : undefined}
            ui={this.props.ui}
            uiActions={this.props.uiActions}
            data={this.props.data}
            dataActions={this.props.dataActions}
            showsearch={showsearch}
        />)
    }

    modalTextsEdit = () => {
        let d = this.props.data[this.texts_id];
        let tInput = (t, r) => {
            if (r.key === d.editing) {
                return (<Input.TextArea value={d.editedRecord['eValue']} rows={3} onChange={(e) => {
                    this.props.dataActions.genericDataSetEdit(this.texts_id, { eValue: e.target.value });
                }} />)
            } else {
                return t
            }
        }
        let bIcon = (t, oc) => (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={t} onClick={oc} />)
        let eButton = (t, r) => {
            if (r.key !== d.editing) {
                return bIcon("edit", () => {
                    this.props.dataActions.genericDataStartEditing(this.texts_id, r.key, r.index)
                });
            } else {
                return (<span>
                    {bIcon("check", () => {
                        this.saveEdit(this.texts_id, this.api.save_texts, this.api.get_texts, { lang: this.getText(100) });
                    })}
                    {bIcon("close", () => { this.cancelEditInline(this.texts_id) })}
                </span>)
            }
        }

        let columns = [
            { title: this.getText(14105), key: 'eDesc', dataIndex: 'eDesc', width: '30%', render: (t) => t },
            { title: this.getText(14106), key: 'eValue', dataIndex: 'eValue', width: '50%', render: tInput },
            { title: this.getText(14107), width: '10%', render: eButton },
        ];

        return (
            <Modal
                visible={this.state.modalTextsEdit}
                onCancel={() => {
                    this.setState({ modalTextsEdit: false }, () => {
                        this.cancelEditInline(this.texts_id)
                    })
                }}
                footer={false}
                width={this.mobile ? '95%' : '60%'}
                title={this.getModalTitle(this.getText(14104), [], [], val => { this.setState({ modalTextsSearch: val }) })}
            >
                <ResponsiveTable
                    tableOnly={true}
                    idTable={{
                        ...this.props.data[this.texts_id],
                        data: this.props.data[this.texts_id].data.filter(f => !this.state.modalTextsSearch || f?.indexOf(this.state.modalTextsSearch) > -1)
                    }}
                    columns={columns}
                    ui={this.props.ui}
                    pagination={{ pageSize: 10 }}
                />
            </Modal>)
    }

    cancelEditInline = (id) => {
        if (this.props.data[id].editedRecord.key == 0)
            this.props.dataActions.genericDataCancelInlineCreate(id);
        else
            this.props.dataActions.genericDataCancelEditing(id);
    }

    saveEdit = (id, saveScript, getScript, params) => {
        console.log("loadingdata-2", { id, saveScript, getScript, params }, Date.now())
        if (params && getScript) {
            this.props.dataActions.genericDataSaveEditWithParams(
                id, this.props.user.companyCode, this.props.user.token, saveScript, getScript, params);
        } else if (getScript) {
            this.props.dataActions.genericDataSaveEdit(
                id, this.props.user.companyCode, this.props.user.token, saveScript, getScript);
        } else if (params) {
            this.props.dataActions.genericDataJustSaveEditWithParams(
                id, this.props.user.companyCode, this.props.user.token, saveScript, params);
        } else {
            this.props.dataActions.genericDataJustSaveEdit(
                id, this.props.user.companyCode, this.props.user.token, saveScript);
        }
    }

    modalPayToSite = () => {
        let data = [...this.props.data[this.pay_id].data];

        let cb = (t, r) => {
            return (<Checkbox checked={t === '1'} onChange={(e) => {
                let tForVS = e.target.checked ? '1' : '0';
                data[r.index] = r.key + '\f' + r.tName + '\f' + tForVS;
                this.props.dataActions.setJson(this.pay_id, { data: data });
            }} />)
        }

        let columns = [
            { title: '', key: 'tForVS', dataIndex: 'tForVS', width: '20%', render: (t, r) => cb(t, r) },
            { title: this.getText(13524), key: 'tName', dataIndex: 'tName', width: '80%', render: (t, r) => t }
        ];

        return (<Modal
            visible={this.state.modalPayToSite}
            onCancel={() => { this.setState({ modalPayToSite: false }) }}
            width={this.mobile ? '95%' : '30%'}
            footer={[
                <Button type="primary" onClick={() => {
                    let list = data.filter(f => f.split("\f")[2] === '1').map(x => x.split("\f")[0]);
                    let tList = list ? list.join(',') : '';
                    this.sendAPI(this.api.save_pay,
                        "tList\r" + tList,
                        () => { this.setState({ modalPayToSite: false }) },
                        (e) => { message.error(e) });
                }}>{this.getText(13468)}</Button>
            ]}
            title={this.getModalTitle(this.getText(13523))}
        >
            <ResponsiveTable
                tableOnly={true}
                idTable={this.props.data[this.pay_id]}
                columns={columns}
                ui={this.props.ui}
                pagination={{ pageSize: 10 }}
            />
        </Modal>)
    }

    modalCompanyDetails = () => {
        let fieldStyle = (label, field, editor) => {
            let idEdit = field == "pContactAsHtml" || field == "pContactAsFranchiseHtml" || field == "pPrivacyStatementHtml" || field == "pTermOfUse" ? this.id : this.details_id

            let v = this.props.data[idEdit].editedRecord[field];
            let oc = (e) => { this.props.dataActions.genericDataSetEdit(idEdit, { [field]: e }) }

            return (<Col span={this.mobile ? 24 : editor ? 12 : 6} style={{ padding: 10 }}>
                <Form.Item label={this.getText(label)}>
                    {editor ? this.getEditorContainer(v, oc, field) : (<Input value={v} onChange={e => { oc(e.target.value) }} />)}
                </Form.Item>
            </Col>)
        }

        return (<Modal
            visible={this.state.modalCompanyDetails}
            onCancel={() => { this.setState({ modalCompanyDetails: false }) }}
            width={'95%'}
            footer={[
                <Button type="primary" onClick={() => {
                    this.setState({ modalCompanyDetails: false }, () => {
                        let pLogoHeader = this.props.data[this.id].editedRecord.pLogoHeader;
                        this.saveEdit(this.details_id, this.api.save_details, this.api.get_details, { pLogoHeader });
                        this.handleOk()
                    });
                }}>{this.getText(13572)}</Button>
            ]}
            title={this.getModalTitle(this.getText(14093))}
        >
            <Row>
                {fieldStyle(14094, 'dName')}
                {fieldStyle(14095, 'dDesc')}
                {fieldStyle(14146, 'dPhone')}
                {this.fieldStyleImage(15454, 'pLogoHeader')}
                {fieldStyle(14096, 'pTermOfUse', 1)}
                {fieldStyle(14097, 'dAbout', 1)}
                {fieldStyle(18731, 'pContactAsHtml', 1)}
                {fieldStyle(18732, 'pContactAsFranchiseHtml', 1)}
                {fieldStyle(18733, 'pPrivacyStatementHtml', 1)}
            </Row>
        </Modal>)
    }

    modalColors = () => {
        let fieldStyle = (label, field) => {
            let buttonStyle = {
                marginTop: "5px",
                marginLeft: "5px",
                width: "100px",
                backgroundColor: this.props.data[this.id].editedRecord[field]
            }

            return (<Col span={this.mobile ? 24 : 6} style={{ padding: 10 }}>
                <Form.Item label={this.getText(label)}>
                    <Tooltip title={this.getText(14118)}>
                        <Popover placement="top" trigger="click"
                            content={(<TwitterPicker colors={colorPallete} width={'100%'} triangle='hide' onChange={(color) => {
                                this.props.dataActions.genericDataSetEdit(this.id, { [field]: colourHexToName(color.hex) })
                            }} />)}
                            title={this.getModalTitle(this.getText(14119))} >
                            <Button className={"btn"} style={buttonStyle}>
                                <Icon style={{ fontSize: 16 }} type="edit" theme="outlined" />
                            </Button>
                        </Popover>
                    </Tooltip>
                </Form.Item>
            </Col>)
        }

        return (<Modal
            visible={this.state.modalColors}
            onCancel={() => { this.setState({ modalColors: false }) }}
            width={this.mobile ? '95%' : 800}
            footer={[
                <Button type="primary" onClick={() => {
                    this.setState({ modalColors: false }, () => {
                        this.saveEdit(this.id, this.api.save_colors, 0, 0);
                        setTimeout(this.loadingData, 500);
                    });
                }}>{this.getText(14122)}</Button>
            ]}
            title={this.getModalTitle(this.getText(14121))}
        >
            <Row>
                {fieldStyle(14123, 'rTitle')}
                {fieldStyle(14124, 'rSubTitles')}
                {fieldStyle(14125, 'rPrimaryButtons')}
                {fieldStyle(14126, 'rSecondaryButtons')}
                {fieldStyle(14127, 'rBanners')}
                {fieldStyle(14128, 'rFonts')}
                {fieldStyle(17225, 'rNavbar1')}
                {fieldStyle(17226, 'rNavbar2')}
            </Row>
        </Modal>)
    }

    modalImages = () => {

        return (<Modal
            visible={this.state.modalImages}
            onCancel={() => { this.setState({ modalImages: false, googleImage: null, googleImageField: null }) }}
            width={this.mobile ? '95%' : 800}
            footer={[
                <Button type="primary" onClick={() => {
                    this.setState({ modalImages: false }, () => {
                        this.saveEdit(this.id, this.api.save_images, 0, 0);
                        setTimeout(this.loadingData, 500);
                    });
                }}>{this.getText(14131)}</Button>
            ]}
            title={this.getModalTitle(this.getText(14129))}
        >
            <Row>
                {this.fieldStyleImage(14132, 'pImageTop')}
                {this.fieldStyleImage(20470, 'pImageTopMobile')}

                {this.fieldStyleImage(14133, 'pBanner1')}
                {this.fieldStyleImage(14134, 'pBanner2')}
                {this.fieldStyleImage(14135, 'pBanner3')}
                {this.fieldStyleImage(14136, 'pBanner4')}
                {this.fieldStyleImage(14137, 'pBanner5')}
                {this.fieldStyleImage(14138, 'pBanner6')}
                {this.fieldStyleImage(14139, 'pBanner7')}
            </Row>
            <Row>
                {this.fieldStyleImage(15811, 'pOrderFormTopBanner')}
                {this.fieldStyleImage(15812, 'pOrderFormBootomBanner')}
            </Row>
        </Modal>)
    }

    fieldStyleImage = (label, field) => {
        let aspect = field === 'pLogoHeader' ? 1 : field === 'pImageTop' ? 16 / 9 : field === 'pImageTopMobile' ? 5 / 12 : 4 / 1;
        let imageSearchValue = field === 'pLogoHeader' ? this.props.data[this.details_id].editedRecord.dName : '';
        let onChange = (url) => this.props.dataActions.genericDataSetEdit(this.id, { [field]: url });
        return (<Col span={this.mobile ? 24 : field === 'pImageTop' || field === 'pImageTopMobile' ? 12 : 6} style={{ padding: 10, textAlign: 'center' }}>
            <Form.Item label={this.getText(label)}>
                {field === 'pImageTop' || field === 'pImageTopMobile' ?
                    <VideoView
                        {...this.props}
                        url={this.props.data[this.id].editedRecord[field]}
                        onChange={onChange}
                        onRemove={() => { onChange("") }}
                        onLoadDuration={() => { }}
                        notLimit />
                    : <>
                        <PictureView
                            imageType={'V'} aspect={aspect} url={this.props.data[this.id].editedRecord[field]}
                            onRemove={() => { onChange('') }} onChange={onChange}
                            user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                            ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                            onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
                            googleImage={this.state.googleImageField === field ? this.state.googleImage : null}
                        />
                        <div style={{ float: 'left' }}>
                            <ImageSearch
                                onChange={(base64) => this.setState({ googleImage: base64, googleImageField: field })}
                                defaultValue={imageSearchValue}
                                user={this.props.user} data={this.props.data} dataActions={this.props.dataActions}
                                ui={this.props.ui} uiActions={this.props.uiActions} ActionQueue={this.props.ActionQueue}
                            />
                        </div>
                    </>}
            </Form.Item>
        </Col>)
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    checkIfItemExist = (field, s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemCode = itemsRows[0].split('\f')[1];

                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal, {
                            ...this.props.ui.data,
                            idSelector: `itemSelector_${field}`
                        });
                    }
                },
                () => {
                    message.error(this.getText(12034));
                }
            );
        }
    }

    getPizaLogicBar = () => {
        let value = this.props.data[this.id].editedRecord.pPizzaLogicDetailed;
        let barData = value ? value.split('&').map(x => x ? x.split('=') : []) : [];
        barData = [...barData, [null, null]]

        let changeData = (row, box, data) => {
            barData[box][row] = data;
            this.props.dataActions.genericDataSetEdit(this.id, {
                pPizzaLogicDetailed: barData.filter(f => f && f[0]).map(x => x.join('=')).join('&')
            })
        }

        let frameStyle = {
            width: '100%',
            overflow: 'scroll'
        }

        let rowStyle = {
            width: (barData.length + 1) * 75,
            backgroundColor: '#aaaaaa',
        }

        let boxStyle = {
            display: 'inline-block',
            verticalAlign: 'text-top',
            width: 75,
            padding: 2
        }

        return (<div style={frameStyle}>
            {[0, 1].map(row => {
                return (<div style={rowStyle}>
                    <h3 style={boxStyle}>{this.getText(row ? 16479 : 16478)}</h3>
                    {barData.map((box, boxIndex) => {
                        return (<div style={boxStyle}>
                            <InputNumber style={{ width: '100%' }} value={box[row]} onChange={data => { changeData(row, boxIndex, data) }} />
                        </div>)
                    })}
                </div>)
            })}
        </div>)

    }

    render() {

        let editedRecord = this.props.data[this.id].editedRecord;

        let fStyle = {
            display: 'inline-block',
            verticalAlign: 'text-top',
            padding: 8,
        }

        let dStyle = {
            padding: 4,
            margin: 'auto',
            marginTop: 10,
            width: '100%',
        }

        let hn = window.origin + this.props.baseUrl;
        let details = this.props.data[this.details_id].data[0];
        let siteTitle = details ? details.split("\f")[0] : '';

        let tokenUrl = editedRecord.tokenUrl ?
            hn +
            "?token=" + editedRecord.tokenUrl +
            "&type=4" +
            "&code=" + this.props.user.companyCode +
            "&title=" + siteTitle +
            "&lang=" + this.getText(100)
            : "";

        let fieldStyle = (label, field, type, inline) => {
            let content = () => {
                let value = editedRecord[field];
                let onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }
                let getSelect = (options) => (<Select value={value} onChange={onChange} style={{ width: '90%' }}>{options}</Select>);

                switch (type) {
                    case 'text':
                        return (<Input value={value} onChange={e => { onChange(e.target.value) }} />);

                    case 'textarea':
                        return (<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />);

                    case 'number': case 'sum':
                        return (<InputNumber min={0} step={1} precision={type === 'sum' ? 2 : 0} value={value} onChange={onChange} />);

                    case 'checkbox':
                        return (<Checkbox checked={value == '1' ? true : false} onChange={(e) => { onChange(e.target.checked ? '1' : '0') }} />);

                    case 'select':
                        return getSelect(this.props.data[this.selectorTags].dataset.map((x) => (<Option value={x.code}>{x.name}</Option>)));

                    case 'messages':
                        return getSelect(this.props.data[this.selectorMsg].dataset.map((x) => (<Option value={x.code}>{x.name}</Option>)));

                    case 'startSelect':
                        let sList = [{ l: 13590, v: 'Shop' }, { l: 13591, v: 'OrderType' }];
                        if (field === 'pStartPage') sList = [...sList, { l: 13592, v: 'Login' }, { l: 19002, v: 'LoginRequired' }];
                        return getSelect(sList.map(x => (<Option value={x.v}>{this.getText(x.l)}</Option>)));

                    case 'mcvSelect':
                        return getSelect([
                            <Option value={'1'}>{this.getText(14355)}</Option>,
                            <Option value={'2'}>{this.getText(14356)}</Option>,
                        ]);

                    case 'dcvSelect':
                        return getSelect([
                            <Option value={'1'}>{this.getText(15814)}</Option>,
                            <Option value={'2'}>{this.getText(15815)}</Option>,
                            <Option value={'3'}>{this.getText(18481)}</Option>,
                            <Option value={'4'}>{this.getText(18643)}</Option>,
                            <Option value={'5'}>{this.getText(19350)}</Option>,
                            <Option value={'6'}>{this.getText(19549)}</Option>,
                        ]);

                    case 'lovSelect':
                        return getSelect([
                            <Option value={'1'}>{this.getText(14421)}</Option>,
                            <Option value={'2'}>{this.getText(14422)}</Option>,
                        ]);

                    case 'pizzaSelect':
                        return getSelect([
                            <Option value={'1'}>{this.getText(15567)}</Option>,
                            <Option value={'2'}>{this.getText(15568)}</Option>,
                            <Option value={'3'}>{this.getText(16477)}</Option>,
                            <Option value={'4'}>{this.getText(19929)}</Option>,
                        ])

                    case 'ivdSelect':
                        return getSelect([
                            <Option value={'1'}>{this.getText(16123)}</Option>,
                            <Option value={'2'}>{this.getText(16124)}</Option>,
                        ]);

                    case 'dctSelect':
                        return getSelect([
                            <Option value={'0'}>{this.getText(19756)}</Option>,
                            <Option value={'1'}>{this.getText(19757)}</Option>,
                        ]);

                    case 'oexSelect':
                        return getSelect([
                            <Option value={'0'}>{this.getText(16210)}</Option>,
                            <Option value={'1'}>{this.getText(16211)}</Option>,
                        ]);

                    case 'bhvSelect':
                        return getSelect([
                            <Option value={'1'}>{this.getText(18875)}</Option>,
                            <Option value={'2'}>{this.getText(18876)}</Option>,
                        ]);

                    case 'bsSelect':
                        return getSelect(['1', '2', '3', '4', '5', '6', '7'].map(x => (<Option value={x}>{x}</Option>)));

                    case 'custTags':
                        return getSelect(this.props.data[TableIDs.genericMultiSelectorCustomerTags].dataset.map((node, index) => {
                            return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                                {node.tags.map((item, index) => {
                                    return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                                })}
                            </OptGroup>)
                        }));

                    case 'itemTags':
                        return getSelect([
                            <Option key={'null'} value={''}> - </Option>,
                            ...this.props.data[TableIDs.genericMultiSelectorItemTags].dataset.map((node, index) => {
                                return (<OptGroup label={node.type.text} key={"type_" + node.type.id}>
                                    {node.tags.map((item, index) => {
                                        return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                                    })}
                                </OptGroup>)
                            })]);

                    case 'itemPicker':
                        return (
                            <SelectorItems
                                idSelector={`itemSelector_${field}`}
                                user={this.props.user} uiActions={this.props.uiActions} ui={this.props.ui} dataActions={this.props.dataActions} data={this.props.data} ActionQueue={this.props.ActionQueue}
                                ctrlBKeyFlag={null}
                                isExpandMatrixFatherItem={false}
                                itemType={1}
                                value={value}
                                onChangeSearch={(s) => onChange(s.mCode)}
                                onChange={(s) => { onChange(s.target.value) }}
                                onBlur={(s) => { this.checkIfItemExist(field, s.target.value) }}
                            />
                        )

                    case 'methodSelect':
                        return (<div style={{ width: '90%' }}>
                            <GenericSelector
                                {...this.props}
                                id={TableIDs.genericSelectorDeliveryMethods}
                                api={"delivery_methods_list"}
                                value={value}
                                onChange={onChange}
                                addFirstRow={{ code: "-", name: "" }}
                            />
                        </div>)

                    case 'customer':
                        return (<div style={{ width: '90%' }}>
                            <CustPicker
                                value={value}
                                onChange={onChange}
                                defaultView={this.getText(12121)}
                                {...this.props}
                            />
                        </div>)

                }
            }

            return type == 'checkbox' ? (<div>
                <div style={fStyle}>{content()}</div>
                <div style={fStyle}>{this.getText(label)}</div>
            </div>) :
                // inline ?
                (<div>
                    <div style={{ ...fStyle, width: '50%' }}>{this.getText(label)}</div>
                    <div style={{ ...fStyle, width: '50%' }}>{content()}</div>
                </div>)
            // :
            // (<Form.Item label={this.getText(label)}>{content()}</Form.Item>)
        }

        let buttonStyle = (m, t, l) => {
            let bStyle = { width: '95%', margin: 5 }
            return (<Button onClick={() => { this.setState({ [m]: true }, l) }} style={bStyle}>{this.getText(t)}</Button>);
        }

        return (
            <div style={dStyle} >
                <PageToolbar
                    title={this.getText(13449)}
                    actionButtons={[{
                        buttonType: 'refresh',
                        buttonFunction: this.loadingData
                    }]}
                    isModal={false}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />

                {tokenUrl ? (<Row>
                    <Col span={this.mobile ? 24 : 20} style={{ padding: 5 }}>
                        <Card style={{ border: '1px solid LightBlue' }}>
                            <h3>{this.getText(14142)}:</h3>
                            <a href={tokenUrl} target="_blank" style={{ wordBreak: "break-all" }}>{tokenUrl}</a>
                        </Card>
                    </Col>
                    <Col span={this.mobile ? 24 : 4} style={{ padding: 5 }}>
                        <Card style={{ border: '1px solid LightBlue' }} >
                            <h3>{this.getText(14143)}:</h3>
                            {editedRecord.insertNumerator}
                        </Card>
                    </Col>
                </Row>) :
                    editedRecord.tokenUrl === undefined ? "" :
                        buttonStyle('urlCreate', 14145, () => {
                            if (!this.urlCreateClicked) {
                                this.urlCreateClicked = true;
                                this.sendAPI('create_link_to_virtual_shop', "", this.loadingData, (e) => { console.error(e) })
                            }
                        })}

                <Row>
                    <Col span={this.mobile ? 24 : 12} style={{ padding: 5 }}><Card title={this.getText(20649)}>
                        <GenericSelector
                            {...this.props}
                            id={TableIDs.genericSelectorBranches}
                            api={"get_branch_list"}
                            value={this.state._branch}
                            onChange={e => { this.setState({ _branch: e }, this.loadingData) }}
                            addFirstRow={{ code: '0', name: this.getText(16391) }}
                        />
                        {buttonStyle('modalPrimaryView', 16117)}
                        {buttonStyle('modalColors', 14120)}
                        {buttonStyle('modalImages', 14130)}
                        {buttonStyle('modalShopSettings', 16115)}
                        {buttonStyle('modalCustomerAuthentication', 16116)}
                    </Card></Col>
                    <Col span={this.mobile ? 24 : 12} style={{ padding: 5 }}><Card title={this.getText(20650)}>
                        {buttonStyle('modalCompanyDetails', 14092, this.loadingDetails)}
                        {buttonStyle('modalTextsEdit', 14103, this.loadingTexts)}
                        {buttonStyle('modalPizzaSlicesTypes', 20444)}
                        {buttonStyle('modalComponents', 18446)}
                        {buttonStyle('modalDeliveryMethods', 13459)}
                        {buttonStyle('modalMessages', 14360)}
                        {buttonStyle('modalCategories', 14158)}
                        {buttonStyle('modalNetworkLinks', 15803)}
                        {buttonStyle('modalRadius', 13487)}
                        {buttonStyle('modalBranchShippingAreas', 19731)}
                        {buttonStyle('modalDowHours', 13488)}
                        {buttonStyle('modalPayToSite', 13523, this.loadingPayments)}
                        {buttonStyle('modalStatuses', 14471)}
                    </Card></Col>

                </Row>


                <Modal
                    visible={this.state.modalPrimaryView}
                    onCancel={() => { this.setState({ modalPrimaryView: false }) }}
                    width={this.mobile ? '95%' : 800}
                    footer={[
                        <Button type="primary" onClick={() => {
                            this.setState({ modalPrimaryView: false }, this.handleOk)
                        }}>{this.getText(13458)}</Button>
                    ]}
                    title={this.getModalTitle(this.getText(16117))}
                >
                    {fieldStyle(13589, 'pStartPage', 'startSelect')}
                    {fieldStyle(15388, 'pStartPageFromPos', 'startSelect')}
                    {fieldStyle(15681, 'pPOSDefaultDeliveryMethod', 'methodSelect')}
                    {fieldStyle(15682, 'pPOSDefaultCustomerId', 'customer')}
                    {fieldStyle(14354, 'pMobileCategoriesView', 'mcvSelect')}
                    {fieldStyle(15813, 'pDesktopCategoriesView', 'dcvSelect')}
                    {fieldStyle(14420, 'pLevelOptionsView', 'lovSelect')}
                    {fieldStyle(13457, 'pSliderTag', 'select')}
                    {fieldStyle(16121, 'pDefaultItemViewDesktop', 'ivdSelect')}
                    {fieldStyle(16122, 'pDefaultItemViewPos', 'ivdSelect')}
                    {fieldStyle(16209, 'pNotOpenExtraByDefault', 'oexSelect')}
                    {fieldStyle(18874, 'pBranchActiveHourViewType', 'bhvSelect')}
                    {fieldStyle(16364, 'pWarningDeleteCartInPos', 'checkbox')}

                    {fieldStyle(19755, 'pDeliveryCalcType', 'dctSelect')}
                    {fieldStyle(19899, 'pCustomerSupportSMS', 'text')}
                    {fieldStyle(19900, 'pCustomerSupportRemarks', 'textarea')}
                    {fieldStyle(19910, 'pCustomerSupportMessageId', 'messages')}
                </Modal>
                <Modal
                    visible={this.state.modalShopSettings}
                    onCancel={() => { this.setState({ modalShopSettings: false }) }}
                    width={'95%'}
                    footer={[
                        <Button type="primary" onClick={() => {
                            this.setState({ modalShopSettings: false }, this.handleOk)
                        }}>{this.getText(13458)}</Button>
                    ]}
                    title={this.getModalTitle(this.getText(16115))}
                >
                    <Row>
                        <Col span={this.mobile ? 24 : 8}>
                            {fieldStyle(13456, 'pMinimum', 'sum', true)}
                            {fieldStyle(13544, 'pMaxPayNum', 'number', true)}
                            {fieldStyle(13545, 'pMinToPay', 'sum', true)}
                            {fieldStyle(16213, 'pDiscountItemTag', 'itemTags', true)}
                            {fieldStyle(16221, 'pDiscountItem', 'itemPicker', true)}
                            {fieldStyle(16281, 'pBonSize', 'bsSelect', true)}
                        </Col>
                        <Col span={this.mobile ? 24 : 6}>
                            {fieldStyle(17535, 'pPricesNoTax', 'checkbox', true)}
                            {fieldStyle(13452, 'pIsCibus', 'checkbox', true)}
                            {fieldStyle(13453, 'pIs10Bis', 'checkbox', true)}
                            {fieldStyle(13454, 'pIsGoodi', 'checkbox', true)}
                            {fieldStyle(13455, 'pIsCustDebit', 'checkbox', true)}
                            {fieldStyle(14195, 'pIsCustPoints', 'checkbox', true)}
                            {fieldStyle(14465, 'pLinkToOrder', 'checkbox', true)}
                            {fieldStyle(15485, 'pNotifyWhatsappManager', 'checkbox', true)}
                            {fieldStyle(15529, 'pBlockBranchWhenDelivery', 'checkbox', true)}
                            {fieldStyle(16365, 'pBranchSelectInPos', 'checkbox', true)}
                        </Col>
                        <Col span={this.mobile ? 24 : 10}>
                            {fieldStyle(17685, 'pMinusPriceItemWoo', 'itemPicker', true)}
                            {fieldStyle(13569, 'pMinusPriceItemMishlocha', 'itemPicker', true)}
                            {fieldStyle(13567, 'pMinusPriceItemCibus', 'itemPicker', true)}
                            {fieldStyle(13568, 'pMinusPriceItem10bis', 'itemPicker', true)}
                            {fieldStyle(14466, 'pMinusPriceItemGoodi', 'itemPicker', true)}
                            {fieldStyle(18745, 'pMinusPriceItemManager', 'itemPicker', true)}
                            {fieldStyle(20395, 'pMinusPriceItemWolt', 'itemPicker', true)}
                            {fieldStyle(20438, 'pMinusPriceItemAmazing', 'itemPicker', true)}
                            {fieldStyle(13570, 'pItemReplacement', 'itemPicker', true)}
                            {fieldStyle(14469, 'pItemDeliveryFee', 'itemPicker', true)}
                            {fieldStyle(15566, 'pPizzaLogic', 'pizzaSelect')}
                            {(editedRecord['pPizzaLogic'] == '3') ? this.getPizaLogicBar() : ""}
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    visible={this.state.modalCustomerAuthentication}
                    onCancel={() => { this.setState({ modalCustomerAuthentication: false }) }}
                    width={window.innerWidth > 1000 ? 1000 : '95%'}
                    footer={[
                        <Button type="primary" onClick={() => {
                            this.setState({ modalCustomerAuthentication: false }, this.handleOk)
                        }}>{this.getText(13458)}</Button>
                    ]}
                    title={this.getModalTitle(this.getText(16116))}
                >
                    {fieldStyle(13542, 'pGuestTag', 'custTags', true)}
                    {fieldStyle(13543, 'pRegTag', 'custTags', true)}
                    {fieldStyle(13522, 'pMessageID', 'messages', true)}
                    {fieldStyle(14300, 'pNotRegMessageId', 'messages', true)}
                    {fieldStyle(13450, 'pTokenMin', 'number', true)}
                    {fieldStyle(13451, 'pCancelMin', 'number', true)}
                    {fieldStyle(13521, 'pTwoFactor', 'number', true)}
                    {fieldStyle(16148, 'pSumTwoFactorInPos', 'sum')}

                </Modal>
                {this.modalCompanyDetails()}
                {this.modalPayToSite()}
                {this.modalTextsEdit()}
                {this.modalColors()}
                {this.modalImages()}

                <VirtualShopMethods visible={this.state.modalDeliveryMethods} onCancel={() => { this.setState({ modalDeliveryMethods: false }) }} {...this.props} />
                <VirtualShopDowHours visible={this.state.modalDowHours} onCancel={() => { this.setState({ modalDowHours: false }) }} {...this.props} />
                <VirtualShopCategories visible={this.state.modalCategories} onCancel={() => { this.setState({ modalCategories: false }) }} {...this.props} />
                <VirtualShopMessages visible={this.state.modalMessages} onCancel={() => { this.setState({ modalMessages: false }) }} {...this.props} />
                <VirtualShopStatuses visible={this.state.modalStatuses} onCancel={() => { this.setState({ modalStatuses: false }) }} {...this.props} />
                <VirtualShopRadius visible={this.state.modalRadius} onCancel={() => { this.setState({ modalRadius: false }) }} {...this.props} />
                <VirtualShopBranchShippingAreas visible={this.state.modalBranchShippingAreas} onCancel={() => { this.setState({ modalBranchShippingAreas: false }) }} {...this.props} />
                <VirtualShopNetworkLinks visible={this.state.modalNetworkLinks} onCancel={() => { this.setState({ modalNetworkLinks: false }) }} {...this.props} />
                <VirtualShopCSSValues visible={this.state.modalComponents} onCancel={() => { this.setState({ modalComponents: false }) }} {...this.props} />
                <PizzaSlicesExchange visible={this.state.modalPizzaSlicesTypes} onCancel={() => { this.setState({ modalPizzaSlicesTypes: false }) }} {...this.props} />
            </div >)
    }

}
export default OrdersSiteSettings;