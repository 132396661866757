/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import Jax from "../Jax/jax.es6.module";
import { Button, Col, Form, Row, Tooltip, Icon, Card, DatePicker, Select, Slider, Switch, message, Spin, Radio, } from "antd";
import { withRouter } from 'react-router-dom';
//import Nis from "../components/Nis";
import 'ant-design-pro/dist/ant-design-pro.css';
import moment from "moment";
import ClockOn from "../components/ClockOn";
import TableIDs from "../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import LangContext from "../contextProvider/LangContext";
import "./Dashboard.css";
import BranchTableSection from "./Dashboard/BranchTableSection";
import BranchPaysTableSection from "./Dashboard/BranchPaysTableSection";
import TopEmployeesSection from "./Dashboard/TopEmployeesSection";
import TopLinesSection from "./Dashboard/TopLinesSection";
import DocTypesSection from "./Dashboard/DocTypesSection";
import GraphBetwenSection from "./Dashboard/GraphBetwenSection";
import ShortSummarySection from "./Dashboard/ShortSummarySection";
import SegmentationSection from "./Dashboard/SegmentationSection";
import FlashTitle from "./Dashboard/FlashTitle";
import GenericModal from "../components/GenericModal";
import FavoriteReportsForAutoIphone from "./Dashboard/FavoriteReportsForAutoIphone";
import NewOrdersForAutoIphone from "./foods/NewOrdersForAutoIphone";
import CarListForAutoIphone from "./Dashboard/CarListForAutoIphone";
import PosMessagesForAutoIphone from "./Dashboard/PosMessagesForAutoIphone";
import GenericSelector from "../components/GenericSelector";
import CreateDoc15ForAutoIphone from "./Dashboard/CreateDoc15ForAutoIphone";
import { Checkbox } from "antd";
import GenericSelectorPosStorages from "../components/GenericSelectorPosStorages";
import ExcludedItemsForAutoIphone from "./ItemList/ExcludedItemsForAutoIphone";
const FormItem = Form.Item;
const Option = Select.Option;

type History = {
    push: Function,
    location: {
        pathname: string
    }
};

type Props = {
    user: any,
    data: Object,
    dataActions: Object,
    ActionQueue: Object,
    history: History,
    baseUrl: string
};

type State = {
    isLoading: boolean,
    isSalesExtended: boolean,
    isAverageSaleExtended: boolean,
    isAverageLinesExtended: boolean,
    isProductivityExtended: boolean,
    isMonthlySalesGraphExtended: boolean,
    isHourlySalesGraphExtended: boolean,
    isPaymentsGraphExtended: boolean,
    dashboardDate: Date,
    dashboardPos: Array<number>,
    isFilterModalShown: boolean,
    updateInterval: number,
    timer: any,
    dashboardData?: {
        recent: { pos: number, sum: number, timestamp: string },
        documents: {
            today: { count: number, max: number, sum: number, avg: number },
            weekAgo: { count: number, max: number, sum: number, avg: number, diff: number, diffAvg: number },
            monthAgo: { count: number, max: number, sum: number, avg: number, diff: number, diffAvg: number },
            sixMonthsAgo: { count: number, max: number, sum: number, avg: number, diff: number, diffAvg: number },
            twelveMonthsAgo: { count: number, max: number, sum: number, avg: number, diff: number, diffAvg: number },
        },
        lines: {
            today: { documentCount: number, lineCount: number, avg: number },
            weekAgo: { documentCount: number, lineCount: number, avg: number, diff: number },
            monthAgo: { documentCount: number, lineCount: number, avg: number, diff: number },
            sixMonthsAgo: { documentCount: number, lineCount: number, avg: number, diff: number },
            twelveMonthsAgo: { documentCount: number, lineCount: number, avg: number, diff: number },
        },
        productivity: {
            today: { totalSales: number, totalWorkingHours: number, productivity: number },
            weekAgo: { totalSales: number, totalWorkingHours: number, productivity: number, diff: number },
            monthAgo: { totalSales: number, totalWorkingHours: number, productivity: number, diff: number },
            sixMonthsAgo: { totalSales: number, totalWorkingHours: number, productivity: number, diff: number },
            twelveMonthsAgo: { totalSales: number, totalWorkingHours: number, productivity: number, diff: number },
        },
        payments: Array<{ x: string, y: number }>,
        monthly: Array<{ x: number, y: number }>,
        hourly: Array<Object>,
        pos: Array<{ key: number, pos: string, count: number, totalSales: number, average: number, averageLines: number, discountSum: number, returnSum: number, returnCount: number }>
    }
};


class Dashboard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.sButtonList = [
            'shortSummary',
            'segmentation',
            'branchTable',
            'branchPaysTable',
            'graphBetwen',
            'topEmployees',
            'topLines',
            'docTypes',
        ];

        this.state = {
            isLoading: true,
            isSalesExtended: false,
            isAverageSaleExtended: false,
            isAverageLinesExtended: false,
            isProductivityExtended: false,
            isMonthlySalesGraphExtended: false,
            isHourlySalesGraphExtended: false,
            isPaymentsGraphExtended: false,
            dashboardDate: new Date(),
            dashboardPos: [],
            isFilterModalShown: false,
            updateInterval: 240,
            timer: null,
            lastLoad: null,
            graphBetwenType: 0,
            graphBetwenDinami: 0,
            graphHoursPerPos: 0,
            branchTablePerPos: 0,
            viewPaysType: '0',
            dDateCheck: null,
            soundActive: false,
            cMinimizes: {
                shortSummary: 0,
                segmentation: 0,
                branchTable: 0,
                branchPaysTable: 0,
                graphBetwen: 0,
                topEmployees: 0,
                topLines: 0,
                docTypes: 0,
            },
            sOrder: this.sButtonList,
            sButtons: false,
            modalSound: false,

            firstLoading: -1,

            modalFilter: {
                _dashboardDate: new Date(),
                _dashboardPos: [],
                _dDateCheck: null,
                _updateInterval: 240,
            },

        }

        //this.date = new Date;
        this.flagPos = false;
        this.flagConfiguration = false;
        this.lastRefresh = null;

        [TableIDs.genericSelectorPos, TableIDs.UsersDashboardConfigurations].forEach((x) => {
            if (props.data[x] === undefined) { props.data[x] = { ...props.data.genericSelector } }
        });

        this.isAutoIphone = window.location.search.indexOf("autoIphone=1") > -1;
        if (this.isAutoIphone) this.state.firstLoading = 0;

        this.catering_id = "CATERING_TICKETS_DATA";
        if (props.data[this.catering_id] === undefined) props.data[this.catering_id] = { ...props.data.genericData }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getParseInt = (str) => {
        return str ? parseInt(str) : 0
    }

    componentDidMount(): void {
        // if (this.isAutoIphone) this.getStatistics()
        // else this.timelyRefresh();

        this.getDataButtonsOrder();

        /////// TODO: remove this  in the future ///////
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, "fix_missing_item_defaults_v0", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        //job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab",
            (ob: Object) => { console.log("FIX: ", ob) },
            (error: any) => { console.log("FIX ERROR:", error) }
        );

        this.selectorPosApi();
    }

    selectorPosApi = () => {
        this.sendAPI("list_pos", "", (ob) => {

            let newPosList = ob.data ? ob.data.split('\r').map((x) => {
                let y = x.split('\f');
                return { code: y[0], name: y[1] }
            }) : [];

            this.sendAPI("get_users_dashboard_configurations", "", (ob2) => {
                let confList = ob2.data ? ob2.data.split('\r').map((x) => {
                    let y = x.split('\f');
                    return { code: y[0], name: y[1] }
                }) : [];

                this.props.dataActions.setJson(TableIDs.genericSelectorPos, { dataset: newPosList });
                this.props.dataActions.setJson(TableIDs.UsersDashboardConfigurations, { dataset: confList });

                let confPos = confList.find(f => f.code === '7' && f.name !== "");
                let confPosList = confPos ? confPos.name.split(',') : []
                let posListFilter = confPosList.length > 0 ? confPosList : newPosList.map(z => z.code);

                if (posListFilter.length > 0) this.filterFromPos(posListFilter);

            }, (e) => { console.error(e) })

        }, (e) => { console.error(e) })
    }

    filterFromPos = (value) => {
        if (value.length < 1) {
            this.selectorPosApi();
        } else {
            this.setState(
                Jax.set(this.state, "dashboardPos", value)
                , () => {
                    this.lastRefresh = null;

                    this.getStatistics()
                    // if (!this.isAutoIphone)  this.timelyRefresh();

                    this.getUsersConfigurations();
                });
        }
    }

    getDataCateringTickets = () => {
        let request = {
            _date: this.state.dashboardDate ? moment(this.state.dashboardDate).format("YYYY-MM-DD") : null,
            _posList: this.state.dashboardPos,
        };

        this.props.dataActions.genericDataSetFilter(this.catering_id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_catering_tickets");
    }

    componentDidUpdate(p, s) {
        if (this.isAutoIphone) document.title = "Cash On Tab";
        else document.title = "Cash On Tab - " + this.getText(10002);

        if (s.dashboardDate !== this.state.dashboardDate || s.dashboardPos !== this.state.dashboardPos) {
            this.getDataCateringTickets()
        }
    }

    componentWillUnmount(): void {
        if (this.state) {
            clearTimeout(this.state.timer);
        }
    }

    getUsersConfigurations = () => {
        let newState = {}
        let cMin = {}
        this.props.data[TableIDs.UsersDashboardConfigurations].dataset.forEach((x) => {
            switch (x.code) {
                case '1': cMin = { ...cMin, shortSummary: this.getParseInt(x.name) }; break;
                case '2': cMin = { ...cMin, segmentation: this.getParseInt(x.name) }; break;
                case '3': cMin = { ...cMin, branchTable: this.getParseInt(x.name) }; break;
                case '15': cMin = { ...cMin, branchPaysTable: this.getParseInt(x.name) }; break;
                case '4': cMin = { ...cMin, graphBetwen: this.getParseInt(x.name) }; break;
                case '5': cMin = { ...cMin, topEmployees: this.getParseInt(x.name) }; break;
                case '6': cMin = { ...cMin, topLines: this.getParseInt(x.name) }; break;
                case '12': cMin = { ...cMin, docTypes: this.getParseInt(x.name) }; console.log('docTypes', this.getParseInt(x.name)); break;
                // case '7': newState = { ...newState, dashboardPos: x.name.split(',').map((y) => this.getParseInt(y)) }; break;
                case '8': newState = { ...newState, graphBetwenType: this.getParseInt(x.name) }; break;
                case '9': newState = { ...newState, graphBetwenDinami: this.getParseInt(x.name) }; break;
                case '10': newState = { ...newState, dDateCheck: x.name === "" ? null : new Date(x.name) }; break;
                case '11': newState = { ...newState, graphHoursPerPos: this.getParseInt(x.name) }; break;
                case '13': newState = { ...newState, branchTablePerPos: this.getParseInt(x.name) }; break;
                case '14': newState = { ...newState, soundActive: x.name == '1' }; break;
                case '16': newState = { ...newState, viewPaysType: x.name ?? '0' }; break;
            }
        })
        newState = { ...newState, cMinimizes: { ...this.state.cMinimizes, ...cMin } }
        this.setState(newState);
    }

    parameterBuilder(request: Object) {
        request.setColumn("date", moment(this.state.dashboardDate).format("YYYY-MM-DD"));
        request.setColumn("pos", this.state.dashboardPos);

    }

    getStatistics() {
        let _interval = this.state.updateInterval * 1000;
        let _now = Date.now();

        if (!this.isAutoIphone || !this.lastRefresh || (_now - this.lastRefresh) > _interval) {
            this.lastRefresh = _now
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.genericNetworkRequest,
                args: [
                    "dashboard_get",
                    this.props.user.companyCode,
                    this.props.user.token,
                    this.parameterBuilder.bind(this),
                    function (state, error, data) {
                        let oState = state;
                        if (error != null) {
                            console.error(error.errorCode, error.errorMessage);
                        } else {
                            this.setState({ lastLoad: moment().format('HH:mm:ss') }, () => {

                                window.console.log(data);
                                let oData = JSON.parse(data);
                                let aHourly = oData.hourly.map((o) => {
                                    let midnight = new Date(this.state.dashboardDate.getFullYear(), this.state.dashboardDate.getMonth(), this.state.dashboardDate.getDate());
                                    o.x = midnight.getTime() + o.x;
                                    return o;
                                });
                                let hourlyMin = aHourly.reduce((v, o) => {
                                    let sum = 0;
                                    for (let key in o) {
                                        if (o.hasOwnProperty(key) && key.indexOf('y') === 0) {
                                            sum += o[key];
                                        }
                                    }
                                    if (sum !== 0) {
                                        if (v > -1) {
                                            return v;
                                        } else {
                                            return o.x;
                                        }
                                    } else {
                                        return v;
                                    }
                                }, -1);
                                let hourlyMax = aHourly.reduce((v, o) => {
                                    let sum = 0;
                                    for (let key in o) {
                                        if (o.hasOwnProperty(key) && key.indexOf('y') === 0) {
                                            sum += o[key];
                                        }
                                    }
                                    if (sum !== 0) {
                                        return o.x;
                                    } else {
                                        return v;
                                    }
                                }, -1);
                                aHourly = aHourly.filter((o) => {
                                    return o.x >= hourlyMin && o.x <= hourlyMax;
                                });
                                let aMonthly = [{ x: 0, y: 0 }];
                                let lastX = 0;
                                for (let i = 0, j = oData.monthly.length; i < j; i++) {
                                    let thisX = oData.monthly[i].x;
                                    while (thisX - lastX > 1) {
                                        lastX++;
                                        aMonthly.push({ x: lastX, y: 0 });
                                    }
                                    aMonthly.push(oData.monthly[i]);
                                    lastX = thisX;
                                }
                                let fixedData = Jax.set(oData, "monthly", aMonthly);
                                let localState = Jax.set(this.state, "dashboardData", Jax.set(fixedData, "hourly", aHourly));
                                localState = Jax.set(localState, "isLoading", false);

                                this.setState({ ...localState, firstLoading: this.state.firstLoading + 1 });
                            })
                        }
                        return oState;

                    }.bind(this),
                    {
                        loading: this.getText(10030),
                        failed: this.getText(10031),
                        success: ""
                    }
                ]
            });
        }
    }

    // timelyRefresh() {
    //     let _interval = this.state.updateInterval * 1000;
    //     this.getStatistics();
    //     clearTimeout(this.state.timer);
    //     this.setState(Jax.set
    //         (this.state, "timer",
    //             setTimeout(this.timelyRefresh.bind(this), _interval)
    //         )
    //     );
    // }

    getMonthName = (date) => {
        switch (date.getMonth()) {
            case 0: return this.getText(10041);
            case 1: return this.getText(10042);
            case 2: return this.getText(10043);
            case 3: return this.getText(10044);
            case 4: return this.getText(10045);
            case 5: return this.getText(10046);
            case 6: return this.getText(10047);
            case 7: return this.getText(10048);
            case 8: return this.getText(10049);
            case 9: return this.getText(10050);
            case 10: return this.getText(10051);
            case 11: return this.getText(10052);
        }
    }

    getDayName = (date) => {
        switch (date.getDay()) {
            case 0: return this.getText(10033);
            case 1: return this.getText(10034);
            case 2: return this.getText(10035);
            case 3: return this.getText(10036);
            case 4: return this.getText(10037);
            case 5: return this.getText(10038);
            case 6: return this.getText(10039);
        }
    }

    printDate(date: Date) {
        let str = this.getText(10032) + " ";
        str += this.getDayName(date);
        str += ", " + date.getDate();
        str += " " + this.getText(10040);
        str += this.getMonthName(date);
        str += ", " + date.getFullYear();

        return str;
    }

    handleFilterModalVisibilityChange(isVisible) {
        if (isVisible) {
            const { dashboardDate, dDateCheck, dashboardPos, updateInterval } = this.state;
            this.setState({
                isFilterModalShown: true,
                modalFilter: {
                    _dashboardDate: dashboardDate,
                    _dDateCheck: dDateCheck,
                    _dashboardPos: dashboardPos,
                    _updateInterval: updateInterval
                }
            })
        } else {
            const { _dashboardDate, _dDateCheck, _dashboardPos, _updateInterval } = this.state.modalFilter;

            this.setState({
                isFilterModalShown: false,
                dashboardDate: _dashboardDate ? moment(_dashboardDate).toDate() : null,
                dDateCheck: _dDateCheck ? moment(_dDateCheck).toDate() : null,
                dashboardPos: _dashboardPos.length ? _dashboardPos : this.props.data[TableIDs.genericSelectorPos].dataset.map(x => x.code),
                updateInterval: _updateInterval,
                graphBetwenDinami: moment(_dashboardDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? this.state.graphBetwenDinami : 0
            }, () => {
                this.lastRefresh = null;
                this.getStatistics()
            })
        }
    }

    colBox = (content, bottom) => {
        let styleDiv = { width: (window.innerWidth > 600) ? bottom ? '20%' : '33%' : '100%', padding: 10, display: 'inline-block', verticalAlign: 'text-top' };
        if (bottom) styleDiv = { ...styleDiv, marginBottom: 100 }
        return (<div style={styleDiv}>{content}</div>)
    }

    createGraphBetwenSelectors = () => {
        let selector = (state, list) => {
            return (<Select
                value={this.state[state]}
                onChange={(e) => { this.setState({ [state]: e }) }}
                style={{ width: "100%", marginBottom: 20 }}
            >{list.map((x, i) => (<Option value={i}>{x}</Option>))}</Select>)
        }

        return (<div style={{ display: this.state.sButtons ? 'block' : 'none' }}>
            <h3>{this.getText(10053)}:</h3>
            <div>
                {selector("graphBetwenType", [this.getText(10054), this.getText(10055), this.getText(10056)])}
                {selector("graphBetwenDinami", [this.getText(10057), this.getText(10058)])}
                {selector("graphHoursPerPos", [this.getText(16354), this.getText(16355)])}
                {selector("branchTablePerPos", [this.getText(17255), this.getText(17256)])}
                {/* {dc ? colBox(<h3>תאריך להשוואה: {moment(dc).format("YYYY-MM-DD")}</h3>) : ''} */}
            </div>
            <div style={{ direction: "rtl" }}>
                <Radio.Group value={this.state.viewPaysType} onChange={e => { this.setState({ viewPaysType: e.target.value }) }}>
                    <Radio value={'0'}>{this.getText(19334)}</Radio><br />
                    <Radio value={'1'}>{this.getText(19335)}</Radio><br />
                    <Radio value={'2'}>{this.getText(19336)}</Radio>
                </Radio.Group>
            </div>
        </div>)
    }

    getDataButtonsOrder = () => {
        this.sendAPI("get_dashboard_buttons_order", "", (ob) => {
            let res = ob.data.split("\r");
            let notInOrder = this.sButtonList.filter(f => res.indexOf(f) === -1)
            this.setState({ sOrder: [...res, ...notInOrder] })
        }, (e) => { console.error(e) })
    }

    sendAPI = (script, data, sCal, eCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, eCal);
    }

    getSections = () => {
        let res = [];
        let sData = this.state.sOrder;

        let props = {
            dashboardDate: this.state.dashboardDate,
            dashboardPos: this.state.dashboardPos,
            dDateCheck: this.state.dDateCheck,
            isLoading: this.state.isLoading,
            data: this.props.data,
            dataActions: this.props.dataActions,
            user: this.props.user,
            ui: this.props.ui,
            uiActions: this.props.uiActions,
            ActionQueue: this.props.ActionQueue,
        }

        let gbProps = {
            graphBetwenType: this.state.graphBetwenType,
            graphBetwenDinami: this.state.graphBetwenDinami,
        }

        let apiData = {
            productivity: Jax.get(this.state, "dashboardData.productivity", {
                today: { totalSales: 0, totalWorkingHours: 0, productivity: 0 },
                weekAgo: { totalSales: 0, totalWorkingHours: 0, productivity: 0, diff: 0 },
                monthAgo: { totalSales: 0, totalWorkingHours: 0, productivity: 0, diff: 0 },
                sixMonthsAgo: { totalSales: 0, totalWorkingHours: 0, productivity: 0, diff: 0 },
                twelveMonthsAgo: { totalSales: 0, totalWorkingHours: 0, productivity: 0, diff: 0 },
            }),
            lines: Jax.get(this.state, "dashboardData.lines", {
                today: { documentCount: 0, lineCount: 0, avg: 0 },
                weekAgo: { documentCount: 0, lineCount: 0, avg: 0, diff: 0 },
                monthAgo: { documentCount: 0, lineCount: 0, avg: 0, diff: 0 },
                sixMonthsAgo: { documentCount: 0, lineCount: 0, avg: 0, diff: 0 },
                twelveMonthsAgo: { documentCount: 0, lineCount: 0, avg: 0, diff: 0 },
            }),
            documents: Jax.get(this.state, "dashboardData.documents", {
                today: { count: 0, max: 0, sum: 0, avg: 0 },
                weekAgo: { count: 0, max: 0, sum: 0, avg: 0, diff: 0, diffAvg: 0 },
                monthAgo: { count: 0, max: 0, sum: 0, avg: 0, diff: 0, diffAvg: 0 },
                sixMonthsAgo: { count: 0, max: 0, sum: 0, avg: 0, diff: 0, diffAvg: 0 },
                twelveMonthsAgo: { count: 0, max: 0, sum: 0, avg: 0, diff: 0, diffAvg: 0 },
            }),
            payments: Jax.get(this.state, "dashboardData.payments", []),
            monthly: Jax.get(this.state, "dashboardData.monthly", []),
            hourly: Jax.get(this.state, "dashboardData.hourly", []),
            pos: Jax.get(this.state, "dashboardData.pos", []),
        }

        const { graphHoursPerPos, branchTablePerPos, viewPaysType } = this.state;

        sData.filter(f => this.state.cMinimizes[f] != 1).forEach((x) => {
            switch (x) {
                case "shortSummary": res.push(<ShortSummarySection {...props} apiData={apiData} viewPaysType={viewPaysType} />); break;
                case "segmentation": res.push(<SegmentationSection  {...props} apiData={apiData} graphHoursPerPos={graphHoursPerPos} viewPaysType={viewPaysType} viewPaysTypeChange={e => { this.setState({ viewPaysType: e.target.value }) }} />); break;
                case "branchTable": res.push(<BranchTableSection {...props} branchTablePerPos={branchTablePerPos == 1} />); break;
                case "branchPaysTable": res.push(<BranchPaysTableSection {...props} branchTablePerPos={branchTablePerPos == 1} />); break;
                case "graphBetwen": res.push(<GraphBetwenSection  {...props} {...gbProps} />); break;
                case "topEmployees": res.push(<TopEmployeesSection {...props} />); break;
                case "topLines": res.push(<TopLinesSection {...props} />); break;
                case "docTypes": res.push(<DocTypesSection {...props} />); break;
            }
        });
        return (<span>{res}</span>)
    }

    spinView = () => {
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        if (isDev) origin += '/cash-on-tab/build';

        return (<div style={{ paddingTop: window.innerHeight / 3, textAlign: 'center' }}>
            <img src={origin + "/img/logo.png"} />
            <br /><br /><Spin />
        </div>)
    }

    findUiComponent = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset ?
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === key)
            : false;
    }

    getClockIcon = (type, tooltip, onClick, greenBold) => {
        return (<Tooltip title={this.getText(tooltip)}>
            <Icon type={type} onClick={onClick} style={greenBold ? { fontWeight: "bold", color: "green", fontSize: 30 } : {}} />
        </Tooltip>)
    }

    onSaveUserDashboardConfigurations = () => {
        let posListStr = '';
        this.state.dashboardPos.map((x, i) => {
            if (i > 0) posListStr += ',';
            posListStr += x;
        });
        let s1 = this.state.cMinimizes.shortSummary;
        let s2 = this.state.cMinimizes.segmentation;
        let s3 = this.state.cMinimizes.branchTable;
        let s15 = this.state.cMinimizes.branchPaysTable;
        let s4 = this.state.cMinimizes.graphBetwen;
        let s5 = this.state.cMinimizes.topEmployees;
        let s6 = this.state.cMinimizes.topLines;
        let s12 = this.state.cMinimizes.docTypes;
        let s7 = posListStr;
        let s8 = this.state.graphBetwenType;
        let s9 = this.state.graphBetwenDinami;
        let s10 = this.state.dDateCheck ? moment(this.state.dDateCheck).format("YYYY-MM-DD") : '';
        let s11 = this.state.graphHoursPerPos;
        let s13 = this.state.branchTablePerPos;
        let s14 = this.state.soundActive ? '1' : '0';
        let s16 = this.state.viewPaysType;
        let sOrd = (f) => this.state.sOrder.indexOf(f) + 1;
        let data = "sNum\fsValue\fsOrder\r";
        data += "1\f" + s1 + "\f" + sOrd("shortSummary") + "\r";
        data += "2\f" + s2 + "\f" + sOrd("segmentation") + "\r";
        data += "3\f" + s3 + "\f" + sOrd("branchTable") + "\r";
        data += "15\f" + s15 + "\f" + sOrd("branchPaysTable") + "\r";
        data += "4\f" + s4 + "\f" + sOrd("graphBetwen") + "\r";
        data += "5\f" + s5 + "\f" + sOrd("topEmployees") + "\r";
        data += "6\f" + s6 + "\f" + sOrd("topLines") + "\r";
        data += "12\f" + s12 + "\f" + sOrd("docTypes") + "\r";
        data += "7\f" + s7 + "\f\r";
        data += "8\f" + s8 + "\f\r";
        data += "9\f" + s9 + "\f\r";
        data += "10\f" + s10 + "\f\r";
        data += "11\f" + s11 + "\f\r";
        data += "13\f" + s13 + "\f\r";
        data += "14\f" + s14 + "\f\r";
        data += "16\f" + s16 + "\f";
        // + s2 + "\f" + s3 + "\f" + s4 + "\f" + s5 + "\f" + s6 + "\f" + s7 + "\f" + s8 + "\f" + s9 + "\f" + s10;
        this.sendAPI("edit_users_dashboard_configurations", data, () => { message.success(this.getText(10109)) }, (e) => e)
    }

    filterFromDate = (date, field, def) => {
        let d = date ? date : def
        this.setState(
            Jax.set(this.state, field, d ? d.toDate() : null),
            () => {
                this.lastRefresh = null;
                this.getStatistics()
            }
        );
    }

    filterFromDateAdd = (icon, num, type) => {
        let toDate = moment(this.state.dashboardDate, "YYYY-MM-DD").add(num, type);
        let extraText = "";
        if (type === "month") extraText = this.getMonthName(new Date(toDate));
        if (type === "year") extraText = new Date(toDate).getFullYear();

        return (<button
            style={{ margin: 5, display: "inline-block" }}
            onClick={() => {
                this.setState({
                    dashboardDate: toDate ? moment(toDate).toDate() : moment().toDate(),
                    graphBetwenDinami: moment(toDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? this.state.graphBetwenDinami : 0
                }, () => {
                    this.lastRefresh = null;
                    this.getStatistics()
                })
            }}>
            {num > 0 ? extraText : ""}
            <Icon type={icon} />
            {num < 0 ? extraText : ""}
        </button>)
    }



    render() {

        const pointsOfSale = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => {
            return (<Option value={obj.code} key={obj.code}>{obj.name}</Option>);
        });

        let sData = this.state.sOrder;

        let mobile = window.innerWidth > 600 ? false : true;

        let isMinimizes = (field) => this.state.cMinimizes[field] == 1 ? true : false;

        let bMinimizes = (field, name) => {

            let changeLocal = (sectionChange, newLocal, oldLocal) => {
                let newData = sData.map((x, i) => i === oldLocal ? field : i === newLocal ? sectionChange : x)
                this.setState({ sOrder: newData });
            }

            let line = document.getElementById(field + '_button');
            if (line) {
                line.draggable = true;
                line.ondragstart = (e) => { e.dataTransfer.setData("keyStart", field) }
                line.ondragover = (e) => { e.preventDefault() }
                line.ondrop = (e) => {
                    let sectionChange = e.dataTransfer.getData("keyStart")
                    let newLocal = sData.indexOf(field)
                    let oldLocal = sData.indexOf(sectionChange)
                    changeLocal(sectionChange, newLocal, oldLocal);
                }
            }

            let curLocal = sData.indexOf(field);
            let backSection = sData[curLocal - 1];
            let nextSection = sData[curLocal + 1];
            let typeBtn = isMinimizes(field) ? "" : "primary";

            return (<div id={field + '_button'}>
                <Button type={typeBtn} disabled={curLocal == 0} onClick={() => { changeLocal(backSection, curLocal, curLocal - 1) }}>
                    <Icon type={"arrow-up"} />
                </Button>
                <Button type={typeBtn} disabled={!sData[curLocal + 1]} onClick={() => { changeLocal(nextSection, curLocal, curLocal + 1) }}>
                    <Icon type={"arrow-down"} />
                </Button>
                <Button
                    type={typeBtn}
                    style={{ margin: 5, width: 200, textAlign: 'right' }}
                    onClick={() => {
                        this.setState({ cMinimizes: { ...this.state.cMinimizes, [field]: isMinimizes(field) ? 0 : 1 } })
                    }}>
                    <Icon style={{ margin: 5 }} type={isMinimizes(field) ? "plus-circle" : "minus-circle"} />
                    {name}
                </Button>
            </div>)
        }


        let sButtons = () => {
            return sData.map((x) => {
                switch (x) {
                    case "shortSummary": return bMinimizes("shortSummary", this.getText(10111)); break;
                    case "segmentation": return bMinimizes("segmentation", this.getText(10112)); break;
                    case "branchTable": return bMinimizes("branchTable", this.getText(10113)); break;
                    case "branchPaysTable": return bMinimizes("branchPaysTable", this.getText(17555)); break;
                    case "graphBetwen": return bMinimizes("graphBetwen", this.getText(10114)); break;
                    case "topEmployees": return bMinimizes("topEmployees", this.getText(10115)); break;
                    case "topLines": return bMinimizes("topLines", this.getText(10116)); break;
                    case "docTypes": return bMinimizes("docTypes", this.getText(17088)); break;
                }
            });
        }


        return (<div style={{ direction: "rtl", margin: "16px" }} className={"dashboard"}>
            {this.state.firstLoading < 0 ? this.spinView() : ""}
            <Row gutter={24} style={{ marginBottom: "24px", display: this.state.firstLoading < 0 ? "none" : "block" }}>

                <Col xl={18} lg={16} md={24} sm={24} xs={24} >
                    <FlashTitle
                        dashboardDate={this.state.dashboardDate}
                        dashboardPos={this.state.dashboardPos}
                        lastLoad={this.state.lastLoad}
                        data={this.props.data}
                        dataActions={this.props.dataActions}
                        user={this.props.user}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                        soundActive={this.state.soundActive}
                    />

                </Col>
                <Col xl={6} lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "24px" }}>
                    <Card
                        style={{ textAlign: 'center', direction: 'rtl' }}
                        title={(<div>
                            <h5 style={{ fontWeight: "bold", display: "inline-block" }}>
                                <div>
                                    {this.filterFromDateAdd("fast-forward", -1, 'year')}
                                    {this.filterFromDateAdd("forward", -1, 'month')}
                                    {this.filterFromDateAdd("backward", 1, 'month')}
                                    {this.filterFromDateAdd("fast-backward", 1, 'year')}
                                </div>
                                <div>
                                    {this.filterFromDateAdd("caret-right", -1, 'days')}
                                    {this.printDate(this.state.dashboardDate)}
                                    {this.filterFromDateAdd("caret-left", 1, 'days')}
                                </div>
                            </h5>
                        </div>)}
                        actions={[
                            this.getClockIcon("reload", 17542, () => { if (!this.props.ui.workInProgress) this.getStatistics() }, 1),
                            this.getClockIcon("filter", 10143, () => { this.handleFilterModalVisibilityChange(true) }),
                            this.getClockIcon("setting", 16976, () => { this.setState({ sButtons: true }) }),
                            this.getClockIcon("sound", 17541, () => { this.setState({ modalSound: true }) }),
                            this.getClockIcon("save", 10110, this.onSaveUserDashboardConfigurations),
                        ]}
                        headStyle={{ padding: 0 }}>
                        <ClockOn />
                    </Card>
                </Col>
            </Row>
            <div style={{ display: this.state.firstLoading < 0 ? "none" : "block" }}>
                {this.isAutoIphone ? [
                    <FavoriteReportsForAutoIphone {...this.props} />,
                    this.findUiComponent('17') ? <NewOrdersForAutoIphone {...this.props} /> : "",
                    this.findUiComponent('128') ? <CarListForAutoIphone {...this.props} /> : "",
                    this.findUiComponent('108') ? <PosMessagesForAutoIphone {...this.props} /> : "",
                    this.findUiComponent('516') ? <CreateDoc15ForAutoIphone {...this.props} /> : "",
                    this.findUiComponent('40') ? <ExcludedItemsForAutoIphone {...this.props} /> : "",
                ] : ""}
                {this.getSections()}
            </div>
            <GenericModal
                visible={this.state.sButtons}
                onCancel={() => { this.setState({ sButtons: false }) }}
                style={{ direction: "rtl" }}
                title={this.getText(16976)}
                width={800}>
                <Row>
                    <Col span={mobile ? 24 : 12}>{sButtons()}</Col>
                    <Col span={mobile ? 24 : 12}>{this.createGraphBetwenSelectors()}</Col>
                </Row>
            </GenericModal>

            <GenericModal
                visible={this.state.modalSound}
                onCancel={() => { this.setState({ modalSound: false }) }}
                style={{ direction: "rtl" }}
                title={this.getText(17541)}
                width={300}>
                <Switch
                    onChange={soundActive => { this.setState({ soundActive }) }}
                    checked={this.state.soundActive}
                    checkedChildren={this.getText(17543)}
                    unCheckedChildren={this.getText(17544)}
                />
            </GenericModal>

            <GenericModal
                visible={this.state.isFilterModalShown}
                onCancel={() => { this.handleFilterModalVisibilityChange(false) }}
                style={{ direction: "rtl" }}
                title={this.getText(10143)}
                width={300}
            >
                <div>
                    <h3 style={{ marginTop: 10 }}>{this.getText(10144)}</h3>
                    <DatePicker
                        className={"dashboard"}
                        format={"DD/MM/YYYY"}
                        value={this.state.modalFilter._dashboardDate ? moment(this.state.modalFilter._dashboardDate, "YYYY-MM-DD") : null}
                        onChange={(date) => {
                            this.setState({ modalFilter: { ...this.state.modalFilter, _dashboardDate: date ? date : new Date() } })
                            // this.filterFromDate(date, "dashboardDate", moment(new Date()))
                        }}
                    />
                    <h3 style={{ marginTop: 10 }}> {this.getText(10145)} </h3>
                    <DatePicker
                        format={"DD/MM/YYYY"}
                        value={this.state.modalFilter._dDateCheck ? moment(this.state.modalFilter._dDateCheck, "YYYY-MM-DD") : null}
                        onChange={(date) => {
                            this.setState({ modalFilter: { ...this.state.modalFilter, _dDateCheck: date } })
                            // this.filterFromDate(date, "dDateCheck", null)
                        }} />
                    <h3 style={{ marginTop: 10 }}>{this.getText(10146)}</h3>
                    {/* <Select
                        style={{ display: "block", marginTop: "4px", width: '100%' }}
                        mode={"multiple"}
                        maxTagCount={10}
                        placeholder={this.getText(10147)}
                        notFountContent={this.getText(10148)}
                        value={this.state.modalFilter._dashboardPos ? this.state.modalFilter._dashboardPos : []}
                        onChange={(list) => {
                            this.setState({ modalFilter: { ...this.state.modalFilter, _dashboardPos: list } })
                            // this.filterFromPos
                        }}
                    >
                        {pointsOfSale}
                    </Select> */}
                    <GenericSelectorPosStorages
                        {...this.props}
                        style={{ display: "block", marginTop: "4px", width: '100%' }}
                        multi
                        // id={TableIDs.genericSelectorPos}
                        maxTagCount={10}
                        placeholder={this.getText(10147)}
                        notFountContent={this.getText(10148)}
                        value={this.state.modalFilter._dashboardPos ? this.state.modalFilter._dashboardPos : []}
                        onChange={(list) => {
                            this.setState({ modalFilter: { ...this.state.modalFilter, _dashboardPos: list } })
                            // this.filterFromPos
                        }}
                    />

                    {/* {this.isAutoIphone ? "" :
                        <div style={{ direction: "rtl", marginTop: '4px', textAlign: "right" }}>
                            {this.getText(10149)}
                            <Slider style={{ marginTop: "8px" }} defaultValue={60} value={this.state.modalFilter._updateInterval} max={600} min={240}
                                onChange={(value) => { this.setState({ modalFilter: { ...this.state.modalFilter, _updateInterval: value } }) }} />
                        </div>
                    } */}
                </div>
            </GenericModal>
        </div>);
    }
}

export default withRouter(Dashboard);
