/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import { Button, Col, Form, Input, InputNumber, message, Modal, Radio } from "antd";
import CustPicker from "../../components/CustPicker";
import { Row } from "antd/es/grid";
import GenericSelector from "../../components/GenericSelector";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import TableLineIcons from "../../components/TableLineIcons";
import { Job, OutputType, ProcessType, User } from "../../JmReact";
import ResponsiveTable from "../../components/ResponsiveTable";
import PictureView from "../../components/PictureView"


class ServiceCallsTable extends Component {
    constructor(props: Props) {
        super(props);
        this.defaultFilters = {
            _custId: null,
            _fromDateOpen: null,
            _toDateOpen: null,
            _isClosed: '-1',
            _fromDateClose: null,
            _toDateClose: null,
            _subjectList: "",
            _subjectDetailList: "",
            _itemCode: "",
            _sn: "",
            _statusList: "",
            _tagList: "",
        }

        if (props.filters) {
            this.defaultFilters = { ...this.defaultFilters, ...props.filters }
        }

        this.state = {
            ...this.defaultFilters,
            modalFilter: false,
            modalReadOnly: false,
            modalDetails: false,
            modalDetailsOldStatus: null,
            modalDetailsOnly: false,
            modalDetailLineEdit: false,
            modalDetailLineView: null,
        };

        this.id = "SERVICE_CALLS";
        this.status_id = "SERVICE_CALL_STATUSES";
        this.details_id = "SERVICE_CALL_DETAILS";

        this.api = {
            get: "get_service_calls",
            create_edit: "create_edit_service_calls",
            get_status: "get_service_call_statuses",

            get_details: "get_service_call_details",
            edit_details: "edit_service_call_details",
            open_user: "service_call_detail_open_user",

        }

        this.selectors = [
            { id: "SERVICE_SUBJECTS_LIST", api: "service_subjects_list" },
            { id: "SERVICE_SUBJECT_DETAILS_LIST", api: "service_subject_details_list" },
            { id: "SELECTOR_TAG_TYPE_9", api: "get_tags_from_type_9" },
            { id: "CUSTOMER_BUSINESS_BRANCHES_LIST", api: "customer_business_branches_list" },
            { id: "SERVICE_SUBJECTS_TAGS_AND_USERS", api: "get_service_subjects_tags_and_users" },
            { id: "PROVIDERS_CONTACT_MAIL_LIST", api: "providers_contact_mail_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.status_id] === undefined) props.data[this.status_id] = { ...props.data.genericData };
        if (props.data[this.details_id] === undefined) props.data[this.details_id] = { ...props.data.genericData };
        if (props.data.SERVICE_SUBJECT_DETAILS_LIST2 === undefined) props.data.SERVICE_SUBJECT_DETAILS_LIST2 = { dataset: [] };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    componentDidMount() {
        this.props.dataActions.genericDataGetRequested(
            this.status_id, this.props.user.companyCode, this.props.user.token, this.api.get_status);

        const s1 = this.selectors[1]
        const s4 = this.selectors[4]
        const s5 = this.selectors[5]

        this.sendAPI(s1.api, "", (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f")
                return {
                    code: y[0],
                    name: y[1],
                    expectedClosing: y[2]
                }
            }) : []
            this.props.dataActions.setJson("SERVICE_SUBJECT_DETAILS_LIST2", { dataset })
        })


        this.sendAPI(s4.api, "", (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f")
                return {
                    subjectDetailId: y[0],
                    tagId: y[1],
                    tagName: y[2],
                    userId: y[3],
                    userName: y[4],
                }
            }) : []
            this.props.dataActions.setJson(s4.id, { dataset })
        })

        let dataset5 = []

        this.sendAPI(s5.api, "", (ob) => {
            if (ob.data) ob.data.split("\r").forEach(x => {
                let y = x.split("\f")
                let findProvider = dataset5.findIndex(f => f.type?.id == y[0])
                if (findProvider > -1) {
                    dataset5[findProvider].tags.push({ id: y[1], text: y[2], email: y[4] })
                } else {
                    dataset5.push({
                        type: { id: y[0], text: y[3] },
                        tags: [{ id: y[1], text: y[2], email: y[4] }]
                    })
                }
            })
            this.props.dataActions.setJson(s5.id, { dataset: dataset5 })
        })

    }

    componentDidUpdate() {

    }

    handleCancel = () => {
        this.setState({ modalReadOnly: false, modalDetails: false, modalDetailsOldStatus: null }, () => {
            if (this.props.data[this.id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
            else this.props.dataActions.genericDataCancelEditing(this.id);
        })
    }

    handleOk = () => {

        const { editing, editedRecord } = this.props.data[this.id]

        if (!editedRecord.cCustomerID || !editedRecord.cSubjectDetailId || !editedRecord.cDesc) {
            message.error(this.getText(20417))
        } else if (editing == 0 && (!editedRecord.cTargetUserID && !editedRecord.cTargetUserTagID)) {
            message.error(this.getText(20418))
        } else {
            this.props.dataActions.genericDataSaveEdit(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
        }
    }

    handleSaveStatus = () => {
        const { editedRecord, filters } = this.props.data[this.id]
        this.sendAPI(this.api.create_edit, editedRecord, () => {
            this.sendAPI(this.api.get, filters, (ob) => {
                let data = ob.data.split("\r").slice(1, -1)
                this.props.dataActions.setJson(this.id, { data })
                this.setState({ modalDetailsOldStatus: editedRecord.cStatusID })
            })
        })
    }

    apiCustBranches = (cust) => {
        this.props.dataActions.genericSelectorRefreshDataset(
            this.selectors[3].id, this.props.user.companyCode, this.props.user.token, this.selectors[3].api, { cust });
    }

    editField = (field) => {
        const gt = this.getText
        const { editing, editedRecord } = this.props.data[this.id]
        const value = editedRecord[field]
        const onChange = e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }
        let dataset = []
        switch (field) {
            case 'cCustomerID':
                return editing > 0 ?
                    value + " - " + editedRecord.cCustName
                    : (<CustPicker {...this.props} defaultView={gt(12121)} disabled={editing > 0} value={value} onChange={e => {
                        onChange(e)
                        this.apiCustBranches(e)
                    }} />)
            case 'cBranchId':
                return this.state.modalReadOnly ?
                    value + " - " + editedRecord.cBranchName
                    : (<GenericSelector {...this.props} id={this.selectors[3].id} {...{ value, onChange }} />)
            case 'cSubjectDetailId':
                return editing > 0 ?
                    value + " - " + editedRecord.cSubjectDetailDesc
                    : (<GenericSelector {...this.props} id={"SERVICE_SUBJECT_DETAILS_LIST2"} value={value} onChange={e => {
                        let findRow = this.props.data.SERVICE_SUBJECT_DETAILS_LIST2.dataset.find(f => f.code == e)
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: e, cExpectedClosingDateTime: moment().add(findRow?.expectedClosing, "minute").format("YYYY-MM-DD HH:mm:ss") })
                    }} />)
            case 'cCallerName': case 'cCallerTel1': case 'cCallerTel2': case 'cDesc': case 'cItemSN':
                return this.state.modalReadOnly || (editing > 0 && (field == 'cDesc' || field == 'cItemSN')) ? value
                    : (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
            case 'cRemarks':
                return this.state.modalReadOnly ? value
                    : (<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />)
            case 'cItemCode':
                return editing > 0 ?
                    value + " - " + editedRecord.cItemName
                    : (<SelectorItemClassic {...this.props} valueId={value} valueName={editedRecord.cItemName}
                        onChange={(cItemCode, cItemName) => { this.props.dataActions.genericDataSetEdit(this.id, { cItemCode, cItemName }) }} />)
            case 'cStatusID':
                dataset = this.getStatusList().map(x => { return { ...x, disabled: x.sOpen || x.sClose } })
                let disabled = editing == 0 || (this.state.modalReadOnly && !this.state.modalDetails)
                if (this.state.modalDetails && this.state.modalDetailsOldStatus != editedRecord.cStatusID) {
                    return (<Row>
                        <Col span={20}>
                            <GenericSelector {...this.props} {...{ value, onChange, dataset, disabled }} backgroundSet />
                        </Col>
                        <Col span={4}>
                            <Button key={"create"} type={"primary"} onClick={this.handleSaveStatus} style={{ margin: 5 }}>{gt(20386)}</Button>
                        </Col>
                    </Row>)
                } else {
                    return (<GenericSelector {...this.props} {...{ value, onChange, dataset, disabled }} backgroundSet />)
                }
            case 'cTargetUserID':
                dataset = this.getUsersDataset()
                return (<GenericSelector {...this.props} {...{ value, dataset }} onChange={e => {
                    this.props.dataActions.genericDataSetEdit(this.id, { cTargetUserID: e, cTargetUserTagID: "" })
                }} />)
            case 'cTargetUserTagID':
                dataset = this.getUsersDataset(true)
                return (<GenericSelector {...this.props} {...{ value, dataset }} onChange={e => {
                    this.props.dataActions.genericDataSetEdit(this.id, { cTargetUserID: "", cTargetUserTagID: e })
                }} />)
            case 'cExpectedClosingDateTime':
                return moment(value).format("DD/MM/YYYY HH:mm")
        }
    }

    getUsersDataset = (isTags) => {
        let dataset = [];
        let codeField = isTags ? "tagId" : "userId"
        let nameField = isTags ? "tagName" : "userName"
        const { editedRecord } = this.props.data[this.id]
        this.props.data[this.selectors[4].id].dataset.forEach(x => {
            if (editedRecord.cSubjectDetailId == x.subjectDetailId && !dataset.find(f => f.code == x[codeField]))
                dataset.push({ code: x[codeField], name: x[nameField] })
        })
        return dataset
    }

    getStatusList = () => {
        return this.props.data[this.status_id].data
            .map(x => x.split("\f"))
            .filter(f => f[6] == '1')
            .map(y => {
                return {
                    code: y[0],
                    name: y[1],
                    color: y[3],
                    sOpen: y[4] == '1',
                    sClose: y[5] == '1',
                }
            })
    }

    apiDetails = (cID) => {
        this.props.dataActions.genericDataSetFilter(
            this.details_id, this.props.user.companyCode, this.props.user.token, { cID }, this.api.get_details);
    }

    renderEditCol = (text, record) => {
        const startEditing = () => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }
        return (<TableLineIcons
            genericIcons={[
                {
                    type: "edit",
                    onClick: () => {
                        startEditing()
                        this.apiCustBranches(record.cCustomerID)
                    },
                    tooltip: this.getText(20419),
                    disabled: record.cCloseDateTime ? true : false,
                }, {
                    type: "idcard",
                    onClick: () => {
                        this.setState({ modalReadOnly: true, modalDetails: true, modalDetailsOldStatus: record.cStatusID }, () => {
                            this.apiDetails(record.cID)
                            startEditing()
                        })
                    },
                    tooltip: this.getText(20420),
                    disabled: record.cCloseDateTime ? true : false,
                }, {
                    type: "eye",
                    onClick: () => {
                        this.setState({ modalReadOnly: true }, () => {
                            this.apiDetails(record.cID)
                            startEditing()
                        })
                    },
                    tooltip: this.getText(20421),
                }, {
                    type: "printer",
                    onClick: () => { message.info("בפיתוח...") },
                    tooltip: this.getText(20422),
                },
            ]}
        />)
    }

    renderDetaildEditCol = (text, record) => {
        let isOpenToUser = !record.dCloseDateTime && record.dOpenUserId == this.props.user.userId
        return (<TableLineIcons
            genericIcons={[
                {
                    type: isOpenToUser ? "edit" : "eye",
                    onClick: () => {
                        this.setState({
                            modalDetailLineEdit: isOpenToUser,
                            modalDetailLineView: {
                                ...record,
                                _saveType: 1,
                                _targetUserId: null,
                                _targetUserTagId: null,
                                _providerContactId: null,
                            }
                        })
                    },
                    tooltip: this.getText(isOpenToUser ? 20690 : 20691),
                }, {
                    type: "user",
                    onClick: () => {
                        this.props.dataActions.genericDataDeleteRow(
                            this.details_id, record.key, this.props.user.companyCode, this.props.user.token,
                            this.api.open_user, this.api.get_details);
                    },
                    tooltip: this.getText(20692),
                    popconfirm: { title: this.getText(20694), okText: this.getText(20695), cancelText: this.getText(20696) },
                    disabled: record.dOpenUserId || record.dCloseDateTime || (record.dIsToUser != '1' && record.dTargetUserId != this.props.user.userId)
                },
            ]}
        />)
    }


    renderDetailLineImage = (num) => {

        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || 'http://auto-club.co.il' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        let fileName = this.state.modalDetailLineView ? this.state.modalDetailLineView["dImageFile" + num] : null;
        const onChange = (e) => { this.setState({ modalDetailLineView: { ...this.state.modalDetailLineView, ["dImageFile" + num]: e } }) }

        return this.state.modalDetailLineEdit ?
            <PictureView {...this.props}
                imageType={'D'} url={fileName}
                onRemove={() => { onChange(null) }} onChange={onChange}
                onUploadedSuccess={() => { }} onUploadedFailed={() => { }}
            />
            : fileName ?
                <img
                    style={{ width: "80%", margin: "5%" }}
                    src={baseUrl + "/uploads/" + this.props.user.companyCode + "/" + fileName} />
                : null
    }

    onSaveModalDetailLineEdit = () => {
        if (!this.state.modalDetailLineView?.dDesc) {
            message.error(this.getText(20717));
            return;
        }

        if (this.state.modalDetailLineView?._saveType == 2 && !this.state.modalDetailLineView?._targetUserId && !this.state.modalDetailLineView?._targetUserTagId) {
            message.error(this.getText(20718));
            return;
        }

        if (this.state.modalDetailLineView?._saveType == 3 && !this.state.modalDetailLineView?._providerContactId) {
            message.error(this.getText(20719));
            return;
        }

        const onSave = () => {
            this.sendAPI(this.api.edit_details, this.state.modalDetailLineView, () => {
                this.setState({ modalDetailLineView: null, modalDetailLineEdit: false }, () => {
                    this.props.dataActions.genericDataGetRequested(
                        this.details_id, this.props.user.companyCode, this.props.user.token, this.api.get_details);
                })
            })
        }

        if (this.state.modalDetailLineView?._saveType == 4) {
            Modal.confirm({
                title: "",
                content: this.getText(20729),
                iconType: 'exclamation-circle',
                okText: this.getText(20730),
                cancelText: this.getText(20731),
                onOk: onSave,
                onCancel() { },
            })
        } else {
            onSave()
        }
    }


    render() {
        const gt = this.getText;
        const render = t => t
        const renderDatetime = t => t ? moment(t).format("DD/MM/YYYY HH:mm") : null
        const renderStatus = (t, r) => (<div style={{ background: r.cStatusColor }}>{t}</div>)
        const renderDetaildTarget = (t, r) => r.dTargetUserId ? t : r.dTargetUserTagName

        const { editing, editedRecord } = this.props.data[this.id]
        const validProps = (v, d) => { return d && d.length ? {} : { required: true, validateStatus: !v ? "error" : "success" } }

        let modalEditFooter = [];
        if (this.state.modalReadOnly && !this.state.modalDetails) {
            modalEditFooter.push(<Button key={"open-Detaild"} onClick={() => { this.setState({ modalDetailsOnly: true }) }} style={{ margin: 5 }}>{gt(20423)}</Button>)
        }
        if (!this.state.modalDetails) {
            modalEditFooter.push(<Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(this.state.modalReadOnly ? 20424 : 20385)}</Button>)
        }
        if (!this.state.modalReadOnly) {
            modalEditFooter.push(<Button key={"create"} type={"primary"} onClick={this.handleOk} style={{ margin: 5 }}>{gt(20386)}</Button>)
        }

        const detailsTable = (<ResponsiveTable
            tableOnly
            idTable={this.props.data[this.details_id]}
            columns={[
                { title: "#", key: "dID", dataIndex: "dID", width: "10%", render },
                { title: gt(20664), key: "dSourceUserName", dataIndex: "dSourceUserName", width: "10%", render },
                { title: gt(20665), key: "dSourceDatetime", dataIndex: "dSourceDatetime", width: "10%", render: renderDatetime },
                { title: gt(20666), key: "dTargetUserName", dataIndex: "dTargetUserName", width: "10%", render: renderDetaildTarget },
                { title: gt(20667), key: "dOpenUserName", dataIndex: "dOpenUserName", width: "10%", render },
                { title: gt(20668), key: "dOpenDateTime", dataIndex: "dOpenDateTime", width: "10%", render: renderDatetime },
                { title: gt(20669), key: "dCloseUserName", dataIndex: "dCloseUserName", width: "10%", render },
                { title: gt(20670), key: "dCloseDateTime", dataIndex: "dCloseDateTime", width: "10%", render: renderDatetime },
                { title: gt(20671), width: "10%", render: this.renderDetaildEditCol },
            ]}
            pagination={{ pageSize: 10 }}
        />)

        return (<>
            <GenericTable
                {...this.props}
                tableOnly
                rowsFilter={r => r.key > 0}
                id={this.id}
                getApi={this.api.get}
                filters={this.defaultFilters}
                columns={[
                    { title: gt(20333), key: "cID", dataIndex: "cID", width: "5%", render },
                    { title: gt(20334), key: "cOpenDateTime", dataIndex: "cOpenDateTime", width: "7%", render: renderDatetime },
                    { title: gt(20335), key: "cCustomerID", dataIndex: "cCustomerID", width: "5%", render },
                    { title: gt(20336), key: "cCustName", dataIndex: "cCustName", width: "9", render },
                    { title: gt(20337), key: "cBranchName", dataIndex: "cBranchName", width: "9%", render },
                    { title: gt(20338), key: "cCallerName", dataIndex: "cCallerName", width: "9%", render },
                    { title: gt(20339), key: "cCallerTel1", dataIndex: "cCallerTel1", width: "7%", render },
                    { title: gt(20340), key: "cCallerTel2", dataIndex: "cCallerTel2", width: "7%", render },
                    { title: gt(20341), key: "cSubjectDetailDesc", dataIndex: "cSubjectDetailDesc", width: "9%", render },
                    { title: gt(20342), key: "cDesc", dataIndex: "cDesc", width: "9%", render },
                    { title: gt(20343), key: "cStatusName", dataIndex: "cStatusName", width: "9%", render: renderStatus },
                    { title: gt(20344), key: "cCloseDateTime", dataIndex: "cCloseDateTime", width: "7%", render: renderDatetime },
                    { title: gt(20345), width: "8%", render: this.renderEditCol },

                ]}
            />
            <GenericModal
                visible={editing > -1}
                onCancel={this.handleCancel}
                title={(<span>
                    <span>{gt(20367)} {editedRecord.cID > 0 ? editedRecord.cID : gt(20371)}</span> &nbsp; &nbsp; &nbsp;	&nbsp;
                    <span>{gt(20368)} {renderDatetime(editedRecord.cOpenDateTime)}</span> &nbsp;	&nbsp; &nbsp; &nbsp;
                    <span>{gt(20369)} {editedRecord.cOpenUserName}</span> &nbsp;	&nbsp; &nbsp; &nbsp;
                    <span>{gt(20370)} {renderDatetime(editedRecord.cCloseDateTime)}</span>
                </span>)}
                width={1200}
                footer={modalEditFooter}>
                <Form
                    style={{ columnCount: 2, columnGap: 70 }}
                    labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                >
                    <Form.Item label={gt(20372)} {...validProps(editedRecord.cCustomerID)}>{this.editField('cCustomerID')}</Form.Item>
                    <Form.Item label={gt(20373)}>{this.editField('cBranchId', 3)}</Form.Item>
                    <Form.Item label={gt(20374)}>{this.editField('cCallerName')}</Form.Item>
                    <Form.Item label={gt(20375)}>{this.editField('cCallerTel1')}</Form.Item>
                    <Form.Item label={gt(20376)}>{this.editField('cCallerTel2')}</Form.Item>
                    <Form.Item label={gt(20377)} {...validProps(editedRecord.cSubjectDetailId)}>{this.editField('cSubjectDetailId')}</Form.Item>
                    <Form.Item label={gt(20378)} {...validProps(editedRecord.cDesc)}>{this.editField('cDesc')}</Form.Item>
                    <Form.Item label={gt(20379)}>{this.editField('cRemarks')}</Form.Item>
                    <Form.Item label={gt(20380)}>{this.editField('cItemCode')}</Form.Item>
                    <Form.Item label={gt(20381)}>{this.editField('cItemSN')}</Form.Item>
                    <Form.Item label={gt(20382)}>{this.editField('cStatusID')}</Form.Item>
                    {editing == 0 ? <>
                        <Form.Item label={gt(20384)} {...validProps(editedRecord.cTargetUserID, editedRecord.cTargetUserTagID)} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {this.editField('cTargetUserID')}
                        </Form.Item>
                        <Form.Item label={gt(20387)} {...validProps(editedRecord.cTargetUserTagID, editedRecord.cTargetUserID)} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {this.editField('cTargetUserTagID')}
                        </Form.Item>
                    </> : ""}

                </Form>
                {this.state.modalDetails ? detailsTable : ""}
            </GenericModal>
            <GenericModal
                visible={this.state.modalDetailsOnly}
                onCancel={() => { this.setState({ modalDetailsOnly: false }) }}
                title={gt(20693)}
                width={1200}>
                {detailsTable}
            </GenericModal>
            <GenericModal
                visible={this.state.modalDetailLineView}
                onCancel={() => { this.setState({ modalDetailLineView: null, modalDetailLineEdit: false }) }}
                title={`${gt(20704)} ${this.state.modalDetailLineView?.key}`}
                width={1000}
                footer={this.state.modalDetailLineEdit ?
                    [
                        <Button type="primary" onClick={this.onSaveModalDetailLineEdit}>{this.getText(20710)}</Button>,
                        <Button onClick={() => { this.setState({ modalDetailLineView: null, modalDetailLineEdit: false }) }}>{this.getText(20711)}</Button>,
                    ]
                    : false}>
                <Row>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <p>
                            <b>{gt(20705)}</b>: {this.state.modalDetailLineView?.dSourceUserName}&nbsp;&nbsp;&nbsp;
                            <b>{gt(20706)}</b>: {renderDatetime(this.state.modalDetailLineView?.dSourceDatetime)}
                        </p>
                        <p>
                            <b>{gt(20707)}</b>: {this.state.modalDetailLineView?.dOpenUserName}&nbsp;&nbsp;&nbsp;
                            <b>{gt(20708)}</b>: {renderDatetime(this.state.modalDetailLineView?.dOpenDateTime)}
                        </p>

                        <p><b>{gt(20709)}</b>:</p>
                        {this.state.modalDetailLineEdit ?
                            <Form.Item required validateStatus={!this.state.modalDetailLineView?.dDesc ? "error" : "success"}>
                                <Input.TextArea rows={6}
                                    value={this.state.modalDetailLineView?.dDesc}
                                    onChange={e => { this.setState({ modalDetailLineView: { ...this.state.modalDetailLineView, dDesc: e.target.value } }) }}
                                />
                            </Form.Item>
                            : <h4>{this.state.modalDetailLineView?.dDesc}</h4>}

                        {this.state.modalDetailLineEdit ?
                            <div style={{ marginTop: 20 }}>
                                <p><b>{gt(20712)}</b>:</p>
                                <Radio.Group
                                    value={this.state.modalDetailLineView?._saveType}
                                    onChange={e => {
                                        this.setState({ modalDetailLineView: { ...this.state.modalDetailLineView, _saveType: e.target.value } })
                                    }}>
                                    <Radio value={1}>{gt(20713)}</Radio><br />
                                    <Radio value={2}>{gt(20714)}</Radio><br />
                                    <Radio value={3}>{gt(20715)}</Radio><br />
                                    <Radio value={4}>{gt(20716)}</Radio><br />
                                </Radio.Group>
                            </div>
                            : ""}
                        {this.state.modalDetailLineView?._saveType == 2 ?
                            <>
                                <Form.Item label={gt(20720)} {...validProps(this.state.modalDetailLineView?._targetUserId, this.state.modalDetailLineView?._targetUserTagId)} >
                                    <GenericSelector {...this.props} dataset={this.getUsersDataset()}
                                        value={this.state.modalDetailLineView?._targetUserId}
                                        onChange={e => { this.setState({ modalDetailLineView: { ...this.state.modalDetailLineView, _targetUserId: e, _targetUserTagId: null } }) }} />
                                </Form.Item>
                                <Form.Item label={gt(20721)} {...validProps(this.state.modalDetailLineView?._targetUserTagId, this.state.modalDetailLineView?._targetUserId)}>
                                    <GenericSelector {...this.props} dataset={this.getUsersDataset(true)}
                                        value={this.state.modalDetailLineView?._targetUserTagId}
                                        onChange={e => { this.setState({ modalDetailLineView: { ...this.state.modalDetailLineView, _targetUserTagId: e, _targetUserId: null } }) }} />
                                </Form.Item>
                            </>
                            : this.state.modalDetailLineView?._saveType == 3 ?
                                <Form.Item label={gt(20722)} {...validProps(this.state.modalDetailLineView?._providerContactId)}>
                                    <GenericSelector {...this.props}
                                        optionsGroup
                                        defaultModalView
                                        id={this.selectors[5].id}
                                        value={this.state.modalDetailLineView?._providerContactId}
                                        onChange={e => { this.setState({ modalDetailLineView: { ...this.state.modalDetailLineView, _providerContactId: e } }) }}
                                        modalStyle={{
                                            title: gt(20723),
                                            col1Title: gt(20724),
                                            col2Title: gt(20725),
                                            col3Title: gt(20726),
                                            col3Field: "email",
                                            cancelText: gt(20727),
                                            clearText: gt(20728),
                                        }}
                                    />
                                </Form.Item>
                                : ""}
                    </Col>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Row>
                            <Col span={12} style={{ height: 200 }}>{this.renderDetailLineImage(1)}</Col>
                            <Col span={12} style={{ height: 200 }}>{this.renderDetailLineImage(2)}</Col>
                            <Col span={12} style={{ height: 200 }}>{this.renderDetailLineImage(3)}</Col>
                            <Col span={12} style={{ height: 200 }}>{this.renderDetailLineImage(4)}</Col>
                        </Row>
                    </Col>
                </Row>
            </GenericModal>



        </>)
    }

}

export default ServiceCallsTable;