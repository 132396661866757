/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import TableIDs from "../../data/TableIDs";
import GenericSelector from "../../components/GenericSelector";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import TableLineIcons from "../../components/TableLineIcons";
import { Popconfirm, Button, Row, Col, Select } from "antd";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import moment from "moment";
import GenericModalForm from "../../components/GenericModalForm";
import GenericFromToDatePicker from "../../components/GenericFromToDatePicker";


class TransfersPending extends Component {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalItems: null,
            modalFilters: false,
            filters: {
                _storageList: "",
                _sourceStorageList: "",
                _fromDate: moment().add(-14, "day").format("YYYY-MM-DD"),
                _toDate: moment().format("YYYY-MM-DD"),
                _status: '0',
            }
        };

        this.id = "TRANSFERS_PENDING";
        this.items_id = "TRANSFERS_PENDING_LINES";

        this.api = {
            get: "transfers_pending_list",
            // get_def: "get_default_storage_from_user",
            get_items: "get_documentLines_purchase_list_table",
            update: "update_doc_transfer_storage",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setFilter = (request) => {
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, this.api.get)
    }

    componentDidMount() {
        const { id, api } = this.selectors[0]
        this.sendAPI(api, '', (ob) => {
            if (ob.data) {
                let dataset = ob.data.split("\r").map(x => {
                    const [code, name] = x.split("\f")
                    return { code, name }
                })
                let _storageList = dataset.map(x => x.code).join(",")
                this.setState({ filters: { ...this.state.filters, _storageList } }, () => {
                    this.setFilter(this.state.filters)
                    this.props.dataActions.setJson(id, { dataset })
                })
            }
        })

    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(16079);
    }

    getEditIcons = (text, record) => {
        const gt = this.getText;
        const docParams = {
            DocumentNumber: record.dNumber,
            DocumentTypeID: 51,
            DocumentPOSID: record.dPOSID,
            userID: this.props.user.userId,
            userName: encodeURIComponent(this.props.user.displayName),
            isOriginal: false,
            reportName: "reports/DocumentCopy.pdf",
            REPORT_LOCALE: this.getText(101)
        }

        const printDoc = (params) => {
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }

        let genericIcons = [
            {
                type: 'printer',
                tooltip: gt(20639),
                onClick: () => { printDoc(docParams) }
            }, {
                type: 'table',
                tooltip: gt(20640),
                onClick: () => { printDoc({ ...docParams, reportName: "reports/DocumentCopyMatrix.pdf" }) },
                disabled: record.dIsMatrixItem == 0
            }, {
                type: 'file-pdf',
                tooltip: gt(20641),
                showReport: true,
                params: docParams,
            }, {
                type: 'eye',
                tooltip: gt(16088),
                onClick: () => { this.setState({ modalItems: record }, () => { this.getDocItems(record) }) },
            }, {
                type: 'check-circle',
                tooltip: gt(record.dStatus == '1' ? 16089 : 16105),
                onClick: () => { this.transferChange(record, 1) },
            },

        ];

        if (record.dStatus == '1') {
            genericIcons.push({
                type: 'close-circle',
                tooltip: gt(16090),
                onClick: () => { this.transferChange(record, 0) },
                popconfirm: { title: gt(16091), okText: gt(16092), cancelText: gt(16093) },
            })
        }



        return <TableLineIcons {...this.props} genericIcons={genericIcons} />
    }

    getDocItems = (record) => {
        let numOfRowsByHeight = parseInt((window.innerHeight - 340 > 30) ? (window.innerHeight - 340) / 30 : 1);

        let request = {
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _id: 0,
            _words: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _isAsc: 1,
            _sort_by: 'col_1',

            //filters   
            mNumber: record.dNumber,
            mDocType: 51,
            mPOSID: record.dPOSID,
        }

        this.props.dataActions.genericDataSetFilter(this.items_id, this.props.user.companyCode, this.props.user.token, request, this.api.get_items)

    }

    getStatusText = (status) => {
        switch (status) {
            case '1': return this.getText(16103);
            case '2': return this.getText(16104);
        }
    }

    transferChange = (record, _isOk) => {
        let _docNum = record.dNumber;
        let _docPos = record.dPOSID;
        let _employee = this.props.user.displayName;

        let dataSend = '_docNum\f_docPos\f_isOk\f_employee\r' + _docNum + '\f' + _docPos + '\f' + _isOk + '\f' + _employee;

        this.sendAPI(this.api.update, [{ name: 'DOC_PARAMS', data: dataSend }, { name: 'DOC_BLOB', data: '' }], () => {
            this.setState({ modalItems: null }, () => { this.setFilter({}) })
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType['OUTPUT_TYPE_DATA'], ProcessType.PROCESS_TYPE_SYNC);
        if (typeof data === 'object') {
            data.map(x => { job.setSection(x.name, x.data) })
        } else {
            job.setInput(data);
        }
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getItemDesc = (text, record) => {
        if (record.mItemIsMatrix == 1) {
            return (<Row>
                <Col span={12}>{text}</Col>
                <Col span={2}>{record.mSizeCode}</Col>
                <Col span={4}>{record.mSizeDesc}</Col>
                <Col span={2}>{record.mColorUserCode}</Col>
                <Col span={4}>{record.mColorDesc}</Col>
            </Row>)
        }
        return text;
    }

    onFilter = () => {
        const { filters } = this.state;
        this.setState({ modalFilters: false }, () => {
            this.setFilter({ ...filters, _page_num: 1, page: 1 });
        })
    }

    onClearFilter = () => {
        const filters = {
            _storageList: "",
            _sourceStorageList: "",
            _fromDate: null,
            _toDate: null,
            _status: '0',
        }
        this.setState({ modalFilters: false, filters }, () => {
            this.setFilter({ ...filters, _page_num: 1, page: 1 });
        })
    }

    onCancelFilter = () => {
        this.setState({ modalFilters: false, filters: this.props.data[this.id].filters });
    }

    getFilterField = (field) => {
        const gt = this.getText;
        const onChange = (data) => { this.setState({ filters: { ...this.state.filters, ...data } }) }

        switch (field) {
            case 'dates':
                return (<GenericFromToDatePicker
                    format={"DD/MM/YYYY"}
                    fromDate={{ label: gt(20634), value: this.state.filters._fromDate, onChange: e => { onChange({ _fromDate: e }) } }}
                    toDate={{ label: gt(20635), value: this.state.filters._toDate, onChange: e => { onChange({ _toDate: e }) } }}
                    inline
                />)
            case '_storageList':
            case '_sourceStorageList':
                return (<GenericSelector multi {...this.props}
                    id={this.selectors[0].id}
                    value={this.state.filters[field] ? this.state.filters[field].split(',') : []}
                    onChange={(e) => { onChange({ [field]: e ? e.join(',') : '' }) }}
                />)
            case '_status':
                return (<Select value={this.state.filters._status} onChange={e => { onChange({ _status: e }) }}>
                    <Select.Option value={'0'}> - </Select.Option>
                    {['1', '2'].map(x => (<Select.Option value={x}>{this.getStatusText(x)}</Select.Option>))}
                </Select>)
        }
    }

    render() {
        let gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        // let _storageList = this.props.data[this.id].filters._storageList ? this.props.data[this.id].filters._storageList : '';
        let modalItems = this.state.modalItems ? this.state.modalItems : {}
        const formatDatetime = t => (<div dir="ltr">{moment(t).format("DD/MM/YYYY HH:mm")}</div>)

        return (<div style={divStyle}>
            {/* <div style={{ maxWidth: 500, paddingBottom: 20 }}>
                <GenericSelector
                    multi
                    {...this.props}
                    {...this.selectors[0]}
                    value={_storageList ? _storageList.split(',') : []}
                    onChange={(e) => { this.setFilter({ _storageList: e ? e.join(',') : '' }) }}
                    placeholder={gt(16081)}
                // addFirstRow={{ code: '-', name: gt(16081) }}
                />
            </div> */}
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(16080)}
                columns={[
                    { title: gt(16082), key: 'dNumber', dataIndex: 'dNumber', width: '10%', sorter: true, render: t => t },
                    { title: gt(16084), key: 'dPos', dataIndex: 'dPos', width: '15%', sorter: true, render: t => t },
                    { title: gt(16085), key: 'dStorageFrom', dataIndex: 'dStorageFrom', width: '15%', sorter: true, render: t => t },
                    { title: gt(16518), key: 'dStorageTo', dataIndex: 'dStorageTo', width: '15%', sorter: true, render: t => t },
                    { title: gt(16086), key: 'dDatetime', dataIndex: 'dDatetime', width: '13%', sorter: true, render: formatDatetime },
                    { title: gt(16102), key: 'dStatus', dataIndex: 'dStatus', width: '12%', sorter: true, render: this.getStatusText },
                    { title: gt(16197), key: 'dLines', dataIndex: 'dLines', width: '6%', sorter: true, render: t => t },
                    { title: gt(16198), key: 'dQty', dataIndex: 'dQty', width: '7%', sorter: true, render: t => t },
                    { title: gt(16087), width: '7%', render: this.getEditIcons },
                ]}
                percentageToMobile={[40, 0, 0, 60]}
                filters={this.state.filters}
                filterButton={() => { this.setState({ modalFilters: true }) }}
            />
            <GenericModalForm
                visible={this.state.modalFilters}
                onCancel={this.onCancelFilter}
                title={gt(20629)}
                width={1000}
                columnCount={2}
                footerBtn={[
                    { type: "primary", text: gt(20630), onClick: this.onFilter },
                    { text: gt(20632), onClick: this.onClearFilter },
                    { text: gt(20631), onClick: this.onCancelFilter },
                ]}
                formItems={[
                    { label: this.getText(20633), content: this.getFilterField("dates") },
                    { label: this.getText(20636), content: this.getFilterField("_storageList") },
                    { label: this.getText(20637), content: this.getFilterField("_sourceStorageList") },
                    { label: this.getText(20638), content: this.getFilterField("_status") },
                ]} />

            <GenericModal
                visible={this.state.modalItems !== null}
                onCancel={() => { this.setState({ modalItems: null }) }}
                title={gt(16096) + ' ' + (modalItems.dNumber ? modalItems.dNumber : "")}
                footer={[
                    <Button type='primary' style={{ marginLeft: 5 }} onClick={() => { this.transferChange(modalItems, 1) }}>{gt(16094)}</Button>,
                    <Popconfirm title={gt(16091)} okText={gt(16092)} cancelText={gt(16093)} onConfirm={() => { this.transferChange(modalItems, 0) }}>
                        <Button>{gt(16095)}</Button>
                    </Popconfirm>
                ]}>
                <GenericTable
                    {...this.props}
                    id={this.items_id}
                    getApi={this.api.get_items}
                    tableOnly
                    keyIndex={3}
                    columns={[
                        { title: gt(16097), key: 'mNumber', dataIndex: 'mNumber', width: '10%', render: t => t },
                        { title: gt(16098), key: 'mItemCode', dataIndex: 'mItemCode', width: '20%', render: t => t },
                        { title: gt(16099), key: 'mItemBarcode', dataIndex: 'mItemBarcode', width: '20%', render: t => t },
                        { title: gt(16100), key: 'mDescription', dataIndex: 'mDescription', width: '35%', render: this.getItemDesc },
                        { title: gt(16101), key: 'mQuantity', dataIndex: 'mQuantity', width: '15%', render: t => t },
                    ]}
                    percentageToMobile={[40, 0, 0, 60]}
                    filters={{
                        _id: 0,
                        mNumber: modalItems.dNumber ? modalItems.dNumber : 0,
                        mDocType: 51,
                        mPOSID: modalItems.dPOSID ? modalItems.dPOSID : 0,
                    }}
                />
            </GenericModal>
        </div>)
    }

}

export default TransfersPending;