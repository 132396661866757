/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Icon, Popconfirm, Input, Button, Form, Row, Col, message, Tooltip, Checkbox } from "antd";
import ResponsiveTable from "../../components/ResponsiveTable";
import PictureView from "../../components/PictureView";
import LangContext from "../../contextProvider/LangContext";
import GenericModal from "../../components/GenericModal";
//import GenericSelector from "../../components/GenericSelector";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import InvoiceApi from "../../components/InvoiceApi";
import GenericTable from "../../components/GenericTable";
import TableLineIcons from "../../components/TableLineIcons";
import ModalCreateEditBranch from "../../components/ModalCreateEditBranch";
import TableIDs from "../../data/TableIDs";
import ModalCreateEditStorages from "../../components/ModalCreateEditStorages";
import ModalCreateEditPointsOfSale from "../../components/ModalCreateEditPointsOfSale";

const topHeight = 340; // px
const rowHeight = 30;  // px

class Franchisees extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            modalEditWhatsapp: null,
            modalEditLogo: null,
            searchInput: '',
            // invoiceIL
            url: null,
            isInvoiceILShown: false,
            isPageGotFocus: true,
            refreshingData: false,

            modalManagement: null,
            modalManagementBranch: null,
            modalManagementStorage: null,
        };

        this.api = {
            get: "get_franchisees_table",
            create_edit: "create_edit_franchisees_table",
            delete: "delete_franchisees_table",
            renewTokens: "invoice_il_get_authorization_code",

            get_branch: "get_branches_list_table",
            get_storage: "get_storages_per_branch",
            get_pos: "get_pos_per_storage",

        }

        this.id = 'FRANCHISEES_TABLE';
        this.branch_id = "BRANCHES_MAIN";
        this.storage_id = "STORAGES_PER_BRANCH";
        this.pos_id = "POS_PER_STORAGE";

        this.selectors = [
            { id: "EDI_SUPP_LIST", api: "get_edi_supp_list" }
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.branch_id] === undefined) props.data[this.branch_id] = { ...props.data.genericData };
        if (props.data[this.storage_id] === undefined) props.data[this.storage_id] = { ...props.data.genericData };
        if (props.data[this.pos_id] === undefined) props.data[this.pos_id] = { ...props.data.genericData };

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.actionButtons = [
            {
                buttonType: 'add',
                buttonFunction: () => {
                    this.props.dataActions.genericDataStartInlineCreate(this.id);
                }
            },
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ]

    }

    static contextType = LangContext;


    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    // renewTokens = (e) => {
    //     this.addEventListeners();
    //     const sendAPI = (script, data, sCal) => {
    //         let user = new User(this.props.user.token, this.props.user.companyCode);
    //         let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
    //         job.setInput(data);
    //         job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    //     }
    //     let franchise_id = this.props.data[this.id].editedRecord["fID"];
    //     sendAPI(this.api.renewTokens, "franchise_id\r" + franchise_id, (ob) => {
    //         //if (ob.data) { this.setState({ url: ob.data, isInvoiceILShown: true }) }
    //         if (ob.data) { window.open(ob.data, '_blank').focus(); }
    //     })
    // }

    onPagefocus = () => {
        // if (this.state.isPageGotFocus === false) {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        this.setState({ isPageGotFocus: true, refreshingData: true }, async () => {
            const key = this.props.data[this.id]?.editedRecord?.key;
            const index = this.props.data[this.id]?.data?.findIndex(i => {
                const arr = i.split('\f');
                return arr && arr[0] == key;
            })
            this.refreshData();
            await delay(1000);
            this.props.dataActions.genericDataStartEditing(this.id, key, index);
            this.setState({ refreshingData: false });
        });
        // }
    }

    // onPageblur = () => {
    //     this.setState({ isPageGotFocus: false });
    // }

    // clearEventListeners = () => {
    //     window.removeEventListener("focus", this.onPagefocus)
    //     window.removeEventListener("blur", this.onPageblur)
    // }

    // addEventListeners = () => {
    //     window.addEventListener("focus", this.onPagefocus);
    //     window.addEventListener("blur", this.onPageblur);
    //     // force remove listeners after some time
    //     const ttd = 5 * 60 * 1000;
    //     setTimeout(this.clearEventListeners, ttd);
    // }

    componentDidMount() {
        this.refreshData();

        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(13994);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.refreshData);
    };

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    editButtons = (t, r) => {
        return (<span>
            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"}
                onClick={() => {
                    let rIndex = this.props.data[this.id].data.findIndex(f => f.split('\f')[0] === r.key)
                    this.props.dataActions.genericDataStartEditing(this.id, r.key, rIndex)
                }} />
            <Popconfirm title={this.getText(11835)}
                onConfirm={() => {
                    this.props.dataActions.genericDataDeleteRow(
                        this.id,
                        r.key,
                        this.props.user.companyCode,
                        this.props.user.token,
                        this.api.delete,
                        this.api.get);
                }}
                okText={this.getText(11836)} cancelText={this.getText(11837)}>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
            </Popconfirm>
            <Tooltip title={this.getText(20587)}>
                <Icon
                    style={{ fontSize: 16 }} className={"clickable margin-4"} type={"apartment"}
                    onClick={() => { this.setState({ modalManagement: r }) }} />
            </Tooltip>
        </span>)
    }

    inputEdit = (label, field, required) => {
        let d = this.props.data[this.id];
        let value = d.editedRecord[field];
        let onChange = (e) => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }
        let input = '';

        if (field === 'fLogo' || field === 'fFooterLogo') {
            input = (<PictureView   {...this.props}
                url={value} onChange={onChange}
                onRemove={() => { onChange('') }}
                onUploadedSuccess={(response) => { }}
                onUploadedFailed={(errorMessage, errorCode) => { }}
                imageType={field === 'fLogo' ? 'L' : 'N'}
            />);
            // } else if (field === 'fEDI') {
            //     input = (<GenericSelector
            //         {...this.props}
            //         {...this.selectors[0]}
            //         {...{ value, onChange }}
            //     />)
            // } else if (field === 'fAllocatingAccessToken') {
            //     input = (<>
            //         <Input value={value} readOnly style={{ width: '60%' }} onChange={(e) => { onChange(e.target.value); }} />
            //         <Button onClick={this.renewTokens} style={{ marginRight: 8 }}>{this.getText(19928)}</Button>
            //     </>)
            // } else if (field === 'fAllocatingRefreshToken') {
            //     input = (<Input value={value} readOnly style={{ width: '60%' }} onChange={(e) => { onChange(e.target.value); }} />)
            // 
        } else {
            input = (<Input value={value} onChange={(e) => { onChange(e.target.value) }} />)
        }

        return (<Form.Item label={this.getText(label)} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} required={required} validateStatus={!required || value ? "success" : "error"}>{input}</Form.Item>)
    }

    cancelEdit = () => {
        const { editing } = this.props.data[this.id];
        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
        else this.props.dataActions.genericDataCancelEditing(this.id);
        // this.clearEventListeners()
    }

    saveEdit = () => {
        const { editedRecord } = this.props.data[this.id]
        if (!editedRecord.fName || !editedRecord.fOsek || !editedRecord.fNikuy) {
            message.error(this.getText(20028))
        } else {
            this.props.dataActions.genericDataSaveEdit(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.create_edit,
                this.api.get);
            // this.clearEventListeners()
        }
    }

    modalManagementActions = () => {
        const { modalManagementBranch, modalManagementStorage } = this.state
        let id = modalManagementStorage ? this.pos_id : modalManagementBranch ? this.storage_id : this.branch_id
        let api = modalManagementStorage ? this.api.get_pos : modalManagementBranch ? this.api.get_storage : this.api.get_branch
        let modalId = modalManagementStorage ? TableIDs.modalCreateEditPointsOfSale : modalManagementBranch ? TableIDs.modalCreateEditStorages : TableIDs.modalCreateEditBranch
        let addKey = modalManagementStorage || modalManagementBranch ? { sID: 0 } : 0
        return [
            {
                buttonType: 'add',
                buttonFunction: () => { this.props.uiActions.showModal(modalId, addKey) }
            }, {
                buttonType: 'refresh',
                buttonFunction: () => { this.props.dataActions.genericDataGetRequested(id, this.props.user.companyCode, this.props.user.token, api) }
            }
        ]
    }

    modalManagementTitle = () => {
        const { modalManagement, modalManagementBranch, modalManagementStorage } = this.state
        let t = this.getText(20587)
        if (modalManagement) {
            t = (<span>
                <span onClick={() => { this.setState({ modalManagementBranch: null, modalManagementStorage: null }) }}>
                    {this.getText(20587)} {modalManagement.fID} - {modalManagement.fName}
                </span>
                {modalManagementBranch ?
                    <span onClick={() => { this.setState({ modalManagementStorage: null }) }}>
                        {" > "} {this.getText(20596)} {modalManagementBranch.mID} - {modalManagementBranch.mName}
                    </span>
                    : ""}
                {modalManagementStorage ?
                    <span>
                        {" > "} {this.getText(20597)} {modalManagementStorage.mID} - {modalManagementStorage.mName}
                    </span>
                    : ""}
            </span>)
        }
        return t
    }

    modalManagementContent = () => {
        const { modalManagement, modalManagementBranch, modalManagementStorage } = this.state
        const gt = this.getText
        const render = t => t
        const renderActive = t => (<b style={{ color: t == 1 ? "green" : "red" }}>{t == 1 ? 'V' : 'X'}</b>)
        const renderEditBranch = (t, r) => (<TableLineIcons
            onEdit={() => { this.props.uiActions.showModal(TableIDs.modalCreateEditBranch, r.mID) }}
            genericIcons={[
                { type: "table", onClick: () => { this.setState({ modalManagementBranch: r }) }, tooltip: gt(20592), }
            ]}
        />)
        const renderEditStorage = (t, r) => (<TableLineIcons
            onEdit={() => { this.props.uiActions.showModal(TableIDs.modalCreateEditStorages, { sID: r.mID }) }}
            genericIcons={[
                { type: "table", onClick: () => { this.setState({ modalManagementStorage: r }) }, tooltip: gt(20595), }
            ]}
        />)
        const renderEditPOS = (t, r) => (<TableLineIcons
            onEdit={() => { this.props.uiActions.showModal(TableIDs.modalCreateEditPointsOfSale, { sID: r.mID }) }}
        />)


        if (modalManagement) {

            if (modalManagementStorage) {
                return (<GenericTable {...this.props} tableOnly
                    id={this.pos_id}
                    getApi={this.api.get_pos}
                    columns={[
                        { title: gt(20598), dataIndex: "mID", key: "mID", width: '25%', render },
                        { title: gt(20599), dataIndex: "mName", key: "mName", width: '65%', render },
                        { width: '10%', render: renderEditPOS },
                    ]}
                    filters={{ _storage: modalManagementStorage.mID }}
                />)
            } else if (modalManagementBranch) {
                return (<GenericTable {...this.props} tableOnly
                    id={this.storage_id}
                    getApi={this.api.get_storage}
                    columns={[
                        { title: gt(20593), dataIndex: "mID", key: "mID", width: '25%', render },
                        { title: gt(20594), dataIndex: "mName", key: "mName", width: '65%', render },
                        { width: '10%', render: renderEditStorage },
                    ]}
                    filters={{ _branch: modalManagementBranch.mID }}
                />)
            } else {
                return (<GenericTable {...this.props} tableOnly
                    id={this.branch_id}
                    getApi={this.api.get_branch}
                    columns={[
                        { title: gt(20588), dataIndex: "mID", key: "mID", width: '15%', render },
                        { title: gt(20589), dataIndex: "mName", key: "mName", width: '30%', render },
                        { title: gt(20590), dataIndex: "mAddress", key: "mAddress", width: '30%', render },
                        { title: gt(20591), dataIndex: "mIsActive", key: "mIsActive", width: '15%', render: renderActive },
                        { width: '10%', render: renderEditBranch },
                    ]}
                    filters={{ _id: "0", _isActive: "0", _franchiseList: modalManagement.fID }}
                />)
            }

        }
    }

    render() {
        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        const { editing, editedRecord } = this.props.data[this.id];
        const render = t => t;

        const modalSpan = window.innerWidth > 600 ? 12 : 24;
        const modalSpanStyle = window.innerWidth > 600 ? { paddingRight: 20, paddingLeft: 20 } : {};


        let numOfRowsByHeight = parseInt((window.innerHeight - topHeight > rowHeight)
            ? (window.innerHeight - topHeight) / rowHeight
            : 1);

        let columns = [
            { title: this.getText(13995), dataIndex: "fID", key: "fID", width: '5%', render },
            { title: this.getText(13996), dataIndex: "fName", key: "fName", width: '12%', render },
            { title: this.getText(13997), dataIndex: "fOsek", key: "fOsek", width: '12%', render },
            { title: this.getText(13998), dataIndex: "fNikuy", key: "fNikuy", width: '12%', render },
            { title: this.getText(13999), dataIndex: "fStreet", key: "fStreet", width: '12%', render },
            { title: this.getText(14000), dataIndex: "fHouse", key: "fHouse", width: '5%', render },
            { title: this.getText(14001), dataIndex: "fCity", key: "fCity", width: '10%', render },
            { title: this.getText(14002), dataIndex: "fZip", key: "fZip", width: '7%', render },
            { title: this.getText(15530), dataIndex: "fEmail", key: "fEmail", width: '15%', render },
            { title: this.getText(14003), width: '10%', render: this.editButtons },
        ];

        let idTable = { ...this.props.data[this.id] }
        if (this.state.searchInput) {
            idTable = { ...idTable, data: idTable.data.filter(f => f.indexOf(this.state.searchInput) > -1) }
        }
        return (
            <div style={divStyle}>
                <ResponsiveTable
                    idTable={idTable}
                    columns={columns}
                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(14004)}
                    actionButtons={this.actionButtons}
                    showsearch={(e) => { this.setState({ searchInput: e }) }}
                    pagination={{ pageSize: numOfRowsByHeight }}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />
                <GenericModal
                    visible={editing > -1 || this.state.refreshingData}
                    onCancel={this.cancelEdit}
                    title={this.getText(17252)}
                    footer={[
                        <Button onClick={this.cancelEdit}>{this.getText(17249)}</Button>,
                        <Button type="primary" onClick={this.saveEdit}>{this.getText(17250)}</Button>
                    ]}
                >
                    <Row>
                        <Col span={modalSpan} style={modalSpanStyle}>
                            {this.inputEdit(13996, 'fName', true)}
                            {this.inputEdit(13997, 'fOsek', true)}
                            {this.inputEdit(13998, 'fNikuy', true)}
                            {this.inputEdit(13999, 'fStreet')}
                            {this.inputEdit(14000, 'fHouse')}
                            {this.inputEdit(14001, 'fCity')}
                            {this.inputEdit(14002, 'fZip')}
                            {this.inputEdit(15530, 'fEmail')}
                            {this.inputEdit(20109, 'fPhone')}
                            {this.inputEdit(19511, 'fAccountingNumber')}
                            {this.inputEdit(14384, 'fManagersWhatsapp')}
                            {this.inputEdit(14385, 'fEmployeesWhatsapp')}
                        </Col>
                        <Col span={modalSpan} style={modalSpanStyle}>
                            {this.inputEdit(16694, 'fLogo')}
                            {this.inputEdit(18839, 'fFooterLogo')}
                            {this.inputEdit(17251, 'fEDI')}
                            {this.inputEdit(17523, 'fManagersTelegram')}
                            {this.inputEdit(17524, 'fEmployeesTelegram')}
                            {/* {this.inputEdit(19926, 'fAllocatingAccessToken')}
                            {this.inputEdit(19927, 'fAllocatingRefreshToken')} */}
                            <InvoiceApi
                                {...this.props}
                                franchiseId={this.props.data[this.id].editedRecord["fID"]}
                                onPagefocus={this.onPagefocus}
                                accessToken={editedRecord.fAllocatingAccessToken}
                                refreshToken={editedRecord.fAllocatingRefreshToken}
                                expiration={editedRecord.fAccessExpiration}
                                onChangeAccessToken={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { fAllocatingAccessToken: e }) }}
                                onChangeRefreshToken={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { fAllocatingRefreshToken: e }) }}
                                onChangeExpiration={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { fAccessExpiration: e }) }}
                            />
                        </Col>
                    </Row>
                </GenericModal>

                <GenericModal
                    visible={this.state.modalManagement != null}
                    onCancel={() => { this.setState({ modalManagement: null, modalManagementBranch: null, modalManagementStorage: null }) }}
                    width={1000}
                    title={this.modalManagementTitle()}
                    actionButtons={this.modalManagementActions()}
                >
                    {this.modalManagementContent()}
                </GenericModal>

                <ModalCreateEditBranch
                    {...this.props}
                    id={TableIDs.modalCreateEditBranch}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditBranch)}
                    title={this.getText(11474)}
                    staticFranchise={this.state.modalManagement?.fID}
                    onSave={() => {
                        this.props.dataActions.genericDataGetRequested(
                            this.branch_id, this.props.user.companyCode, this.props.user.token, this.api.get_branch);
                    }}
                />

                <ModalCreateEditStorages
                    {...this.props}
                    id={TableIDs.modalCreateEditStorages}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditStorages)}
                    staticBranch={this.state.modalManagementBranch?.mID}
                    onSave={() => {
                        this.props.dataActions.genericDataGetRequested(
                            this.storage_id, this.props.user.companyCode, this.props.user.token, this.api.get_storage);
                    }} />

                <ModalCreateEditPointsOfSale
                    {...this.props}
                    id={TableIDs.modalCreateEditPointsOfSale}
                    toggle={this.props.ui.modalStack.includes(TableIDs.modalCreateEditPointsOfSale)}
                    staticStorage={this.state.modalManagementStorage?.mID}
                    onSave={() => {
                        this.props.dataActions.genericDataGetRequested(
                            this.pos_id, this.props.user.companyCode, this.props.user.token, this.api.get_pos);
                    }} />

            </div>
        )

    }
}
export default Franchisees;