/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Button, Card, Icon, Modal, Tooltip } from "antd";
import { Bar } from "ant-design-pro/lib/Charts";
import Nis from "../../components/Nis";
import moment from "moment";
import LangContext from "../../contextProvider/LangContext";
import "../Dashboard.css";
import Events from "../UserSettings/Events";
import TableIDs from "../../data/TableIDs";

class GraphBetwenSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            LastTimeRefreshe: null,
            graphBetwenType: 0,
            graphBetwenDinami: 0,
            stateMobile: 'tab1',
            modalEvents: false,
        }

        this.id = "DASHBOARD_GRAPH_DATA";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(p) {
        if (
            p.graphBetwenType !== this.props.graphBetwenType ||
            p.graphBetwenDinami !== this.props.graphBetwenDinami ||
            p.dashboardDate !== this.props.dashboardDate ||
            p.dashboardPos !== this.props.dashboardPos ||
            p.dDateCheck !== this.props.dDateCheck
        ) {
            this.getDataGraphBetwen()
        }
    }

    getDataGraphBetwen = () => {
        let request = {
            graphBetwenType: this.props.graphBetwenType,
            graphBetwenDinami: this.props.graphBetwenDinami,
            dashboardDate: this.props.dashboardDate ? moment(this.props.dashboardDate).format("YYYY-MM-DD") : null,
            dashboardPos: this.props.dashboardPos,
            dDateCheck: this.props.dDateCheck ? moment(this.props.dDateCheck).format("YYYY-MM-DD") : null,
        };

        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, request, "get_dashboard_graph_data");
        this.setState({ LastTimeRefreshe: moment().format('HH:mm:ss'/*'LTS'*/) });
    }

    createGraphBetwen = (mobile) => {
        let gt = this.getText;
        let et = (t) => t.replace(" ", "\n")
        let dataGB = this.props.data[this.id];
        let dataGBOb = {}
        if (dataGB && dataGB.data[0]) dataGB.data[0].split("\f").forEach((x, i) => dataGBOb = { ...dataGBOb, [dataGB.headers[i]]: x });
        let dc = dataGBOb.dDateCheck === '1' ? true : false;
        let firstCol = { x: '', y: 0 }
        let firstColMonth = dataGBOb.dSumMonthlyTargets > 0 ? { x: gt(18531), y: Math.round(dataGBOb.dSumMonthlyTargets) } : firstCol

        let data = [
            [firstCol, { x: gt(10092), y: Math.round(dataGBOb.dToday) }, { x: gt(10093), y: Math.round(dataGBOb.dYesterday) }],
            [firstCol, { x: gt(10092), y: Math.round(dataGBOb.dToday) }, { x: et(dc ? gt(10094) : gt(10095)), y: Math.round(dataGBOb.dTodayWeekAgo) }],
            [firstCol, { x: gt(10096), y: Math.round(dataGBOb.dThisWeek) }, { x: et(dc ? gt(10097) : gt(10098)), y: Math.round(dataGBOb.dLastWeek) }],
            [firstColMonth, { x: gt(10099), y: Math.round(dataGBOb.dThisMonth) }, { x: et(dc ? gt(10100) : gt(10101)), y: Math.round(dataGBOb.dLastMonth) }],
            [firstCol, { x: gt(10102), y: Math.round(dataGBOb.dThisYear) }, { x: et(dc ? gt(10103) : gt(10104)), y: Math.round(dataGBOb.dLastYear) }],
        ];

        let styleDiv = {
            width: (window.innerWidth > 600) ? '20%' : '100%',
            padding: 10,
            display: 'inline-block',
            verticalAlign: 'text-top',
            marginBottom: 100
        };

        let res = (z) => {
            let curNum = z[1].y ? z[1].y : 0;
            let preNum = z[2].y ? z[2].y : 0;
            let targetNum = z[0].y ? z[0].y : 0;
            let percent = (preNum > 0 ? (((curNum - preNum) / preNum) * 100) : curNum ? 100 : 0).toFixed(2);
            let percentTarget = (targetNum > 0 ? ((curNum / targetNum) * 100) : 0).toFixed(2);
            let endSum = this.props.graphBetwenType === 0 ? gt(10059) : gt(10060);

            let title = (<div style={{ textAlign: 'center' }}>
                <h3>{z[1].x + ': ' + curNum + endSum}</h3>
                <h3>{z[2].x + ': ' + preNum + endSum}</h3>
                <div style={{ height: 40, lineHeight: 0.8 }}>
                    {targetNum ? [
                        <h4>{z[0].x + ': ' + targetNum + endSum}</h4>,
                        <h4>{gt(18532) + ': ' + percentTarget + "%"}</h4>,
                    ] : ""}
                </div>

                <h5 style={{ color: (percent === 0 ? 'black' : percent > 0 ? 'green' : 'red'), fontSize: 20 }}>
                    <Icon type={"arrow-" + (percent > 0 ? "up" : "down")} />
                    {' ' + percent + '%'}
                </h5>
            </div>)

            console.log("bar-data", z)

            return (<div style={styleDiv}><Bar height={300} title={title} data={z} /></div>)
        }

        if (mobile) {
            switch (this.state.stateMobile) {
                case 'tab1': return res(data[0]);
                case 'tab2': return res(data[1]);
                case 'tab3': return res(data[2]);
                case 'tab4': return res(data[3]);
                case 'tab5': return res(data[4]);
            }
        } else {
            return data.map(res);
        }


    }


    render() {
        let gt = this.getText;
        let tabTitle = t => (<div style={{ fontSize: 14 }}>{gt(t)}</div>)

        let isUserEditEvents = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset &&
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '223')


        let titleCard = (<div>
            <span>{gt(10139)}</span>
            <span style={{ float: "left" }}>
                <span style={{ marginLeft: 4 }}>{this.state.LastTimeRefreshe}</span>
                <Button className={"btn"}
                    disabled={this.props.ui.workInProgress}
                    onClick={this.getDataGraphBetwen}>
                    <Icon type={"reload"} style={{ fontSize: 16, padding: 4 }} />
                </Button>
                {isUserEditEvents ?
                    <Tooltip title={this.getText(18849)}>
                        <Button className={"btn"} onClick={() => { this.setState({ modalEvents: true }) }}>
                            <Icon type={"calendar"} style={{ fontSize: 16, padding: 4 }} />
                        </Button>
                    </Tooltip>
                    : ""}
            </span>
        </div>)

        let mobile = window.innerWidth <= 600;
        let mobileProps = {}

        if (mobile) {
            mobileProps = {
                activeTabKey: this.state.stateMobile,
                onTabChange: key => { this.setState({ stateMobile: key }) },
                tabList: [
                    { key: 'tab1', tab: tabTitle(16962) },
                    { key: 'tab2', tab: tabTitle(16963) },
                    { key: 'tab3', tab: tabTitle(16964) },
                    { key: 'tab4', tab: tabTitle(16965) },
                    { key: 'tab5', tab: tabTitle(16966) },
                ]
            }
        }


        return (<div style={{ marginBottom: "24px" }}>
            <Card
                loading={this.state.isLoading}
                bordered={false}
                title={titleCard}
                bodyStyle={{ padding: 24 }}
                style={{ direction: "ltr" }}
                {...mobileProps}>
                {this.createGraphBetwen(mobile)}
            </Card>
            {isUserEditEvents ?
                <Modal visible={this.state.modalEvents} onCancel={() => { this.setState({ modalEvents: false }) }} footer={false} width={650} className={"padding_0"}>
                    {this.state.modalEvents ? <Events {...this.props} /> : ""}
                </Modal> : ""}
        </div>)
    }
}
export default GraphBetwenSection;
