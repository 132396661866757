/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import "../App.css";
import { Button } from "antd";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import GenericTable from "./GenericTable";
import moment from "moment";

class ModalLastPrices extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.id = "LAST_DOCS_CUST_ITEM";
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }

        this.api = {
            get: "get_last_docs_cust_item"
        }
    }


    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }



    handleOk = (record) => {
        const { unitPriceNoTax, unitPriceWithTax, taxTypeID, taxValue, discountPercentage, remarks } = record;
        const { quantity } = this.props.editedRecord;
        this.props.onChange({
            ...this.props.editedRecord,
            unitPriceNoTax,
            unitPriceWithTax,
            totalSumNoTax: (parseFloat(unitPriceNoTax) * quantity * (1 - discountPercentage / 100)).toFixed(4),
            totalSumWithTax: (parseFloat(unitPriceWithTax) * quantity * (1 - discountPercentage / 100)).toFixed(2),
            taxTypeID,
            taxValue,
            discountPercentage,
            remarks,
        })
        this.props.onCancel()
    }



    render() {

        const { visible, onCancel, editedRecord, data, documentType } = this.props;
        console.log("ModalLastPrices",this.props)
        return (<GenericModal {...{ visible, onCancel }}
            title={this.getText(20486)}
            width={1000}>
            {this.props.visible ?
                <GenericTable
                    {...this.props}
                    tableOnly
                    id={this.id}
                    getApi={this.api.get}
                    filters={{
                        _cust: data.document2.customerCode,
                        _item: editedRecord.itemCode,
                        _isSupp: [4, 16, 17, 18, 20, 21].find(f => f == documentType) ? '1' : '0'
                    }}
                    columns={[
                        { title: this.getText(20487), key: "sourceDocNum", dataIndex: "sourceDocNum", render: t => t },
                        { title: this.getText(20488), key: "sourceDocDatetime", dataIndex: "sourceDocDatetime", render: t => moment(t).format("DD/MM/YYYY HH:mm") },
                        { title: this.getText(20489), key: "unitPriceNoTax", dataIndex: "unitPriceNoTax", render: t => t },
                        { title: this.getText(20490), key: "unitPriceWithTax", dataIndex: "unitPriceWithTax", render: t => t },
                        { title: this.getText(20491), key: "discountPercentage", dataIndex: "discountPercentage", render: t => t },
                        { title: "", render: (t, r) => (<Button onClick={() => { this.handleOk(r) }}>{this.getText(20492)}</Button>) },
                    ]}
                />
                : ""}
        </GenericModal>)
    }
}

export default ModalLastPrices;