/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import GenericSelector from "../../components/GenericSelector";
import { Button, Checkbox, Col, Form, message, Radio, Select } from "antd";
import TableIDs from "../../data/TableIDs";
import moment from "moment";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import { Row } from "antd/es/grid";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import DateTimePicker from "../../components/DateTimePicker";
import { Job, User, OutputType, ProcessType } from '../../JmReact';


class ExcludedItems extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            importTagId: null,
            modalFilter: false,
            _branchList: "",
            _typeView: "1",
        };

        this.id = "EXCLUDED_ITEMS";

        this.api = {
            get: "get_excluded_items",
            create_edit: "create_edit_excluded_items",
            delete: "delete_excluded_items",
        }

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list" },
            { id: TableIDs.genericSelectorPos, api: "list_pos" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.forEach((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20000);
    }

    handleCancel = () => {
        if (this.props.data[this.id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id)
        else this.props.dataActions.genericDataCancelEditing(this.id)
    }

    handleOk = () => {
        const { editedRecord } = this.props.data[this.id]
        if (!editedRecord.mItemCode && !this.state.importTagId) {
            message.error(this.getText(20021))
        } else if (!editedRecord.mEntityType || !editedRecord.mEntityCode) {
            message.error(this.getText(20022))
        } else {
            this.props.dataActions.genericDataSaveEditWithParams(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get, { mTagId: this.state.importTagId });
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    onFilter = () => {
        const { _branchList, _typeView } = this.state
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { _branchList, _typeView, page: 1, _page_num: 1, _words: "" }, this.api.get);
    }

    handleClear = () => {
        this.setState({ _branchList: "", _typeView: "0" }, this.onFilter)
    }

    handleFilter = () => {
        this.setState({ modalFilter: false }, this.onFilter)
    }

    render() {
        const gt = this.getText;
        const { editing, editedRecord, data, headers } = this.props.data[this.id]
        const sorter = true;
        const onChangeRecord = data => { this.props.dataActions.genericDataSetEdit(this.id, data) }
        const render = t => t;
        // const renderEntity = t => t == '1' ? gt(20011) : t == '2' ? gt(20012) : t == '3' ? gt(20013) : ""
        const renderSelector = (t, r) => (<GenericSelector {...this.props} id={this.selectors[r.mEntityType ? parseInt(r.mEntityType) - 1 : 0].id} value={t} disabled />)
        const renderCheckbox = t => (<Checkbox checked={t == '1'} readOnly />)
        const renderDatetime = t => t ? moment(t).format("DD/MM/YYYY HH:mm:ss") : ""
        const renderEdit = (t, r) => (<TableLineIcons
            onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, r.key, r.index) }}
            // onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, r.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
            // deleteQuetion={{ title: gt(20014), okText: gt(20015), cancelText: gt(20016) }}
            genericIcons={[
                {
                    type: r.mIsExcluded == 1 ? "minus-square" : "check-square",
                    onClick: () => {
                        let findRow = [...data.map(x => x.split("\f")).find(f => f[0] == r.key)]
                        findRow[5] = r.mIsExcluded == 1 ? "0" : "1";
                        findRow[6] = r.mIsExcluded == 1 ? "" : moment().format("YYYY-MM-DD HH:mm:ss");
                        findRow[7] = "";
                        this.sendAPI(this.api.create_edit, headers.join("\f") + "\fmTagId\r" + findRow.join("\f") + "\f", this.refreshData)
                    },
                    tooltip: gt(r.mIsExcluded == 1 ? 20097 : 20096),
                    popconfirm: { title: gt(r.mIsExcluded == 1 ? 20099 : 20098), okText: gt(20015), cancelText: gt(20016) },
                }
            ]}
        />)


        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const colSpan = window.innerWidth > 600 ? 12 : 24

        const defaultExcludeDates = { mFromDateTime: moment().format("YYYY-MM-DD HH:mm:ss"), /*mToDateTime: moment().format("YYYY-MM-DD 23:59:59")*/ }

        const { _branchList, _typeView } = this.state
       

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                title={gt(20001)}
                columns={[
                    // { title: gt(20002), key: "mID", dataIndex: "mID", width: "6%", sorter, render },
                    { title: gt(20003), key: "mItemCode", dataIndex: "mItemCode", width: "14%", sorter, render },
                    { title: gt(20004), key: "mItemName", dataIndex: "mItemName", width: "25%", sorter, render },
                    // { title: gt(20005), key: "mEntityType", dataIndex: "mEntityType", width: "10%", sorter, render: renderEntity },
                    { title: gt(20006), key: "mEntityCode", dataIndex: "mEntityCode", width: "21%", sorter, render: renderSelector },
                    { title: gt(20007), key: "mIsExcluded", dataIndex: "mIsExcluded", width: "6%", sorter, render: renderCheckbox },
                    { title: gt(20008), key: "mFromDateTime", dataIndex: "mFromDateTime", width: "14%", sorter, render: renderDatetime },
                    { title: gt(20009), key: "mToDateTime", dataIndex: "mToDateTime", width: "14%", sorter, render: renderDatetime },
                    { title: gt(20010), width: "6%", render: renderEdit },
                ]}
                searchBox
                addButton={() => {
                    this.props.dataActions.genericDataStartInlineCreate(this.id)
                    setTimeout(() => {
                        let branchesDataset = this.props.data[this.selectors[0].id].dataset;
                        let initData = { mEntityType: '1', mIsExcluded: '1', ...defaultExcludeDates }
                        if (branchesDataset?.length == 1) initData = { ...initData, mEntityCode: branchesDataset[0].code }
                        onChangeRecord(initData)
                    }, 500);
                }}
                actionButtons={[{
                    buttonType: 'filter',
                    buttonFunction: () => { this.setState({ modalFilter: true }) }
                }]}
                filters={{ _branchList, _typeView }}
                percentageToMobile={[ 20, 40, null, 20, null, null, 20]}
                isModal={this.props.isModalAutoIphone}
                modalCancel={this.props.onCancel}
            />
            <GenericModal
                visible={editing > -1}
                onCancel={() => { this.props.dataActions[editing == 0 ? "genericDataCancelInlineCreate" : "genericDataCancelEditing"](this.id) }}
                title={gt(20017)}
                width={1000}
                footer={[
                    <Button onClick={this.handleCancel}>{gt(20019)}</Button>,
                    <Button type="primary" onClick={this.handleOk}>{gt(20020)}</Button>,
                ]}>
                <Row>
                    <Col span={colSpan}>
                <Form.Item label={gt(20003)} required validateStatus={!editedRecord.mItemCode && !this.state.importTagId ? "error" : "success"}>
                    <SelectorItemClassic
                        {...this.props}
                        valueId={editedRecord.mItemCode}
                        valueName={editedRecord.mItemName}
                        onChange={(mItemCode, mItemName) => { this.setState({ importTagId: null }, () => { onChangeRecord({ mItemCode, mItemName }) }) }}
                    />
                </Form.Item>
                </Col>
                    <Col span={colSpan}>
                        {editing == 0 ?
                            <Form.Item label={gt(20018)}>
                                <GenericSelectorItemTags
                                    {...this.props} notReport
                                    value={this.state.importTagId}
                                    onChange={e => { this.setState({ importTagId: e }, () => { onChangeRecord({ mItemCode: null, mItemName: null }) }) }}
                                />
                            </Form.Item>
                            : ""}
                    </Col>
                </Row>

                {/* <Row>
                    <Col span={colSpan}>
                        <Form.Item label={gt(20005)} required validateStatus={!editedRecord.mEntityType ? "error" : "success"}>
                            <Select style={{ width: 250 }} value={editedRecord.mEntityType} onChange={e => { onChangeRecord({ mEntityType: e, mEntityCode: null }) }}>
                                <Select.Option value="1">{gt(20011)}</Select.Option>
                                <Select.Option value="2">{gt(20012)}</Select.Option>
                                <Select.Option value="3">{gt(20013)}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={colSpan}> */}
                <Form.Item label={gt(20006)} required validateStatus={!editedRecord.mEntityCode ? "error" : "success"}>
                    <GenericSelector {...this.props}
                        id={this.selectors[editedRecord.mEntityType ? parseInt(editedRecord.mEntityType) - 1 : 0].id}
                        value={editedRecord.mEntityCode} onChange={e => { onChangeRecord({ mEntityCode: e }) }}
                    />
                </Form.Item>
                {/* </Col>
                </Row> */}

                <Form.Item>
                    <Checkbox
                        checked={editedRecord.mIsExcluded == '1'}
                        onChange={e => { onChangeRecord(e.target.checked ? { mIsExcluded: "1", ...defaultExcludeDates } : { mIsExcluded: "0", mFromDateTime: null, mToDateTime: null }) }}
                    >{gt(20007)}</Checkbox>
                </Form.Item>

                {editedRecord.mIsExcluded == '1' ?
                    <Row>
                        <Col span={colSpan}>
                            <Form.Item label={gt(20008)}>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={editedRecord.mFromDateTime}
                                    onChange={e => { onChangeRecord({ mFromDateTime: e }) }}
                                    maxDate={editedRecord.mToDateTime}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={colSpan}>
                            <Form.Item label={gt(20009)}>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={editedRecord.mToDateTime}
                                    onChange={e => { onChangeRecord({ mToDateTime: e }) }}
                                    minDate={editedRecord.mFromDateTime}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    : ""}

            </GenericModal>

            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(20100)}
                width={600}
                footer={[
                    <Button onClick={() => { this.setState({ modalFilter: false }) }}>{gt(20101)}</Button>,
                    <Button onClick={this.handleClear}>{gt(20102)}</Button>,
                    <Button type="primary" onClick={this.handleFilter}>{gt(20103)}</Button>,
                ]}>
                <Form.Item label={gt(20105)}>
                    <GenericSelector {...this.props} multi
                        id={this.selectors[0].id}
                        value={_branchList ? _branchList.split(",") : []}
                        onChange={e => { this.setState({ _branchList: e ? e.join(",") : "" }) }}
                    />
                </Form.Item>
                <Form.Item label={gt(20104)}>
                    <Radio.Group
                        value={_typeView}
                        onChange={e => { this.setState({ _typeView: e.target.value }) }}
                    >
                        <Radio value={'0'}>{gt(20106)}</Radio><br />
                        <Radio value={'1'}>{gt(20107)}</Radio><br />
                        <Radio value={'2'}>{gt(20108)}</Radio><br />
                    </Radio.Group>
                </Form.Item>
            </GenericModal>
        </div>)
    }

}

export default ExcludedItems;