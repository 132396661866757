/* @flow */
/*jshint esversion: 6 */
import React from "react";
import "../App.css";
import { Modal, Button } from "antd";
import PageToolbar from "./PageToolbar";

class GenericModal extends React.Component {

    componentDidUpdate(prev) {
        if (prev.visible && !this.props.visible) {
            setTimeout(() => {
                let mList = [...document.getElementsByClassName("ant-modal-wrap")]
                let body = [...document.getElementsByTagName("body")]
                console.log("modal_closed_did_update", mList, body, mList.filter(f => f.style.display != 'none'), body[0].style.overflow)
                if (!mList.filter(f => f.style.display != 'none').length && body[0].style.overflow == 'hidden') {
                    body[0].style.overflow = 'auto'
                    body[0].style.width = 'auto'
                    body[0].style.position = 'auto'
                }
            }, 500);
        }
    }


    render() {

        let width = window.innerWidth > 600 && this.props.width ? this.props.width : "95%";
        let footer = this.props.footer ? this.props.footer : false
        let maskClosable = this.props.maskClosable ? this.props.maskClosable : false
        let title = (<PageToolbar
            {...this.props}
            isModal={true}
            actionButtons={this.props.actionButtons ?? []}
        />)

        let modalProps = { ...this.props, width, footer, title, maskClosable }

        return (<Modal  {...modalProps} />)
    }
}
export default GenericModal;