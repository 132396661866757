/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import GenericModal from "../../components/GenericModal";
import { Button, Col, Form, Input, InputNumber, message, Modal, Radio } from "antd";
import CustPicker from "../../components/CustPicker";
import GenericFromToDatePicker from "../../components/GenericFromToDatePicker";
import { Row } from "antd/es/grid";
import GenericSelector from "../../components/GenericSelector";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import PageToolbar from "../../components/PageToolbar";
import { escapeRegExp } from "lodash";
import ServiceCallsTable from "./ServiceCallsTable";


class ServiceCalls extends Component {
    constructor(props: Props) {
        super(props);
        this.defaultFilters = {
            _custId: null,
            _fromDateOpen: null,
            _toDateOpen: null,
            _isClosed: '-1',
            _fromDateClose: null,
            _toDateClose: null,
            _subjectList: "",
            _subjectDetailList: "",
            _itemCode: "",
            _sn: "",
            _statusList: "",
            _tagList: "",
        }

        this.state = {
            ...this.defaultFilters,
            modalFilter: false,
            modalReadOnly: false,
            modalDetails: false,
            modalDetailsOldStatus: null,
            modalDetailsOnly: false,
            modalDetailLineEdit: false,
            modalDetailLineView: null,
        };

        this.id = "SERVICE_CALLS";
        this.status_id = "SERVICE_CALL_STATUSES";
        this.details_id = "SERVICE_CALL_DETAILS";

        this.api = {
            get: "get_service_calls",
            create_edit: "create_edit_service_calls",
            get_status: "get_service_call_statuses",

            get_details: "get_service_call_details",
            edit_details: "edit_service_call_details",
            open_user: "service_call_detail_open_user",

        }

        this.selectors = [
            { id: "SERVICE_SUBJECTS_LIST", api: "service_subjects_list" },
            { id: "SERVICE_SUBJECT_DETAILS_LIST", api: "service_subject_details_list" },
            { id: "SELECTOR_TAG_TYPE_9", api: "get_tags_from_type_9" },
            { id: "CUSTOMER_BUSINESS_BRANCHES_LIST", api: "customer_business_branches_list" },
            { id: "SERVICE_SUBJECTS_TAGS_AND_USERS", api: "get_service_subjects_tags_and_users" },
            { id: "PROVIDERS_CONTACT_MAIL_LIST", api: "providers_contact_mail_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.status_id] === undefined) props.data[this.status_id] = { ...props.data.genericData };
        if (props.data[this.details_id] === undefined) props.data[this.details_id] = { ...props.data.genericData };
        if (props.data.SERVICE_SUBJECT_DETAILS_LIST2 === undefined) props.data.SERVICE_SUBJECT_DETAILS_LIST2 = { dataset: [] };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20331);
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    onFilter = () => {
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token,
                { ...this.props.data[this.id].filters, ...this.state, page: 1, _page_num: 1 },
                this.api.get);
        })
    }

    filterField = (field) => {
        const gt = this.getText
        const value = this.state[field]
        const onChange = e => { this.setState({ [field]: e }) }
        switch (field) {
            case '_custId': return (<CustPicker {...this.props} {...{ value, onChange }} defaultView={gt(12121)} />)
            case '_isClosed':
                return (<Radio.Group value={value} onChange={e => { onChange(e.target.value) }}>
                    <Radio value="-1">{gt(20362)}</Radio>
                    <Radio value="0">{gt(20363)}</Radio>
                    <Radio value="1">{gt(20364)}</Radio>
                </Radio.Group>)
            case '_itemCode':
                return (<SelectorItemClassic {...this.props} valueId={this.state._itemCode} valueName={this.state._itemName}
                    onChange={(_itemCode, _itemName) => { this.setState({ _itemCode, _itemName }) }} />)
            case '_sn': return (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
        }
    }

    filterFromToDate = (field1, field2) => {
        return (<GenericFromToDatePicker
            format={"DD/MM/YYYY HH:mm"} showTime inline
            fromDate={{ label: this.getText(20359), value: this.state[field1], onChange: e => { this.setState({ [field1]: e }) } }}
            toDate={{ label: this.getText(20360), value: this.state[field2], onChange: e => { this.setState({ [field2]: e }) } }}
        />)
    }

    filterSelector = (field, index, moreAttr) => {
        let props = { ...this.props }
        if (index > -1) props = { ...props, ...this.selectors[index] }
        if (moreAttr) props = { ...props, ...moreAttr }
        return ((<GenericSelector {...props} multi
            value={this.state[field] ? this.state[field].split(",") : []}
            onChange={e => { this.setState({ [field]: e?.join(",") }) }} />
        ))
    }

    showsearch = (val) => {
        this.props.dataActions.genericDataSetFilter(
            this.id,
            this.props.user.companyCode,
            this.props.user.token,
            {
                ...this.props.data[this.id].filters,
                page: 1,
                _page_num: 1,
                _words: escapeRegExp(val),
                // _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
            },
            this.api.get);
    }

    addButton = () => {
        let findOpenStatus = this.props.data[this.status_id].data.map(x => x.split("\f"))
            .map(y => { return { code: y[0], name: y[1], sOpen: y[4] == '1' } })
            .find(f => f.sOpen)
        console.log("findOpenStatus", findOpenStatus)
        this.props.dataActions.genericDataStartInlineCreate(this.id)
        this.props.dataActions.genericDataSetEdit(this.id, {
            cOpenDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            cOpenUserID: this.props.user.userId,
            cOpenUserName: this.props.user.displayName,
            cStatusID: findOpenStatus?.code,
            cStatusName: findOpenStatus?.name,
        })
    }


    render() {
        const gt = this.getText;
        const colSpan = n => window.innerWidth > 600 ? n : 24

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        return (<div style={divStyle}>
            <PageToolbar
                title={gt(20332)}
                showsearch={this.showsearch}
                actionButtons={[
                    { buttonType: 'refresh', buttonFunction: this.refreshData },
                    { buttonType: 'add', buttonFunction: this.addButton },
                    { buttonType: 'filter', buttonFunction: () => { this.setState({ modalFilter: true }) } }
                ]}
            />
            <ServiceCallsTable {...this.props} />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(20346)}
                footer={[
                    <Button onClick={() => { this.setState({ modalFilter: false }) }}>{this.getText(20356)}</Button>,
                    <Button onClick={() => { this.setState({ ...this.defaultFilters }) }}>{this.getText(20357)}</Button>,
                    <Button type="primary" onClick={this.onFilter}>{this.getText(20358)}</Button>,
                ]}>
                <Row>
                    <Col span={colSpan(8)}>
                        <Form.Item label={gt(20347)}>{this.filterField('_custId')}</Form.Item>
                        <Form.Item label={gt(20348)}>{this.filterFromToDate('_fromDateOpen', '_toDateOpen')}</Form.Item>
                        <Form.Item label={gt(20361)}>{this.filterField('_isClosed')}</Form.Item>
                        {this.state._isClosed == '0' ? "" : <Form.Item label={gt(20349)}>{this.filterFromToDate('_fromDateClose', '_toDateClose')}</Form.Item>}
                    </Col>
                    <Col span={colSpan(8)}>
                        <Form.Item label={gt(20350)}>{this.filterSelector('_subjectList', 0)}</Form.Item>
                        <Form.Item label={gt(20351)}>{this.filterSelector('_subjectDetailList', 1)}</Form.Item>
                        <Form.Item label={gt(20352)}>{this.filterField('_itemCode')}</Form.Item>
                        <Form.Item label={gt(20353)}>{this.filterField('_sn')}</Form.Item>
                    </Col>
                    <Col span={colSpan(8)}>
                        <Form.Item label={gt(20354)}>{this.filterSelector('_statusList', -1, {
                            dataset: this.props.data[this.status_id].data.map(x => x.split("\f")).map(y => { return { code: y[0], name: y[1], color: y[3] } }),
                            backgroundSet: true
                        })}</Form.Item>
                        <Form.Item label={gt(20355)}>{this.filterSelector('_tagList', 2)}</Form.Item>
                    </Col>
                </Row>
            </GenericModal>

        </div>)
    }

}

export default ServiceCalls;