/* @flow */
/*jshint esversion: 6 */
import { Button, Icon } from "antd";
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import ExcludedItems from "./ExcludedItems";


class ExcludedItemsForAutoIphone extends Component {
    state = {
        modalOpen: false
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    render() {
        return (<div style={{ textAlign: "center", marginBottom: 24, display: 'inline-block', verticalAlign: 'text-top' }}>
            <Button onClick={() => { this.setState({ modalOpen: true }) }}>
                <Icon type={"exclamation-circle"} style={{ marginLeft: 5 }} />
                {this.getText(20785)}
            </Button>
            {this.state.modalOpen ? (<ExcludedItems {...this.props} isModalAutoIphone onCancel={() => { this.setState({ modalOpen: false }) }} />) : ""}
        </div>)
    }
}

export default ExcludedItemsForAutoIphone;