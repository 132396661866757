/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import { Card, Checkbox, Col, Form, Radio } from "antd";
import PageToolbar from "../../components/PageToolbar";
import GenericFromToDatePicker from "../../components/GenericFromToDatePicker";
import SelectorItemsMulti from "../../components/SelectorItemsMulti";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustomers from "../../components/GenericSelectorCustomers";
import { Row } from "antd/es/grid";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelector from "../../components/GenericSelector";
import TableIDs from "../../data/TableIDs";
import EventDatePicker from "../../components/EventDatePicker";


class ItemAnalysis extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            fromDate: null,
            toDate: null,
            itemList: "",
            itemTagList: "",
            custList: "",
            secondaryCustomers: "0",
            supplierList: "",
            branchList: "",
            costFromDoc: '0',
            priceListId: null,
            groupType: '1',
            subGroup: '0',
            sumOnly: '0',
            hideZeroSales: '1',
            reportName: "reports/ItemAnalysis.pdf"
        };

        this.selectors = [
            { id: TableIDs.genericSelectorPriceList, api: "get_prices" },
            { id: TableIDs.genericSelectorBranches, api: "get_branch_list" },
        ];

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20170);
    }

    createCheckbox = (text, field, groupType) => {
        return (<Checkbox
            style={{ marginRight: 40 }}
            checked={this.state.groupType == groupType && this.state[field] == '1'}
            onChange={e => { this.setState({ [field]: e.target.checked ? '1' : '0' }) }}
            disabled={this.state.groupType != groupType}
        >{text}</Checkbox>)
    }

    render() {
        const gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        const mobile = window.innerWidth > 600 ? false : true;

        return (<div style={divStyle}>
            <Card title={(<PageToolbar title={gt(20171)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: '95%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 8} style={{ padding: 5 }}>
                        <Row>
                            <Col span={4}>
                                <Form.Item>
                                    <EventDatePicker {...this.props}
                                        onSelectDate={(from, to) => {
                                            this.setState({ fromDate: from, toDate: to })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <GenericFromToDatePicker
                                    format={"DD/MM/YYYY"}
                                    fromDate={{ label: gt(20172), value: this.state.fromDate, onChange: e => { this.setState({ fromDate: e }) } }}
                                    toDate={{ label: gt(20173), value: this.state.toDate, onChange: e => { this.setState({ toDate: e }) } }}
                                    inline
                                />
                            </Col>
                        </Row>
                        <Form.Item label={gt(20174)}>
                            <SelectorItemsMulti
                                {...this.props}
                                fieldName={"itemList"}
                                onChange={(value) => { this.setState({ itemList: value?.map(x => x.mCode).join(',') }) }} />
                        </Form.Item>

                        <Form.Item label={gt(20175)}>
                            <GenericSelectorItemTags
                                {...this.props} multi
                                value={this.state.itemTagList ? this.state.itemTagList.split(",") : []}
                                onChange={(value) => { this.setState({ itemTagList: value?.join(',') }) }} />

                        </Form.Item>


                        <Form.Item label={gt(20176)}>
                            <GenericSelectorCustomers
                                {...this.props} multi
                                value={this.state.custList ? this.state.custList.split(",") : []}
                                onChange={(value) => { this.setState({ custList: value?.join(',') }) }}
                                isGroupFromConnected={this.state.secondaryCustomers}
                            />
                            <Radio.Group
                                value={this.state.secondaryCustomers}
                                onChange={e => { this.setState({ secondaryCustomers: e.target.value }) }}
                            >
                                <Radio value={'0'}>{gt(19084)}</Radio><br />
                                <Radio value={'1'}>{gt(19085)}</Radio><br />
                                <Radio value={'2'}>{gt(19086)}</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Col>

                    <Col span={mobile ? 24 : 8} style={{ padding: 5 }}>

                        <Form.Item label={gt(20178)}>
                            <GenericSelectorSuppliers
                                {...this.props} multi
                                value={this.state.supplierList ? this.state.supplierList.split(",") : []}
                                onChange={(value) => { this.setState({ supplierList: value?.join(',') }) }}
                            />
                        </Form.Item>

                        <Form.Item label={gt(20192)}>
                            <GenericSelector
                                {...this.props} multi {...this.selectors[1]}
                                value={this.state.branchList ? this.state.branchList.split(",") : []}
                                onChange={(value) => { this.setState({ branchList: value?.join(',') }) }}
                            />
                        </Form.Item>

                        <Form.Item label={gt(20200)}>
                            <Radio.Group
                                value={this.state.costFromDoc}
                                onChange={e => { this.setState({ costFromDoc: e.target.value }) }}
                            >
                                <Radio value={'0'}>{gt(20201)}</Radio><br />
                                <Radio value={'1'}>{gt(20202)}</Radio><br />
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label={gt(20191)}>
                            <GenericSelector
                                {...this.props} {...this.selectors[0]}
                                value={this.state.priceListId}
                                onChange={(value) => { this.setState({ priceListId: value }) }}
                            />
                        </Form.Item>




                    </Col>

                    <Col span={mobile ? 24 : 8} style={{ padding: 5 }}>

                        <Form.Item label={gt(20193)}>
                            <Radio.Group
                                value={this.state.groupType}
                                onChange={e => { this.setState({ groupType: e.target.value, subGroup: '0', sumOnly: '0' }) }}
                            >
                                <Radio value={'1'}>{gt(20194)}</Radio><br />
                                {this.createCheckbox(gt(20198), 'subGroup', '1')}<br />
                                {this.createCheckbox(gt(20199), 'sumOnly', '1')}<br />
                                <Radio value={'2'}>{gt(20195)}</Radio><br />
                                {this.createCheckbox(gt(20197), 'subGroup', '2')}<br />
                                {this.createCheckbox(gt(20199), 'sumOnly', '2')}<br />
                                <Radio value={'3'}>{gt(20196)}</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item>
                            <Checkbox
                                checked={this.state.hideZeroSales == '1'}
                                onChange={e => { this.setState({ hideZeroSales: e.target.checked ? '1' : '0' }) }}
                            >{gt(20203)}</Checkbox>
                        </Form.Item>

                        <ReportTypeButton
                            {...this.props}
                            params={this.state}
                            datesToFavorites={[
                                { field: 'fromDate', label: gt(20172) },
                                { field: 'toDate', label: gt(20173) },
                            ]}
                            branchesField={'branchList'}
                            favoriteSave
                        />
                    </Col>
                </Row>
            </Card>
        </div>)
    }

}

export default ItemAnalysis;