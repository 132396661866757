/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../../contextProvider/LangContext";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import { Form, DatePicker, Button, Row, Col, Tooltip, Icon, Popover, Radio, Select, Checkbox, Input } from "antd";
import moment from "moment";
import TableIDs from "../../data/TableIDs";
import { escapeRegExp, disabledDatePicker } from "../../utils/utils";
import ReportFavoriteSave from "../../components/ReportFavoriteSave";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import DateTimePicker from "../../components/DateTimePicker";

const { Option } = Select;
const { MonthPicker } = DatePicker;

const topHeight = 380; // px
const rowHeight = 40;  // px

class NewOrdersTable extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            modalPackage: false,
            modalDetails: null,
            modalFilter: false,
            updateStatusModal: null,
            multiReportStyle: "OrderReportCentralNotItems",
            orderType: '0',
            modalDeliveryTracking: false,
            deliveryTrackingReportName: "reports/DeliveryTrackingOrder.pdf",
            deliveryTrackingReportDetailes: 0,
            withLevelNames: '0',
            viewRemarks: '0',

            filterActive: false,
            valueSearch: '',

            modalAreasDeliveryCost: false,
            modalAreasDeliveryCostParams: {
                _fromYear: null,
                _toYear: null,
                _fromMonth: null,
                _toMonth: null,
                _posList: "",
                _employeeList: "",
                _statusList: "",
                _methodList: "1",
            },

            modalPrint: null,

            modalLinesLog: null,
        }

        this.doOnResize = null;

        this.id = "EXTERNAL_ORDERS_TABLE";
        this.group_id = "EXTERNAL_ORDERS_GROUP";
        this.lines_id = "EXTERNAL_ORDER_LINES";
        this.lines_log_id = "EXTERNAL_ORDER_LINES_LOG";

        this.selectors = [
            { id: TableIDs.genericSelectorExternalOrderSuppliers, api: 'external_orders_supplier_logo_list' },
            { id: TableIDs.genericSelectorBranches, api: 'get_branch_list' },
            { id: TableIDs.genericSelectorOrderStatuses, api: 'external_orders_statuses_list' },
            { id: 'EMPLOYEE_DELIVERY_LIST', api: 'employee_delivery_list' },
            { id: TableIDs.genericSelectorDeliveryMethods, api: 'delivery_methods_list' },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.group_id] === undefined) props.data[this.group_id] = { ...props.data.genericData };
        if (props.data[this.lines_id] === undefined) props.data[this.lines_id] = { ...props.data.genericData };
        if (props.data[this.lines_log_id] === undefined) props.data[this.lines_log_id] = { ...props.data.genericData };

        this.selectors.forEach((x) => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

        this.api = {
            get: "get_external_orders_table_bo",
            get_lines: "get_external_order_lines",
            update_status: "update_status_and_branch_external_orders",

            get_lines_log: "get_external_order_lines_log_bo",
        }

        this.favoriteReportDeliveryTracking = false;
        this.favoriteReportAreasDeliveryCost = false;
        this.favoriteReportOrders = false;
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);

    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, () => {
            this.setFilter(this.props.data[this.id].filters);
        });
    };

    initFilters = () => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        let curdate = moment(Date.now()).format("YYYY-MM-DD");

        let request = {
            // ui
            page: 1,
            recordsPerPage: numOfRowsByHeight,
            sortType: 1,
            sortColumn: 1,
            // api
            _FromDate: null,
            _ToDate: null,
            _FromDateReq: curdate,
            _ToDateReq: curdate,
            _SupplierList: '',
            _BranchList: '',
            _MethodList: '',
            _StatusList: '1,3,4,5,7,8,9',
            _EmployeeList: '',
            _page_num: 1,
            _rows_num: numOfRowsByHeight,
            _packageId: null,
            _words: '',
            _isAsc: 1,
            _sort_by: 'col_0',
        };

        if (this.props.filters) {
            request = { ...request, ...this.props.filters }
        }
        console.log('initFilters', numOfRowsByHeight)
        this.setFilter(request);




    }

    checkFilter = () => {
        this.setFilter({
            ...this.props.data[this.id].filters,
            ...this.state.modalFilter,
            _page_num: 1,
            page: 1
        });
        this.closeFilter();
    }

    closeFilter = () => {
        this.setState({ modalFilter: false })
    }

    clearFilter = () => {
        this.setState({
            modalFilter: this.state.modalFilter ? {
                ...this.state.modalFilter,
                _FromDate: null,
                _ToDate: null,
                _FromDateReq: null,
                _ToDateReq: null,
                _SupplierList: null,
                _BranchList: null,
                _MethodList: null,
                _StatusList: null,
                _EmployeeList: null,
            } : false,
            filterActive: false,
            valueSearch: ""
        }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, {
                _FromDate: null,
                _ToDate: null,
                _FromDateReq: null,
                _ToDateReq: null,
                _SupplierList: null,
                _BranchList: null,
                _MethodList: null,
                _StatusList: null,
                _EmployeeList: null,
                _words: '',
            }, this.api.get);
        })
    }

    setFilter = (request) => {
        let numOfRowsByHeight = parseInt((this.state.height - topHeight > rowHeight)
            ? (this.state.height - topHeight) / rowHeight
            : 1);

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { ...request, _rows_num: numOfRowsByHeight, recordsPerPage: numOfRowsByHeight }, this.api.get);
        if (this.props.onFilter) this.props.onFilter(request);

        this.setState({ filterActive: true })
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14478);

        if (this.props.ui.favoriteParams && this.props.favoriteReportDeliveryTracking && !this.favoriteReportDeliveryTracking) {
            const { reportName, isDetailed } = this.props.ui.favoriteParams

            this.favoriteReportDeliveryTracking = true;
            this.setState({
                modalDeliveryTracking: true,
                deliveryTrackingReportName: reportName,
                deliveryTrackingReportDetailes: isDetailed,
            })
        }

        if (this.props.ui.favoriteParams && this.props.favoriteReportAreasDeliveryCost && !this.favoriteReportAreasDeliveryCost) {

            this.favoriteReportAreasDeliveryCost = true;
            this.setState({ modalAreasDeliveryCost: true })
        }

        if (this.props.ui.favoriteParams && this.props.favoriteReportOrders && !this.favoriteReportOrders) {

            this.favoriteReportOrders = true;
            const { _FromDate, _ToDate, _FromDateReq, _ToDateReq, _SupplierList, _BranchList, _MethodList, _StatusList, _EmployeeList, withLevelNames, viewRemarks, orderType, reportName } = this.props.ui.favoriteParams
            this.setState({
                modalPrint: { _FromDate, _ToDate, _FromDateReq, _ToDateReq, _SupplierList, _BranchList, _MethodList, _StatusList, _EmployeeList },
                withLevelNames, viewRemarks, orderType,
                multiReportStyle: reportName?.split("/")[1]?.split('.')[0]
            })
        }
    }

    getModalTable = (name, title, columns, id) => {
        return (<GenericModal visible={this.state[name]} onCancel={() => { this.setState({ [name]: false }) }} title={title} >
            <ResponsiveTable
                tableOnly
                columns={columns}
                percentageToMobile={[20, 40, 40]}
                idTable={this.props.data[id]}
                scroll={{ y: window.innerHeight - topHeight }}
                pagination={false} />
        </GenericModal>)
    }

    loadGroupData = (num) => {
        this.setState({ modalPackage: true }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.group_id, this.props.user.companyCode, this.props.user.token, {
                ...this.props.data[this.id].filters, _packageId: num
            }, this.api.get);
        })
    }

    loadDetailsData = (id, record) => {
        this.setState({ modalDetails: record, modalPackage: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.lines_id, this.props.user.companyCode, this.props.user.token, { oID: id }, this.api.get_lines);

        })
    }


    sumFormat = (n) => {
        return n ? (Math.round(parseFloat(n) * 100) / 100).toFixed(2) : '0.00'
    }

    itemDetails = (text, record) => {
        //iGroup, iCode, iName, iPrice, iFoodDishId, iLevelJson, iOriginalPrice, iFatherCode, iColorID, iColorDesc, iSize1ID, iSize2ID, iSizeDesc, iQty, iMivza, iTotalSum   
        // if (record.iFoodDishId && record.iLevelJson) {
        if (record.iLevelJson && record.iLevelJson.indexOf('{') > -1) {
            let dataObj = JSON.parse(record.iLevelJson);
            return dataObj.levels.map((l) => {
                return (<div className={'text_item'} style={{ marginTop: 5, padding: 5 }}>
                    <div style={{ fontSize: 17, fontWeight: 600 }}>{l.name}</div>
                    <div style={{ fontSize: 15 }}>
                        {l.ownerData.map((o) => {
                            return (<div>
                                <div>{o.oItemName}</div>
                                <div>{o.oExtraItems ? this.sortExtraItems(o.oExtraItems) : ""}</div>
                            </div>)
                        })}
                    </div>
                </div>)
            })
        } else if (record.iSizeDesc || record.iColorDesc) {
            let sDiv = record.iSizeDesc ? (<div>{this.getText(14500)}: {record.iSizeDesc}</div>) : ""
            let cDiv = record.iColorDesc ? (<div>{this.getText(14501)}: {record.iColorDesc}</div>) : ""
            return (<div className={'text_item'} style={{ fontSize: 20, lineHeight: 1.2, minHeight: 40 }}>{sDiv}{cDiv}</div>)
        } else {
            return ''
        }
    }

    sortExtraItems = (e) => {
        let eType2 = e.filter(f => f.eType == 2);
        let eType3 = e.filter(f => f.eType == 3);
        let eType4 = e.filter(f => f.eType == 4);

        return (<div>
            {eType4[0] ? (<div>
                <u>{this.getText(14502)}:</u>
                {eType4.map((et, ii) => (<span>{' ' + et.eItemName}{(ii === eType4.length - 1) ? "." : ","}</span>))}
            </div>) : ""}
            {eType2[0] ? (<div>
                <u>{this.getText(14503)}:</u>
                {eType2.map((et, ii) => (<span>{' ' + et.eItemName}{(ii === eType2.length - 1) ? "." : ","}</span>))}
            </div>) : ""}
            {eType3[0] ? (<div>
                <u>{this.getText(14504)}:</u>
                {eType3.map((et, ii) => (<span>{' ' + et.eItemName}{(ii === eType3.length - 1) ? "." : ","}</span>))}
            </div>) : ""}
        </div>)
    }

    textSearch = (val) => {
        if (val) { this.setState({ filterActive: true }) }
        this.setFilter({
            ...this.props.data[this.id].filters,
            _words: escapeRegExp(val),
            //  _words: val.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'"),
            page: 1,
            _page_num: 1,
        })

    }

    generateReport = (params) => {
        if (params && params.reportName === 'reports/OrderReportCentral.xls') {
            params = { ...params, reportName: 'reports/OrderReportCentralExcel.xls' }
        }

        if (params._words) params = { ...params, _words: encodeURIComponent(params._words) }

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.generateReport,
            args: [this.props.user.companyCode, this.props.user.token, { ...params, REPORT_LOCALE: this.getText(101) }]
        });
    }

    getGenericActionButtons = (arr) => {
        return arr.map((x) => {
            return (<Tooltip title={this.getText(x.tooltip)}>
                <Button onClick={x.onClick} style={{ marginLeft: 5 }}><Icon type={x.icon} style={{ fontSize: 16 }} /></Button>
            </Tooltip>)
        })
    }

    openUpdateStatus = (record) => {
        this.setState({
            updateStatusModal: {
                eStatus: record.rStatus,
                eBranch: record.rBranchId,
                eOwnerId: record.key,
                eCity: record.rCity,
                eStreet: record.rStreet,
                eBuilding: record.rBuilding,
                eApartement: record.rApartement,
                eEntrance: record.rEntrance,
                eFloor: record.rFloor,
                ePhone: record.rCustomerPhone,
                eRemarks: record.rRemarks,
                eDateTime: record.rRequieredDateTime
            }
        })
    }

    closeUpdateStatus = () => {
        this.setState({ updateStatusModal: null, modalPackage: false })
    }

    updateStatus = () => {
        let params = { ...this.props.data[this.id].filters, ...this.state.updateStatusModal }
        this.props.dataActions.genericDataSaveEditWithParams(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.update_status, this.api.get, params);
        setTimeout(() => {
            this.props.dataActions.genericDataSetFilter(
                this.group_id, this.props.user.companyCode, this.props.user.token, params, this.api.get);
        }, 1000);

        this.closeUpdateStatus();
    }

    setYearDates = (type) => {
        const p = this.state.modalAreasDeliveryCostParams;

        let curMonth = moment().format("MM");
        let curYear = moment().format("YYYY");
        let prevMonth = moment().add(-1, 'month').format("MM");
        let prevYear = moment().add(-1, 'year').format("YYYY");

        switch (type) {
            case 1: this.setState({ modalAreasDeliveryCostParams: { ...p, _fromMonth: curMonth, _fromYear: prevYear, _toMonth: prevMonth, _toYear: curYear } }); break;
            case 2: this.setState({ modalAreasDeliveryCostParams: { ...p, _fromMonth: '01', _fromYear: curYear, _toMonth: '12', _toYear: curYear } }); break;
            case 3: this.setState({ modalAreasDeliveryCostParams: { ...p, _fromMonth: '01', _fromYear: prevYear, _toMonth: '12', _toYear: prevYear } }); break;
        }
    }

    getFieldEdit = (span, label, field, selector) => {
        const er = this.state.updateStatusModal ?? {}
        return (<Col span={window.innerWidth > 600 ? span : 24} style={{ padding: 4 }}>
            <Form.Item label={this.getText(label)}>
                {field == 'eDateTime' ?
                    <DateTimePicker format={"DD/MM/YYYY HH:mm"} showTime value={er[field]} onChange={e => { this.setState({ updateStatusModal: { ...er, [field]: e } }) }} />
                    : selector ?
                        <GenericSelector value={er[field]} onChange={e => { this.setState({ updateStatusModal: { ...er, [field]: e } }) }} {...this.selectors[selector]} {...this.props} />
                        :
                        <Input value={er[field]} onChange={e => { this.setState({ updateStatusModal: { ...er, [field]: e.target.value } }) }} />}
            </Form.Item>
        </Col>)
    }

    linesLogOpen = (t, record) => {
        return (<TableLineIcons
            genericIcons={[{
                type: "table",
                onClick: () => {
                    this.setState({ modalLinesLog: record }, () => {
                        this.props.dataActions.genericDataSetFilter(
                            this.lines_log_id, this.props.user.companyCode, this.props.user.token, { _id: record.iId }, this.api.get_lines_log);
                    })
                },
                tooltip: this.getText(20656),
            }]}
        />)
    }

    render() {

        let dStyle = {
            padding: 4,
            margin: 'auto',
            marginTop: 10,
            width: '100%',
        }

        let colTxt = (t) => t
        let colDatetime = (t) => moment(t, 'YYYY-MM-DD HH:mm:ss').format("HH:mm DD/MM/YYYY")
        let origin = window.location.origin;
        const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
        let baseUrl = isDev ? "http://147.235.163.248" : origin;
        let supplierLogo = (t) => (<img src={baseUrl + '/uploads/shared/' + t} height="40" />)
        let colDetails = (t, r) => {
            let p = r.rIsPackage == 1 ? true : false;
            let icons = p ?
                [{ type: 'database', tooltip: this.getText(14491), onClick: () => { this.loadGroupData(r.key) } }] :
                [
                    { type: 'edit', tooltip: this.getText(15442), disabled: r.rStatus == 99, onClick: () => { this.openUpdateStatus(r) } },
                    { type: 'eye', tooltip: this.getText(14492), onClick: () => { this.loadDetailsData(r.key, r) } },
                    // { type: 'printer', tooltip: this.getText(15380), onClick: () => { this.generateReport({ orderID: r.key, reportName: "reports/OrderReport.pdf" }) } },
                    { type: 'printer', tooltip: this.getText(15380), showReport: true, params: { orderID: r.key, reportName: "reports/OrderReport.pdf" }, },
                ]
            return (<TableLineIcons {...this.props} genericIcons={icons} />)
        }

        let columns = [
            { title: this.getText(14480), key: 'rSupplierLogo', dataIndex: 'rSupplierLogo', width: '5%', render: supplierLogo },
            { title: this.getText(14481), key: 'rNum', dataIndex: 'rNum', width: '7%', render: colTxt },
            { title: this.getText(14482), key: 'rPosName', dataIndex: 'rPosName', width: '9%', render: colTxt },
            { title: this.getText(14483), key: 'rDateTime', dataIndex: 'rDateTime', width: '9%', render: colDatetime },
            { title: this.getText(14484), key: 'rRequieredDateTime', dataIndex: 'rRequieredDateTime', width: '9%', render: colDatetime },
            { title: this.getText(14485), key: 'rMethodName', dataIndex: 'rMethodName', width: '7%', render: colTxt },
            { title: this.getText(19366), key: 'rEmployeeName', dataIndex: 'rEmployeeName', width: '7%', render: colTxt },
            { title: this.getText(14486), key: 'rCustomerName', dataIndex: 'rCustomerName', width: '9%', render: colTxt },
            { title: this.getText(19976), key: 'rCustomerPhone', dataIndex: 'rCustomerPhone', width: '9%', render: colTxt },
            { title: this.getText(14487), key: 'rStatusName', dataIndex: 'rStatusName', width: '8%', render: colTxt },
            { title: this.getText(14488), key: 'rRemaining', dataIndex: 'rRemaining', width: '8%', render: this.sumFormat },
            { title: this.getText(14489), key: 'rTotal', dataIndex: 'rTotal', width: '8%', render: this.sumFormat },
            { title: this.getText(14490), width: '5%', render: colDetails },
        ];

        let detailsColumns = [
            { title: this.getText(14493), key: 'iCode', dataIndex: 'iCode', width: '10%', render: colTxt },
            { title: this.getText(14494), key: 'iName', dataIndex: 'iName', width: '20%', render: colTxt },
            { title: this.getText(14495), width: '30%', render: this.itemDetails },
            { title: this.getText(14496), key: 'iPrice', dataIndex: 'iPrice', width: '8%', render: this.sumFormat },
            { title: this.getText(14497), key: 'iQty', dataIndex: 'iQty', width: '8%', render: this.sumFormat },
            { title: this.getText(14498), key: 'iMivza', dataIndex: 'iMivza', width: '8%', render: this.sumFormat },
            { title: this.getText(14499), key: 'iTotalSum', dataIndex: 'iTotalSum', width: '8%', render: this.sumFormat },
            { title: this.getText(20655), width: '8%', render: this.linesLogOpen },
        ];

        let actionButtons = [{
            buttonType: 'filter',
            buttonFunction: () => { this.setState({ modalFilter: this.props.data[this.id].filters }) }
        }, {
            buttonDisabled: !this.state.filterActive,
            buttonType: 'clear',
            buttonFunction: this.clearFilter,
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        let mf = this.state.modalFilter;
        console.log("modal_filter", mf)
        let mp = this.state.modalPrint;
        let onChange = (f, v) => { this.setState({ modalFilter: { ...mf, [f]: v } }) }
        let onChangePrint = (f, v) => { this.setState({ modalPrint: { ...mp, [f]: v } }) }
        let formItem = (label, content) => (<Form.Item label={this.getText(label)}>{content}</Form.Item>);
        let datePicker = (field, print) => {
            let md = print && mp ? mp : mf ?? {};
            let oc = print ? onChangePrint : onChange
            let minDate = field == '_ToDate' ? md._FromDate : field == '_ToDateReq' ? md._FromDateReq : null
            let maxDate = field == '_FromDate' ? md._ToDate : field == '_FromDateReq' ? md._ToDateReq : null
            return (<DatePicker
                format="DD/MM/YYYY"
                value={md[field] ? moment(md[field], "YYYY-MM-DD") : null}
                onChange={(v) => { oc(field, v ? v.format("YYYY-MM-DD") : null) }}
                disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
            />)
        }

        let genericSelector = (field, index, print) => {
            let s = this.selectors[index];
            let md = print && mp ? mp : mf ?? {};
            let oc = print ? onChangePrint : onChange
            return (<GenericSelector
                id={s.id} api={s.api} multi
                value={md[field] ? md[field].split(',') : []}
                onChange={(v) => { oc(field, v ? v.join(',') : '') }}
                data={this.props.data} dataActions={this.props.dataActions} user={this.props.user}
            />)
        }

        const { _FromDate, _ToDate, _FromDateReq, _ToDateReq, _SupplierList, _BranchList, _MethodList, _StatusList, _EmployeeList } = this.props.data[this.id].filters;

        let genericActionButtons = [
            <Tooltip title={this.getText(14515)}>
                <Button onClick={this.props.backPage} style={{ marginLeft: 5 }}><Icon type={'dashboard'} style={{ fontSize: 16 }} /></Button>
            </Tooltip>,
            <Tooltip title={this.getText(15381)}>
                {/* <Popover content={contentOrdersMultiReport} title={this.getText(15381)} trigger="click"> */}
                <Button style={{ marginLeft: 5 }} onClick={() => { this.setState({ modalPrint: { _FromDate, _ToDate, _FromDateReq, _ToDateReq, _SupplierList, _BranchList, _MethodList, _StatusList, _EmployeeList } }) }} ><Icon type={'printer'} style={{ fontSize: 16 }} /></Button>
                {/* </Popover> */}
            </Tooltip>,
            <Tooltip title={this.getText(16382)}>
                <Button onClick={() => { this.setState({ modalDeliveryTracking: true }) }} style={{ marginLeft: 5 }}>
                    <Icon type={'control'} style={{ fontSize: 16 }} />
                </Button>
            </Tooltip>,
            <Tooltip title={this.getText(19395)}>
                <Button onClick={() => { this.setState({ modalAreasDeliveryCost: true }) }} style={{ marginLeft: 5 }}>
                    <Icon type={'team'} style={{ fontSize: 16 }} />
                </Button>
            </Tooltip>]

        const { _fromYear, _toYear, _fromMonth, _toMonth } = this.state.modalAreasDeliveryCostParams;

        let modalDetailsTitle = (<span>
            {this.getText(14492)} {this.state.modalDetails?.rNum} {supplierLogo(this.state.modalDetails?.rSupplierLogo)} {this.state.modalDetails?.rCustomerName} {this.state.modalDetails?.rCustomerPhone}
        </span>)


        return (<div style={dStyle} >
            {this.props.isModalAutoIphone ?
                (<GenericModal
                    title={this.getText(14479)}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}
                    visible
                    onCancel={this.props.onCancel}
                    showsearch={this.textSearch}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}

                >
                    <ResponsiveTable
                        tableOnly
                        idTable={this.props.data[this.id]}
                        columns={columns}
                        percentageToMobile={[20, 40, 40]}
                        handleTableChange={this.setFilter}
                        sorters={true}
                        ui={this.props.ui}
                        uiActions={this.props.uiActions}
                    />
                </GenericModal>)
                : (<ResponsiveTable
                    idTable={this.props.data[this.id]}
                    columns={columns}
                    showsearch={this.textSearch}
                    valueSearch={this.state.valueSearch}
                    onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}

                    percentageToMobile={[20, 40, 40]}
                    title={this.getText(14479)}
                    actionButtons={actionButtons}
                    genericActionButtons={genericActionButtons}

                    handleTableChange={this.setFilter}
                    sorters={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                />)}



            {this.getModalTable('modalPackage', this.getText(14491), columns, this.group_id)}
            {this.getModalTable('modalDetails', modalDetailsTitle, detailsColumns, this.lines_id)}
            <GenericModal visible={mf} onCancel={this.closeFilter} title={this.getText(14505)}
                footer={[
                    <Button key="back" onClick={this.closeFilter}>{this.getText(14506)}</Button>,
                    <Button key="clear" onClick={this.clearFilter}>{this.getText(14507)}</Button>,
                    <Button key="create" type="primary" onClick={this.checkFilter}>{this.getText(14508)}</Button>,
                    <ReportFavoriteSave
                        {...this.props}
                        btnText={this.getText(19267)}
                        isNotReport
                        params={mf}
                        datesToFavorites={[
                            { label: this.getText(14509), field: '_FromDate' },
                            { label: this.getText(14510), field: '_ToDate' },
                            { label: this.getText(15378), field: '_FromDateReq' },
                            { label: this.getText(15379), field: '_ToDateReq' },
                        ]}
                    />
                ]}>
                <Row>
                    <Col span={window.innerWidth > 1000 ? 5 : 24} style={{ padding: 5 }}>
                        {formItem(14509, datePicker('_FromDate'))}
                        {formItem(14510, datePicker('_ToDate'))}
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 5 : 24} style={{ padding: 5 }}>
                        {formItem(15378, datePicker('_FromDateReq'))}
                        {formItem(15379, datePicker('_ToDateReq'))}
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 7 : 24} style={{ padding: 5 }}>
                        {formItem(14511, genericSelector('_SupplierList', 0))}
                        {formItem(14512, genericSelector('_BranchList', 1))}
                        {formItem(19885, genericSelector('_MethodList', 4))}
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 7 : 24} style={{ padding: 5 }}>
                        {formItem(14513, genericSelector('_StatusList', 2))}
                        {formItem(19365, genericSelector('_EmployeeList', 3))}
                    </Col>
                </Row>
            </GenericModal>

            <GenericModal
                visible={this.state.modalPrint != null}
                onCancel={() => { this.setState({ modalPrint: null }) }}
                title={this.getText(19836)}>
                <Row>
                    <Col span={window.innerWidth > 1000 ? 8 : 24} style={{ padding: 5 }}>
                        {formItem(14509, datePicker('_FromDate', 1))}
                        {formItem(14510, datePicker('_ToDate', 1))}
                        {formItem(15378, datePicker('_FromDateReq', 1))}
                        {formItem(15379, datePicker('_ToDateReq', 1))}
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 8 : 24} style={{ padding: 5 }}>
                        {formItem(14511, genericSelector('_SupplierList', 0, 1))}
                        {formItem(14512, genericSelector('_BranchList', 1, 1))}
                        {formItem(19885, genericSelector('_MethodList', 4, 1))}
                        {formItem(14513, genericSelector('_StatusList', 2, 1))}
                        {formItem(19365, genericSelector('_EmployeeList', 3, 1))}
                    </Col>
                    <Col span={window.innerWidth > 1000 ? 8 : 24} style={{ padding: 5 }}>
                        {formItem(19837, <Radio.Group value={this.state.multiReportStyle} onChange={(e) => { this.setState({ multiReportStyle: e.target.value }) }}>
                            <Radio value={"OrderReportCentralNotItems"}>{this.getText(19834)}</Radio><br />
                            <Radio value={"OrdersMultiReport"}>{this.getText(15505)}</Radio><br />
                            <Radio value={"OrderReportCentral"}>{this.getText(15506)}</Radio><br />

                            <Radio value={"Report80OrderCentral1"}>{this.getText(20388)}</Radio><br />
                            <Radio value={"Report80OrderCentral2"}>{this.getText(20389)}</Radio><br />
                            <Radio value={"Report80OrderCentral3"}>{this.getText(20390)}</Radio><br />
                            <Radio value={"Report80OrderCentral4"}>{this.getText(20391)}</Radio><br />

                        </Radio.Group>)}
                        <Radio.Group
                            value={this.state.withLevelNames}
                            onChange={(e) => { this.setState({ withLevelNames: e.target.value }) }}
                            style={{ display: this.state.multiReportStyle == "OrdersMultiReport" || this.state.multiReportStyle == "OrderReportCentral" ? "block" : "none" }}>
                            <Radio value={"0"}>{this.getText(16923)}</Radio>
                            <Radio value={"1"}>{this.getText(16924)}</Radio>
                        </Radio.Group>
                        <br />

                        <Checkbox
                            checked={this.state.viewRemarks == '1'}
                            onChange={(e) => { this.setState({ viewRemarks: e.target.checked ? "1" : "0" }) }}
                            style={{ display: this.state.multiReportStyle == "OrderReportCentralNotItems" ? "block" : "none" }}>
                            {this.getText(19852)}
                        </Checkbox>
                        <br />

                        {formItem(20392, <Radio.Group value={this.state.orderType} onChange={(e) => { this.setState({ orderType: e.target.value }) }}>
                            <Radio value={"0"}>{this.getText(20393)}</Radio><br />
                            <Radio value={"1"}>{this.getText(20394)}</Radio><br />

                        </Radio.Group>)}

                        <ReportTypeButton
                            {...this.props}
                            params={{
                                ...this.state.modalPrint,
                                withLevelNames: this.state.withLevelNames,
                                viewRemarks: this.state.viewRemarks,
                                PosId: 999,
                                reportName: "reports/" + this.state.multiReportStyle + ".pdf",
                            }}
                            favoriteSave
                            datesToFavorites={[
                                { label: this.getText(14509), field: '_FromDate' },
                                { label: this.getText(14510), field: '_ToDate' },
                                { label: this.getText(15378), field: '_FromDateReq' },
                                { label: this.getText(15379), field: '_ToDateReq' },
                            ]}
                        />
                    </Col>
                </Row>
            </GenericModal>

            <GenericModal
                visible={this.state.modalDeliveryTracking}
                onCancel={() => { this.setState({ modalDeliveryTracking: false }) }}
                title={this.getText(16383)}
                width={400}>
                <Form.Item label={this.getText(16384)}>
                    <Select
                        style={{ width: "100%" }}
                        value={this.state.deliveryTrackingReportName}
                        onChange={e => {
                            this.setState({ deliveryTrackingReportName: e })
                        }}>
                        <Option value={"reports/DeliveryTrackingOrder.pdf"}>{this.getText(16385)}</Option>
                        <Option value={"reports/DeliveryTrackingItem.pdf"}>{this.getText(16386)}</Option>
                        <Option value={"reports/DeliveryTrackingRequieredDate.pdf"}>{this.getText(16387)}</Option>
                        <Option value={"reports/DeliveryTrackingCustomer.pdf"}>{this.getText(16388)}</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={this.state.deliveryTrackingReportDetailes == 1}
                        onChange={e => {
                            this.setState({ deliveryTrackingReportDetailes: e.target.checked ? 1 : 0 })
                        }}>{this.getText(16389)}</Checkbox>
                </Form.Item>
                <ReportTypeButton
                    {...this.props}
                    params={{
                        _FromDate, _ToDate, _FromDateReq, _ToDateReq, _SupplierList, _BranchList, _MethodList, _StatusList, _EmployeeList,
                        reportName: this.state.deliveryTrackingReportName,
                        isDetailed: this.state.deliveryTrackingReportDetailes,
                    }}
                    favoriteSave
                />
            </GenericModal>

            <GenericModal
                visible={this.state.modalAreasDeliveryCost}
                onCancel={() => { this.setState({ modalAreasDeliveryCost: false }) }}
                title={this.getText(19395)}
                width={400}>

                <Row>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Form.Item label={this.getText(19396)}>
                            <MonthPicker
                                format="MM/YYYY"
                                value={_fromYear && _fromMonth ? moment(_fromYear + _fromMonth, "YYYYMM") : null}
                                onChange={e => {
                                    this.setState({
                                        modalAreasDeliveryCostParams: {
                                            ...this.state.modalAreasDeliveryCostParams,
                                            _fromMonth: e ? moment(e).format("MM") : null,
                                            _fromYear: e ? moment(e).format("YYYY") : null
                                        }
                                    })
                                }}
                                disabledDate={(d) => disabledDatePicker(d,
                                    _toYear && _toMonth ? moment(_toYear + _toMonth + "02", "YYYYMMDD").add(-11, 'month').format("YYYY-MM-DD") : null,
                                    _toYear && _toMonth ? _toYear + "-" + _toMonth + "-01" : null)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={window.innerWidth > 600 ? 12 : 24}>
                        <Form.Item label={this.getText(19397)}>
                            <MonthPicker
                                format="MM/YYYY"
                                value={_toYear && _toMonth ? moment(_toYear + _toMonth, "YYYYMM") : null}
                                onChange={e => {
                                    this.setState({
                                        modalAreasDeliveryCostParams: {
                                            ...this.state.modalAreasDeliveryCostParams,
                                            _toMonth: e ? moment(e).format("MM") : null,
                                            _toYear: e ? moment(e).format("YYYY") : null
                                        }
                                    })
                                }}
                                disabledDate={(d) => disabledDatePicker(d,
                                    _fromYear && _fromMonth ? _fromYear + "-" + _fromMonth + "-01" : null,
                                    _fromYear && _fromMonth ? moment(_fromYear + _fromMonth + "02", "YYYYMMDD").add(11, 'month').format("YYYY-MM-DD") : null)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Button style={{ margin: 5 }} onClick={() => { this.setYearDates(1) }}>{this.getText(19398)}</Button>
                <Button style={{ margin: 5 }} onClick={() => { this.setYearDates(2) }}>{this.getText(19399)}</Button>
                <Button style={{ margin: 5 }} onClick={() => { this.setYearDates(3) }}>{this.getText(19400)}</Button>

                <Form.Item label={this.getText(19401)}>
                    <GenericSelectorPosStorages {...this.props} multi
                        value={this.state.modalAreasDeliveryCostParams._posList ? this.state.modalAreasDeliveryCostParams._posList.split(",") : []}
                        onChange={e => {
                            this.setState({
                                modalAreasDeliveryCostParams: {
                                    ...this.state.modalAreasDeliveryCostParams,
                                    _posList: e?.join(",")
                                }
                            })
                        }} />
                </Form.Item>

                <Form.Item label={this.getText(19402)}>
                    <GenericSelector {...this.props} multi {...this.selectors[3]}
                        value={this.state.modalAreasDeliveryCostParams._employeeList ? this.state.modalAreasDeliveryCostParams._employeeList.split(",") : []}
                        onChange={e => {
                            this.setState({
                                modalAreasDeliveryCostParams: {
                                    ...this.state.modalAreasDeliveryCostParams,
                                    _employeeList: e?.join(",")
                                }
                            })
                        }} />
                </Form.Item>

                <Form.Item label={this.getText(19967)}>
                    <GenericSelector {...this.props} multi {...this.selectors[2]}
                        value={this.state.modalAreasDeliveryCostParams._statusList ? this.state.modalAreasDeliveryCostParams._statusList.split(",") : []}
                        onChange={e => {
                            this.setState({
                                modalAreasDeliveryCostParams: {
                                    ...this.state.modalAreasDeliveryCostParams,
                                    _statusList: e?.join(",")
                                }
                            })
                        }} />
                </Form.Item>

                <Form.Item label={this.getText(19968)}>
                    <GenericSelector {...this.props} multi {...this.selectors[4]}
                        value={this.state.modalAreasDeliveryCostParams._methodList ? this.state.modalAreasDeliveryCostParams._methodList.split(",") : []}
                        onChange={e => {
                            this.setState({
                                modalAreasDeliveryCostParams: {
                                    ...this.state.modalAreasDeliveryCostParams,
                                    _methodList: e?.join(",")
                                }
                            })
                        }} />
                </Form.Item>

                <ReportTypeButton
                    {...this.props}
                    params={{
                        ...this.state.modalAreasDeliveryCostParams,
                        reportName: "reports/AreasDeliveryCost.pdf"
                    }}
                    favoriteSave
                    posField={'_posList'}
                    datesToFavorites={[
                        { fields: ["_fromMonth", "_fromYear"], label: this.getText(19396), format: 2 },
                        { fields: ["_toMonth", "_toYear"], label: this.getText(19397), format: 2 },
                    ]}
                    xlsReportName={"reports/AreasDeliveryCostExcel.xls"}
                />
            </GenericModal>
            <GenericModal
                visible={this.state.updateStatusModal !== null}
                onCancel={this.closeUpdateStatus}
                title={this.getText(15443) + " " + (this.state.updateStatusModal ? this.state.updateStatusModal.eOwnerId : '')}
                width={1200}
                footer={[
                    <Button key="backStatus" onClick={this.closeUpdateStatus}>{this.getText(14506)}</Button>,
                    <Button key="updateStatus" type="primary" onClick={this.updateStatus}>{this.getText(16125)}</Button>,
                ]}>
                <Row>
                    {this.getFieldEdit(6, 20031, 'eCity')}
                    {this.getFieldEdit(10, 20032, 'eStreet')}
                    {this.getFieldEdit(2, 20033, 'eBuilding')}
                    {this.getFieldEdit(2, 20034, 'eEntrance')}
                    {this.getFieldEdit(2, 20035, 'eFloor')}
                    {this.getFieldEdit(2, 20036, 'eApartement')}
                    {this.getFieldEdit(6, 20038, 'ePhone')}
                    {this.getFieldEdit(18, 20037, 'eRemarks')}
                    {this.getFieldEdit(6, 20047, 'eDateTime')}
                    {this.getFieldEdit(9, 15444, 'eStatus', 2)}
                    {this.getFieldEdit(9, 19835, 'eBranch', 1)}
                </Row>
                {/* <Form.Item label={this.getText(15444)}>
                    <GenericSelector
                        value={this.state.updateStatusModal ? this.state.updateStatusModal.eStatus : null}
                        onChange={(e) => { this.setState({ updateStatusModal: { ...this.state.updateStatusModal, eStatus: e } }) }}
                        {...this.selectors[2]}
                        {...this.props}
                        filterList={'1,2,3,4,5,6,7,8,9'.split(',')}
                    />
                </Form.Item>
                <Form.Item label={this.getText(19835)}>
                    <GenericSelector
                        value={this.state.updateStatusModal ? this.state.updateStatusModal.eBranch : null}
                        onChange={(e) => { this.setState({ updateStatusModal: { ...this.state.updateStatusModal, eBranch: e } }) }}
                        {...this.selectors[1]}
                        {...this.props}
                    />
                </Form.Item> */}
            </GenericModal>
            <GenericModal
                visible={this.state.modalLinesLog !== null}
                onCancel={() => { this.setState({ modalLinesLog: null }) }}
                title={this.getText(20657)}
                width={1200}
            >
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.lines_log_id]}
                    columns={[
                        { title: this.getText(20658), key: 'lQtyFrom', dataIndex: 'lQtyFrom', width: '25%', render: colTxt },
                        { title: this.getText(20659), key: 'lQtyTo', dataIndex: 'lQtyTo', width: '25%', render: colTxt },
                        { title: this.getText(20660), key: 'lDateTime', dataIndex: 'lDateTime', width: '25%', render: colTxt },
                        { title: this.getText(20661), key: 'lEmployeeName', dataIndex: 'lEmployeeName', width: '25%', render: colTxt },
                    ]}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>
        </div>)
    }
}

export default NewOrdersTable;