
/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Select, Radio, Checkbox, Input } from "antd";
//import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import DateTimePicker from "../../components/DateTimePicker";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelector from "../../components/GenericSelector";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import EventDatePicker from "../../components/EventDatePicker";
//import moment from "moment";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesAnalysisBranches extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            dateFrom: null,
            dateTo: null,
            branchList: '',
            typeReport: '1',
            sumView: '1',
            mOnlyDefaultTag: 0,
            mOnlyDefaultSupp: 0,
            itemCodeRange: 0,
            itemCodeFrom: '',
            itemCodeTo: '',
            tagRange: 0,
            tagList: '',
            suppRange: 0,
            suppList: '',
            _withSendingDocs: '1',
            _winnerView: null,
            reportName: "reports/SalesAnalysisBranches.pdf",
            fileReport: "pdf",
            itemCodeSpecific: null,
            itemNameSpecific: null,
        };

        if (props.data[TableIDs.genericSelectorBranches] === undefined) props.data[TableIDs.genericSelectorBranches] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        if (props.data["WINNERS_LIST_FILTER"] === undefined) props.data["WINNERS_LIST_FILTER"] = { ...props.data.genericSelector };


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14571);

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams && this.props.ui.favoriteParams) {
            const { itemCodeRange, itemCodeFrom, itemCodeTo } = this.props.ui.favoriteParams;
            let itemCodeSpecific = null;
            let itemNameSpecific = null;
            if (itemCodeRange == 1 && itemCodeFrom === itemCodeTo) {
                itemCodeSpecific = itemCodeFrom;
            }

            this.setState({ ...this.props.ui.favoriteParams, itemCodeSpecific, itemNameSpecific })
        }
    }

    daysInMonth = (date) => {
        if (date) {
            let d = date.split("-");
            return new Date(d[0], d[1], 0).getDate();
        }
    }

    createDatePicker = (label, field) => {
        let minDate = null;
        let maxDate = null;
        if (field == 'dateFrom') maxDate = this.state.dateTo;
        if (field == 'dateTo') minDate = this.state.dateFrom;
        // if (this.state.typeReport == '5') {
        //     if (field == 'dateFrom' && this.state.dateTo) minDate = moment(this.state.dateTo).format("YYYY-MM-01");
        //     if (field == 'dateTo' && this.state.dateFrom) maxDate = moment(this.state.dateFrom).format("YYYY-MM-" + this.daysInMonth(this.state.dateFrom));
        // }
        // דניאל ביקש להוריד 23.07.2023
        return (<FormItem label={this.getText(label)}>
            <DateTimePicker
                format="DD/MM/YYYY"
                value={this.state[field]}
                onChange={(e) => { this.setState({ [field]: e }) }}
                minDate={minDate}
                maxDate={maxDate}
            />
        </FormItem>)
    }

    hideField = (field, text, content) => {
        return (<span>
            <FormItem>
                <Checkbox
                    checked={this.state[field] == 1}
                    onChange={(e) => {
                        this.setState({ [field]: e.target.checked ? 1 : 0 });
                    }}>{this.getText(text)}</Checkbox>
            </FormItem>
            {this.state[field] == 1 ? content : ""}
        </span>)
    }


    render() {

        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        const {
            dateFrom, dateTo, branchList, typeReport, sumView, mOnlyDefaultTag, mOnlyDefaultSupp, itemCodeRange,
            itemCodeFrom, itemCodeTo, tagRange, tagList, suppRange, suppList, reportName, _withSendingDocs, _winnerView,
            fileReport,
        } = this.state;

        let params = {
            dateFrom, dateTo, branchList, typeReport, sumView, mOnlyDefaultTag, mOnlyDefaultSupp, itemCodeRange,
            itemCodeFrom, itemCodeTo, tagRange, tagList, suppRange, suppList, _withSendingDocs, _winnerView, reportName
        }

        if (!params.itemCodeRange) params = { ...params, itemCodeFrom: '', itemCodeTo: '' }
        if (!params.tagRange) params = { ...params, tagList: '' }
        if (!params.suppRange) params = { ...params, suppList: '' }

        return (<div style={divStyle} >
            <Card title={(<PageToolbar title={this.getText(14572)} showsearch={false} actionButtons={[]} />)}
                bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                bodyStyle={{ padding: 16 }}
                headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>
                <Row>
                    <Col span={mobile ? 24 : 1}>
                        <FormItem>
                            <EventDatePicker {...this.props}
                                onSelectDate={(from, to) => {
                                    this.setState({ dateFrom: from, dateTo: to })
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={mobile ? 24 : 5}>
                        {this.createDatePicker(14577, 'dateFrom')}
                        {this.createDatePicker(14578, 'dateTo')}
                        <FormItem label={this.getText(14574)}>
                            <Radio.Group
                                value={sumView}
                                onChange={(e) => { this.setState({ sumView: e.target.value }) }}>
                                <Radio key={1} value={'1'}>{this.getText(14575)}</Radio>
                                <Radio key={0} value={'0'}>{this.getText(14576)}</Radio>
                            </Radio.Group>
                        </FormItem>
                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state._withSendingDocs}
                                onChange={e => { this.setState({ _withSendingDocs: e }) }}
                            />
                        </FormItem>

                    </Col>
                    <Col span={mobile ? 24 : 12}>
                        <FormItem label={this.getText(14573)}>
                            <GenericSelector
                                multi
                                id={TableIDs.genericSelectorBranches}
                                api={"get_branch_list"}
                                value={branchList ? branchList.split(',') : []}
                                onChange={(e) => { this.setState({ branchList: e ? e.join(',') : '' }) }}
                                data={this.props.data}
                                dataActions={this.props.dataActions}
                                user={this.props.user}
                            />
                        </FormItem>
                        <FormItem label={this.getText(14579)}>
                            <Select
                                style={{ width: 300 }}
                                value={typeReport}
                                onChange={(e) => { this.setState({ typeReport: e }) }}>
                                <Option key={1} value={'1'}>{this.getText(14580)}</Option>
                                <Option key={2} value={'2'}>{this.getText(14581)}</Option>
                                <Option key={3} value={'3'}>{this.getText(14582)}</Option>
                                <Option key={4} value={'4'}>{this.getText(14583)}</Option>
                                <Option key={5} value={'5'}>{this.getText(18093)}</Option>
                            </Select>
                        </FormItem>
                        {typeReport == 1 || tagRange == 1 ? (
                            <FormItem>
                                <Checkbox
                                    checked={mOnlyDefaultTag == 1}
                                    onChange={(e) => {
                                        this.setState({ mOnlyDefaultTag: e.target.checked ? 1 : 0 });
                                    }}>{this.getText(15370)}</Checkbox>
                            </FormItem>)
                            : ''}

                        {typeReport == 3 || suppRange == 1 ? (
                            <FormItem>
                                <Checkbox
                                    checked={mOnlyDefaultSupp == 1}
                                    onChange={(e) => {
                                        this.setState({ mOnlyDefaultSupp: e.target.checked ? 1 : 0 });
                                    }}>{this.getText(16985)}</Checkbox>
                            </FormItem>)
                            : ''}
                        {this.hideField('tagRange', 15598, (
                            <GenericSelectorItemTags
                                {...this.props}
                                multi
                                value={tagList ? tagList.split(',') : []}
                                onChange={(e) => { this.setState({ tagList: e ? e.join(',') : '' }) }}
                            // optionsGroup
                            // id={TableIDs.genericMultiSelectorItemTags}
                            // api={"get_tags_with_types"}
                            // data={this.props.data}
                            // dataActions={this.props.dataActions}
                            // user={this.props.user}
                            />))}
                        {this.hideField('suppRange', 15599, (
                            <GenericSelectorSuppliers
                                {...this.props}
                                multi
                                value={suppList ? suppList.split(',') : []}
                                onChange={(e) => { this.setState({ suppList: e ? e.join(',') : '' }) }}
                            />))}
                        {typeReport == 4 ? (
                            <span>
                                {this.hideField('itemCodeRange', 51776, [
                                    <Row>
                                        <Col span={mobile ? 24 : 12} style={{ paddingLeft: 10 }}>
                                            <FormItem label={this.getText(51777)}>
                                                <Input value={itemCodeFrom} onChange={(e) => {
                                                    this.setState({
                                                        itemCodeFrom: e.target.value,
                                                        itemCodeSpecific: null,
                                                        itemNameSpecific: null
                                                    })
                                                }} />
                                            </FormItem>
                                        </Col>
                                        <Col span={mobile ? 24 : 12} style={{ paddingLeft: 10 }}>
                                            <FormItem label={this.getText(51778)}>
                                                <Input value={itemCodeTo} onChange={(e) => {
                                                    this.setState({
                                                        itemCodeTo: e.target.value,
                                                        itemCodeSpecific: null,
                                                        itemNameSpecific: null
                                                    })
                                                }} />
                                            </FormItem>
                                        </Col>
                                    </Row>,
                                    <FormItem label={this.getText(15711)}>
                                        <SelectorItemClassic
                                            {...this.props}
                                            valueId={this.state.itemCodeSpecific}
                                            valueName={this.state.itemNameSpecific}
                                            onChange={(code, name) => {
                                                this.setState({
                                                    itemCodeSpecific: code,
                                                    itemCodeFrom: code,
                                                    itemCodeTo: code,
                                                    itemNameSpecific: name
                                                })
                                            }} />
                                    </FormItem>
                                ])}
                            </span>)
                            : ''}
                    </Col>
                    <Col span={mobile ? 24 : 6}>
                        {fileReport == "xls" && typeReport == '5' ?
                            (<FormItem label={this.getText(18568)}>
                                <GenericSelector
                                    {...this.props}
                                    id={"WINNERS_LIST_FILTER"}
                                    api={"winners_list_filter"}
                                    filters={{
                                        wSubject: sumView == '1' ? 7 : 8,
                                        wDateFrom: dateFrom,
                                        wDateTo: dateTo,
                                        wPeriodList: "1,2",
                                        wGroup: 1,
                                    }}
                                    value={_winnerView}
                                    onChange={e => { this.setState({ _winnerView: e }) }}
                                />
                            </FormItem>)
                            : ""}
                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            branchesField={'branchList'}
                            tagsField={'tagList'}
                            encodeURIFields={[
                                'itemCodeFrom',
                                'itemCodeTo',
                                'itemCodeSpecific',
                                'itemNameSpecific',
                            ]}
                            datesToFavorites={[
                                { field: 'dateFrom', label: this.getText(14577) },
                                { field: 'dateTo', label: this.getText(14578) },
                            ]}
                            favoriteSave
                            xlsReportName={"reports/SalesAnalysisBranchesExcel.xls"}
                            onChange={v => { this.setState({ fileReport: v }) }}
                        />
                    </Col>
                </Row>

            </Card>
        </div >
        );
    }
}
export default SalesAnalysisBranches;