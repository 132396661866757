/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Card, Col, Row, Form, Select, Checkbox, Switch, Radio } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
//import SelectorItems from "../../components/SelectorItems";
//import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import GenericSelector from "../../components/GenericSelector";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import { disabledDatePicker } from "../../utils/utils";
import DateTimePicker from "../../components/DateTimePicker";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import EventDatePicker from "../../components/EventDatePicker";

const FormItem = Form.Item;
//const Option = Select.Option;
const { Option, OptGroup } = Select;

class SalesDaysHours extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            SalesDaysHours: {
                LowerSelectionDate: null,
                LowerSelectionHour: "00:00:00",
                UpperSelectionDate: null,
                UpperSelectionHour: "23:59:59",
                StorageList: '',
                PosList: '',
                EmployeeList: '',
                TagList: '',
                AllHours: '1',
                viewStorages: '1',
                viewEmployees: '0',
                viewTags: 0,
                viewDays: 0,
                daysList: '1,2,3,4,5,6,7',
                _withSendingDocs: '1',
                centralPerHours: 0,
                reportName: "reports/SalesDaysHours.pdf",
            }
        };

        this.selectors = [
            { id: TableIDs.genericSelectorStorages, api: "get_storage_list", multi: true },
            { id: TableIDs.genericSelectorEmployees, api: "get_employee_list", multi: true },
            { id: TableIDs.genericMultiSelectorItemTags, api: "get_tags_with_types", multi: true, optionsGroup: true },
        ];

        this.selectors.forEach(x => {
            if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector };
        })

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    // generateReport(report: string) {
    //     let params = Jax.get(this.state || {}, report, null);
    //     if (params !== null && params.reportName) {
    //         console.log("Generating report...", params);
    //         params = this.getFullSelector(params);
    //         params = { ...params, REPORT_LOCALE: this.getText(101) }
    //         if (params.AllHours == '1' && params.reportName.split('.')[0] == "reports/SalesDaysHours") params = { ...params, reportName: "reports/SalesDays." + params.reportName.split('.')[1] }
    //         this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
    //     }
    // }

    // getFullSelector = (params) => {
    //     // let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
    //     let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
    //     // if (params.PosList === '') params = { ...params, PosList: fullPosList }
    //     if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
    //     return params;
    // }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(13977);
        // let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            // posList && posList.dataset[0]
            // &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("SalesDaysHours", this.props.ui.favoriteParams)
        }

    }


    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesDaysHours.LowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesDaysHours.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("SalesDaysHours", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('LowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('UpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
    }

    getDayText = (day) => {
        switch (day) {
            case '1': return this.getText(18157)
            case '2': return this.getText(18158)
            case '3': return this.getText(18159)
            case '4': return this.getText(18160)
            case '5': return this.getText(18161)
            case '6': return this.getText(18162)
            case '7': return this.getText(18163)
        }
    }

    getDayCheckbox = (day) => {
        let text = this.getDayText(day);
        const { daysList } = this.state.SalesDaysHours;

        return (<Checkbox
            onChange={(e) => {
                let newDaysList = daysList ? daysList.split(',') : []
                if (e.target.checked) newDaysList = [...newDaysList, day]
                else newDaysList = newDaysList.filter(f => f !== day)
                this.setReportParameters("SalesDaysHours", { daysList: newDaysList.join(',') })
            }}
            checked={daysList.indexOf(day) > -1}
        >{text}</Checkbox>)
    }

    getSwitchAndContent = (switchLabel, switchChecked, switchUnchecked, switchField, content) => {
        let mobile = window.innerWidth > 600 ? false : true;
        return (<Row>
            <Col span={mobile ? 24 : 8}>
                <FormItem label={this.getText(switchLabel)}>
                    <Switch checkedChildren={this.getText(switchChecked)} unCheckedChildren={this.getText(switchUnchecked)}
                        onChange={(e) => { this.setReportParameters("SalesDaysHours", { [switchField]: e ? 1 : 0 }) }}
                        checked={(this.state.SalesDaysHours[switchField] == 1) ? true : false} />
                </FormItem>
            </Col>
            <Col span={mobile ? 24 : 16} style={{ display: this.state.SalesDaysHours[switchField] == 1 ? 'inline-block' : 'none' }}>
                {content}
            </Col>
        </Row>)
    }

    render() {


        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let mobile = window.innerWidth > 600 ? false : true;

        let params = { ...this.state.SalesDaysHours }
        if (params.AllHours == '1' && params.reportName.split('.')[0] == "reports/SalesDaysHours") params = { ...params, reportName: "reports/SalesDays." + params.reportName.split('.')[1] }
        console.log("test data", params)

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(13977)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: '95%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{
                            columnWidth: 'auto',
                            columnCount: '1',
                            padding: 10,
                            paddingTop: mobile ? 50 : 0,
                        }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <Row>
                            <Col span={mobile ? 24 : 10}>
                                <FormItem label={this.getText(13978)}>
                                    <Select
                                        style={{ width: 250 }}
                                        value={this.state.SalesDaysHours.AllHours}
                                        onChange={(e) => { this.setReportParameters("SalesDaysHours", { AllHours: e }) }}>
                                        <Option value={'1'}>{this.getText(13979)}</Option>
                                        <Option value={'0'}>{this.getText(13980)}</Option>
                                        <Option value={'-1'}>{this.getText(15947)}</Option>
                                    </Select>

                                </FormItem>


                                <Row>
                                    <Col span={mobile ? 24 : 4}>
                                        <FormItem>
                                            <EventDatePicker {...this.props}
                                                onSelectDate={(from, to) => {
                                                    this.setReportParameters("SalesDaysHours", { LowerSelectionDate: from, UpperSelectionDate: to })
                                                }}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={mobile ? 24 : 10}>
                                        <FormItem label={this.getText(12039)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                            <DatePicker
                                                disabledDate={d => disabledDatePicker(d, null, this.state.SalesDaysHours.UpperSelectionDate)}
                                                //  disabledDate={this.disabledStartDate}
                                                // showTime
                                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                                placeholder={this.getText(12055)}
                                                value={this.state.SalesDaysHours.LowerSelectionDate ? moment(this.state.SalesDaysHours.LowerSelectionDate, 'YYYY-MM-DD') : null}
                                                onChange={this.onStartChange}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={mobile ? 24 : 10}>
                                        <FormItem label={this.getText(12040)} /*style={isEndDateShownStyle}*/>
                                            <DatePicker
                                                disabledDate={d => disabledDatePicker(d, this.state.SalesDaysHours.LowerSelectionDate, null)}
                                                // disabledDate={this.disabledEndDate}
                                                // showTime
                                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                                placeholder={this.getText(12056)}
                                                value={this.state.SalesDaysHours.UpperSelectionDate ? moment(this.state.SalesDaysHours.UpperSelectionDate, 'YYYY-MM-DD') : null}
                                                onChange={this.onEndChange}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={mobile ? 24 : 4}></Col>
                                    <Col span={mobile ? 24 : 10}>
                                        <FormItem label={this.getText(19175)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                            <DateTimePicker
                                                format="HH:mm:ss"
                                                value={this.state.SalesDaysHours.LowerSelectionHour}
                                                onChange={e => { this.setReportParameters("SalesDaysHours", { LowerSelectionHour: e }) }}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={mobile ? 24 : 10}>
                                        <FormItem label={this.getText(19176)} /*style={isEndDateShownStyle}*/>
                                            <DateTimePicker
                                                format="HH:mm:ss"
                                                value={this.state.SalesDaysHours.UpperSelectionHour}
                                                onChange={e => { this.setReportParameters("SalesDaysHours", { UpperSelectionHour: e }) }}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <FormItem>
                                    <CheckboxIncludeSendingDocs
                                        value={this.state.SalesDaysHours._withSendingDocs}
                                        onChange={e => { this.setReportParameters("SalesDaysHours", { _withSendingDocs: e }) }}
                                    />
                                </FormItem>

                                <ReportTypeButton
                                    {...this.props}
                                    params={params}
                                    storagesField={'StorageList'}
                                    posField={'PosList'}
                                    xlsReportName={"reports/SalesDaysHoursXls.xls"}
                                    datesToFavorites={[
                                        { field: 'LowerSelectionDate', label: this.getText(12039) },
                                        { field: 'UpperSelectionDate', label: this.getText(12040) },
                                    ]}
                                    favoriteSave
                                />
                            </Col>
                            <Col span={mobile ? 24 : 14}>
                                <Row>
                                    <Col span={mobile ? 24 : 8}>
                                        <Form.Item label={this.getText(13981)}>
                                            <Radio.Group
                                                value={this.state.SalesDaysHours.viewStorages}
                                                onChange={e => { this.setReportParameters("SalesDaysHours", { viewStorages: e.target.value }) }}>
                                                <Radio value={'0'}>{this.getText(19548)}</Radio><br />
                                                <Radio value={'1'}>{this.getText(19546)}</Radio><br />
                                                <Radio value={'2'}>{this.getText(19547)}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={mobile ? 24 : 16}>
                                        {this.state.SalesDaysHours.viewStorages != '0' ?
                                            <Form.Item label={this.getText(13985)}>
                                                <GenericSelectorPosStorages
                                                    {...this.props} multi
                                                    onChange={(list, typeList) => {
                                                        this.setReportParameters("SalesDaysHours", { StorageList: typeList?.join(',') ?? '', PosList: list?.filter(f => f).join(',') ?? '' })
                                                    }}
                                                    value={this.state.SalesDaysHours.PosList?.split(',') ?? []}
                                                />
                                            </Form.Item>
                                            : ""}
                                    </Col>
                                </Row>

                                {/* {this.getSwitchAndContent(13981, 13983, 13984, 'viewStorages',
                                    <Form.Item label={this.getText(13985)}>
                                        <GenericSelector
                                            {...this.props}
                                            {...this.selectors[0]}
                                            onChange={(value) => this.setReportParameters("SalesDaysHours", { StorageList: value ? value.join(',') : '' })}
                                            value={this.state.SalesDaysHours.StorageList ? this.state.SalesDaysHours.StorageList.split(',') : []}
                                        />
                                    </Form.Item>)} */}
                                <Row>
                                    <Col span={mobile ? 24 : 8}>
                                        <Form.Item label={this.getText(13982)}>
                                            <Radio.Group
                                                value={this.state.SalesDaysHours.viewEmployees}
                                                onChange={e => {
                                                    this.setReportParameters("SalesDaysHours", { viewEmployees: e.target.value })
                                                }}>
                                                <Radio value={'1'}>{this.getText(13983)}</Radio><br />
                                                <Radio value={'0'}>{this.getText(13984)}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={mobile ? 24 : 16}>
                                        {this.state.SalesDaysHours.viewEmployees == '1' ?
                                            <Form.Item label={this.getText(13986)}>
                                                <GenericSelector
                                                    {...this.props}
                                                    {...this.selectors[1]}
                                                    onChange={(value) => this.setReportParameters("SalesDaysHours", { EmployeeList: value ? value.join(',') : '' })}
                                                    value={this.state.SalesDaysHours.EmployeeList ? this.state.SalesDaysHours.EmployeeList.split(',') : []}
                                                />
                                            </Form.Item>
                                            : ''}
                                    </Col>
                                </Row>
                                {this.getSwitchAndContent(15948, 13983, 13984, 'viewTags',
                                    this.state.SalesDaysHours.viewTags == 1 ?
                                        <Form.Item label={this.getText(15949)}>
                                            <GenericSelectorItemTags
                                                {...this.props}
                                                {...this.selectors[2]}
                                                onChange={(value) => this.setReportParameters("SalesDaysHours", { TagList: value ? value.join(',') : '' })}
                                                value={this.state.SalesDaysHours.TagList ? this.state.SalesDaysHours.TagList.split(',') : []}
                                            />
                                        </Form.Item>
                                        : "")}
                                {this.getSwitchAndContent(18156, 13983, 13984, 'viewDays', '1,2,3,4,5,6,7'.split(',').map(x => this.getDayCheckbox(x)))}
                                {this.state.SalesDaysHours.AllHours != '1' ?
                                    (<FormItem label={this.getText(18355)}>
                                        <Switch checkedChildren={this.getText(13983)} unCheckedChildren={this.getText(13984)}
                                            onChange={(e) => { this.setReportParameters("SalesDaysHours", { centralPerHours: e ? 1 : 0 }) }}
                                            checked={(this.state.SalesDaysHours.centralPerHours == 1) ? true : false} />
                                    </FormItem>)
                                    : ""}
                            </Col>

                        </Row>



                        {/* <Row>
                            <Col span={mobile ? 24 : 12}>
                                <FormItem key={"reportName"} label={this.getText(12048)}>
                                    <Select style={{ width: 220 }}
                                        className={"reports/SalesDaysHours.pdf"}
                                        defaultValue={"reports/SalesDaysHours.pdf"}
                                        onChange={(value) => this.setReportParameters("SalesDaysHours", { reportName: value })}
                                    >
                                        <Option key={"reports/SalesDaysHours.pdf"}>{this.getText(12049)}</Option>
                                        <Option key={"reports/SalesDaysHoursXls.xls"}>{this.getText(12050)}</Option>
                                        <Option key={"reports/SalesDaysHours.docx"}>{this.getText(12051)}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={mobile ? 24 : 12} style={{ paddingTop: 25 }}>
                                <Button type={"primary"} onClick={() => {
                                    this.generateReport.apply(this, ["SalesDaysHours"])
                                }}>{this.getText(12052)}</Button>
                            </Col>
                        </Row> */}

                    </Form>
                </Card>
            </div>
        );
    }
}
export default SalesDaysHours;