/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, //Col, Row, Button, 
    Form, Select, Checkbox, //InputNumber, Input, 
    Switch, message,
    Col
} from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
import SelectorItems from "../../components/SelectorItems";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import GenericSelectorSuppliers from "../../components/GenericSelectorSuppliers";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelector from "../../components/GenericSelector";
import { disabledDatePicker } from "../../utils/utils";
import CustomerPicker from "../../components/CustomerPicker";
import CustPicker from "../../components/CustPicker";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import GenericSwitchRadio from "../../components/GenericSwitchRadio"
import { Row } from "antd/es/grid";
import EventDatePicker from "../../components/EventDatePicker";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesItemProfitReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            SalesProfit: {
                mApplyRangeApplyItemCode: 0,
                mApplyRangeLowerItemCode: '',
                mApplyRangeUpperItemCode: '',
                mApplyRangeApplyItemName: 0,
                mApplyRangeLowerItemName: '',
                mApplyRangeUpperItemName: '',
                mApplyRangeApplySupplierCode: 0,
                mApplyRangeLowerSupplierCode: '',
                mApplyRangeUpperSupplierCode: '',
                mApplyRangeApplySupplierName: 0,
                mApplyRangeLowerSupplierName: '',
                mApplyRangeUpperSupplierName: '',
                mItemGroupList: '', // TODO remove
                mStorageList: '',
                mSupplierList: '',
                mItemTagList: '', // TODO ADD TO JASPER
                //PosList: '',
                mLowerSelectionDate: null,
                mUpperSelectionDate: null,
                mIsActive: '-1',
                mSortField: 'code',
                //ShowPricesWithTax: 0, //ShowPricesWithTax: 0,
                mTagRelation: '1',
                //  mCalculateProfitMethod: encodeURIComponent(this.getText(12091)),
                mCalculateProfitMethod: "",
                mCalculateCostMethod: "2",
                isByFatherItem: 0,
                withSendingDocs: '1',
                mFieldCentral: '0',
                mOnlyCentral: '0',
                mOnlyDefaultTag: '0',
                mTagTypeId: null,
                mCustId: null,
                mCustTagList: "",
                mIncludeConnectedItems: '0',
                reportName: "reports/salesProfit.pdf",
            }
        };

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        //if (props.data[TableIDs.genericSelectorPos] === undefined) {
        //    props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        //}
        //if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //    props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
        //    props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        //}

        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
            props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        }

        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }


        this.api = {
            //selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
        }

        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        //this.setReportParameters("SalesProfit", { mRequestedReport: 0 });
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
        //this.setReportParameters("SalesProfit", { mDaysOfWeek: [1, 2, 3, 4, 5, 6, 7] });
        //const list = this.props.data[TableIDs.genericSelectorPos].dataset.map(i => i.code).join(',');
        //this.setReportParameters("SalesProfit", { mPosList: list });
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            params = this.getFullSelector(params);
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            if (params.mCalculateProfitMethod === "") params = { ...params, mCalculateProfitMethod: encodeURIComponent(this.getText(12091)) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        // let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        // if (params.mPosList === '') params = { ...params, mPosList: fullPosList }
        if (params.mStorageList === '') params = { ...params, mStorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(10200);
        // let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            // posList && posList.dataset[0]
            // &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("SalesProfit", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        //});
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        });
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.SalesProfit.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.SalesProfit.mUpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("SalesProfit", { [field]: value })
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    createCheckBox = (field, data) => {
        return (
            <Checkbox onChange={(e) => {
                if (e.target.checked)
                    this.setReportParameters("SalesProfit", { [field]: data });
                else
                    this.setReportParameters("SalesProfit", { [field]: '' });
            }} />);
    }

    checkIfItemExist = (s, m) => {
        if (s) {
            let p = m ? "\f0\f2" : "\f1\f0";
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + p;
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let _ItemCode = (ob.data).split('\f')[1];
                    if (!_ItemCode) this.props.uiActions.showModal(TableIDs.SelectorItemsModal, {});
                    else this.onChangeItemCode(_ItemCode)
                },
                (error) => {
                    message.error(this.getText(12080));
                }
            );
        }
    }

    keyboardListener(e) {
        let m = this.state.SalesProfit.isByFatherItem === 1 ? true : false;
        ////console.log('yuda test ', this.barcodeReaderBuffer, m);
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.onChangeItemCode('');
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer, m)
                break;

            default: break;
        }
    }


    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setReportParameters("SalesProfit", {
                mApplyRangeApplyItemCode: code ? 1 : 0,
                mApplyRangeLowerItemCode: code ? code : 0,
                mApplyRangeUpperItemCode: code ? code : 0,
            })
        }
    }

    sortFromItem = (label, isMatrix) => {

        return (<FormItem label={label}>
            <SelectorItems
                doFocus={true}
                user={this.props.user}
                uiActions={this.props.uiActions}
                ui={this.props.ui}
                dataActions={this.props.dataActions}
                data={this.props.data}
                ActionQueue={this.props.ActionQueue}
                ctrlBKeyFlag={null}
                isExpandMatrixFatherItem={false}
                itemType={isMatrix ? 2 : 1}
                value={this.state.SalesProfit.mApplyRangeApplyItemCode == 1 ? this.state.SalesProfit.mApplyRangeLowerItemCode : ''}
                onChangeSearch={(s) => { this.onChangeItemCode(s.mCode) }}
                onChange={(s) => { this.onChangeItemCode(s.target.value) }}
                onBlur={(s) => { this.checkIfItemExist(s.target.value, isMatrix) }}
                onKeyDown={this.keyboardListener.bind(this)}
            />
        </FormItem>)
    }




    render() {
        const { SalesProfit, endOpen } = this.state;
        const { LowerSelectionDate, LowerSelectionTime,
            UpperSelectionDate, UpperSelectionTime } = SalesProfit;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        let listItemTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []).map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )
        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listSuppliers = Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });


        let mobile = window.innerWidth > 600 ? false : true;

        let params = { ...this.state.SalesProfit }
        if (params.mCalculateProfitMethod === "") params = { ...params, mCalculateProfitMethod: encodeURIComponent(this.getText(12091)) }

        let isUserCalcFifo = this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset &&
            this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset.find(f => f.code === '517')

        let encodeURIFields = [
            'mApplyRangeLowerItemCode',
            'mApplyRangeUpperItemCode',
            'mApplyRangeLowerItemName',
            'mApplyRangeUpperItemName',
        ]

        if (this.state.SalesProfit.mTagRelation == '2') encodeURIFields.push("mItemTagList")

        return (
            <div style={divStyle}>
                <Card title={(<PageToolbar title={this.getText(12063)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>

                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >
                        <FormItem label={this.getText(12064)}>
                            <GenericSwitchRadio checkedChildren={this.getText(12081)} unCheckedChildren={this.getText(12082)}
                                onChange={(e) => {
                                    this.setReportParameters("SalesProfit", {
                                        isByFatherItem: (e) ? 1 : 0,
                                        mApplyRangeApplyItemCode: 0,
                                        mApplyRangeLowerItemCode: 0,
                                        mApplyRangeUpperItemCode: 0,
                                    });
                                }}
                                checked={(this.state.SalesProfit.isByFatherItem == 1) ? true : false} />
                        </FormItem>

                        {
                            (this.state.SalesProfit.isByFatherItem == 1) ?
                                this.sortFromItem(this.getText(12079), true) :
                                this.sortFromItem(this.getText(12065), false)
                        }

                        <FormItem>
                            <Checkbox
                                checked={this.state.SalesProfit.mIncludeConnectedItems == '1'}
                                onChange={e => {
                                    this.setReportParameters("SalesProfit", { mIncludeConnectedItems: e.target.checked ? '1' : '0' })
                                }}>{this.getText(19285)}</Checkbox>
                        </FormItem>

                        <FormItem label={this.getText(16418)}>
                            <Select
                                style={{ width: '100%' }}
                                value={this.state.SalesProfit.mFieldCentral}
                                onChange={e => {
                                    let mFieldCentral = e;
                                    let mOnlyCentral = this.state.SalesProfit.mOnlyCentral;
                                    let reportName = "reports/salesProfit";
                                    if (e == '0') {
                                        mOnlyCentral = '0';
                                    } else {
                                        reportName += 'Central';
                                        if (this.state.SalesProfit.mOnlyCentral == '0') reportName += 'Details';
                                    }
                                    reportName += ".pdf";
                                    this.setReportParameters("SalesProfit", { mFieldCentral, mOnlyCentral, reportName, mTagTypeId: '' })
                                }}>
                                <Option value={"0"}>{this.getText(16419)}</Option>
                                <Option value={"1"}>{this.getText(16420)}</Option>
                                <Option value={"2"}>{this.getText(16816)}</Option>
                                <Option value={"3"}>{this.getText(16817)}</Option>
                            </Select>
                        </FormItem>
                        <FormItem style={{ display: this.state.SalesProfit.mFieldCentral != '0' ? 'block' : 'none' }}>
                            <Checkbox
                                checked={this.state.SalesProfit.mOnlyCentral == '1'}
                                onChange={e => {
                                    let mOnlyCentral = e.target.checked ? '1' : '0';
                                    let reportName = "reports/salesProfit";
                                    if (this.state.SalesProfit.mFieldCentral != '0') {
                                        reportName += 'Central';
                                        if (mOnlyCentral == '0') reportName += 'Details';
                                    }
                                    this.setReportParameters("SalesProfit", { mOnlyCentral, reportName: reportName + ".pdf" })
                                }}>{this.getText(16818)}</Checkbox>
                        </FormItem>

                        <FormItem style={{ display: this.state.SalesProfit.mFieldCentral == '2' ? 'block' : 'none' }}>
                            <Checkbox
                                checked={this.state.SalesProfit.mOnlyDefaultTag == '1'}
                                onChange={e => {
                                    this.setReportParameters("SalesProfit", { mOnlyDefaultTag: e.target.checked ? '1' : '0' })
                                }}>{this.getText(16954)}</Checkbox>
                        </FormItem>

                        <FormItem label={this.getText(17122)} style={{ display: this.state.SalesProfit.mFieldCentral == '2' ? 'block' : 'none' }}>
                            <GenericSelector
                                {...this.props}
                                id={TableIDs.genericSelectorItemTagTypes}
                                api={this.api.selector_item_tagTypes}
                                optionsGroup
                                value={this.state.SalesProfit.mTagTypeId}
                                onChange={(value) => {
                                    this.setReportParameters("SalesProfit", { mTagTypeId: value });
                                }}
                                groupFilterList={['ItemTags']}
                                addFirstRow={{ id: '0', text: ' - ' }} />
                        </FormItem>

                        <Row>
                            <Col span={4}>
                                <FormItem>
                                    <EventDatePicker {...this.props}
                                        onSelectDate={(from, to) => {
                                            this.setState({ mLowerSelectionDate: from, mUpperSelectionDate: to }, () => {
                                                this.setReportParameters("SalesProfit", { mLowerSelectionDate: from, mUpperSelectionDate: to })
                                            })
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={20}>
                                <FormItem label={this.getText(12066)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, SalesProfit.mUpperSelectionDate)}
                                        // disabledDate={this.disabledStartDate}
                                        // showTime
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        //format="D/M/YY"
                                        value={SalesProfit.mLowerSelectionDate ? moment(SalesProfit.mLowerSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(12083)}
                                        onChange={this.onStartChange}
                                    //onOpenChange={this.handleStartOpenChange}
                                    />

                                </FormItem>
                                <FormItem label={this.getText(12067)} /*style={isEndDateShownStyle}*/>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, SalesProfit.mLowerSelectionDate, null)}
                                        // disabledDate={this.disabledEndDate}
                                        // showTime
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        //format="D/M/YY"
                                        //value={ToDate ? moment(ToDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(12084)}
                                        value={SalesProfit.mUpperSelectionDate ? moment(SalesProfit.mUpperSelectionDate, "YYYY-MM-DD") : null}
                                        onChange={this.onEndChange}
                                    //open={endOpen}
                                    //onOpenChange={this.handleEndOpenChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <FormItem label={this.getText(18096)} >
                            <CustPicker
                                value={SalesProfit.mCustId}
                                onChange={(code) => { this.setReportParameters("SalesProfit", { mCustId: code }) }}
                                defaultView={this.getText(12121)}
                                {...this.props}
                            />
                        </FormItem>
                        <FormItem label={this.getText(12068)} >
                            {/* {this.createCheckBox('mSupplierList', Jax.get(this.props.data, TableIDs.genericSelectorSuppliers + ".dataset", []).map((obj, index) => obj['code']).join(','))
                            } */}
                            {/* <Select key={"mSupplierList"} style={{ width: '90%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                //mode="multiple"
                                //maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesProfit", {
                                    mApplyRangeApplySupplierCode: value ? 1 : 0,
                                    mApplyRangeLowerSupplierCode: value ? value : 0,
                                    mApplyRangeUpperSupplierCode: value ? value : 0,
                                })}
                                value={(this.state.SalesProfit.mApplyRangeApplySupplierCode === 0) ? '' : this.state.SalesProfit.mApplyRangeLowerSupplierCode}
                            >
                                {listSuppliers}
                            </Select> */}
                            <GenericSelectorSuppliers
                                {...this.props} notApi
                                multi
                                onChange={(value) => this.setReportParameters("SalesProfit", {
                                    // mApplyRangeApplySupplierCode: value ? 1 : 0,
                                    // mApplyRangeLowerSupplierCode: value ? value : 0,
                                    // mApplyRangeUpperSupplierCode: value ? value : 0,
                                    mSupplierList: value ? value.join(",") : ""
                                })}
                                value={
                                    // (this.state.SalesProfit.mApplyRangeApplySupplierCode == 0) ? '' : this.state.SalesProfit.mApplyRangeLowerSupplierCode}
                                    this.state.SalesProfit.mSupplierList ? this.state.SalesProfit.mSupplierList.split(",") : []}
                            />
                        </FormItem>
                        <FormItem label={this.getText(12069)}>
                            {this.createCheckBox('mStorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesProfit", { mStorageList: value.join(',') })}
                                value={(this.state.SalesProfit.mStorageList === '') ? [] : this.state.SalesProfit.mStorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem>
                        <FormItem label={this.getText(12070)} style={{ display: this.state.SalesProfit.mTagTypeId > 0 ? 'none' : 'block' }}>
                            {/* {
                                this.createCheckBox('mItemTagList', Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", [])
                                    .reduce((acc, i) => {
                                        //console.log("acc: ", acc); 
                                        return acc.concat((i.tags.map(j => j.id)));
                                    }, [])
                                    .join(',')
                                )
                            }
                            <Select key={"mItemTagList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("SalesProfit", { mItemTagList: value.sort().join(',') })}
                                value={(this.state.SalesProfit.mItemTagList === '') ? [] : this.state.SalesProfit.mItemTagList.split(',')}
                            >
                                {listItemTags}
                            </Select>
                            <Switch checkedChildren={this.getText(12085)} unCheckedChildren={this.getText(12086)} 
                            <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("SalesProfit", { mItemTagList: value.join(',') })}
                                value={(this.state.SalesProfit.mItemTagList === '') ? [] : this.state.SalesProfit.mItemTagList.split(',')}
                            />
                            <TagRelationRadio showed={this.state.SalesProfit.mItemTagList}
                                checked={this.state.SalesProfit.mTagRelation == '1' ? true : false}
                                onChange={(value) => this.setReportParameters("SalesProfit", { mTagRelation: (value === true) ? 1 : 0 })}
                            //checked={}
                            />*/}
                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.SalesProfit.mItemTagList}
                                onChange={(value) => { this.setReportParameters("SalesProfit", { mItemTagList: value }) }}
                                tagRelationValue={this.state.SalesProfit.mTagRelation}
                                tagRelationOnChange={(value) => {
                                    let mItemTagList = this.state.SalesProfit.mItemTagList;
                                    let from = this.state.SalesProfit.mTagRelation;
                                    let to = value.target.value;
                                    let oldJson = mItemTagList.indexOf("{") > -1 ? JSON.parse(mItemTagList) : {}
                                    if (from == "2") mItemTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") mItemTagList = JSON.stringify({ tagList1: mItemTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("SalesProfit", { mTagRelation: to, mItemTagList })
                                }}
                            />
                        </FormItem>


                        <FormItem label={this.getText(19246)}>
                            <GenericSelectorCustTags
                                {...this.props}
                                multi
                                value={this.state.SalesProfit.mCustTagList ? this.state.SalesProfit.mCustTagList.split(",") : []}
                                onChange={(value) => { this.setReportParameters("SalesProfit", { mCustTagList: value ? value.join(",") : "" }) }}
                            />
                        </FormItem>


                        <FormItem label={this.getText(12071)}>
                            <Select key={"IsActive"} style={{ width: 250 }}
                                // defaultValue={-1}
                                value={this.state.SalesProfit.mIsActive}
                                onChange={(value) => { this.setReportParameters("SalesProfit", { mIsActive: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'-1'}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(12087)}</Option>
                                <Option key={3} value={'0'}>{this.getText(12088)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(12072)}>
                            <Select key={"SortField"} style={{ width: 250 }}
                                // defaultValue={'code'}
                                value={this.state.SalesProfit.mSortField}
                                onChange={(value) => { this.setReportParameters("SalesProfit", { mSortField: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'code'}>{this.getText(12089)}</Option>
                                <Option key={2} value={'name'}>{this.getText(12090)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(12073)}>
                            <GenericSwitchRadio checkedChildren={this.getText(12091)} unCheckedChildren={this.getText(12092)}
                                checked={this.state.SalesProfit.mCalculateProfitMethod !== encodeURIComponent(this.getText(12092)) ? true : false}
                                onChange={(value) => this.setReportParameters(
                                    "SalesProfit", { mCalculateProfitMethod: (value === true) ? encodeURIComponent(this.getText(12091)) : encodeURIComponent(this.getText(12092)) })
                                }
                            />
                        </FormItem>
                        <FormItem>
                            <CheckboxIncludeSendingDocs value={this.state.SalesProfit.withSendingDocs} onChange={(e) => {
                                this.setReportParameters("SalesProfit", { withSendingDocs: e })
                            }} />
                            {/* <Checkbox checked={this.state.SalesProfit.withSendingDocs == 1} onChange={(e) => {
                                this.setReportParameters("SalesProfit", { withSendingDocs: e.target.checked ? 1 : 0 })
                            }}>{this.getText(15507)}</Checkbox> */}
                        </FormItem>

                        <FormItem label={this.getText(18842)}>
                            <Select key={"mCalculateCostMethod"} style={{ width: 250 }}
                                value={this.state.SalesProfit.mCalculateCostMethod}
                                onChange={(value) => { this.setReportParameters("SalesProfit", { mCalculateCostMethod: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'1'}>{this.getText(18843)}</Option>
                                <Option key={2} value={'2'}>{this.getText(18844)}</Option>
                                {isUserCalcFifo ? <Option key={3} value={'3'}>{this.getText(18845)}</Option> : ""}
                                {isUserCalcFifo ? <Option key={4} value={'4'}>{this.getText(18846)}</Option> : ""}
                            </Select>

                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            storagesField={'mStorageList'}
                            tagsField={'mItemTagList'}
                            xlsReportName={this.state.SalesProfit.reportName.split('.')[0] + "Excel.xls"}
                            encodeURIFields={encodeURIFields}
                            datesToFavorites={[
                                { field: 'mLowerSelectionDate', label: this.getText(12066) },
                                { field: 'mUpperSelectionDate', label: this.getText(12067) },
                            ]}
                            favoriteSave
                        />
                        {/* <FormItem key={"reportName"} label={this.getText(12074)}>
                            <Select style={{ width: 250 }}
                                //size={"large"}
                                className={"reports/salesProfit.pdf"}
                                //combobox={null}
                                defaultValue={"reports/salesProfit.pdf"}
                                onChange={(value) => this.setReportParameters("SalesProfit", { reportName: value })}
                            >
                                <Option key={"reports/salesProfit.pdf"}>{this.getText(12075)}</Option>
                                <Option key={"reports/salesProfitExcel.xls"}>{this.getText(12076)}</Option>
                                <Option key={"reports/salesProfit.docx"}>{this.getText(12077)}</Option>
                            </Select>
                        </FormItem>
                        <Button type={"primary"} onClick={() => {
                            this.generateReport.apply(this, ["SalesProfit"])
                        }}>{this.getText(12078)}</Button> */}
                    </Form>
                </Card>
            </div>
        );
    }
}
export default SalesItemProfitReport;
