
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import { Card, InputNumber, Input, Checkbox, Form, DatePicker, Select, Radio, Button, message } from "antd";
import "../App.css";
import moment from "moment";
import QuestionnaireForm from "./QuestionnaireForm";
import FormLinkStyle from "./FormLinkStyle";
import { Job, OutputType, ProcessType, User } from "../JmReact";

class QuestionnaireOnly extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstShortMessage: "",
            firstLongMessage: "",

            docInHtml: null,
        }

        this.qq_id = "QUESTIONNAIRE_QUESTIONS_LIST";
        if (props.data[this.qq_id] === undefined) props.data[this.qq_id] = { data: [], qID: null }

    }

    componentDidMount() {
        const { firstShortText, firstLongText, entityType, entityValue } = this.getDBParams();
        console.log({ firstShortText, firstLongText })
        if (firstShortText && firstLongText) {
            this.props.sendAPI("get_answer_value", "_QAID\r" + firstShortText + "\r" + firstLongText, (ob) => {
                let data = ob.data ? ob.data.split("\r") : ['', '']
                this.setState({ firstShortMessage: data[0], firstLongMessage: data[1] })
            }, this.props.errorMessage)
        }

        if (entityType == 3) this.getdocInHtml(entityValue?.split("-"))
    }

    getDBParams = () => {
        let dbParams = {}
        if (this.props.data.formData && this.props.data.formData.params) {
            this.props.data.formData.params.split('&').forEach(x => {
                let y = x.split('=');
                dbParams = { ...dbParams, [y[0]]: y[1] }
            })
        }
        return dbParams;
    }

    saveAnswers = (isDraft) => {
        const { entityType, entityValue, qId, qDraftIdentity } = this.getDBParams();

        let qqData = this.props.data[this.qq_id].data;

        if (isDraft != '1' && qqData.filter(x => x.qqIsRequired === "1" && !x.qaValue).length) {
            message.error(this.props.getText(90008));
        } else {
            let qaList = "_qId\f_qqId\f_value\f_datetime\f_entityType\f_entityValue\f_isDraft\f_qIdentityID";

            qaList += "\r" + qId + "\f-1\f\f" + moment().format("YYYY-MM-DD HH:mm:ss") + "\f"
                + entityType + "\f"
                + entityValue + "\f"
                + isDraft + "\f"
                + (qDraftIdentity ? qDraftIdentity : '0');

            qqData.forEach(x => {
                qaList += "\r" + qId + "\f" + x.qqID + "\f" + x.qaValue + "\f" + x.qaDatetime + "\f\f\f" + isDraft + "\f" + (qDraftIdentity ? qDraftIdentity : '0');;
            })

            this.props.sendAPI("set_questionnaire_answers_v2", qaList, (ob) => {
                const { linkId } = this.props.data.formData;

                const onSuccess = () => {
                    let message = {
                        title: this.props.getText(isDraft == '1' ? 90070 : 90009),
                        desc: "",
                    }
                    this.props.setJson("message", message);
                    this.props.overrideJson("formData", {});


                }

                if (isDraft == '1') {
                    let addParams = "";
                    if (!qDraftIdentity) addParams = "&qDraftIdentity=" + ob.data;

                    this.props.sendAPI("update_form_token_draft", "_id\f_addParams\r" + linkId + "\f" + addParams, onSuccess, this.props.errorMessage)
                } else {
                    if (entityType == 3) {
                        const [DocNum, DocTypeID, DocPosID] = entityValue?.split("-") ?? ['', '', '']
                        this.props.sendAPI("after_answer_entity_type_3", "DocNum\fDocPosID\fDocTypeID\r" + DocNum + "\f" + DocPosID + "\f" + DocTypeID, () => {
                            this.props.sendAPI("delete_form_token", linkId, onSuccess, this.props.errorMessage)
                        }, this.props.errorMessage)
                    } else {
                        this.props.sendAPI("delete_form_token", linkId, onSuccess, this.props.errorMessage)
                    }
                }
            }, this.props.errorMessage)
        }
    }


    getdocInHtml = ([DocumentNumber, DocumentTypeID, DocumentPOSID]) => {
        console.log("getdocInHtml", this.props)
        let params = 'DocumentNumber\fDocumentTypeID\fDocumentPOSID\fCompanyCode\fisDraft\fCentralFatherItem\fPrintFormat\freportName\r'
            + DocumentNumber + '\f' + DocumentTypeID + '\f' + DocumentPOSID + '\f' + this.props.companyCode + '\f\f0\f\freports/DocumentCopy.html'
        this.props.sendAPI("generate_report", params, response => {
            const data = response.blob;
            const reader = new FileReader();
            reader.onload = () => {
                let docInHtml = reader.result
                docInHtml = "<div style='width:600px;overflow-x:auto'><table dir='ltr'" + docInHtml.split('<table width="100%"').slice(1).join("<table")
                docInHtml = docInHtml.split("</table>").slice(0, -1).join("</table>") + "</table></div>"
                this.setState({ docInHtml })
            }
            reader.onerror = e => { console.error(e) };
            reader.readAsText(data); // קריאה כטקסט

            // let blob = data.slice(0, data.size, "application/pdf");

            // const docInHtml = window.URL.createObjectURL(blob);
            // this.setState({ docInHtml })
        }, e => { console.error(e) })

    }


    // getdocInHtml_old = ([num, type, pos]) => {
    //     this.props.sendAPI("get_document_report_link",
    //         "docNum\fdocType\fdocPos\r" + num + "\f" + type + "\f" + pos,
    //         (ob) => {
    //             this.setState({ docInHtml: ob.data })
    //         }, (e) => {
    //             console.error(e)
    //         })
    // }



    render() {

        const { entityType, entityValue, qId, qName, qIsAllowsDraft, qDraftIdentity } = this.getDBParams();

        // let formStyle = {
        //     columnWidth: 'auto',
        //     columnCount: window.innerWidth <= 600 ? '1' : '3',
        //     padding: 5,
        //     margin: 5,
        // }

        let URLtoken = window.location.search.split("&token=")[1];

        return URLtoken ?
            (<div style={{ maxWidth: 1000, margin: 'auto', minWidth: this.state.docInHtml ? 600 : 100 }}>
                <div style={{ background: '#ff4d4f' }}>
                    <h1 style={{ textAlign: 'center', padding: 10 }}>
                        {this.props.companyLogo ? (<img src={this.props.companyLogo} alt={'Logo'} title={this.props.companyName} />) : ""}
                        <div style={{ color: 'white' }}>{this.props.companyName}</div>
                    </h1>
                </div>
                <h2 style={{ padding: 10, textAlign: 'center' }}>{qName}</h2>
                <h3 style={{ padding: 10, textAlign: 'center', color: 'lightblue' }}>{this.state.firstShortMessage}</h3>
                <p>{this.state.firstLongMessage ? this.state.firstLongMessage.split("\n").map(x => [x, <br />]) : ""}</p>

                {/* <Form className="data-entry-form" layout='vertical' style={formStyle}> */}
                <QuestionnaireForm {...this.props} qId={qId} custId={entityType == 1 ? entityValue : null} qDraftIdentity={qDraftIdentity} docInHtml={this.state.docInHtml} />
                <Button style={{ background: '#ff4d4f', color: 'white', margin: 5 }} size="large" onClick={() => { this.saveAnswers('0') }}>{this.props.getText(90011)}</Button>
                {qIsAllowsDraft == '1' ?
                    <Button style={{ background: '#ff4d4f', color: 'white', margin: 5 }} size="large" onClick={() => { this.saveAnswers('1') }}>{this.props.getText(90069)}</Button>
                    : ""}
                {/* </Form> */}
            </div>)
            // (<FormLinkStyle
            //     colorForm={colorForm}
            //     defaultSection={1}
            //     compData={{ name: this.props.companyName, logo: this.props.companyLogo }}
            //     formTitle={qName}
            //     sections={[{
            //         title: "",
            //         key: "QuestionnaireOnly",
            //         content: (<span>
            //             <QuestionnaireForm {...this.props} qId={qId} custID={entityType == 1 ? entityValue : null} />
            //             <Button style={{ background: colorForm, color: 'white' }} size="large" onClick={this.handleOk}>{this.props.getText(90011)}</Button>
            //         </span>)
            //     }]}
            // />)
            : (<span></span>)
    }
}

export default QuestionnaireOnly;