import React, { Component } from "react";
import { Button, Tooltip, Icon, Checkbox } from "antd";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import { Job, OutputType, ProcessType, User } from "../JmReact";


class EventDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      checedId: null,
      eventsList: []
    };
  }

  static contextType = LangContext;

  getText = (id) => {
    return this.context.get(id) || '[' + id + ']'
  }

  componentDidMount() {
    this.sendAPI("event_dates_list", "", ob => {
      let eventsList = []
      if (ob.data) {
        ob.data.split("\r").forEach(x => {
          let y = x.split("\f")
          let eIndex = eventsList.findIndex(f => f.eId == y[0])
          if (eIndex > -1 && eventsList[eIndex].eDates) eventsList[eIndex].eDates.push({ id: y[2], name: y[3], start: y[4], end: y[5] })
          else eventsList.push({ eId: y[0], eName: y[1], eDates: [{ id: y[2], name: y[3], start: y[4], end: y[5] }] })
        })
      }
      this.setState({ eventsList })
    })
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  sendAPI = (script, data, sCal) => {
    let user = new User(this.props.user.token, this.props.user.companyCode);
    let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
    job.setInput(data);
    job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
  }

  render() {
    console.log("eventDatePicker", this.state)
    return (
      <>
        <Tooltip title={this.getText(20646)}>
          <Button type="primary" onClick={this.showModal}>
            <Icon type="calendar" />
          </Button>
        </Tooltip>
        <GenericModal
          title={this.getText(20647)}
          visible={this.state.isModalVisible}
          onCancel={this.handleCancel}
          width={400}>
          {this.state.eventsList.map(x => {
            return (<>
              <h2>{x.eName}</h2>
              <div style={{ marginRight: 15 }}>
                {x.eDates.map(y => {
                  return (<Checkbox
                    checked={this.state.checedId == y.id}
                    onChange={() => {
                      this.setState({
                        isModalVisible: false,
                        checedId: y.id
                      }, () => {
                        this.props.onSelectDate(y.start, y.end)
                      })
                    }}>{y.name}</Checkbox>)
                })}
              </div>
            </>)
          })}
          {this.state.eventsList.length == 0 ? this.getText(20648) : ""}
        </GenericModal>
      </>
    );
  }
}

export default EventDatePicker;
