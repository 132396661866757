/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import {
    Card, Button, //Form, Select, 
    Checkbox, //InputNumber, Input, 
    Icon, Popconfirm, //Table, Collapse, 
    Popover, Tooltip, message, Radio, Form, Input, Select,
    Modal
} from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
//import { DatePicker } from 'antd';
//import moment from "moment";
import PageToolbar from "../../components/PageToolbar";
//import ItemPicker from "../../components/ItemPicker";
import ModalAdvanceSearchReports from "../../components/ModalAdvanceSearchReports";
import ModalShareMessage from "../../components/ModalShareMessage";
import LabelPrinter from "../../components/LabelPrinterV2";
import ResponsiveTable from "../../components/ResponsiveTable";
import LangContext from "../../contextProvider/LangContext";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericModal from "../../components/GenericModal";
import ModalDocumentLines from "../../components/ModalDocumentLines";
//import GenericSelector from "../../components/GenericSelector";
import { escapeRegExp } from "../../utils/utils";
import ReportExcelBtn from "../../components/ReportExcelBtn";
import moment from "moment";
import ReportShowButton from "../../components/ReportShowButton";
import GenericIcon from "../../components/GenericIcon";
import ModalContacts from "../../components/ModalContacts";
import UsersTableColumns from "../../components/UsersTableColumns";




type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class PaymentsSearchReport extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,
            PaymentsSearch: {
                mDocTypeList: '',
                mPosList: '',
                mLowerSelectionDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
                mLowerSelectionTime: '00:00:00',
                mUpperSelectionDate: moment().format('YYYY-MM-DD'),
                mUpperSelectionTime: '23:59:59',
                mApplyCreditCardSearchQuery: null,
                mIsCustomerActive: 1,
                mCustomer: null,
                mItemCode: '',
                mEmployeeList: '',
                mStorageList: '',
                mDocumentIsClosed: 0,
                mSumFrom: null,
                mSumTo: null,
                mSumAbsolute: 0,
                mSupplierDoc: null,
                mSupplierDocDateFrom: null,
                mSupplierDocDateTo: null,
                mTagList: '',
                mCustTagList: '',
                mItemSerialNumber: '',
                mCustWithConnected: "0",
                mCustomerList: "",
                searchColFilter: "4",
                mCustType: "0",
            },
            PaymentsSearchReport: {
                DocumentNumber: null,
                DocumentTypeID: null,
                DocumentPOSID: null,
                CompanyCode: this.props.user.companyCode,
                reportName: "reports/DocumentCopy.pdf",
            },
            // mWords: '',
            userID: -1,
            modalLines: null,

            labelsA4Params: {},

            reportTypeButton: false,
            centralFatherItem: false,

            modalSendDocByCustSetting: null,
            sendDocByCustLoading: false,

            valueSearch: "",


            reportIsSum: "",

            valueRangeDates: 30,

            modalCancelDepositeChecks: null,

            modalReservedNumber: null,
            modalReservedNumberEdit: false,
            modalReservedNumberEditCustTaxId: false,

            userColumnsList: [],

            modalDigitalSignCheck: false,
            modalDigitalSignCheckInput: "",
            modalDigitalSignCheckPrintFormat: null,

        };

        this.timeOut = null;
        this.state.collapeKey = null;
        this.id = "DOC_SEARCH_TABLE";
        this.labelsId = "DOC_ITEMS_TO_LABELS";
        this.state = { ...this.state, defaultItemCode: "", itemCodeSelectionVisible: false, viewPopPrint: null };

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData } ?? {};
        }

        if (props.data[this.labelsId] === undefined) props.data[this.labelsId] = { dataset: [] };


        this.tableApi = {
            get: "get_doc_search_table_list",
            create: "",
            edit: "",
            //delete: "delete_customer",
            selector: "",
            modalPriceListGet: "",
        }

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorDocumentTypes] === undefined) {
            props.data[TableIDs.genericSelectorDocumentTypes] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
            props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }

        if (props.data.USER_GP_VALUES === undefined) {
            props.data.USER_GP_VALUES = {};
        }

        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
            selector_payment_types: "get_payment_types_list",

            selector_docTypes: "get_document_types_list",
            selector_customers: "get_customers_list_v3",
            selector_employee: "get_employee_list",
            selector_storages: "get_storage_list",
        }

        this.reportPrinting = false;

        this.refreshDataSelectors();
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {

        this.sendAPI("get_all_gp_by_user", "", ob => {
            let res = {}
            let searchColFilter = '4';
            let valueRangeDates = 30;

            if (ob.data) {
                ob.data.split("\r").forEach(x => {
                    let y = x.split("\f");
                    res = { ...res, [y[0]]: y[1] }
                })
            }

            if (res.GP_DocsFilterOption > 0) searchColFilter = res.GP_DocsFilterOption

            if (res.GP_DocsDateRangeOption > 0) valueRangeDates = parseInt(res.GP_DocsDateRangeOption);

            this.setState({
                PaymentsSearch: {
                    ...this.state.PaymentsSearch,
                    searchColFilter,
                    mLowerSelectionDate: moment().add((valueRangeDates * -1), 'days').format('YYYY-MM-DD'),
                    mLowerSelectionTime: '00:00:00',
                    mUpperSelectionDate: moment().format('YYYY-MM-DD'),
                    mUpperSelectionTime: '23:59:59',
                },
                valueRangeDates,
            }, () => {
                this.loadData();
                this.initFilters();

            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isModalFromDashboard) document.title = "Cash On Tab - " + this.getText(10163);
        if (this.props.user.userId !== this.state.userID) {
            this.setState({ userID: this.props.user.userId }, () => {
                this.refreshDataSelectors();
                setTimeout(() => { this.initFilters() }, 600);
            })
        }

        if (this.reportPrinting && prevProps.ui.workInProgress && !this.props.ui.workInProgress) {
            this.reportPrinting = false;
            this.refreshData();
        }

        if (this.props.isModalFromDashboard && prevProps.visible !== this.props.visible) {
            this.setState({
                PaymentsSearch: {
                    ...this.state.PaymentsSearch,
                    mPosList: this.props.pos ?? "",
                    mDocTypeList: this.props.docType ?? "",
                    mLowerSelectionDate: this.props.date ?? "",
                    mUpperSelectionDate: this.props.date ?? "",
                    mCustomer: this.props.custId ?? "",
                    mCustType: this.props.custId ? "1" : "0",
                }
            }, this.initFilters)
        }

        if (this.props.data.DOC_SEARCH_TABLE && this.props.data.DOC_SEARCH_TABLE.filters !== prevProps.data.DOC_SEARCH_TABLE?.filters) {
            this.setState({
                PaymentsSearch: {
                    ...this.state.PaymentsSearch,
                    ...this.props.data.DOC_SEARCH_TABLE.filters
                }
            })
        }

        if (this.state.modalReservedNumber && this.props.data.DOC_SEARCH_TABLE?.data !== prevProps.data.DOC_SEARCH_TABLE?.data) {
            let findRow = this.props.data.DOC_SEARCH_TABLE.data.map(f => {
                let y = f.split("\f")
                return {
                    key: y[0] + '#' + y[1] + '#' + y[2],
                    reservedNumber: y[27],
                    reservedNumberShort: parseInt(y[28]) ?? null,
                    taxIdentity: y[29],
                }
            }).find(f => this.state.modalReservedNumber.key == f.key)
            if (findRow) {
                this.setState({ modalReservedNumber: { ...this.state.modalReservedNumber, ...findRow } })
            }
        }

    }

    initFilters = () => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos] ? this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',') : "";

        let request = {
            mLowerSelectionDate: this.state.PaymentsSearch.mLowerSelectionDate,
            mLowerSelectionTime: this.state.PaymentsSearch.mLowerSelectionTime,
            mUpperSelectionDate: this.state.PaymentsSearch.mUpperSelectionDate,
            mUpperSelectionTime: this.state.PaymentsSearch.mUpperSelectionTime,
            mPosList: this.state.PaymentsSearch.mPosList === '' ? fullPosList : this.state.PaymentsSearch.mPosList,
            mDocTypeList: this.state.PaymentsSearch.mDocTypeList,
            mApplyCreditCardSearchQuery: this.state.PaymentsSearch.mApplyCreditCardSearchQuery,

            mItemCode: this.state.PaymentsSearch.mItemCode,
            mCustomer: this.state.PaymentsSearch.mCustomer,

            mEmployeeList: this.state.PaymentsSearch.mEmployeeList,
            mStorageList: this.state.PaymentsSearch.mStorageList,
            mDocumentIsClosed: this.state.PaymentsSearch.mDocumentIsClosed,
            mSumFrom: this.state.PaymentsSearch.mSumFrom,
            mSumTo: this.state.PaymentsSearch.mSumTo,
            mSumAbsolute: this.state.PaymentsSearch.mSumAbsolute,
            mSupplierDoc: this.state.PaymentsSearch.mSupplierDoc,
            mSupplierDocDateFrom: this.state.PaymentsSearch.mSupplierDocDateFrom,
            mSupplierDocDateTo: this.state.PaymentsSearch.mSupplierDocDateTo,
            mTagList: this.state.PaymentsSearch.mTagList,
            mCustTagList: this.state.PaymentsSearch.mCustTagList,
            mItemSerialNumber: this.state.PaymentsSearch.mItemSerialNumber,
            searchColFilter: this.state.PaymentsSearch.searchColFilter,
            mCustWithConnected: this.state.PaymentsSearch.mCustWithConnected,
            mCustomerList: this.state.PaymentsSearch.mCustomerList,
            mCustType: this.state.PaymentsSearch.mCustType,

            _id: 0,
            // ui
            page: 1,
            recordsPerPage: 10,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: '',
            _page_num: 1,
            _rows_num: 10,
            _isAsc: 0,
            _sort_by: 'col_0',
        };
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.tableApi.get);

        //  this.refreshDataSelectors();
    }

    refreshData = () => {
        this.refreshDataFilters()
        this.refreshDataSelectors()
    }

    refreshDataSelectors = () => {
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employee);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);

    }

    refreshDataFilters = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data[this.id].filters, this.tableApi.get);
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 11;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "DocumentPOSID": col--; // falls through
            case "POSName": col--; // falls through
            case "DocTypeName": col--; // falls through
            case "DocumentNumber": col--; // falls through
            case "DocumentCustomerName": col--; // falls through
            case "DocumentTotalWithTax": col--; // falls through
            case "DocumentTimestamp": col--; // falls through
            case "EmployeeName": col--; // falls through
            case "DocZNum": col--; // falls through
            case "StorageName": col--; // falls through
            case "DocRef2": break; // 11

            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            // _words: this.state.mWords,
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.tableApi.get)
    }


    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    setReportParametersAndDo(report: string, params: Object, func) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState, func);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            this.reportPrinting = true;
            params = { ...params, REPORT_LOCALE: this.getText(101) }
            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });

        }
    }

    openModalSendDocByCustSetting = (record) => {
        let PrintFormat = record['DocFormat'];
        let reportName = record['DocumentTypeId'] == '5' ? "DocumentCopyCentral" : "DocumentCopy";
        // switch (record['DocFormat']) {
        //     case '0': reportName = "DocumentCopyCentral"; break;
        //     case '1': reportName = "DocumentCopyCentralDetails"; break;
        //     case '2': reportName = "DocumentCopyCentralPerCust"; break;
        //     case '3': reportName = "DocumentCopyCentralPerCustDetails"; break;
        //     case '4': reportName = "DocumentCopyCentralSum"; break;
        // }
        let params = {
            DocumentNumber: record['DocumentNumber'],
            DocumentTypeID: record['DocumentTypeId'],
            DocumentPOSID: record['DocumentPOSID'],
            userID: this.props.user.userId,
            userName: encodeURIComponent(this.props.user.displayName),
            isOriginal: record['DocIsOrigin'] == '1',
            docIsWithSigns: record['docIsWithSigns'],
            PrintFormat,
            reportName: "reports/" + reportName + ".pdf"
        }
        this.setState({ modalSendDocByCustSetting: { params, custID: record.custID } }, this.getCustDocTarget)
    }

    getCustDocTarget = () => {
        if (this.state.modalSendDocByCustSetting && this.state.modalSendDocByCustSetting.params) {
            const { DocumentNumber, DocumentTypeID, DocumentPOSID } = this.state.modalSendDocByCustSetting.params;
            this.sendAPI("get_doc_cust_target", "_num\f_type\f_pos\r" + DocumentNumber + "\f" + DocumentTypeID + "\f" + DocumentPOSID, ob => {
                let res = ob.data ? ob.data.split("\f") : [];
                let targetType = res[2] > 1 ? res[2] : ""
                this.setState({
                    modalSendDocByCustSetting: {
                        ...this.state.modalSendDocByCustSetting,
                        customerId: res[0],
                        target: res[1],
                        targetType,
                        targetCheckable: !targetType,
                        target1: res[3],
                        target2: res[4],
                    }
                })
            }, e => { console.error(e) })
        }
    }

    sendReportByCustSetting = () => {
        this.setState({ sendDocByCustLoading: true }, () => {

            const { customerId, target, targetType, params } = this.state.modalSendDocByCustSetting;

            const nextSend = (ob) => {
                if (ob?.errorCode) {
                    message.error(ob.errorMessage);
                } else {
                    message.success(this.getText(17755))
                    this.setState({ modalSendDocByCustSetting: null, sendDocByCustLoading: false })
                }
            }

            const onError = (e) => { console.error(e) }

            const sendReport = () => {
                let reportNameAndFormat = params.reportName.split("/")[1].split(".");
                let reportName = reportNameAndFormat[0];
                let user = new User(this.props.user.token, this.props.user.companyCode);
                let job = new Job(user, 'send_report', OutputType.OUTPUT_TYPE_BLOB, ProcessType.PROCESS_TYPE_SYNC);
                let metaData = 'targetType\freportName\ftargetSystem\fcustomerId\ftarget\freportFormat\fisDoc\r' +
                    targetType + '\f' + reportName + '\f0\f' + customerId + '\f' + target + '\f0\f1';
                let paramsKeys = Object.keys(params);
                let paramsData = paramsKeys.join("\f") + "\r" + paramsKeys.map(x => params[x] ? params[x] : "").join("\f")
                job.setSection('META', metaData);
                job.setSection('PARAMS', paramsData);
                job.send("/cgi-bin/CashOnTab", nextSend, onError);
            }

            if (!parseInt(params.docIsWithSigns)) {
                this.sendAPI("get_sign_print_options", "_pos\f_type\r" + params.DocumentPOSID + "\f" + params.DocumentTypeID, ob => {
                    const signPrintOptions = ob.data ? ob.data.split("\f") : []
                    if (signPrintOptions[0] == '1') {
                        let p = "entityType=3&entityValue="
                            + params.DocumentNumber + "-"
                            + params.DocumentTypeID + "-"
                            + params.DocumentPOSID
                            + "&qId=" + signPrintOptions[1]
                            + "&qName=" + signPrintOptions[2]
                            + "&qIsAllowsDraft=0";

                        let dataSend = "_type\f_params\f_mobile\f_lang\f_messageType\r7\f"
                            + p + "\f" + target + "\f" + this.getText(100) + "\f" + targetType;
                        this.sendAPI("create_random_connection_token_v2", dataSend, nextSend, onError);
                    } else {
                        sendReport();
                    }
                }, onError)
            } else {
                sendReport();
            }

        });
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorDocumentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_docTypes]
        });

        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.PaymentsSearch.LowerYearSelection;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.PaymentsSearch.UpperYearSelection;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }

    onChange = (field, value, field2, value2) => {
        this.setReportParameters("PaymentsSearch", { [field]: value, [field2]: value2 })
    }

    onStartChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');;
        this.onChange('mLowerSelectionDate', arr[0], 'mLowerSelectionTime', '00:00:00' /*arr[1]*/);
    }

    onEndChange = (value) => {
        let arr = (value ? value.format("YYYY-MM-DD#hh:mm:ss") : '').split('#');
        this.onChange('mUpperSelectionDate', arr[0], 'mUpperSelectionTime', '23:59:59' /*arr[1]*/);
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentsSearch", { [field]: data });
                    else
                        this.setReportParameters("PaymentsSearch", { [field]: '' });
                }} />);
    }

    collapeCallback = (key) => {
        this.setState({ collapeKey: key });
    }
    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }

    createLabels = (docNum, docType, docPos) => {
        // this.setState({ labelsA4Params: { docNum, docType, docPos } }, () => {
        let dataSend = "mDocNum\fmTypeID\fmPOSID\r" + docNum + "\f" + docType + "\f" + docPos
        this.sendAPI("get_doc_items_to_labels", dataSend, ob => {
            this.sendAPI("get_doc_cust_detailed", dataSend, ob2 => {
                let dataset = []
                let deliveriesDataset = []

                if (ob.data) {
                    let headers = []
                    ob.data.split("\r").forEach((x, i) => {
                        let y = x.split('\f')
                        if (!i) {
                            headers = y
                        } else {
                            let obj = { index: i - 1, checked: true, _package_qty: 1 }
                            headers.forEach((z, zi) => { obj = { ...obj, [z]: y[zi] } })
                            dataset.push(obj)
                        }
                    })
                }
                if (ob2.data) {
                    let d = ob2.data.split("\r")
                    deliveriesDataset = d[0]?.split("\f").map(x => { return { key: x, value: "", label: "" } })
                    if (d[1]) d[1].split("\f").forEach((x, i) => { deliveriesDataset[i] = { ...deliveriesDataset[i], value: x } })
                    if (d[2]) d[2].split("\f").forEach((x, i) => { deliveriesDataset[i] = { ...deliveriesDataset[i], label: x } })
                }
                this.props.dataActions.setJson(this.labelsId, { dataset, deliveriesDataset })
            })
        })
        // this.props.dataActions.genericDataSetFilter(
        //     this.labelsId,
        //     this.props.user.companyCode,
        //     this.props.user.token,
        //     { mDocNum: docNum, mTypeID: docType, mPOSID: docPos },
        //     "get_doc_items_to_labels");
        // })
    }

    // responsLabels = () => {
    //     let data = this.props.data[this.labelsId] ? this.props.data[this.labelsId].data : [];
    //     let res = [];
    //     data.map((x) => {
    //         let y = x.split("\f");
    //         let qty = parseInt(y[1]);
    //         if (qty > 0) res.push({ _ITEM_CODE: y[0], _quantity: qty, _CUSTOMER_ID: y[2], _CART_CUSTOMER_NAME: y[3] })
    //     })
    //     return res;
    // }

    reportButton = () => {
        let generateReport = (reportName) => {
            let f = this.props.data[this.id].filters;
            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, {
                    mLowerSelectionDate: f.mLowerSelectionDate,
                    mLowerSelectionTime: f.mLowerSelectionTime,
                    mUpperSelectionDate: f.mUpperSelectionDate,
                    mUpperSelectionTime: f.mUpperSelectionTime,
                    mApplyCreditCardSearchQuery: f.mApplyCreditCardSearchQuery,
                    mIsCustomerActive: f.mIsCustomerActive,
                    mCustomer: f.mCustomer,
                    mItemCode: encodeURIComponent(f.mItemCode),
                    mDocTypeList: f.mDocTypeList,
                    mPosList: f.mPosList,
                    mEmployeeList: f.mEmployeeList,
                    mStorageList: f.mStorageList,
                    mDocumentIsClosed: f.mDocumentIsClosed,
                    mSumFrom: f.mSumFrom,
                    mSumTo: f.mSumTo,
                    mSumAbsolute: f.mSumAbsolute,
                    mSupplierDoc: f.mSupplierDoc,
                    mSupplierDocDateFrom: f.mSupplierDocDateFrom,
                    mSupplierDocDateTo: f.mSupplierDocDateTo,
                    mTagList: f.mTagList,
                    mCustTagList: f.mCustTagList,
                    mItemSerialNumber: f.mItemSerialNumber,
                    searchColFilter: f.searchColFilter,
                    mCustWithConnected: f.mCustWithConnected,
                    mCustomerList: f.mCustomerList,
                    mCustType: f.mCustType,

                    _id: f._id,
                    _words: encodeURIComponent(f._words),
                    _sort_by: f._sort_by,
                    _isAsc: f._isAsc,
                    reportName: "reports/" + reportName,
                    REPORT_LOCALE: this.getText(101),
                }]
            });
        }

        let subButton = (text, type, reportName) => {
            let onClick = () => {
                this.setState({ reportTypeButton: false }, () => {
                    generateReport(reportName)
                })
            }
            return (
                <Tooltip title={this.getText(text)}>
                    <Icon style={{ fontSize: 32 }} className={"clickable margin-4"} type={type} onClick={onClick} />
                </Tooltip>)
        }



        let content = (<div>
            <Radio.Group value={this.state.reportIsSum} onChange={e => { this.setState({ reportIsSum: e.target.value }) }}>
                <Radio value={""}>{this.getText(19172)}</Radio><br />
                <Radio value={"Detail"}>{this.getText(19843)}</Radio><br />
                <Radio value={"Sum"}>{this.getText(19173)}</Radio><br />
            </Radio.Group>
            {subButton(12721, "file-pdf", this.state.reportIsSum + "DocumentsReport.pdf")}
            {subButton(12722, "file-excel", this.state.reportIsSum + "DocumentsReportExcel.xls")}
        </div>);

        return (
            <Tooltip title={this.getText(12720)}>
                <Popover content={content} title={this.getText(12723)} trigger="click" visible={this.state.reportTypeButton} onClick={() => {
                    this.setState({ reportTypeButton: !this.state.reportTypeButton })
                }} >
                    <Button key={"report-print"} className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Icon style={{ fontSize: 16 }} type={"printer"} theme="outlined" />
                    </Button>
                </Popover>
            </Tooltip >)
    }

    sendAPI = (script, data, sCall, eCall) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, eCall);
    };

    cancelDepositDoc = (record, checkNum) => {
        let dataSend = "_num\f_type\f_pos\f_checkNum\r"
            + record.DocumentNumber + "\f"
            + record.DocumentTypeId + "\f"
            + record.DocumentPOSID + "\f"
            + (checkNum ?? "")
        this.sendAPI("cancel_deposit_doc", dataSend, this.refreshData, e => { console.error(e) })
    }


    cancelSpecificCheckBtn = (text, record) => {
        return (<Button
            type={"primary"}
            size="small"
            onClick={() => {
                const { modalCancelDepositeChecks } = this.state;
                this.cancelDepositDoc(modalCancelDepositeChecks, record.CheckNumber);
                this.setState({ modalCancelDepositeChecks: null })
            }}
        >{this.getText(19541)}</Button>)
    }

    getReportShowBtn = (iconButton, DocumentNumber, DocumentTypeID, DocumentPOSID, DocIsOrigin, docIsWithSigns, type5RadioGroup, PrintFormat, textBtn, disabled, checkPrevPrint) => {
        let reportName = PrintFormat ? "DocumentCopyCentral" : "DocumentCopy";

        return (<ReportShowButton
            {...this.props}
            iconButton={iconButton}
            textBtn={textBtn}
            disabled={disabled}
            validSubmit
            confirmBeforePrint={
                DocumentTypeID == 5 ? {
                    title: this.getText(11183),
                    content: type5RadioGroup,
                    okText: this.getText(20092),
                } : null}
            params={{
                DocumentNumber,
                DocumentTypeID,
                DocumentPOSID,
                userID: this.props.user.userId,
                userName: encodeURIComponent(this.props.user.displayName),
                isOriginal: DocIsOrigin == '1',
                CentralFatherItem: this.state.centralFatherItem ? '1' : '0',
                PrintFormat,
                docIsWithSigns,
                reportName: "reports/" + reportName + ".pdf"
            }}
            checkPrevPrint={checkPrevPrint}
        />)
    }

    getType5RadioGroup = (value, onChange) => {
        return (<Radio.Group {...{ value, onChange }} style={{ direction: "rtl", textAlign: "right" }}>
            <Radio value={'0'}>{this.getText(18983)}</Radio><br />
            <Radio value={'8'}>{this.getText(20520)}</Radio><br />
            <Radio value={'1'}>{this.getText(18984)}</Radio><br />
            <Radio value={'6'}>{this.getText(19360)}</Radio><br />
            <Radio value={'2'}>{this.getText(18985)}</Radio><br />
            <Radio value={'3'}>{this.getText(18986)}</Radio><br />
            <Radio value={'5'}>{this.getText(19359)}</Radio><br />
            <Radio value={'7'}>{this.getText(19361)}</Radio><br />
            <Radio value={'4'}>{this.getText(19105)}</Radio>
        </Radio.Group>)
    }

    render() {

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };


        const dataSource = this.props.data[this.id]?.data.map((x, index) => {
            let y = x.split("\f");
            return {
                //key: y[0],
                key: y[0] + '#' + y[1] + '#' + y[2],
                DocumentNumber: y[0],
                DocumentTypeId: y[1],
                DocumentPOSID: y[2],
                DocumentTimestamp: y[3],
                DocumentEmployeeID: y[4],
                DocumentTotalWithTax: y[5],
                DocumentCustomerName: y[6],
                POSName: y[7],
                EmployeeName: y[8],
                //DocumentTypeName: y[9],
                DocTypeName: y[10],
                DocZNum: y[11],
                DocIsForInventory: y[12],
                StorageName: y[13],
                docIsMatrix: y[14],
                DocumentIsClosed: y[15],

                DocFormat: y[16],
                DocRef2: y[17],
                DocIsOrigin: y[18],
                DocumentIsCancelled: y[22],
                docIsWithSigns: y[23],
                customerPhone: y[24],
                CheckList: y[25],
                allocationType: y[26],
                reservedNumber: y[27],
                reservedNumberShort: parseInt(y[28]) ?? null,
                taxIdentity: y[29],
                custID: y[30],
                Remarks: y[31],

                index: index
            }
        }) ?? [];



        const formatter = new Intl.NumberFormat('he-IL', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });


        const render = t => (<div style={{ textAlign: "right" }}>{t}</div>)

        let mdsci = {}
        if (this.state.modalDigitalSignCheckInput) {
            mdsci = {
                DocumentNumber: this.state.modalDigitalSignCheckInput.slice(3, 15),
                DocumentTypeId: this.state.modalDigitalSignCheckInput.slice(15, 18),
                DocumentPOSID: this.state.modalDigitalSignCheckInput.slice(0, 3),
            }
        }


        let columns = [
            {
                title: this.getText(11169),
                dataIndex: "DocumentPOSID",
                key: "DocumentPOSID",
                sorter: true,
                width: '4%',
                render: (text, record) => render(<Tooltip title={record.POSName}>{text}</Tooltip>)
            }, {
                title: this.getText(11170),
                dataIndex: "StorageName",
                key: "StorageName",
                sorter: true,
                width: '9%',
                render,
            }, {
                title: this.getText(11175),
                dataIndex: "DocumentTimestamp",
                key: "DocumentTimestamp",
                sorter: true,
                width: '9%',
                render: t => (<div dir="ltr">{render(t)}</div>),
            }, {
                title: this.getText(19649),
                dataIndex: "custID",
                key: "custID",
                width: '5%',
                render,
            }, {
                title: this.getText(11171),
                dataIndex: "DocumentCustomerName",
                key: "DocumentCustomerName",
                sorter: true,
                width: '10%',
                render: (text, record) => render(text?.split('<CustUID>')[0])
            }, {
                title: this.getText(11172),
                //dataIndex: "DocumentTypeName",
                dataIndex: "DocTypeName",
                //key: "DocumentTypeName",
                key: "DocTypeName",
                sorter: true,
                width: '9%',
                render,
            }, {
                title: this.getText(11173),
                dataIndex: "DocumentNumber",
                key: "DocumentNumber",
                sorter: true,
                width: '8%',
                render: (text, record) => render(<Tooltip title={this.getText(11178) + " : " + record.DocZNum}>{text}</Tooltip>)
            }, {
                title: this.getText(11173) + ' ' + 2,
                dataIndex: "DocRef2",
                key: "DocRef2",
                sorter: true,
                width: '8%',
                render: (text, record) => render(<Tooltip title={this.getText(11178) + " : " + record.DocZNum}>{text}</Tooltip>)
            }, {
                title: this.getText(11174),
                dataIndex: "DocumentTotalWithTax",
                key: "DocumentTotalWithTax",
                sorter: true,
                width: '7%',
                render: (text, record) => render(parseFloat(text) ? formatter.format(text) : '0.00'),
            }, {
                title: this.getText(11176),
                dataIndex: "EmployeeName",
                key: "EmployeeName",
                sorter: true,
                width: '8%',
                render,
            }, {
                title: this.getText(11177),
                width: '23%',
                render: (text, record) => {
                    let PrintFormat = record['DocFormat'];
                    let reportName = PrintFormat ? "DocumentCopyCentral" : "DocumentCopy";
                    const handleClick = (type: String) => {
                        // switch (record['DocFormat']) {
                        //     case '0': reportName = "DocumentCopyCentral"; break;
                        //     case '1': reportName = "DocumentCopyCentralDetails"; break;
                        //     case '2': reportName = "DocumentCopyCentralPerCust"; break;
                        //     case '3': reportName = "DocumentCopyCentralPerCustDetails"; break;
                        //     case '4': reportName = "DocumentCopyCentralSum"; break;
                        // }
                        this.setReportParametersAndDo("PaymentsSearchReport", {
                            DocumentNumber: record['DocumentNumber'],
                            DocumentTypeID: record['DocumentTypeId'],
                            DocumentPOSID: record['DocumentPOSID'],
                            userID: this.props.user.userId,
                            userName: encodeURIComponent(this.props.user.displayName),
                            isOriginal: record['DocIsOrigin'] == '1',
                            CentralFatherItem: this.state.centralFatherItem ? '1' : '0',
                            PrintFormat,
                            docIsWithSigns: record.docIsWithSigns,
                            reportName: "reports/" + reportName + "." + type
                        }, () => {
                            this.generateReport.apply(this, ["PaymentsSearchReport"])
                            this.setState({ viewPopPrint: null })
                        })
                        //this.setReportParameters("PaymentsSearchReport", { reportName: "reports/paymentsSearch." + this.state.type, type: value });         
                    }

                    const type5RadioGroup = this.getType5RadioGroup(
                        record.DocFormat,
                        e => {
                            this.props.dataActions.setJson(this.id, {
                                data: this.props.data[this.id].data.map((x, i) => {
                                    if (i === record.index) {
                                        let y = x.split("\f");
                                        y[16] = e.target.value;
                                        return y.join("\f")
                                    } else {
                                        return x;
                                    }
                                })
                            })
                        })

                    const content = (<div>
                        <Tooltip title={this.getText(11179)}>
                            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                                type={"file-pdf"} onClick={() => { handleClick("pdf") }} />
                        </Tooltip>
                        {PrintFormat ?
                            <Tooltip title={this.getText(11180)}>
                                <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                                    type={"file-excel"} onClick={() => { handleClick("xls") }} />
                            </Tooltip>
                            :
                            <ReportExcelBtn
                                {...this.props}
                                tooltip={this.getText(11180)}
                                api={"get_doc_to_excel"}
                                dataSend={record}
                                reportName={"documentCopyXlsx"}
                            />
                        }
                        <Tooltip title={this.getText(11181)}>
                            <Icon style={{ fontSize: 32 }} className={"clickable margin-4"}
                                type={"file-word"} onClick={() => { handleClick("docx") }} />
                        </Tooltip>
                        {record.docIsMatrix && parseInt(record.docIsMatrix) > 0 ? [
                            <br />,
                            <Checkbox checked={this.state.centralFatherItem} onChange={e => { this.setState({ centralFatherItem: e.target.checked }) }}>{this.getText(17717)}</Checkbox>
                        ] : ""}
                        {record.DocumentTypeId == 5 ? [
                            <br />,
                            type5RadioGroup
                        ] : ""}
                    </div>);

                    let isClosed = record.DocumentIsClosed == 1 ? true : false;
                    let toClosed = [4, 8, 9, 15, 18, 19, 30].filter(f => record.DocumentTypeId == f).length ? true : false;

                    return (
                        <span>
                            <Tooltip title={this.getText(11182)}>
                                <Icon style={{ fontSize: 16, color: record.docIsMatrix && parseInt(record.docIsMatrix) > 0 ? '#000000' : '#aaaaaa' }} className={"clickable margin-4"}
                                    type={"table"} onClick={() => {
                                        if (record.docIsMatrix && parseInt(record.docIsMatrix) > 0) {
                                            this.setReportParametersAndDo("PaymentsSearchReport", {
                                                DocumentNumber: record['DocumentNumber'],
                                                DocumentTypeID: record['DocumentTypeId'],
                                                DocumentPOSID: record['DocumentPOSID'],
                                                userID: this.props.user.userId,
                                                userName: encodeURIComponent(this.props.user.displayName),
                                                isOriginal: false,
                                                reportName: "reports/DocumentCopyMatrix.pdf"
                                            }, () => { this.generateReport.apply(this, ["PaymentsSearchReport"]) })
                                        }
                                    }} />
                            </Tooltip>

                            <Tooltip title={this.getText(19767)}>
                                <Popover content={content} title={this.getText(11183)} trigger="click" visible={this.state.viewPopPrint === record.key}>
                                    <Icon
                                        className={"clickable margin-4"} style={{ fontSize: 16 }} type="printer" theme="outlined"
                                        onClick={() => { this.setState({ viewPopPrint: this.state.viewPopPrint === record.key ? null : record.key }) }}
                                    />
                                </Popover>
                            </Tooltip>

                            {this.getReportShowBtn(
                                true,
                                record.DocumentNumber,
                                record.DocumentTypeId,
                                record.DocumentPOSID,
                                record.DocIsOrigin,
                                record.docIsWithSigns,
                                type5RadioGroup,
                                PrintFormat
                            )}
                            {/* 
                            <ReportShowButton
                                {...this.props}
                                iconButton
                                validSubmit
                                confirmBeforePrint={
                                    record['DocumentTypeId'] == 5 ? {
                                        title: this.getText(11183),
                                        content: type5RadioGroup,
                                        // iconType: 'exclamation-circle',
                                        // cancelButtonProps: { style: { display: 'none' } },
                                        okText: this.getText(20092),
                                        // paramKey: "PrintFormat"
                                    } : null}
                                params={{
                                    DocumentNumber: record['DocumentNumber'],
                                    DocumentTypeID: record['DocumentTypeId'],
                                    DocumentPOSID: record['DocumentPOSID'],
                                    userID: this.props.user.userId,
                                    userName: encodeURIComponent(this.props.user.displayName),
                                    isOriginal: record['DocIsOrigin'] == '1',
                                    CentralFatherItem: this.state.centralFatherItem ? '1' : '0',
                                    PrintFormat,
                                    docIsWithSigns: record.docIsWithSigns,
                                    reportName: "reports/" + reportName + ".pdf"
                                }} /> */}

                            {/* <Tooltip title={this.getText(19768)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"file-pdf"} onClick={() => {
                                    let dataSend = "docNum\fdocType\fdocPos\fprintFormat\r"
                                        + record['DocumentNumber'] + "\f"
                                        + record['DocumentTypeId'] + "\f"
                                        + record['DocumentPOSID'] + "\f"
                                        + (record['DocFormat'] == '1' ? '1' : '0');
                                    this.sendAPI("get_document_report_link", dataSend, (ob) => {
                                        window.open(ob.data, "_blank")
                                    }, (e) => {
                                        message.error(e)
                                    })
                                }} />
                            </Tooltip> */}

                            <Tooltip title={this.getText(12787)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"share-alt"} onClick={() => {
                                    let dataSend = "docNum\fdocType\fdocPos\fprintFormat\r"
                                        + record['DocumentNumber'] + "\f"
                                        + record['DocumentTypeId'] + "\f"
                                        + record['DocumentPOSID'] + "\f"
                                        + (record['DocFormat'] == '1' ? '1' : '0');
                                    this.sendAPI("get_document_report_link", dataSend, (ob) => {
                                        this.props.uiActions.showModal(TableIDs.modalShareMessage, { dLink: ob.data, customerPhone: record['customerPhone'] })
                                    }, (e) => {
                                        message.error(e)
                                    })
                                }} />
                            </Tooltip>
                            <Tooltip title={this.getText([4, 15, 16, 17, 18, 20, 21, 53].find(f => f == record.DocumentTypeId) ? 20222 : 17756)}>
                                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"mail"} onClick={() => {
                                    this.openModalSendDocByCustSetting(record)
                                }} />
                            </Tooltip>
                            <Icon style={{ fontSize: 16, color: record.DocIsForInventory == 1 ? '#000000' : '#aaaaaa' }} className={"clickable margin-4"} type={"barcode"} onClick={() => {
                                if (record.DocIsForInventory == 1) {
                                    this.createLabels(record.DocumentNumber, record.DocumentTypeId, record.DocumentPOSID);
                                    this.props.uiActions.showModal(TableIDs.labelPrinter);
                                }
                            }} />
                            <Tooltip title={this.getText(13976)}>
                                {toClosed ?
                                    (<Popconfirm
                                        title={this.getText(isClosed ? 13973 : 13972)}
                                        onConfirm={() => {
                                            let data = "DocumentIsClosed\fDocumentNumber\fDocumentTypeId\fDocumentPOSID\r" +
                                                (isClosed ? "0" : "1") + "\f" + record.DocumentNumber + "\f" + record.DocumentTypeId + "\f" + record.DocumentPOSID;
                                            this.sendAPI("edit_doc_is_closed", data, this.refreshData, (e) => { message.error(e) })
                                        }}
                                        okText={this.getText(13974)}
                                        cancelText={this.getText(13975)}>
                                        <Icon
                                            style={{ fontSize: 16, color: isClosed ? '#f20e0e' : '#0cd119' }}
                                            className={"clickable margin-4"}
                                            type={isClosed ? "lock" : "unlock"} />
                                    </Popconfirm>)
                                    :
                                    (<Icon
                                        style={{ fontSize: 16, color: '#aaaaaa' }}
                                        className={"clickable margin-4"}
                                        type={isClosed ? "lock" : "unlock"} />)
                                }
                            </Tooltip>
                            <Tooltip title={this.getText(17083)}>
                                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="eye" theme="outlined" onClick={() => { this.setState({ modalLines: record }) }} />
                            </Tooltip>
                            <Tooltip title={this.getText(18439)}>
                                <Icon style={{ fontSize: 16, color: record.docIsWithSigns && parseInt(record.docIsWithSigns) > 0 ? '#000000' : '#aaaaaa' }} className={"clickable margin-4"}
                                    type={"audit"} onClick={() => {
                                        if (record.docIsWithSigns && parseInt(record.docIsWithSigns) > 0) {
                                            this.props.ActionQueue.addToQueue({
                                                action: this.props.dataActions.generateReport,
                                                args: [
                                                    this.props.user.companyCode,
                                                    this.props.user.token,
                                                    {
                                                        _docNum: record['DocumentNumber'],
                                                        _docType: record['DocumentTypeId'],
                                                        _docPos: record['DocumentPOSID'],
                                                        REPORT_LOCALE: this.getText(101),
                                                        reportName: "reports/DocumentCopySigns.pdf"
                                                    }
                                                ]
                                            });
                                        }
                                    }} />
                            </Tooltip>
                            {record.DocumentTypeId == 42 || record.DocumentTypeId == 43 ?
                                record.DocumentIsCancelled == '1' ?
                                    <Tooltip title={this.getText(19532)}>
                                        <Icon style={{ fontSize: 16, color: '#aaaaaa' }} className={"clickable margin-4"} type="rest" theme="outlined" />
                                    </Tooltip>
                                    : <Tooltip title={this.getText(19531)}>
                                        <Popconfirm title={this.getText(19720) + " " + record.DocTypeName + " " + this.getText(19721) + " " + record.DocumentTotalWithTax + "?"}
                                            okText={this.getText(19722)} cancelText={this.getText(19723)}
                                            onConfirm={() => { this.cancelDepositDoc(record) }}>
                                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type="rest" theme="outlined" />
                                        </Popconfirm>
                                    </Tooltip>
                                : ""}
                            {record.DocumentTypeId == 43 && record.CheckList && record.DocumentIsCancelled != '1' ?
                                <Tooltip title={this.getText(19533)}>
                                    <Icon
                                        style={{ fontSize: 16 }}
                                        className={"clickable margin-4"} type="rest" theme="outlined"
                                        onClick={() => { this.setState({ modalCancelDepositeChecks: record }) }}
                                    />
                                </Tooltip>
                                : ""}
                            {record.allocationType > 0 ?
                                <Tooltip title={this.getText(19625)}>
                                    {/* <Icon */}
                                    <GenericIcon
                                        style={{ fontSize: 16 }}
                                        className={"clickable margin-4"}
                                        // type="audit" 
                                        utf8Code={128290}
                                        theme="outlined"
                                        onClick={() => {
                                            this.setState({
                                                modalReservedNumber: record,
                                                modalReservedNumberEdit: record.allocationType == 3,//!record.reservedNumber,
                                                // modalReservedNumberEditCustTaxId: !parseInt(record.taxIdentity),
                                            }, () => {
                                                if (record.reservedNumber && record.reservedNumber.slice(-9) != record.reservedNumberShort) {
                                                    const { DocumentNumber, DocumentTypeId, DocumentPOSID } = record
                                                    this.sendAPI(
                                                        "update_reserved_number",
                                                        "_num\f_type\f_pos\r" + DocumentNumber + "\f" + DocumentTypeId + "\f" + DocumentPOSID,
                                                        this.refreshData,
                                                        e => { console.error(e) })
                                                }
                                            })
                                        }}
                                    />
                                </Tooltip>
                                : ""}


                        </span>
                    )
                }
            }
        ];

        let columnsView = this.state.userColumnsList ? [...this.state.userColumnsList.map(x => columns[x - 1]), columns.slice(-1)[0]] : columns


        let actionButtons = [
            {
                buttonType: 'filter',
                buttonFunction: () => { this.props.uiActions.showModal(TableIDs.modalAdvanceSearchReports) },
            }, {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            },
        ];

        let primaryTable = (<ResponsiveTable
            tableOnly={true}
            percentageToMobile={[30, 35, 35]}

            dataSource={dataSource}
            columns={columnsView}
            // loading={this.props.ui.workInProgress}
            //scroll={{ x: 1650 }}
            bordered
            pagination={this.props.data[this.id]?.pagination}
            onChange={this.handleTableChange}
            ui={this.props.ui}
            uiActions={this.props.uiActions}

        // locale={{ filterConfirm: "אישור", filterReset: "איפוס", emptyText: "לא נמצאו נתונים" }}
        />)



        let modals = [
            <ModalAdvanceSearchReports
                id={TableIDs.modalAdvanceSearchReports}
                user={this.props.user}
                uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalAdvanceSearchReports)}
            />,
            <ModalShareMessage
                id={TableIDs.modalShareMessage}
                user={this.props.user}
                uiActions={this.props.uiActions} ui={this.props.ui} ActionQueue={this.props.ActionQueue}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalShareMessage)}
            />,
            // <LabelPrinter
            //     id={TableIDs.labelPrinter}
            //     labels={this.responsLabels()}
            //     labelsA4Params={this.state.labelsA4Params}
            //     qtyInput={false}
            //     user={this.props.user}
            //     uiActions={this.props.uiActions} ui={this.props.ui}
            //     dataActions={this.props.dataActions} data={this.props.data}
            //     ActionQueue={this.props.ActionQueue}
            //     toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
            //     title={this.getText(11186)}
            // />,
            <LabelPrinter {...this.props}
                id={TableIDs.labelPrinter}
                toggle={this.props.ui.modalStack.includes(TableIDs.labelPrinter)}
                title={this.getText(11186)}
                labels={this.props.data[this.labelsId]?.dataset}
                onChangeLabels={dataset => { this.props.dataActions.setJson(this.labelsId, { dataset }) }}
                deliveriesFields={this.props.data[this.labelsId]?.deliveriesDataset}
                onChangeDeliveriesFields={deliveriesDataset => { this.props.dataActions.setJson(this.labelsId, { deliveriesDataset }) }}
            />,
            <ModalDocumentLines
                user={this.props.user}
                visible={this.state.modalLines !== null ? true : false}
                onCancel={() => { this.setState({ modalLines: null }, this.refreshData) }}
                DocumentNumber={this.state.modalLines ? this.state.modalLines.DocumentNumber : 0}
                DocumentPOSID={this.state.modalLines ? this.state.modalLines.DocumentPOSID : 0}
                DocumentTypeId={this.state.modalLines ? this.state.modalLines.DocumentTypeId : 0}
                DocumentIsClosed={this.state.modalLines ? this.state.modalLines.DocumentIsClosed : 0}
                docTitles={this.state.modalLines ?
                    // columns.slice(0, -1).map(x => { return { label: x.title, value: x.render(this.state.modalLines[x.dataIndex], this.state.modalLines) } })
                    [
                        { label: this.getText(11175), value: (<span dir="ltr">{this.state.modalLines.DocumentTimestamp}</span>) },
                        { label: this.getText(11173), value: this.state.modalLines.DocumentNumber },
                        { label: this.getText(11173) + " 2", value: this.state.modalLines.DocRef2 },
                        { label: this.getText(20168), value: this.state.modalLines.custID + " " + this.state.modalLines.DocumentCustomerName },
                        { label: this.getText(11172), value: this.state.modalLines.DocTypeName },
                        { label: this.getText(11174), value: formatter.format(this.state.modalLines.DocumentTotalWithTax ?? 0) },
                        { label: this.getText(11169), value: this.state.modalLines.DocumentPOSID },
                        { label: this.getText(11170), value: this.state.modalLines.StorageName },
                        { label: this.getText(11176), value: this.state.modalLines.EmployeeName },
                        { label: this.getText(20643), value: this.state.modalLines.Remarks },
                    ]
                    : []}
                onEditTotal={total => { this.setState({ modalLines: { ...this.state.modalLines, DocumentTotalWithTax: total } }) }}
            />,
            <GenericModal
                title={this.getText([4, 15, 16, 17, 18, 20, 21, 53].find(f => f == this.state.modalSendDocByCustSetting?.params?.DocumentTypeID) ? 20222 : 17761)}
                width={600}
                visible={this.state.modalSendDocByCustSetting && this.state.modalSendDocByCustSetting.params}
                onCancel={() => { this.setState({ modalSendDocByCustSetting: null }) }}
                footer={[
                    <Button
                        type="primary" onClick={this.sendReportByCustSetting}
                        loading={this.state.sendDocByCustLoading}
                        disabled={!this.state.modalSendDocByCustSetting || !this.state.modalSendDocByCustSetting.targetType || !this.state.modalSendDocByCustSetting.target}
                    >{this.getText(17763)}</Button>,
                    <Button onClick={() => { this.setState({ modalSendDocByCustSetting: null }) }}>{this.getText(17764)}</Button>
                ]}>
                <Form.Item label={this.getText(18193)}>
                    {this.state.modalSendDocByCustSetting && this.state.modalSendDocByCustSetting.params ?
                        this.state.modalSendDocByCustSetting.targetCheckable ?
                            (<Select
                                style={{ width: '100%' }}
                                value={this.state.modalSendDocByCustSetting.targetType}
                                onChange={e => {
                                    this.setState({
                                        modalSendDocByCustSetting: {
                                            ...this.state.modalSendDocByCustSetting,
                                            targetType: e,
                                            target: this.state.modalSendDocByCustSetting[e == '2' ? 'target1' : 'target2'],
                                        }
                                    })
                                }}
                            >
                                <Select.Option dir="rtl" value={'2'}>{this.getText(18194)}</Select.Option>
                                <Select.Option dir="rtl" value={'3'}>{this.getText(18195)}</Select.Option>
                            </Select>)
                            : (<h3>{this.getText(this.state.modalSendDocByCustSetting.targetType == '2' ? 18194 : 18195)}</h3>)
                        : ""}
                </Form.Item>
                <Form.Item label={this.getText(17762)}>
                    <Input
                        style={{ width: this.state.modalSendDocByCustSetting?.targetType == 3 ? "90%" : "100%" }}
                        value={this.state.modalSendDocByCustSetting ? this.state.modalSendDocByCustSetting.target : ""}
                        onChange={e => { this.setState({ modalSendDocByCustSetting: { ...this.state.modalSendDocByCustSetting, target: e.target.value } }) }}
                    />
                    {this.state.modalSendDocByCustSetting?.targetType == 3 ?
                        <Tooltip title={this.getText(20282)}>
                            <Button onClick={() => {
                                this.setState({
                                    modalContacts: {
                                        code: this.state.modalSendDocByCustSetting?.custID,
                                        ownerType: [4, 15, 16, 17, 18, 20, 21, 53].find(f => f == this.state.modalSendDocByCustSetting?.params?.DocumentTypeID) ? '1' : '0'
                                    }
                                })
                            }}><Icon type="table" /></Button>
                        </Tooltip>
                        : ""}
                </Form.Item>
            </GenericModal>,
            <ModalContacts
                {...this.props}
                visible={this.state.modalContacts?.code ?? null}
                onCancel={() => { this.setState({ modalContacts: null }) }}
                title={this.getText(20282)}
                ownerType={this.state.modalContacts?.ownerType}
                onCheckMail={(e) => {
                    this.setState({
                        modalSendDocByCustSetting: { ...this.state.modalSendDocByCustSetting, target: e },
                        modalContacts: null
                    })
                }}
            />,
            <GenericModal
                visible={this.state.modalCancelDepositeChecks != null}
                onCancel={() => { this.setState({ modalCancelDepositeChecks: null }) }}
                title={this.getText(19534)}
                width={1000}
            >
                <ResponsiveTable
                    tableOnly
                    dataSource={this.state.modalCancelDepositeChecks ? JSON.parse(this.state.modalCancelDepositeChecks.CheckList) : []}
                    columns={[
                        { title: this.getText(19535), key: "CheckNumber", dataIndex: "CheckNumber", with: "20%" },
                        { title: this.getText(19536), key: "BankNumber", dataIndex: "BankNumber", with: "10%" },
                        { title: this.getText(19537), key: "BranchNumber", dataIndex: "BranchNumber", with: "10%" },
                        { title: this.getText(19538), key: "AccountNumber", dataIndex: "AccountNumber", with: "20%" },
                        { title: this.getText(19539), key: "Sum", dataIndex: "Sum", with: "15%" },
                        { title: this.getText(19540), key: "ValueDate", dataIndex: "ValueDate", with: "15%" },
                        { title: "", with: "10%", render: this.cancelSpecificCheckBtn },
                    ]}
                    pagination={{ pageSize: 10 }}
                />
            </GenericModal>,
            <GenericModal
                key={"reserved_number_modal"}
                visible={this.state.modalReservedNumber != null}
                onCancel={() => { this.setState({ modalReservedNumber: null, modalReservedNumberEdit: false, modalReservedNumberEditCustTaxId: false }) }}
                title={this.getText(19569)}
                width={500}
            >
                <div style={{ textAlign: "center" }}>
                    <h3>{this.state.modalReservedNumber?.DocTypeName}: {this.state.modalReservedNumber?.DocumentNumber}</h3>

                    {this.state.modalReservedNumberEdit ?
                        <span>
                            <Form.Item label={this.getText(19571)}>
                                <Input key={"reserved_number_input"}
                                    value={this.state.modalReservedNumber?.reservedNumberShort}
                                    onChange={e => {
                                        let regexp = new RegExp(/^[0-9]{1,9}$/);
                                        let reservedNumberShort = e.target.value;
                                        if (regexp.test(reservedNumberShort)) this.setState({ modalReservedNumber: { ...this.state.modalReservedNumber, reservedNumberShort } })
                                    }}
                                />
                            </Form.Item>
                            <Button
                                key={"reserved_number_save_btn"}
                                type="primary"
                                style={{ margin: 5 }}
                                onClick={() => {
                                    const { DocumentNumber, DocumentTypeId, DocumentPOSID, reservedNumberShort, allocationType } = this.state.modalReservedNumber ?? {}
                                    let dataSend = "_num\f_type\f_pos\f_value\r" + DocumentNumber + "\f" + DocumentTypeId + "\f" + DocumentPOSID + "\f" + reservedNumberShort;
                                    this.sendAPI("save_reserved_number", dataSend, () => {
                                        let newState = { modalReservedNumberEdit: false }
                                        if (allocationType == 3) newState = { ...newState, modalReservedNumber: null }
                                        this.setState(newState, () => {
                                            message.success(this.getText(19626))
                                            this.refreshData()
                                        })
                                    }, e => { console.error(e) })
                                }}>{this.getText(19628)}</Button>
                            <Button
                                key={"reserved_number_cancel_btn"}
                                style={{ margin: 5 }}
                                onClick={() => {
                                    this.setState({ modalReservedNumber: null, modalReservedNumberEdit: false })
                                }}>{this.getText(20065)}</Button>
                        </span>
                        : this.state.modalReservedNumber?.reservedNumber ?
                            <span>
                                <h3>{this.getText(20066)}: {this.state.modalReservedNumber?.taxIdentity}</h3>
                                <h3>{this.getText(20068)}: {this.state.modalReservedNumber?.reservedNumberShort}</h3>
                                <h3>{this.getText(20067)}: {this.state.modalReservedNumber?.reservedNumber}</h3>
                            </span>
                            :
                            <span>
                                {this.state.modalReservedNumber?.reservedNumberShort ? <h3>{this.getText(20072)}: {this.state.modalReservedNumber?.reservedNumberShort}</h3> : ""}
                                <h2>{this.getText(this.state.modalReservedNumber?.reservedNumberShort ? 20070 : 20069)}</h2>
                                <Button
                                    key={"reserved_number_edit_btn"}
                                    type="primary"
                                    style={{ margin: 5 }}
                                    onClick={() => { this.setState({ modalReservedNumberEdit: true }) }}>{this.getText(19627)}</Button>
                                <Button
                                    key={"reserved_number_api_btn"}
                                    type="primary"
                                    style={{ margin: 5 }}
                                    onClick={() => {
                                        const { DocumentNumber, DocumentTypeId, DocumentPOSID } = this.state.modalReservedNumber ?? {}
                                        let dataSend = "docNumber\fdocType\fdocPos\r"
                                            + DocumentNumber + "\f"
                                            + DocumentTypeId + "\f"
                                            + DocumentPOSID;
                                        this.sendAPI("invoice_il_approval_and_add_to_doc", dataSend, (ob) => {
                                            if (ob.errorMessage) {
                                                Modal.confirm({
                                                    content: ob.errorMessage,
                                                    iconType: 'close',
                                                    okButtonProps: { style: { display: 'none' } },
                                                    cancelText: this.getText(20064),
                                                    onOk() { },
                                                    onCancel() { },
                                                })
                                                // message.error(ob.errorMessage)
                                            } else if (ob.data) {
                                                message.success(this.getText(20071))
                                                this.refreshData()
                                            }
                                        }, e => { console.error(e) })
                                    }}>{this.getText(20029)}</Button>
                                <Button
                                    key={"reserved_number_cancel_btn"}
                                    style={{ margin: 5 }}
                                    onClick={() => {
                                        this.setState({ modalReservedNumber: null, modalReservedNumberEdit: false })
                                    }}>{this.getText(20065)}</Button>
                            </span>
                    }
                </div>
            </GenericModal>,
            <GenericModal
                visible={this.state.modalDigitalSignCheck}
                onCancel={() => { this.setState({ modalDigitalSignCheck: false }) }}
                title={this.getText(20412)}
                width={400}
                footer={[
                    this.getReportShowBtn(
                        false,
                        mdsci?.DocumentNumber,
                        mdsci?.DocumentTypeId,
                        mdsci?.DocumentPOSID,
                        false,
                        '0',
                        this.getType5RadioGroup(this.state.modalDigitalSignCheckPrintFormat, e => { this.setState({ modalDigitalSignCheckPrintFormat: e.target.value }) }),
                        this.state.modalDigitalSignCheckPrintFormat,
                        this.getText(20414),
                        this.state.modalDigitalSignCheckInput?.length != 18,
                        true
                    ),
                    <Button onClick={() => { this.setState({ modalDigitalSignCheck: false }) }}>{this.getText(20415)}</Button>
                ]}
            >
                <h2>{this.getText(20413)}</h2>
                <Input value={this.state.modalDigitalSignCheckInput} onChange={e => { this.setState({ modalDigitalSignCheckInput: e.target.value }) }} />
            </GenericModal>
        ]

        let genericActionButtons = [
            this.reportButton(),
            <UsersTableColumns {...this.props}
                title={this.getText(20401)}
                table={"Documents"}
                viewList={this.state.userColumnsList}
                setViewList={v => { this.setState({ userColumnsList: v }) }}
                list={columns.slice(0, -1).map((x, i) => { return { key: i + 1, name: x.title } })}
            />,
            <Tooltip title={this.getText(20411)}>
                <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={() => { this.setState({ modalDigitalSignCheck: true }) }}>
                    <Icon type={"lock"} style={{ fontSize: "16px" }} theme={"filled"} />
                </Button>
            </Tooltip>
        ]

        if (this.props.isModalFromDashboard) {
            return (<GenericModal
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                title={this.getText(11168)}
                actionButtons={actionButtons}
                genericActionButtons={genericActionButtons}
            >{primaryTable}{modals}</GenericModal>)
        } else {
            let searchColFilterList = [
                { id: '4', text: this.getText(19448) },
                { id: '6', text: this.getText(19719) },
                { id: '1', text: this.getText(18814) },
                { id: '2', text: this.getText(18815) },
                { id: '3', text: this.getText(18816) },
                { id: '5', text: this.getText(19461) },
            ]
            return (
                <div style={divStyle} >
                    <Card title={(<PageToolbar
                        title={this.getText(11168)}
                        // showsearch={false} 
                        actionButtons={actionButtons}
                        genericActionButtons={genericActionButtons}
                        showsearch={(val) => {
                            if (!this.props.ui.workInProgress) {
                                this.props.dataActions.genericDataSetFilter(
                                    this.id, this.props.user.companyCode, this.props.user.token, {
                                    ...this.props.data[this.id].filters,
                                    page: 1,
                                    _page_num: 1,
                                    _words: escapeRegExp(val),
                                    searchColFilter: this.state.PaymentsSearch.searchColFilter
                                }, this.tableApi.get)
                            }
                        }}
                        showsearchWidth={300}
                        showsearchSuffix={(<Tooltip title={this.getText(18812)} >
                            <Popover trigger="click" title={this.getText(18812)} placement="bottomLeft" content={(
                                <Radio.Group
                                    style={{ textAlign: 'right', direction: "rtl" }}
                                    value={this.state.PaymentsSearch.searchColFilter}
                                    onChange={e => { this.setState({ PaymentsSearch: { ...this.state.PaymentsSearch, searchColFilter: e.target.value } }) }}>
                                    {searchColFilterList.map(x => [<Radio value={x.id}>{x.text}</Radio>, <br />])}
                                    <br />
                                    <Button type="primary" onClick={() => {
                                        if (this.props.data.USER_GP_VALUES.GP_DocsFilterOption != this.state.PaymentsSearch.searchColFilter) {
                                            this.sendAPI("set_gp_by_user", "_key\f_value\rGP_DocsFilterOption\f" + this.state.PaymentsSearch.searchColFilter, () => {
                                                message.success(this.getText(19460))
                                            })
                                        }
                                    }}>{this.getText(19459)}</Button>
                                </Radio.Group>)}>
                                <Icon type="filter" style={{ cursor: 'pointer', marginLeft: 10 }} />
                            </Popover>
                        </Tooltip>)}
                        showsearchPlaceholder={this.getText(19454) + " " + (searchColFilterList.find(f => f.id == this.state.PaymentsSearch.searchColFilter)?.text ?? "")}
                        valueSearch={this.state.valueSearch}
                        onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                        showRangeDates
                        showRangeDatesPlaceholder={this.state.valueRangeDates + " " + this.getText(19453)}
                        showRangeDatesSuffix={(<Tooltip title={this.getText(19449)} >
                            <Popover trigger="click" title={this.getText(19449)} placement="bottomLeft" content={(
                                <Radio.Group
                                    style={{ textAlign: 'right', direction: "rtl" }}
                                    value={this.state.valueRangeDates}
                                    onChange={e => {
                                        this.setState({ valueRangeDates: e.target.value }, () => {
                                            this.props.dataActions.genericDataSetFilter(
                                                this.id, this.props.user.companyCode, this.props.user.token, {
                                                ...this.props.data[this.id].filters,
                                                page: 1,
                                                _page_num: 1,
                                                mLowerSelectionDate: moment().add((e.target.value * -1), 'days').format("YYYY-MM-DD"),
                                                mLowerSelectionTime: '00:00:00',
                                                mUpperSelectionDate: moment().format("YYYY-MM-DD"),
                                                mUpperSelectionTime: '23:59:59',
                                            }, this.tableApi.get)
                                        })
                                    }}>
                                    {/* <Radio value="0">{this.getText(18813)}</Radio><br /> */}
                                    <Radio value={30}>{this.getText(19450)}</Radio><br />
                                    <Radio value={90}>{this.getText(19451)}</Radio><br />
                                    <Radio value={365}>{this.getText(19452)}</Radio><br />
                                    <br />
                                    <Button type="primary" onClick={() => {
                                        if (this.props.data.USER_GP_VALUES.GP_DocsDateRangeOption != this.state.valueRangeDates) {
                                            this.sendAPI("set_gp_by_user", "_key\f_value\rGP_DocsDateRangeOption\f" + this.state.valueRangeDates, () => {
                                                message.success(this.getText(19460))
                                            })
                                        }
                                    }}>{this.getText(19459)}</Button>
                                </Radio.Group>)}>
                                <Icon type="calendar" style={{ cursor: 'pointer', marginLeft: 10 }} />
                            </Popover>
                        </Tooltip>)}
                    />)}
                        bordered={true} style={{ width: '100%' }}
                        headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}
                    >{primaryTable}</Card>
                    {modals}
                </div >
            );
        }
    }
}
export default PaymentsSearchReport;
