/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import GenericTable from "../../components/GenericTable";
import { Form, Icon } from "antd";
import moment from "moment";
import TableLineIcons from "../../components/TableLineIcons";
import GenericModal from "../../components/GenericModal";
import { Button } from "antd/lib/radio";
import ServiceCallsTable from "./ServiceCallsTable";


class Services extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalView: null,
            modalCalls: null,
        };

        this.id = "SERVICES_TABLE";

        this.api = {
            get: "get_services_table",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20737);
    }

    renderItemName = (text, record) => {
        return (<>
            <p>{text}</p>
            {record.sDesc && record.sDesc != text ? record.sDesc : ""}
        </>)
    }

    renderIsService = (text, record) => {
        const fromDate = moment(record.sFromDate)
        const toDate = moment(record.sToDate)
        const isService = moment().isBetween(fromDate, toDate, undefined, []) && record.sIsActive == 1
        return (<Icon
            type={isService ? "check-circle" : "close-circle"}
            style={{ color: isService ? "green" : "red" }}
        />)
    }

    renderActions = (text, record) => {
        return (<TableLineIcons
            genericIcons={[
                { type: "eye", onClick: () => { this.setState({ modalView: record }) }, tooltip: this.getText(20751) },
                { type: "table", onClick: () => { this.setState({ modalCalls: record }) }, tooltip: this.getText(20752) },
            ]}
        />)
    }


    render() {
        const gt = this.getText;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const render = t => t
        const renderDatetime = d => d ? moment(d).format("DD/MM/YYYY HH:mm") : ""

        const sorter = true

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                id={this.id}
                getApi={this.api.get}
                columns={[
                    { title: '#', key: "sID", dataIndex: "sID", width: "5%", sorter, render },
                    { title: gt(20740), key: "sCustomerID", dataIndex: "sCustomerID", width: "7%", sorter, render },
                    { title: gt(20741), key: "sCustomerName", dataIndex: "sCustomerName", width: "14%", sorter, render },
                    { title: gt(20742), key: "sBranchName", dataIndex: "sBranchName", width: "14%", sorter, render },
                    { title: gt(20743), key: "sItemCode", dataIndex: "sItemCode", width: "7%", sorter, render },
                    { title: gt(20744), key: "sItemName", dataIndex: "sItemName", width: "11%", sorter, render: this.renderItemName },
                    { title: gt(20745), key: "sItemSN", dataIndex: "sItemSN", width: "9%", sorter, render },
                    { title: gt(20746), key: "sQty", dataIndex: "sQty", width: "7%", sorter, render },
                    { title: gt(20747), key: "sFromDate", dataIndex: "sFromDate", width: "8%", sorter, render },
                    { title: gt(20748), key: "sToDate", dataIndex: "sToDate", width: "8%", sorter, render },
                    { title: gt(20749), width: "5%", render: this.renderIsService },
                    { title: gt(20750), width: "5%", render: this.renderActions },
                ]}
                title={gt(20739)}
                filters={{_custID: this.props.filterCust ? this.props.filterCust : null}}
            />
            <GenericModal
                visible={this.state.modalView != null}
                onCancel={() => { this.setState({ modalView: null }) }}
                title={`${gt(20753)} ${this.state.modalView?.sID}`}
                width={1000}
                footer={[<Button onClick={() => { this.setState({ modalView: null }) }}>{gt(20754)}</Button>]}
            >
                <Form
                    style={{ columnCount: 2, columnGap: 70 }}
                    labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                >
                    <Form.Item label={gt(20755)}>{renderDatetime(this.state.modalView?.sOpenDatetime)}</Form.Item>
                    <Form.Item label={gt(20756)}>{this.state.modalView?.sOpenUserName}</Form.Item>
                    <Form.Item label={gt(20757)}>{renderDatetime(this.state.modalView?.sCloseDatetime)}</Form.Item>
                    <Form.Item label={gt(20758)}>{this.state.modalView?.sCloseUserName}</Form.Item>
                    <Form.Item label={gt(20759)}>{this.state.modalView?.sDocumentNumber}</Form.Item>
                    <Form.Item label={gt(20760)}>{this.state.modalView?.sDocumentTypeName}</Form.Item>
                    <Form.Item label={gt(20761)}>{this.state.modalView?.sDocumentPOSName}</Form.Item>
                    <Form.Item label={gt(20762)}>{this.state.modalView?.sTypeDesc}</Form.Item>
                    <Form.Item label={gt(20765)}>{this.state.modalView?.sRemark}</Form.Item>
                    <Form.Item label={gt(20763)}>{gt(this.state.modalView?.sIsActive == 1 ? 20766 : 20767)}</Form.Item>
                    {this.state.modalView?.sCloseDatetime ? <p style={{ color: "red" }}>{gt(20764)}</p> : ""}
                </Form>
            </GenericModal>
            <GenericModal
                visible={this.state.modalCalls != null}
                onCancel={() => { this.setState({ modalCalls: null }) }}
                title={`${gt(20768)} ${this.state.modalCalls?.sCustomerName} ${gt(20769)} ${this.state.modalCalls?.sItemName}`}
            >
                <ServiceCallsTable {...this.props} filters={{ _custId: this.state.modalCalls?.sCustomerID, _itemCode: this.state.modalCalls?.sItemCode }} />
            </GenericModal>
        </div>)
    }

}

export default Services;