import React, { Component } from "react";
import { Modal, Button } from "antd";
import GenericModal from "./GenericModal";

class MapPolygonModal extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.polygonCoordinates = [];
    this.isDrawing = false;
    this.markers = [];
    this.lines = [];
    this.polygon = null;
    this.state = {
      pointCount: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      setTimeout(() => {
        if (this.map) this.removeMap();
        this.initializeMap();
      }, 100);
    }
  }

  removeMap = () => {
    if (this.map) {
      this.map.off();
      this.map.remove();
      this.map = null;
    }
  };

  loadPolygon = (coordinatesString) => {
    if (!coordinatesString) return;

    const L = window.L;
    const coordinates = coordinatesString.split(", ").map(coord => {
      const [lng, lat] = coord.split(" ").map(Number);
      return L.latLng(lat, lng);
    });

    if (coordinates.length > 2) {
      this.polygonCoordinates = coordinates;

      // אם יש פוליגון קודם, נמחק אותו
      if (this.polygon) {
        this.polygon.remove();
      }

      // מחיקת כל הסימונים הקודמים
      this.markers.forEach(marker => marker.remove());
      this.markers = [];

      // יצירת הפוליגון מחדש
      this.polygon = L.polygon(this.polygonCoordinates, {
        color: "green",
        fillColor: "green",
        fillOpacity: 0.4,
      }).addTo(this.map);

      // יצירת סימונים על הנקודות
      this.polygonCoordinates.forEach((coord) => {
        const marker = L.circleMarker(coord, { radius: 5, color: "red" }).addTo(this.map);
        this.markers.push(marker);
      });
    }
  };

  initializeMap = () => {
    const L = window.L;
    const { defLat, defLng } = this.props
    this.map = L.map("map").setView([defLat, defLng], 13);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(this.map);
    this.polygonCoordinates = [];
    this.map.on("click", this.onMapClick);
    if (this.props.initialCoordinates) {
      this.loadPolygon(this.props.initialCoordinates);
    }
  };

  onMapClick = (e) => {
    if (!this.isDrawing || !this.map) return;

    this.polygonCoordinates.push(e.latlng);
    const L = window.L;

    // יצירת נקודה חדשה
    const marker = L.circleMarker(e.latlng, { radius: 5, color: "red" }).addTo(this.map);
    this.markers.push(marker);

    // חיבור הקווים בין נקודות
    if (this.polygonCoordinates.length > 1) {
      const line = L.polyline([this.polygonCoordinates[this.polygonCoordinates.length - 2], e.latlng], {
        color: "blue",
      }).addTo(this.map);
      this.lines.push(line);
    }

    this.setState({ pointCount: this.polygonCoordinates.length });
  };


  startDrawing = () => {
    if (this.polygon) {
      this.polygon.remove();
      this.polygon = null;
    }

    // מחיקת כל הנקודות הישנות
    this.markers.forEach((m) => m.remove());
    this.lines.forEach((l) => l.remove());

    this.polygonCoordinates = [];
    this.markers = [];
    this.lines = [];
    this.isDrawing = true;
    this.setState({ pointCount: 0 });
  };


  closePolygon = () => {
    if (this.polygonCoordinates.length > 2) {
      this.polygonCoordinates.push(this.polygonCoordinates[0]);
      if (this.polygon) this.polygon.remove();
      const L = window.L;
      this.polygon = L.polygon(this.polygonCoordinates, {
        color: "green",
        fillColor: "green",
        fillOpacity: 0.4,
      }).addTo(this.map);

      const coordsString = this.polygonCoordinates
        .map(coord => `${coord.lng.toFixed(6)} ${coord.lat.toFixed(6)}`)
        .join(", ");

      // navigator.clipboard.writeText(coordsString);
      this.props.onSave(coordsString);
      this.props.onClose();
    }
  };

  backDrawing = () => {
    if (this.polygonCoordinates.length < 2) return; // אם אין מספיק נקודות, לא עושים כלום
  
    const L = window.L;
    const lastMarker = this.markers.pop();
    if (lastMarker) lastMarker.remove();
  
    this.polygonCoordinates.pop();
  
    // בדיקה אם הפוליגון היה סגור (אם הנקודה הראשונה והאחרונה זהות)
    const wasClosed =
      this.polygonCoordinates.length > 1 &&
      this.polygonCoordinates[0].lat === this.polygonCoordinates[this.polygonCoordinates.length - 1].lat &&
      this.polygonCoordinates[0].lng === this.polygonCoordinates[this.polygonCoordinates.length - 1].lng;
  
    if (wasClosed) {
      this.polygonCoordinates.pop(); // מסירים את נקודת הסגירה (שהייתה חזרה לראשונה)
    }
  
    // מחיקת הפוליגון עצמו אם היה קיים
    if (this.polygon) {
      this.polygon.remove();
      this.polygon = null;
    }
  
    // מחיקת הקו האחרון בלבד (לא את כל הקווים)
    if (this.lines.length > 0) {
      const lastLine = this.lines.pop();
      if (lastLine) lastLine.remove();
    }
  
    // שיחזור הקווים הקיימים בין שאר הנקודות
    this.lines.forEach(line => line.remove());
    this.lines = [];
  
    if (this.polygonCoordinates.length > 1) {
      for (let i = 0; i < this.polygonCoordinates.length - 1; i++) {
        const line = L.polyline([this.polygonCoordinates[i], this.polygonCoordinates[i + 1]], {
          color: "blue",
        }).addTo(this.map);
        this.lines.push(line);
      }
    }
  
    // פתיחת האפשרות להוספת נקודות מחדש
    this.isDrawing = true;
  
    this.setState({ pointCount: this.polygonCoordinates.length });
  };
  

  render() {
    return (
      <GenericModal
        title="בחר אזור על המפה"
        visible={this.props.isOpen}
        onCancel={this.props.onClose}
        footer={[
          <Button key="start" onClick={this.startDrawing}>
            התחל
          </Button>,
          <Button key="back" onClick={this.backDrawing}>
            חזור
          </Button>,
          <Button key="close" onClick={this.closePolygon} disabled={this.state.pointCount < 3}>
            סגור פוליגון
          </Button>,
        ]}
      >
        <div id="map" style={{ height: "600px" }}></div>
      </GenericModal>
    );
  }
}

class PolygonField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      coordinates: props.value || "",
    };
    this.miniMap = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ coordinates: this.props.value }, this.updateMiniMap);
    }
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  updateMiniMap = () => {
    if (!this.miniMap) return;
    this.miniMap.eachLayer(layer => {
      if (layer instanceof window.L.Polygon) {
        this.miniMap.removeLayer(layer);
      }
    });

    const L = window.L;
    const coordinates = this.state.coordinates.split(", ").map(coord => {
      const [lng, lat] = coord.split(" ").map(Number);
      return L.latLng(lat, lng);
    });

    if (coordinates.length > 2) {
      const polygon = L.polygon(coordinates, { color: "green", fillOpacity: 0.4 }).addTo(this.miniMap);
      this.miniMap.fitBounds(polygon.getBounds());
    }
  };

  initializeMiniMap = () => {
    if (this.miniMap) return;
    const { defLat, defLng } = this.props
    this.miniMap = window.L.map("mini-map", {
      zoomControl: false,
      dragging: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      touchZoom: false,
    }).setView([defLat, defLng], 12);

    window.L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(this.miniMap);
    this.updateMiniMap();
  };

  componentDidMount() {
    setTimeout(this.initializeMiniMap, 500);
  }

  render() {
    return (
      <div>
        <div id="mini-map" style={{ width: "200px", height: "200px", border: "1px solid #ddd", cursor: "pointer" }} onClick={this.openModal}></div>
        <MapPolygonModal
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          onSave={this.props.onChange}
          initialCoordinates={this.state.coordinates}
          defLat={this.props.defLat}
          defLng={this.props.defLng}
        />
      </div>
    );
  }
}

export default PolygonField;
