/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";

import "../../App.css";
import { Switch, Card, Form, Select, Checkbox, InputNumber, Input, Col } from "antd";
import Jax from "../../Jax/jax.es6.module";
import TableIDs from "../../data/TableIDs";
import { DatePicker } from 'antd';
import moment from "moment";
import CustomerPicker from "../../components/CustomerPicker";
import PageToolbar from "../../components/PageToolbar";
import LangContext from "../../contextProvider/LangContext";
import ReportTypeButton from "../../components/ReportTypeButton";
import TagRelationRadio from "../../components/TagRelationRadio";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import GenericSelectorCustTags from "../../components/GenericSelectorCustTags";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import CheckboxIncludeSendingDocs from "../../components/CheckboxIncludeSendingDocs";
import GenericSelectorItemTagsWithRelation from "../../components/GenericSelectorItemTagsWithRelation";
import EventDatePicker from "../../components/EventDatePicker";
import { Row } from "antd/es/grid";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

type Props = {
    user: any,
    ui: any,
    uiActions: any,
    baseUrl: string,
    data: Object,
    dataActions: Object,
    ActionQueue: any
};
type State = {};

class SalesStoreVisits extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            endOpen: false,

            ApplyRangeLowerCustomerName: '',
            ApplyRangeUpperCustomerName: '',

            StoreVisitsReport: {
                ApplyRangeApplyItemCode: 0,
                ApplyRangeLowerItemCode: 0,
                ApplyRangeUpperItemCode: 0,

                ApplyRangeApplyItemName: 0,
                ApplyRangeLowerItemName: '',
                ApplyRangeUpperItemName: '',

                ApplyRangeApplySupplierCode: 0,
                ApplyRangeLowerSupplierCode: 0,
                ApplyRangeUpperSupplierCode: 0,

                ApplyRangeApplySupplierName: 0,
                ApplyRangeLowerSupplierName: '',
                ApplyRangeUpperSupplierName: '',

                ApplyRangeApplyCustomerCode: 0,
                ApplyRangeLowerCustomerCode: null,
                ApplyRangeUpperCustomerCode: null,

                ApplyRangeApplyCustSum: 0,
                ApplyRangeLowerCustSum: null,
                ApplyRangeUpperCustSum: null,

                ApplyRangeApplyCustCount: 0,
                ApplyRangeLowerCustCount: null,
                ApplyRangeUpperCustCount: null,

                ItemGroupList: '',
                StorageList: '',
                ItemTagList: '',
                CustTagList: '',
                PosList: '',
                LowerSelectionDate: null,
                UpperSelectionDate: null,
                IsActive: '-1',
                TagRelation: '1',
                SortField: 'code',
                ShowPricesWithTax: 1, // bool

                IsDetailed: 0,
                IsGrouped: 0,
                SortCust: '1',

                _withSendingDocs: '1',
                DormantCustFromDate: null,

                reportName: "reports/StoreVisitsReport.pdf",
            }
        };

        this.state.type = "pdf";
        this.state.RequestedReport = "Tags"; // Tags // Suppliers

        if (props.data[TableIDs.genericSelectorPos] === undefined) {
            props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector };
        }
        //if (props.data[TableIDs.genericSelectorEmployees] === undefined) {
        //    props.data[TableIDs.genericSelectorEmployees] = { ...props.data.genericSelector };
        //}
        if (props.data[TableIDs.genericMultiSelectorItemTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorItemTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericMultiSelectorCustomerTags] === undefined) {
            props.data[TableIDs.genericMultiSelectorCustomerTags] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorStorages] === undefined) {
            props.data[TableIDs.genericSelectorStorages] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemGroup] === undefined) { //////
            props.data[TableIDs.genericSelectorItemGroup] = { ...props.data.genericSelector };
        }
        if (props.data[TableIDs.genericSelectorItemTagTypes] === undefined) { //////
            props.data[TableIDs.genericSelectorItemTagTypes] = { ...props.data.genericSelector };
        }

        //if (props.data[TableIDs.genericSelectorSuppliers] === undefined) {
        //    props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector };
        //}

        this.api = {
            selector_pos: "list_pos",
            //selector_employees: "get_employee_list",
            selectorMulti_itemtag: "get_tags_with_types",
            selectorMulti_custtag: "get_customer_tags_list",
            selector_storages: "get_storage_list",
            selector_item_tagTypes: "get_tag_types_with_relation_list",//
            selector_item_group: "get_item_group_list",
            selector_supplier: "get_suppliers",
        }

        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos);
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag);
        this.props.dataActions.genericMultiSelectorRefreshDataset(
            TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_custtag);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages);
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes);//
        this.props.dataActions.genericSelectorRefreshDataset(
            TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group); //
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier);
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.networkFetchData,
            args: [this.props.user.companyCode, this.props.user.token, { script: "data_get", params: { dataObject: "customerList" } }, "customerList", { loading: "", failed: "Error loading", success: "" }, false, { action: null, args: [] }, null
            ]
        });
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.loadData();
    }

    setReportParameters(report: string, params: Object) {
        let newState = Jax.mutate(this.state || {}, report, params);
        this.setState(newState);
    }

    generateReport(report: string) {
        let params = Jax.get(this.state || {}, report, null);
        if (params !== null && params.reportName) {
            window.console.log("Generating report...");
            // params = this.getFullSelector(params);
            params = {
                ...this.getFullSelector(params),
                reportName: params.IsDetailed ? "reports/StoreVisitsReportDetailed." + params.reportName.split(".")[1] : params.reportName,
                REPORT_LOCALE: this.getText(101)
            }

            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
        }
    }

    getFullSelector = (params) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let fullStoragesList = this.props.data[TableIDs.genericSelectorStorages].dataset.map((obj) => obj['code']).join(',');
        if (params.PosList === '') params = { ...params, PosList: fullPosList }
        if (params.StorageList === '') params = { ...params, StorageList: fullStoragesList }
        return params;
    }

    componentDidUpdate(prevProps) {
        document.title = "Cash On Tab - " + this.getText(14171);
        let posList = this.props.data[TableIDs.genericSelectorPos];
        let storagesList = this.props.data[TableIDs.genericSelectorStorages];

        if (
            posList && posList.dataset[0]
            &&
            storagesList && storagesList.dataset[0]
            && !this.state.loading
        ) {
            this.setState({ loading: true })
        }

        if (prevProps.ui.favoriteParams !== this.props.ui.favoriteParams) {
            this.setReportParameters("StoreVisitsReport", this.props.ui.favoriteParams)
        }

    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        //});
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorItemTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_itemtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericMultiSelectorRefreshDataset,
            args: [TableIDs.genericMultiSelectorCustomerTags, this.props.user.companyCode, this.props.user.token, this.api.selectorMulti_custtag]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemTagTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_item_tagTypes]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorItemGroup, this.props.user.companyCode, this.props.user.token, this.api.selector_item_group]
        });
        //this.props.ActionQueue.addToQueue({
        //    action: this.props.dataActions.genericSelectorRefreshDataset,
        //    args: [TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector_supplier]
        //});
    }
    disabledStartDate = (FromDate) => {
        const ToDate = this.state.StoreVisitsReport.mLowerSelectionDate;
        if (!FromDate || !ToDate) {
            return false;
        }
        return FromDate.valueOf() > ToDate.valueOf();
    }

    disabledEndDate = (ToDate) => {
        const FromDate = this.state.StoreVisitsReport.UpperSelectionDate;
        if (!ToDate || !FromDate) {
            return false;
        }
        return ToDate.valueOf() <= FromDate.valueOf();
    }


    onChange = (field, value) => {
        this.setReportParameters("StoreVisitsReport", { [field]: value })
        this.setState({ [field]: value, });
    }

    onStartChange = (value) => {
        this.onChange('LowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('LowerSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('LowerSelectionTime', value ? value.format("HH:mm") : null);
    }

    onEndChange = (value) => {
        this.onChange('UpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("hh:mm:ss") : null);
        // this.onChange('UpperSelectionDate', value ? value.format("D/M/YY") : null);
        // this.onChange('UpperSelectionTime', value ? value.format("HH:mm") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/
    createCheckBoxSelector = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("StoreVisitsReport", { [field]: data });
                    else
                        this.setReportParameters("StoreVisitsReport", { [field]: '' });
                }} />);
    }

    createCheckBoxLabel = (field, label, content) => {
        let checked = this.state.StoreVisitsReport[field] == 1;
        let onChange = (value) => this.setReportParameters("StoreVisitsReport", { [field]: value.target.checked ? 1 : 0 })
        return (<FormItem>
            <Checkbox checked={checked} onChange={onChange}>{this.getText(label)}</Checkbox>
            {checked ? content : ""}
        </FormItem>)
    }

    createInput = (field) => {
        return (<Input
            value={this.state.StoreVisitsReport[field]}
            onChange={(value) => this.setReportParameters("StoreVisitsReport", { [field]: value.target.value })}
            style={{ marginLeft: '4px' }} />)
    }

    createInputNumber = (field) => {
        return (<InputNumber
            value={this.state.StoreVisitsReport[field]}
            onChange={(value) => this.setReportParameters("StoreVisitsReport", { [field]: value })}
            style={{ marginLeft: '4px' }} />)
    }


    render() {
        const { StoreVisitsReport, endOpen } = this.state;
        const { LowerSelectionDate, LowerSelectionTime,
            UpperSelectionDate, UpperSelectionTime } = StoreVisitsReport;

        const divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
        };

        //const { StoreVisitsReport } = this.state;
        //const isMinPercShownStyle = { display: (StoreVisitsReport.RequestedReport === 5 || StoreVisitsReport.RequestedReport === 6) ? 'block' : 'none' }

        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let listItemTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorItemTags + ".dataset", []).map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listCustTags = Jax.get(this.props.data, TableIDs.genericMultiSelectorCustomerTags + ".dataset", []).map(
            (node: TagObj, index) => {
                return (
                    <OptGroup label={node.type.text} key={"type_" + node.type.id}>
                        {node.tags.map((item: string, index) => {
                            return (<Option key={"tag_" + item.id} value={item.id} valuefilter={item.text}>{item.text}</Option>)
                        })}
                    </OptGroup>
                )
            }
        )

        let listStorage = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['code'] + '-' + obj['name']}</Option>)
        });

        let customerPickerLabel = this.getText(12121);

        let custValLower = (this.state.StoreVisitsReport.ApplyRangeLowerCustomerCode) ?
            this.state.StoreVisitsReport.ApplyRangeLowerCustomerCode + " - " + this.state./*StoreVisitsReport.*/ApplyRangeLowerCustomerName : customerPickerLabel;
        let custValUpper = (this.state.StoreVisitsReport.ApplyRangeUpperCustomerCode) ?
            this.state.StoreVisitsReport.ApplyRangeUpperCustomerCode + " - " + this.state./*StoreVisitsReport.*/ApplyRangeUpperCustomerName : customerPickerLabel;

        let mobile = window.innerWidth > 600 ? false : true;

        let params = { ...this.state.StoreVisitsReport }
        params = {
            ...params,
            reportName: params.IsDetailed ? "reports/StoreVisitsReportDetailed." + params.reportName.split(".")[1] : params.reportName,
            ApplyRangeLowerCustomerCode: (params.ApplyRangeLowerCustomerCode) ? encodeURIComponent(params.ApplyRangeLowerCustomerCode) : null,
            ApplyRangeUpperCustomerName: (params.ApplyRangeLowerCustomerCode) ? encodeURIComponent(params.ApplyRangeLowerCustomerCode) : null
        }


        return (
            <div style={divStyle} >
                <Card title={(<PageToolbar title={this.getText(14172)} showsearch={false} actionButtons={[]} />)}
                    bordered={true} style={{ width: mobile ? '95%' : '80%' }}
                    bodyStyle={{ padding: 16 }}
                    headStyle={{ paddingLeft: 8, paddingRight: 8, marginTop: 4, height: 56 }}>


                    <Form className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: mobile ? '1' : '3', paddingTop: mobile ? 50 : 0 }}
                        onSubmit={this.handleSaveChanges}
                    >

                        {this.createCheckBoxLabel("IsDetailed", 14173, this.createCheckBoxLabel("IsGrouped", 12095, ""))}

                        <FormItem label={this.getText(12096)}>
                            <GenericSelectorPosStorages  {...this.props} multi
                                onChange={(value, typeList) => this.setReportParameters("StoreVisitsReport", { PosList: value.join(','), StorageList: typeList.join(',') })}
                                value={(this.state.StoreVisitsReport.PosList === '') ? [] : this.state.StoreVisitsReport.PosList.split(',')}
                            />
                            {/* {this.createCheckBoxSelector('PosList', Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"PosList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("StoreVisitsReport", { PosList: value.join(',') })}
                                value={(this.state.StoreVisitsReport.PosList === '') ? [] : this.state.StoreVisitsReport.PosList.split(',')}
                            >
                                {listPos}
                            </Select> */}
                        </FormItem>

                        {/* <FormItem label={this.getText(12097)}>
                            {this.createCheckBoxSelector('StorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"StorageList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                //placeholder="Please select"
                                //className={""}
                                //defaultValue={1}
                                onChange={(value) => this.setReportParameters("StoreVisitsReport", { StorageList: value.join(',') })}
                                value={(this.state.StoreVisitsReport.StorageList === '') ? [] : this.state.StoreVisitsReport.StorageList.split(',')}
                            >
                                {listStorage}
                            </Select>
                        </FormItem> */}
                        <FormItem label={this.getText(12098)}>
                            {/* <GenericSelectorItemTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("StoreVisitsReport", { ItemTagList: value.join(',') })}
                                value={(this.state.StoreVisitsReport.ItemTagList === '') ? [] : this.state.StoreVisitsReport.ItemTagList.split(',')}
                            />
                            <TagRelationRadio showed={StoreVisitsReport.ItemTagList}
                                checked={StoreVisitsReport.TagRelation == 1}
                                onChange={(value) => this.setReportParameters("StoreVisitsReport", { TagRelation: (value === true) ? 1 : 0 })}
                            /> */}
                            <GenericSelectorItemTagsWithRelation {...this.props} notApi
                                value={this.state.StoreVisitsReport.ItemTagList}
                                onChange={(value) => { this.setReportParameters("StoreVisitsReport", { ItemTagList: value }) }}
                                tagRelationValue={this.state.StoreVisitsReport.TagRelation}
                                tagRelationOnChange={(value) => {
                                    let ItemTagList = this.state.StoreVisitsReport.ItemTagList;
                                    let from = this.state.StoreVisitsReport.TagRelation;
                                    let to = value.target.value;
                                    let oldJson = ItemTagList.indexOf("{") > -1 ? JSON.parse(ItemTagList) : {}
                                    if (from == "2") ItemTagList = oldJson.tagList1 ?? ""
                                    if (to == "2") ItemTagList = JSON.stringify({ tagList1: ItemTagList, tagList2: "", tagRelation1: "1", tagRelation2: "1", betwentRelation: "1" })
                                    this.setReportParameters("StoreVisitsReport", { TagRelation: to, ItemTagList })
                                }}
                            />
                        </FormItem>

                        <FormItem label={this.getText(14169)} >
                            <GenericSelectorCustTags {...this.props} multi
                                onChange={(value) => this.setReportParameters("StoreVisitsReport", { CustTagList: value.sort().join(',') })}
                                value={(this.state.StoreVisitsReport.CustTagList === '') ? [] : this.state.StoreVisitsReport.CustTagList.split(',')}
                            />
                        </FormItem>
                        <Row>
                            <Col span={4}>
                                <FormItem>
                                    <EventDatePicker {...this.props}
                                        onSelectDate={(from, to) => {
                                            this.setState({ LowerSelectionDate: from, UpperSelectionDate: to }, () => {
                                                this.setReportParameters("StoreVisitsReport", { LowerSelectionDate: from, UpperSelectionDate: to })
                                            })
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={20}>
                                <FormItem label={this.getText(12099)} /*LowerSelectionDate - LowerSEleCtionTime*/>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, null, StoreVisitsReport.UpperSelectionDate)}
                                        // disabledDate={this.disabledStartDate}
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        value={StoreVisitsReport.LowerSelectionDate ? moment(StoreVisitsReport.LowerSelectionDate, "YYYY-MM-DD") : null}
                                        placeholder={this.getText(12114)}
                                        onChange={this.onStartChange}
                                    />

                                </FormItem>

                                <FormItem label={this.getText(12100)} /*style={isEndDateShownStyle}*/>
                                    <DatePicker
                                        disabledDate={d => disabledDatePicker(d, StoreVisitsReport.LowerSelectionDate, null)}
                                        // disabledDate={this.disabledEndDate}
                                        format="DD-MM-YYYY"//"YYYY-MM-DD"
                                        placeholder={this.getText(12115)}
                                        value={StoreVisitsReport.UpperSelectionDate ? moment(StoreVisitsReport.UpperSelectionDate, "YYYY-MM-DD") : null}
                                        onChange={this.onEndChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <FormItem label={this.getText(17445)} /*style={isEndDateShownStyle}*/>
                            <DatePicker
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                value={StoreVisitsReport.DormantCustFromDate ? moment(StoreVisitsReport.DormantCustFromDate, "YYYY-MM-DD") : null}
                                onChange={e => { this.setReportParameters("StoreVisitsReport", { DormantCustFromDate: e ? e.format("YYYY-MM-DD") : null }) }}
                            />
                        </FormItem>

                        <FormItem>
                            <CheckboxIncludeSendingDocs
                                value={this.state.StoreVisitsReport._withSendingDocs}
                                onChange={e => { this.setReportParameters("StoreVisitsReport", { _withSendingDocs: e }) }}
                            />
                        </FormItem>


                        {this.createCheckBoxLabel("ApplyRangeApplySupplierCode", 12101, [
                            <br />,
                            this.createInputNumber("ApplyRangeLowerSupplierCode"),
                            this.createInputNumber("ApplyRangeUpperSupplierCode"),
                        ])}

                        {this.createCheckBoxLabel("ApplyRangeApplySupplierName", 12102, [
                            this.createInput("ApplyRangeLowerSupplierName"),
                            this.createInput("ApplyRangeUpperSupplierName"),
                        ])}

                        {this.createCheckBoxLabel("ApplyRangeApplyCustomerCode", 12103, [
                            <CustomerPicker
                                defaultCustomerId={(this.state.StoreVisitsReport.ApplyRangeLowerCustomerCode) ? this.state.StoreVisitsReport.ApplyRangeLowerCustomerCode : null}
                                onChange={(value) => {
                                    //console.log("bbbbb ", value);
                                    this.setReportParameters("StoreVisitsReport", {
                                        ApplyRangeLowerCustomerCode: (value.customerId) ? parseInt(value.customerId, 10) : null,
                                        ApplyRangeLowerCustomerName: encodeURIComponent(value.customerName)
                                    })
                                    this.setState({ ApplyRangeLowerCustomerName: value.customerName })
                                }}
                                label={custValLower}
                                //label={custValLower}
                                data={(this.props.data.customerList) ? this.props.data.customerList.data : []}
                                showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                                hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
                            />,
                            <CustomerPicker
                                defaultCustomerId={(this.state.StoreVisitsReport.ApplyRangeUpperCustomerCode) ? this.state.StoreVisitsReport.ApplyRangeUpperCustomerCode : null}
                                onChange={(value) => {
                                    //console.log("bbbbb ", value);
                                    this.setReportParameters("StoreVisitsReport", {
                                        ApplyRangeUpperCustomerCode: (value.customerId) ? parseInt(value.customerId, 10) : null,
                                        ApplyRangeUpperCustomerName: encodeURIComponent(value.customerName)
                                    })
                                    this.setState({ ApplyRangeUpperCustomerName: value.customerName })
                                }}
                                label={custValUpper}
                                //label={custValUpper}
                                data={(this.props.data.customerList) ? this.props.data.customerList.data : []}
                                showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                                hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
                            />
                        ])}

                        {this.createCheckBoxLabel("ApplyRangeApplyCustSum", 14167, [
                            <br />,
                            this.createInputNumber("ApplyRangeLowerCustSum"),
                            this.createInputNumber("ApplyRangeUpperCustSum"),
                        ])}

                        {this.createCheckBoxLabel("ApplyRangeApplyCustCount", 14168, [
                            <br />,
                            this.createInputNumber("ApplyRangeLowerCustCount"),
                            this.createInputNumber("ApplyRangeUpperCustCount"),
                        ])}

                        <FormItem label={this.getText(12104)}>
                            <Select key={"IsActive"} style={{ width: 250 }}
                                value={StoreVisitsReport.IsActive}
                                onChange={(value) => { this.setReportParameters("StoreVisitsReport", { IsActive: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'-1'}>-</Option>
                                <Option key={2} value={'1'}>{this.getText(12116)}</Option>
                                <Option key={3} value={'0'}>{this.getText(12117)}</Option>
                            </Select>

                        </FormItem>
                        <FormItem label={this.getText(12105)}>
                            <Select key={"SortField"} style={{ width: 250 }}
                                // defaultValue={'code'}
                                value={StoreVisitsReport.SortField}
                                onChange={(value) => { this.setReportParameters("StoreVisitsReport", { SortField: value }); }}
                            //value={}
                            >
                                <Option key={1} value={'code'}>{this.getText(12118)}</Option>
                                <Option key={2} value={'name'}>{this.getText(12119)}</Option>
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(14162)}>
                            <Select
                                key={"SortCust"}
                                style={{ width: 250 }}
                                onChange={(value) => { this.setReportParameters("StoreVisitsReport", { SortCust: value }) }}
                                value={this.state.StoreVisitsReport.SortCust}>
                                <Option key={1} value={'1'}>{this.getText(14163)}</Option>
                                <Option key={2} value={'2'}>{this.getText(14164)}</Option>
                                <Option key={3} value={'3'}>{this.getText(14165)}</Option>
                                <Option key={4} value={'4'}>{this.getText(14166)}</Option>
                            </Select>
                        </FormItem>

                        <FormItem label={this.getText(12106)}>
                            <Switch checkedChildren={this.getText(12116)} unCheckedChildren={this.getText(12117)}
                                checked={StoreVisitsReport.ShowPricesWithTax == 1}
                                onChange={(value) => this.setReportParameters("StoreVisitsReport", { ShowPricesWithTax: (value === true) ? 1 : 0 })} />
                        </FormItem>

                        <ReportTypeButton
                            {...this.props}
                            params={params}
                            storagesField={'StorageList'}
                            tagsField={'ItemTagList'}
                            posField={'PosList'}
                            datesToFavorites={[
                                { field: 'LowerSelectionDate', label: this.getText(12099) },
                                { field: 'UpperSelectionDate', label: this.getText(12100) },
                                { field: 'DormantCustFromDate', label: this.getText(17445) },
                            ]}
                            encodeURIFields={this.state.StoreVisitsReport.TagRelation == '2' ? ['ItemTagList'] : null}
                            xlsReportName={params.reportName.split(".")[0] + 'Excel.xls'}
                            favoriteSave
                        />

                    </Form>
                </Card>
            </div>
        );
    }
}
export default SalesStoreVisits;
